import {
    CONTENT_TOOL_SET_TYPE_REQUESTED,
    CONTENT_USER_FEEDBACK_REQUESTED,
    CONTENT_CONTENT_CARD_COPY_REQUESTED,
    CONTENT_TOOL_CREATE_REQUESTED,
    CONTENT_TOOL_GET_REQUESTED,
    CONTENT_CONTEXT_FETCH_REQUESTED,
    CONTENT_INTENTION_FETCH_REQUESTED,
    CONTENT_TECHNIQUE_FETCH_REQUESTED,
    CONTENT_CATEGORY_FETCH_REQUESTED,
    CONTENT_CATEGORY_HEADER_FETCH_REQUESTED,
    CONTENT_PROBLEM_FETCH_REQUESTED,
    CONTENT_SKILL_FETCH_REQUESTED,
    CONTENT_FOCUS_FETCH_REQUESTED,
    CONTENT_TOOL_TAG_FOCUS_ADD_REQUESTED,
    CONTENT_TOOL_TAG_FOCUS_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_PROBLEM_ADD_REQUESTED,
    CONTENT_TOOL_TAG_PROBLEM_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_SKILL_ADD_REQUESTED,
    CONTENT_TOOL_TAG_SKILL_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_INTENTION_ADD_REQUESTED,
    CONTENT_TOOL_TAG_INTENTION_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CONTEXT_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CONTEXT_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED,
    CONTENT_TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED,
    CONTENT_TOOL_FETCH_REQUESTED,
    CONTENT_TOOL_DELETE_REQUESTED,
    CONTENT_TOOL_EDIT_REQUESTED,
    CONTENT_GOALS_REQUESTED,
    CONTENT_GOAL_HEADERS_REQUESTED,
    CONTENT_GOAL_HEADER_CREATE_REQUESTED,
    CONTENT_GOAL_HEADER_DELETE_REQUESTED,
    CONTENT_GOAL_HEADER_LINK_REQUESTED,
    CONTENT_GOAL_HEADER_UNLINK_REQUESTED,
    CONTENT_GOAL_JOURNIES_REQUESTED,
    CONTENT_GOAL_CREATE_REQUESTED,
    CONTENT_JOURNEY_REQUESTED,
    CONTENT_GOAL_EXERCISES_REQUESTED,
    CONTENT_GENERAL_EXERCISES_REQUESTED,
    CONTENT_EXERCISE_REQUESTED,
    CONTENT_JOURNEY_CREATE_REQUESTED,
    CONTENT_JOURNEY_DELETE_REQUESTED,
    CONTENT_JOURNEY_EDIT_REQUESTED,
    CONTENT_JOURNEY_GOAL_EDIT_REQUESTED,
    CONTENT_EXERCISE_CREATE_REQUESTED,
    CONTENT_EXERCISE_EDIT_REQUESTED,
    CONTENT_EXERCISE_DELETE_REQUESTED,
    CONTENT_EXERCISE_MOVE_REQESTED,
    CONTENT_EXERCISE_CONTENT_REQUESTED,
    CONTENT_CONTENT_CARD_CREATE_REQUESTED,
    CONTENT_CONTENT_CARD_DELETE_REQUESTED,
    CONTENT_CONTENT_CARD_COMPLETION_EDIT_REQUESTED,
    CONTENT_CONTENT_CARD_REFERENCE_EDIT_REQUESTED,
    CONTENT_CONTENT_CAPTION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_INFORMATION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TIMER_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_AUDIO_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_VIDEO_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TASK_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_COMPLETE_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED,
    CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_REFLECTION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_SUMMARY_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_EDIT_REQUESTED,
    CONTENT_TAG_CREATE_REQUESTED,
    CONTENT_TAG_DELETE_REQUESTED,
    CONTENT_CONTENT_READ_CARD_EDIT_REQUESTED,
    CONTENT_GOAL_PUBLISH_REQUESTED,
    CONTENT_JOURNEY_PUBLISH_REQUESTED,
    CONTENT_TOOL_PUBLISH_REQUESTED,
    CONTENT_EXERCISE_PUBLISH_REQUESTED,
    CONTENT_TOOL_ARCHIVE_REQUESTED,
    CONTENT_GOAL_LOCK_REQUESTED,
    CONTENT_JOURNEY_LOCK_REQUESTED,
    CONTENT_EXERCISE_LOCK_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_CARD_MOVE_REQUESTED,
    CONTENT_EXERCISE_ACTION_EXERCISE_LINK_REQUESTED,
    CONTENT_EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED,
    CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED,
    CONTENT_TOOL_TAG_CREATE_REQUESTED,
    CONTENT_TOOL_TAG_DELETE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED,
    CONTENT_TECHNIQUE_TAG_ENABLE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_REMOVE_RULE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_ADD_RULE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_CREATE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_DELETE_REQUESTED,
    CONTENT_LINK_ADD_REQUESTED,
    CONTENT_LINK_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_AFFECT_ADD_REQUESTED,
    CONTENT_TOOL_TAG_AFFECT_REMOVE_REQUESTED,
    CONTENT_QUOTE_CREATE_REQUESTED,
    CONTENT_QUOTE_DELETE_REQUESTED,
    CONTENT_QUOTE_GET_REQUESTED,
    CONTENT_QUOTE_EDIT_REQUESTED,
    CONTENT_QUOTE_FETCH_REQUESTED,
    CONTENT_QUOTE_ARCHIVE_REQUESTED,
    CONTENT_QUOTE_PUBLISH_REQUESTED,
    CONTENT_GENERIC_TAG_CREATE_REQUESTED,
    CONTENT_GENERIC_TAG_DELETE_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED,
    CONTENT_SERIES_TOOLS_ADD_REQUESTED,
    CONTENT_SERIES_TOOLS_REMOVE_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_EDIT_REQUESTED,
    CONTENT_DIMENSION_TAG_MODULE_ADD_REQUESTED,
    CONTENT_DIMENSION_TAG_MODULE_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_KEYWORD_ADD_REQUESTED,
    CONTENT_TOOL_TAG_KEYWORD_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_TAG_ADD_REQUESTED,
    CONTENT_TOOL_TAG_TAG_REMOVE_REQUESTED
} from '../constants/content';

// Action Creators

export function addContentLink(tool_id, lesson_id, reason, completion_handler) {
    return {
        type: CONTENT_LINK_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            lesson_id: lesson_id,
            reason: reason,
            completion_handler: completion_handler
        }
    };
}

export function removeContentLink(tool_id, lesson_id, completion_handler) {
    return {
        type: CONTENT_LINK_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            lesson_id: lesson_id,
            completion_handler: completion_handler
        }
    };
}

export function createContentToolTag(tool_id, params = {}) {
    return {
        type: CONTENT_TOOL_TAG_CREATE_REQUESTED,
        payload: {
            tool_id: tool_id,
            params: params
        }
    };
}

export function deleteContentToolTag(tool_id, tag_id) {
    return {
        type: CONTENT_TOOL_TAG_DELETE_REQUESTED,
        payload: {
            tool_id: tool_id,
            tag_id: tag_id
        }
    };
}

export function createContentGenericTag(tool_id, params = {}) {
    return {
        type: CONTENT_GENERIC_TAG_CREATE_REQUESTED,
        payload: {
            tool_id: tool_id,
            params: params
        }
    };
}

export function deleteContentGenericTag(tool_id, tag_id, is_tool = true) {
    return {
        type: CONTENT_GENERIC_TAG_DELETE_REQUESTED,
        payload: {
            tool_id: tool_id,
            tag_id: tag_id,
            is_tool
        }
    };
}

export function setContentToolType(tool_id, tool_type) {
    return {
        type: CONTENT_TOOL_SET_TYPE_REQUESTED,
        payload: {
            tool_id: tool_id,
            tool_type: tool_type
        }
    };
}

export function fetchContentUserFeedback() {
    return {
        type: CONTENT_USER_FEEDBACK_REQUESTED
    };
}

export function createContentTool(params) {
    return {
        type: CONTENT_TOOL_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function deleteContentTool(tool_id) {
    return {
        type: CONTENT_TOOL_DELETE_REQUESTED,
        payload: {
            tool_id: tool_id
        }
    };
}

export function getContentTool(tool_id) {
    return {
        type: CONTENT_TOOL_GET_REQUESTED,
        payload: {
            tool_id: tool_id
        }
    };
}

export function updateContentTool(tool_id, params) {
    return {
        type: CONTENT_TOOL_EDIT_REQUESTED,
        payload: {
            tool_id: tool_id,
            params: params
        }
    };
}

// quotes
export function createContentQuote(params) {
    return {
        type: CONTENT_QUOTE_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function deleteContentQuote(quote_id) {
    return {
        type: CONTENT_QUOTE_DELETE_REQUESTED,
        payload: {
            quote_id: quote_id
        }
    };
}

export function getContentQuote(quote_id) {
    return {
        type: CONTENT_QUOTE_GET_REQUESTED,
        payload: {
            quote_id: quote_id
        }
    };
}

export function updateContentQuote(quote_id, params) {
    return {
        type: CONTENT_QUOTE_EDIT_REQUESTED,
        payload: {
            quote_id: quote_id,
            params: params
        }
    };
}

export function fetchContentQuotes() {
    return {
        type: CONTENT_QUOTE_FETCH_REQUESTED,
        payload: {}
    };
}

export function setContentQuotePublish(quote_id, has_published) {
    return {
        type: CONTENT_QUOTE_PUBLISH_REQUESTED,
        payload: {
            quote_id: quote_id,
            has_published: has_published
        }
    };
}

export function setContentQuoteArchive(quote_id, is_archived) {
    return {
        type: CONTENT_QUOTE_ARCHIVE_REQUESTED,
        payload: {
            quote_id: quote_id,
            is_archived: is_archived
        }
    };
}

export function fetchContentContexts() {
    return { type: CONTENT_CONTEXT_FETCH_REQUESTED };
}

export function fetchContentTechniques() {
    return { type: CONTENT_TECHNIQUE_FETCH_REQUESTED };
}

export function fetchContentCategories() {
    return { type: CONTENT_CATEGORY_FETCH_REQUESTED };
}

export function fetchContentCategoryHeaders() {
    return { type: CONTENT_CATEGORY_HEADER_FETCH_REQUESTED };
}

export function fetchContentIntentions() {
    return { type: CONTENT_INTENTION_FETCH_REQUESTED };
}

export function fetchContentProblems() {
    return { type: CONTENT_PROBLEM_FETCH_REQUESTED };
}

export function fetchContentSkills() {
    return { type: CONTENT_SKILL_FETCH_REQUESTED };
}

export function fetchContentTools(version = 1) {
    return {
        type: CONTENT_TOOL_FETCH_REQUESTED,
        payload: {
            version: version
        }
    };
}

export function fetchContentMoments(version = 4) {
    return {
        type: CONTENT_TOOL_FETCH_REQUESTED,
        payload: {
            version: version
        }
    };
}

export function fetchContentFocuses() {
    return {
        type: CONTENT_FOCUS_FETCH_REQUESTED
    };
}

export function addContentToolTagFocus(tool_id, focus_id) {
    return {
        type: CONTENT_TOOL_TAG_FOCUS_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            focus_id: focus_id
        }
    };
}

export function removeContentToolTagFocus(tool_id, focus_id) {
    return {
        type: CONTENT_TOOL_TAG_FOCUS_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            focus_id: focus_id
        }
    };
}

export function addContentToolTagProblem(tool_id, problem_id) {
    return {
        type: CONTENT_TOOL_TAG_PROBLEM_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            problem_id: problem_id
        }
    };
}

export function removeContentToolTagProblem(tool_id, problem_id) {
    return {
        type: CONTENT_TOOL_TAG_PROBLEM_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            problem_id: problem_id
        }
    };
}

export function addContentToolTagSkill(tool_id, skill_id) {
    return {
        type: CONTENT_TOOL_TAG_SKILL_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            skill_id: skill_id
        }
    };
}

export function removeContentToolTagSkill(tool_id, skill_id) {
    return {
        type: CONTENT_TOOL_TAG_SKILL_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            skill_id: skill_id
        }
    };
}

export function addContentToolTagCategory(tool_id, category_id) {
    return {
        type: CONTENT_TOOL_TAG_CATEGORY_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            category_id: category_id
        }
    };
}

export function removeContentToolTagCategory(tool_id, category_id) {
    return {
        type: CONTENT_TOOL_TAG_CATEGORY_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            category_id: category_id
        }
    };
}

export function addContentToolTagCategoryHeader(tool_id, category_header_id) {
    return {
        type: CONTENT_TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            category_header_id: category_header_id
        }
    };
}

export function removeContentToolTagCategoryHeader(tool_id, category_header_id) {
    return {
        type: CONTENT_TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            category_header_id: category_header_id
        }
    };
}

export function addContentToolTagIntention(tool_id, intention_id) {
    return {
        type: CONTENT_TOOL_TAG_INTENTION_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            intention_id: intention_id
        }
    };
}

export function removeContentToolTagIntention(tool_id, intention_id) {
    return {
        type: CONTENT_TOOL_TAG_INTENTION_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            intention_id: intention_id
        }
    };
}

export function addContentToolTagContext(tool_id, context_id) {
    return {
        type: CONTENT_TOOL_TAG_CONTEXT_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            context_id: context_id
        }
    };
}

export function removeContentToolTagContext(tool_id, context_id) {
    return {
        type: CONTENT_TOOL_TAG_CONTEXT_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            context_id: context_id
        }
    };
}

export function addMoodToolAffect(tool_id, affect_id) {
    return {
        type: CONTENT_TOOL_TAG_AFFECT_ADD_REQUESTED,
        payload: {
            tool_id: tool_id,
            affect_id: affect_id
        }
    };
}

export function removeMoodToolAffect(tool_id, affect_id) {
    return {
        type: CONTENT_TOOL_TAG_AFFECT_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            affect_id: affect_id
        }
    };
}

export function replaceContentToolTagTechnique(tool_id, technique_id) {
    return {
        type: CONTENT_TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED,
        payload: {
            tool_id: tool_id,
            technique_id: technique_id
        }
    };
}

export function removeContentToolTagTechnique(tool_id) {
    return {
        type: CONTENT_TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED,
        payload: {
            tool_id: tool_id
        }
    };
}

export function createContentGoal(params) {
    return {
        type: CONTENT_GOAL_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function fetchContentGoals() {
    return { type: CONTENT_GOALS_REQUESTED };
}

export function fetchContentGoalHeaders(goal_id) {
    return {
        type: CONTENT_GOAL_HEADERS_REQUESTED,
        payload: {
            goal_id: goal_id
        }
    };
}

export function createContentGoalHeader(goal_id, params) {
    return {
        type: CONTENT_GOAL_HEADER_CREATE_REQUESTED,
        payload: {
            goal_id: goal_id,
            params
        }
    };
}

export function deleteContentGoalHeader(goal_header_id) {
    return {
        type: CONTENT_GOAL_HEADER_DELETE_REQUESTED,
        payload: {
            goal_header_id: goal_header_id
        }
    };
}

export function linkContentJourneyGoalHeader(goal_header_id, journey_id) {
    return {
        type: CONTENT_GOAL_HEADER_LINK_REQUESTED,
        payload: {
            goal_header_id: goal_header_id,
            journey_id: journey_id
        }
    };
}

export function unlinkContentJourneyGoalHeader(journey_id) {
    return {
        type: CONTENT_GOAL_HEADER_UNLINK_REQUESTED,
        payload: {
            journey_id: journey_id
        }
    };
}

export function fetchContentGoalJournies(goal_id) {
    return {
        type: CONTENT_GOAL_JOURNIES_REQUESTED,
        payload: {
            goal_id: goal_id
        }
    };
}

export function fetchContentGoalExercises(goal_id) {
    return {
        type: CONTENT_GOAL_EXERCISES_REQUESTED,
        payload: {
            goal_id: goal_id
        }
    };
}

export function fetchContentGeneralExercises() {
    return {
        type: CONTENT_GENERAL_EXERCISES_REQUESTED
    };
}

export function createContentExercise(goal_id, journey_id, tool_id, params) {
    return {
        type: CONTENT_EXERCISE_CREATE_REQUESTED,
        payload: {
            goal_id: goal_id,
            journey_id: journey_id,
            tool_id: tool_id,
            params: params
        }
    };
}

export function updateContentExercise(exercise_id, params) {
    return {
        type: CONTENT_EXERCISE_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            params: params
        }
    };
}

export function deleteContentExercise(exercise_id) {
    return {
        type: CONTENT_EXERCISE_DELETE_REQUESTED,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function getContentJourney(journey_id) {
    return {
        type: CONTENT_JOURNEY_REQUESTED,
        payload: {
            journey_id: journey_id
        }
    };
}

export function getContentExercise(exercise_id) {
    return {
        type: CONTENT_EXERCISE_REQUESTED,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function moveContentJourneyExercise(exercise_id, order) {
    return {
        type: CONTENT_EXERCISE_MOVE_REQESTED,
        payload: {
            exercise_id: exercise_id,
            to_order: order
        }
    };
}

export function createContentJourney(goal_id, params) {
    return {
        type: CONTENT_JOURNEY_CREATE_REQUESTED,
        payload: {
            goal_id: goal_id,
            params: params
        }
    };
}

export function updateContentJourney(journey_id, params) {
    return {
        type: CONTENT_JOURNEY_EDIT_REQUESTED,
        payload: {
            journey_id: journey_id,
            params: params
        }
    };
}

export function updateContentJourneyGoal(journey_id, goal_id) {
    return {
        type: CONTENT_JOURNEY_GOAL_EDIT_REQUESTED,
        payload: {
            journey_id: journey_id,
            goal_id: goal_id
        }
    };
}

export function deleteContentJourney(journey_id) {
    return {
        type: CONTENT_JOURNEY_DELETE_REQUESTED,
        payload: {
            journey_id: journey_id
        }
    };
}

export function getContentExerciseContent(exercise_id) {
    return {
        type: CONTENT_EXERCISE_CONTENT_REQUESTED,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function copyContentContentCard(from_exercise_id, to_exercise_id) {
    return {
        type: CONTENT_CONTENT_CARD_COPY_REQUESTED,
        payload: {
            from_exercise_id: from_exercise_id,
            to_exercise_id: to_exercise_id
        }
    };
}

export function createContentContentCard(exercise_id, type) {
    return {
        type: CONTENT_CONTENT_CARD_CREATE_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            type: type
        }
    };
}

export function deleteContentContentCard(exercise_id, index) {
    return {
        type: CONTENT_CONTENT_CARD_DELETE_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index
        }
    };
}

export function moveContentContentCard(exercise_id, from_index, to_index) {
    return {
        type: CONTENT_CONTENT_CARD_MOVE_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            from_index: from_index,
            to_index: to_index
        }
    };
}

export function updateContentContentCardCompletion(exercise_id, index, type, data) {
    return {
        type: CONTENT_CONTENT_CARD_COMPLETION_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            type: type,
            data: data
        }
    };
}

export function updateContentContentCardReference(exercise_id, index, type, value) {
    return {
        type: CONTENT_CONTENT_CARD_REFERENCE_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            type: type,
            value: value
        }
    };
}

export function updateContentContentCaptionCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_CAPTION_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentInformationCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_INFORMATION_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentSummaryCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_SUMMARY_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentReflectionCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_REFLECTION_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentQuestionnaireCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentAssessmentCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentAssessmentResultCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentChecklistCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_CHECKLIST_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentChecklistCardItem(exercise_id, card_index, item_index, params) {
    return {
        type: CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            params: params
        }
    };
}

export function createContentContentChecklistCardItem(exercise_id, card_index, params = {}) {
    return {
        type: CONTENT_CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function deleteContentContentChecklistCardItem(exercise_id, card_index, item_index) {
    return {
        type: CONTENT_CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function updateContentContentAudioCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_AUDIO_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentVideoCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_VIDEO_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentTaskCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_TASK_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentCompleteCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_COMPLETE_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params: params
        }
    };
}

export function updateContentContentTimerCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_TIMER_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentOverviewSessionsCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function updateContentContentToolCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_TOOL_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function addToolContentContentToolCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function removeToolContentContentToolCard(exercise_id, card_index, item_index) {
    return {
        type: CONTENT_CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function updateContentContentQuizCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_QUIZ_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params: params
        }
    };
}

export function createContentContentQuizCardItem(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function updateContentContentQuizCardItem(exercise_id, card_index, item_index, params) {
    return {
        type: CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            params
        }
    };
}

export function deleteContentContentQuizCardItem(exercise_id, card_index, item_index) {
    return {
        type: CONTENT_CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function updateContentContentInputCardItem(exercise_id, card_index, item_index, params) {
    return {
        type: CONTENT_CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            params: params
        }
    };
}

export function createContentContentInputCardItem(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function deleteContentContentInputCardItem(exercise_id, card_index, item_index) {
    return {
        type: CONTENT_CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function updateContentContentQuoteCardItem(exercise_id, card_index, item_index, params) {
    return {
        type: CONTENT_CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            params: params
        }
    };
}

export function createContentContentQuoteCardItem(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function deleteContentContentQuoteCardItem(exercise_id, card_index, item_index) {
    return {
        type: CONTENT_CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function createContentContentDocumentCardItem(exercise_id, index, type, data) {
    return {
        type: CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            type: type,
            data: data
        }
    };
}

export function deleteContentContentDocumentCardItem(exercise_id, index, item_index) {
    return {
        type: CONTENT_CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: index,
            item_index: item_index
        }
    };
}

export function updateContentContentDocumentCardItem(exercise_id, card_index, item_index, type, data) {
    return {
        type: CONTENT_CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            type: type,
            data: data
        }
    };
}

export function updateContentContentReadCard(exercise_id, index, params) {
    return {
        type: CONTENT_CONTENT_READ_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            index: index,
            params
        }
    };
}

export function createContentExerciseTag(exercise_id, name) {
    return {
        type: CONTENT_TAG_CREATE_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            name: name
        }
    };
}

export function createContentJourneyTag(journey_id, name) {
    return {
        type: CONTENT_TAG_CREATE_REQUESTED,
        payload: {
            journey_id: journey_id,
            name: name
        }
    };
}

export function deleteContentTag(tag_id, refresh) {
    var params = { tag_id: tag_id };
    if (refresh.exercise_id != null) params.exercise_id = refresh.exercise_id;
    if (refresh.journey_id != null) params.journey_id = refresh.journey_id;
    return {
        type: CONTENT_TAG_DELETE_REQUESTED,
        payload: params
    };
}

export function setContentGoalPublish(goal_id, has_published) {
    return {
        type: CONTENT_GOAL_PUBLISH_REQUESTED,
        payload: {
            goal_id: goal_id,
            has_published: has_published
        }
    };
}

export function setContentJourneyPublish(journey_id, has_published) {
    return {
        type: CONTENT_JOURNEY_PUBLISH_REQUESTED,
        payload: {
            journey_id: journey_id,
            has_published: has_published
        }
    };
}

export function setContentToolPublish(tool_id, has_published) {
    return {
        type: CONTENT_TOOL_PUBLISH_REQUESTED,
        payload: {
            tool_id: tool_id,
            has_published: has_published
        }
    };
}

export function setContentToolArchive(tool_id, is_archived) {
    return {
        type: CONTENT_TOOL_ARCHIVE_REQUESTED,
        payload: {
            tool_id: tool_id,
            is_archived: is_archived
        }
    };
}

export function setContentExercisePublish(exercise_id, has_published) {
    return {
        type: CONTENT_EXERCISE_PUBLISH_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            has_published: has_published
        }
    };
}

export function setContentGoalLock(goal_id, is_locked) {
    return {
        type: CONTENT_GOAL_LOCK_REQUESTED,
        payload: {
            goal_id: goal_id,
            is_locked: is_locked
        }
    };
}

export function setContentJourneyLock(journey_id, is_locked) {
    return {
        type: CONTENT_JOURNEY_LOCK_REQUESTED,
        payload: {
            journey_id: journey_id,
            is_locked: is_locked
        }
    };
}

export function setContentExerciseLock(exercise_id, is_locked) {
    return {
        type: CONTENT_EXERCISE_LOCK_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            is_locked: is_locked
        }
    };
}

export function linkContentExerciseActionExercise(exercise_id, action_exercise_id) {
    return {
        type: CONTENT_EXERCISE_ACTION_EXERCISE_LINK_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            action_exercise_id: action_exercise_id
        }
    };
}

export function unlinkContentExerciseActionExercise(exercise_id) {
    return {
        type: CONTENT_EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function fetchContentTechniqueTags() {
    return {
        type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED
    };
}

export function enableContentTechniqueTag(technique_id, tag_id, is_enabled, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_ENABLE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            is_enabled: is_enabled,
            completion_handler: completionHandler
        }
    };
}

export function addContentTechniqueTagPushMessage(technique_id, tag_id, language, message, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            language: language,
            message: message,
            completion_handler: completionHandler
        }
    };
}

export function removeContentTechniqueTagPushMessage(technique_id, tag_id, language, index, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            language: language,
            index: index,
            completion_handler: completionHandler
        }
    };
}

export function removeContentTechniqueTagRule(technique_id, tag_id, index, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_REMOVE_RULE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            index: index,
            completion_handler: completionHandler
        }
    };
}

export function addContentTechniqueTagRule(technique_id, tag_id, rule, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_ADD_RULE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            rule: rule,
            completion_handler: completionHandler
        }
    };
}

export function createContentTechniqueTag(technique_id, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_CREATE_REQUESTED,
        payload: {
            technique_id: technique_id,
            completion_handler: completionHandler
        }
    };
}

export function deleteContentTechniqueTag(technique_id, tag_id, completionHandler) {
    return {
        type: CONTENT_TECHNIQUE_TAG_DELETE_REQUESTED,
        payload: {
            technique_id: technique_id,
            tag_id: tag_id,
            completion_handler: completionHandler
        }
    };
}

// series-tool

export function addContentSeriesTools(series_id, tool_id) {
    return {
        type: CONTENT_SERIES_TOOLS_ADD_REQUESTED,
        payload: {
            series_id: series_id,
            tool_id: tool_id
        }
    };
}

export function removeContentSeriesTools(series_id, tool_id) {
    return {
        type: CONTENT_SERIES_TOOLS_REMOVE_REQUESTED,
        payload: {
            series_id: series_id,
            tool_id: tool_id
        }
    };
}

export function createContentGuideConcept(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function updateContentContentGuideCard(exercise_id, card_index, params) {
    return {
        type: CONTENT_CONTENT_GUIDE_CARD_EDIT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            params
        }
    };
}

export function updateContentContentGuideCardConcept(exercise_id, card_index, item_index, params) {
    return {
        type: CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            params
        }
    };
}

export function deleteContentContentGuideCardConcept(exercise_id, card_index, item_index, concept_id) {
    return {
        type: CONTENT_CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index,
            concept_id: concept_id
        }
    };
}

// content-keyword
export function addContentToolKeyword(tool_id, keyword) {
    return {
        type: CONTENT_TOOL_TAG_KEYWORD_ADD_REQUESTED,
        payload: {
            tool_id,
            keyword
        }
    };
}

export function removeContentToolKeyword(tool_id, keyword) {
    return {
        type: CONTENT_TOOL_TAG_KEYWORD_REMOVE_REQUESTED,
        payload: {
            tool_id,
            keyword
        }
    };
}

export function addContentToolTag(tool_id, type, value) {
    return {
        type: CONTENT_TOOL_TAG_TAG_ADD_REQUESTED,
        payload: {
            tool_id,
            type,
            value
        }
    };
}

export function removeContentToolTag(tool_id, type, value) {
    return {
        type: CONTENT_TOOL_TAG_TAG_REMOVE_REQUESTED,
        payload: {
            tool_id,
            type,
            value
        }
    };
}
