import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchContentTools } from '../../../../../redux/actions/content';
import TagList from '../../../../selection/tagList';
import _ from 'lodash';

import './index.css';

interface Props {
    locale: string;
    tool_id?: string;
    lesson_id?: string;
    onClose: () => any;
    onAdd: (any) => any;
}

class AddLinkModal extends React.Component<Props> {
    state = {
        reason: null
    };

    componentDidMount() {
        const { tool_id, lesson_id } = this.props;
        this.setState({
            tool_id: tool_id,
            lesson_id: lesson_id
        });
        this.props.fetchContentTools();
    }

    resetState = () => {
        this.setState({
            tool_id: null,
            lesson_id: null,
            reason: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'reason':
                this.setState({
                    reason: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.tool_id != null) params['tool_id'] = this.state.tool_id;
        if (this.state.lesson_id != null) params['lesson_id'] = this.state.lesson_id;
        if (this.state.reason != null) params['reason'] = this.state.reason;

        this.props.onAdd(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { locale, isOpen, isLoading } = this.props;

        var tools = this.props.content.tools.data.filter(
            (tool) => tool.is_immediate == true && locale == tool.language && tool.is_archived == false
        );
        tools = _.uniqBy(tools, 'id');
        var lessons = this.props.content.tools.data.filter(
            (tool) => tool.is_immediate == false && locale == tool.language && tool.is_archived == false
        );
        lessons = _.uniqBy(lessons, 'id');

        const tool = this.state.tool_id != null ? tools.find((tool) => tool.id == this.state.tool_id) : null;

        const lesson = this.state.lesson_id != null ? lessons.find((tool) => tool.id == this.state.lesson_id) : null;

        return (
            <Modal
                id="edit-tool-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="edit-tool-modal-header">Add Content Link</Modal.Header>
                <Modal.Content id="edit-tool-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div id={'content-picker'}>
                                <div>{'Tool'}</div>
                                <div id={'content-selection'}>
                                    <TagList
                                        source={tools}
                                        onAdd={(content) => {
                                            this.setState({
                                                tool_id: content.id
                                            });
                                        }}
                                    />
                                    {tool != null && <div id={'selected-content'}>{tool.title}</div>}
                                </div>
                            </div>
                            <div id={'content-picker'}>
                                <div>{'Lesson'}</div>
                                <div id={'content-selection'}>
                                    <TagList
                                        source={lessons}
                                        onAdd={(content) => {
                                            this.setState({
                                                lesson_id: content.id
                                            });
                                        }}
                                    />
                                    {lesson != null && <div id={'selected-content'}>{lesson.title}</div>}
                                </div>
                            </div>
                        </Segment>
                        <Segment basic>
                            <Form.Input
                                value={this.state.reason || ''}
                                type="text"
                                name="reason"
                                placeholder="Reason"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-tool-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Link Content
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.data.content
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchContentTools: () => dispatch(fetchContentTools())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLinkModal);
