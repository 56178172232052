import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    locale: string;
    exercise?: object;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class EditExerciseChatModal extends React.Component<Props> {
    state = {
        chat_introduction: null,
        chat_benefit: null,
        chat_takeaway: null,
        chat_actionable: null
    };

    componentDidMount() {
        const { exercise } = this.props;
        if (exercise != null && exercise.chat != null) {
            this.setState({
                chat_introduction: exercise.chat.introduction,
                chat_benefit: exercise.chat.benefit,
                chat_takeaway: exercise.chat.takeaway,
                chat_actionable: exercise.chat.actionable
            });
        }
    }

    resetState = () => {
        this.setState({
            chat_introduction: null,
            chat_benefit: null,
            chat_takeaway: null,
            chat_actionable: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'chat_introduction':
                this.setState({
                    chat_introduction: inputValue
                });
                break;
            case 'chat_benefit':
                this.setState({
                    chat_benefit: inputValue
                });
                break;
            case 'chat_takeaway':
                this.setState({
                    chat_takeaway: inputValue
                });
                break;
            case 'chat_actionable':
                this.setState({
                    chat_actionable: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.chat_introduction != null) params['chat_introduction'] = this.state.chat_introduction;
        if (this.state.chat_benefit != null) params['chat_benefit'] = this.state.chat_benefit;
        if (this.state.chat_takeaway != null) params['chat_takeaway'] = this.state.chat_takeaway;
        if (this.state.chat_actionable != null) params['chat_actionable'] = this.state.chat_actionable;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading, locale } = this.props;
        return (
            <Modal
                id="edit-exercise-chat-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="edit-exercise-chat-modal-header">Edit Chat Conversation</Modal.Header>
                <Modal.Content id="edit-exercise-chat-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.TextArea
                                value={this.state.chat_introduction || ''}
                                rows="2"
                                type="text"
                                name="chat_introduction"
                                placeholder="Introduction (Introducing the session)"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.chat_benefit || ''}
                                rows="2"
                                type="text"
                                name="chat_benefit"
                                placeholder="Benefit (Why should I pay attention)"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.chat_takeaway || ''}
                                rows="2"
                                type="text"
                                name="chat_takeaway"
                                placeholder="Takeaway (What I should keep in mind)"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.chat_actionable || ''}
                                rows="2"
                                type="text"
                                name="chat_actionable"
                                placeholder="Actionable (What are actionable things I can do)"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-exercise-chat-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Chat Conversation
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditExerciseChatModal;
