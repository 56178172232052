/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { connect } from 'react-redux';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';

class EditCustomerOrganizationModal extends React.Component {
    state = {
        name: '',
        nameError: false,
        access_code: '',
        accessCodeError: false,
        name_en: '',
        name_yue: '',
        name_zh: '',
        account_limit: 0,
        is_archived: false
    };

    resetState = () => {
        this.setState({
            name: '',
            nameError: false,
            access_code: '',
            accessCodeError: false,
            name_en: '',
            name_yue: '',
            name_zh: '',
            account_limit: 0,
            is_activated: false
        });
    };

    componentDidMount() {
        const { organization, organization_id, organization_users } = this.props;
        if (!organization || !organization_id) return;
        const { is_loading, data } = organization;

        if (!is_loading && data) {
            if (parseInt(data.id) === parseInt(organization_id)) {
                const organization_users_data = organization_users?.data?.users;
                const organization_users_org_id = organization_users?.data?.organization_id;
                let is_activated = false;
                if (parseInt(organization_id) === parseInt(organization_users_org_id)) {
                    for (const user of organization_users_data) {
                        const { expires_at } = user;
                        if (!expires_at) is_activated = true;
                    }
                }
                this.setState({
                    name: data.name,
                    access_code: data.access_code,
                    account_limit: data.account_limit,
                    is_activated: is_activated,
                    is_archived: data.has_dashboard
                });
            }
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleSubmit = () => {
        const params = {};
        const { name, access_code, name_en, name_yue, name_zh, account_limit } = this.state;
        if (!name) {
            this.setState({
                nameError: true
            });
            return;
        } else {
            this.setState({
                nameError: false
            });
        }

        if (!access_code) {
            this.setState({
                accessCodeError: true
            });
            return;
        } else {
            this.setState({
                accessCodeError: false
            });
        }
        params['name'] = name;
        params['access_code'] = access_code;
        params['account_limit'] = account_limit;
        params['organization_id'] = this.props.organization_id;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    handleArchive = () => {
        this.props.onArchive(this.props.organization_id, this.state.is_archived);
    };

    handleAction = () => {
        if (this.state.is_activated) {
            this.props.onExpire(this.props.organization_id);
        } else {
            this.props.onActivate(this.props.organization_id);
        }
    };

    render() {
        const { isOpen, isLoading } = this.props;
        return (
            <Modal id="create-focus-modal" closeOnEscape={true} closeOnDimmerClick={true} open={isOpen} onClose={this.handleClose}>
                <Modal.Header id="create-focus-modal-header">Edit Account</Modal.Header>
                <Modal.Content id="create-focus-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6} className="grid-item-container-content">
                                    <div className="section-header">
                                        <Card.Header className="property-value">Name *</Card.Header>
                                    </div>

                                    <Form.Input
                                        error={this.state.nameError}
                                        value={this.state.name || ''}
                                        id="name"
                                        type="text"
                                        name="name"
                                        placeholder="Name e.g: Neurum Health"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />

                                    <div className="section-header">
                                        <Card.Header className="property-value">Access Code *</Card.Header>
                                    </div>
                                    <Form.Input
                                        error={this.state.accessCodeError}
                                        value={this.state.access_code || ''}
                                        id="access_code"
                                        type="text"
                                        name="access_code"
                                        placeholder="Access code e.g: carebyNeurum"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />

                                    <div className="section-header">
                                        <Card.Header className="property-value">Account limit</Card.Header>
                                    </div>

                                    <Form.Input
                                        value={this.state.account_limit || ''}
                                        id="account_limit"
                                        type="text"
                                        name="account_limit"
                                        placeholder="Account Limit e.g: 100"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} sm={6} className="grid-item-container-status">
                                    <div>
                                        <div className="section-header">
                                            <Card.Header className="account-status-text">Account Status</Card.Header>
                                        </div>
                                        <div className="section-header">
                                            <Card.Header className="active-status">
                                                {this.state.is_activated ? 'ACTIVE' : 'EXPIRED'}
                                            </Card.Header>
                                        </div>
                                        <Button onClick={this.handleAction} id="btn-action">
                                            {this.state.is_activated ? 'Expire' : 'Activate'}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-organization-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button onClick={this.handleArchive} id="btn-delete">
                        {this.state.is_archived ? 'Restore Account' : 'Delete Account'}
                    </Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Save Edits
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organization: state.data.customer.organization,
        organization_users: state.data.customer.organization_users
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerOrganizationModal);

EditCustomerOrganizationModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCreate: PropTypes.func
};
