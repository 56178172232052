/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';

class CreateCustomerOrganizationModal extends React.Component {
    state = {
        name: '',
        nameError: false,
        access_code: '',
        accessCodeError: false,
        name_en: '',
        name_yue: '',
        name_zh: '',
        account_limit: 0
    };

    resetState = () => {
        this.setState({
            name: '',
            nameError: false,
            access_code: '',
            accessCodeError: false,
            name_en: '',
            name_yue: '',
            name_zh: '',
            account_limit: 0
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleSubmit = () => {
        const params = {};
        const { name, access_code, name_en, name_yue, name_zh, account_limit } = this.state;
        if (!name) {
            this.setState({
                nameError: true
            });
            return;
        } else {
            this.setState({
                nameError: false
            });
        }

        if (!access_code) {
            this.setState({
                accessCodeError: true
            });
            return;
        } else {
            this.setState({
                accessCodeError: false
            });
        }
        params['name'] = name;
        params['name_en'] = name_en;
        params['name_yue'] = name_yue;
        params['name_zh'] = name_zh;
        params['access_code'] = access_code;
        params['account_limit'] = account_limit;
        params['is_public'] = true;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading } = this.props;

        return (
            <Modal
                id="create-focus-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-focus-modal-header">Create New Organization</Modal.Header>
                <Modal.Content id="create-focus-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="section-header">
                                <Card.Header className="property-value">Name *</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <Form.Input
                                        error={this.state.nameError}
                                        value={this.state.name || ''}
                                        id="name"
                                        type="text"
                                        name="name"
                                        placeholder="Name e.g: Neurum Health"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.name_en || ''}
                                        rows="2"
                                        type="text"
                                        name="name_en"
                                        placeholder="Name English"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.name_yue || ''}
                                        rows="2"
                                        type="text"
                                        name="name_yue"
                                        placeholder="Name Cantonese"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.name_zh || ''}
                                        rows="2"
                                        type="text"
                                        name="name_zh"
                                        placeholder="Name Mandarin"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>

                            <div className="section-header">
                                <Card.Header className="property-value">Access Code *</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <Form.Input
                                        error={this.state.accessCodeError}
                                        value={this.state.access_code || ''}
                                        id="access_code"
                                        type="text"
                                        name="access_code"
                                        placeholder="Access code e.g: carebyNeurum"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>

                            <div className="section-header">
                                <Card.Header className="property-value">Account limit</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <Form.Input
                                        value={this.state.account_limit || ''}
                                        id="account_limit"
                                        type="text"
                                        name="account_limit"
                                        placeholder="Account Limit e.g: 100"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-focus-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Create Organization
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateCustomerOrganizationModal;

CreateCustomerOrganizationModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCreate: PropTypes.func
};
