import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    locale: string;
    card?: object;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditChecklistCardModal extends React.Component<Props> {
    state = {
        type: null,
        title: null,
        instruction: null,
        message: null
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                type: card.content.type,
                title: card.content.title,
                instruction: card.content.instruction,
                message: card.content.message
            });
        }
    }

    resetState = () => {
        this.setState({
            type: null,
            title: null,
            instruction: null,
            message: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.type != null) params['type'] = this.state.type;
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.instruction != null) params['instruction'] = this.state.instruction;
        if (this.state.message != null) params['message'] = this.state.message;
        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'instruction':
                this.setState({
                    instruction: inputValue
                });
                break;
            case 'message':
                this.setState({
                    message: inputValue
                });
                break;
        }
    };

    onTypeChanged = (value) => {
        this.setState({ type: value });
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-modal-header">Edit Checklist Card</Modal.Header>
                <Modal.Content id="content-modal-content">
                    <RadioGroup value={this.state.type} onChange={this.onTypeChanged} horizontal>
                        <RadioButton value="checklist">Checklist</RadioButton>
                        <RadioButton value="bubble">Bubble</RadioButton>
                    </RadioGroup>
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.TextArea
                                value={this.state.title || ''}
                                rows={3}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.instruction || ''}
                                rows={3}
                                type="text"
                                name="instruction"
                                placeholder="Instructions"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.message || ''}
                                type="text"
                                name="message"
                                placeholder="Journal Entry Message"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditChecklistCardModal;
