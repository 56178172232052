import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment, Label } from 'semantic-ui-react';

import './index.css';

class EditGuideConceptContentModal extends React.Component {
    state = {
        id: null,
        conceptTitle: null,
        hintTitle: null,
        hintSuggestions: [null]
    };

    componentDidMount() {
        // set initial state
        const { card, itemIndex } = this.props;
        const concept = card.content.concepts[itemIndex];
        if (!!concept) {
            this.setState({
                id: concept.id,
                conceptTitle: concept.title,
                hintTitle: concept.hint?.title,
                hintSuggestions: concept.hint?.suggestions || [null]
            });
        }
    }

    resetState = () => {
        this.setState({
            conceptTitle: null,
            hintTitle: null,
            hintSuggestions: [null]
        });
    };

    checkFieldsValid = () => {
        const { conceptTitle, hintTitle, hintSuggestions } = this.state;
        if (!conceptTitle) {
            return false;
        }
        if (!hintTitle) {
            return false;
        }
        const suggestions = hintSuggestions.filter((suggestion) => !!suggestion);
        if (!suggestions.length > 0) {
            return false;
        }
        return true;
    };

    handleSubmit = () => {
        if (!this.checkFieldsValid()) return;
        var params = {};
        params['concept_id'] = this.state.id;
        params['concept_title'] = this.state.conceptTitle;
        params['hint_title'] = this.state.hintTitle;
        const suggestions = this.state.hintSuggestions.filter((suggestion) => !!suggestion);
        if (suggestions.length > 0) {
            params['suggestions'] = suggestions;
        }
        this.props.onEdit(params);
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleSuggestionChange = (event) => {
        const { name, value } = event.target;
        const updatedSuggestions = [...this.state.hintSuggestions];
        updatedSuggestions[name] = value;
        this.setState({
            hintSuggestions: updatedSuggestions
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    onAddNewSuggestion = () => {
        const updatedSuggestions = [...this.state.hintSuggestions];
        updatedSuggestions.push(null);
        this.setState({
            hintSuggestions: updatedSuggestions
        });
    };

    handleCancelSuggestion = (index) => {
        const updatedSuggestions = [...this.state.hintSuggestions];
        if (index === 0) {
            updatedSuggestions[0] = null;
        } else {
            updatedSuggestions.splice(index, 1);
        }
        this.setState({
            hintSuggestions: updatedSuggestions
        });
    };

    render() {
        return (
            <Modal
                id="content-guide-modal"
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-guide-modal-header">Edit Guide Card Concept</Modal.Header>
                <Modal.Content id="content-guide-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.conceptTitle || ''}
                                type="text"
                                name="conceptTitle"
                                placeholder="Concept title"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.hintTitle || ''}
                                type="text"
                                name="hintTitle"
                                placeholder="Hint title"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <label>Hint suggestions</label>
                                <Button onClick={this.onAddNewSuggestion} color="teal">
                                    Add suggestion
                                </Button>
                            </div>
                            <div className=" field">
                                {this.state.hintSuggestions.map((suggestion, index) => (
                                    <div className="suggestion-item-wrapper" key={index.toString()}>
                                        <Form.Input
                                            value={this.state.hintSuggestions[index] || ''}
                                            type="text"
                                            name={index}
                                            placeholder={'Suggestion ' + (index + 1)}
                                            onChange={this.handleSuggestionChange}
                                            className="suggestion-item-input"
                                        />
                                        <Button
                                            onClick={() => {
                                                this.handleCancelSuggestion(index);
                                            }}
                                            className="suggestion-item-cancel"
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-guide-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update Concept
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditGuideConceptContentModal;
