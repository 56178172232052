import { fork } from 'redux-saga/effects';

import AuthenticationSaga from './authentication';
import ContentSaga from './content';
import AssessmentSaga from './assessment';
import OrganizationSaga from './organization';
import SettingSaga from './setting';
import MoodSaga from './mood';
import HumanCareSaga from './human-care';
import EapSaga from './eap';
import Customer from './customer';

export default function* saga() {
    yield fork(AuthenticationSaga);
    yield fork(ContentSaga);
    yield fork(AssessmentSaga);
    yield fork(OrganizationSaga);
    yield fork(SettingSaga);
    yield fork(MoodSaga);
    yield fork(HumanCareSaga);
    yield fork(EapSaga);
    yield fork(Customer);
}
