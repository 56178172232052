// assets
import { IconQrcode, IconChartInfographic } from '@tabler/icons';

// constant
const icons = { IconQrcode, IconChartInfographic };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const resources = {
    id: 'resources',
    title: 'Resources',
    type: 'group',
    children: [
        {
            id: 'amplitude',
            title: 'Amplitude',
            type: 'item',
            url: 'https://analytics.amplitude.com/neurum/dashboard/kbpf5iz?source=sidebar',
            icon: icons.IconChartInfographic,
            external: true,
            target: false
        },
        {
            id: 'branch',
            title: 'Branch',
            type: 'item',
            url: 'https://dashboard.branch.io/',
            icon: icons.IconQrcode,
            external: true,
            target: false
        }
    ]
};

export default resources;
