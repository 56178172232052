import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onMove: (number) => any;
    onClose: () => any;
}

class MoveCardModal extends React.Component<Props> {
    state = {
        position: null
    };

    resetState = () => {
        this.setState({
            position: null
        });
    };

    handleSubmit = () => {
        if (this.state.position != null) {
            const position = parseInt(this.state.position);
            this.props.onMove(position);
            this.resetState();
        }
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'position':
                this.setState({
                    position: inputValue
                });
                break;
        }
    };

    render() {
        return (
            <Modal
                id="content-move-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-move-modal-header">Move Card</Modal.Header>
                <Modal.Content id="content-move-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input type="text" name="position" placeholder="Position of Card (#)" onChange={this.handleInputChange} />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-move-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button
                        disabled={this.state.position == null || this.state.position.trim() == ''}
                        onClick={this.handleSubmit}
                        color="teal"
                    >
                        Move
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default MoveCardModal;
