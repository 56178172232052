// assets
import { IconDeviceMobile, IconTestPipe2, IconFocus2 } from '@tabler/icons';

// constant
const icons = {
    IconDeviceMobile,
    IconTestPipe2,
    IconFocus2
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const content = {
    id: 'content',
    title: 'Content',
    type: 'group',
    children: [
        {
            id: 'app-content',
            title: 'App Content',
            type: 'item',
            url: '/content/app-content',
            icon: icons.IconDeviceMobile
        },
        {
            id: 'app-assessment',
            title: 'App Assessment',
            type: 'item',
            url: '/content/app-assessment',
            icon: icons.IconTestPipe2
        }
    ]
};

export default content;
