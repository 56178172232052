import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    locale: string;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class CreateTagModalV2 extends React.Component<Props> {
    state = {
        tag_type: null,
        tag_value: null,
        tags: []
    };

    resetState = () => {};

    handleSubmit = () => {
        const { tags = [] } = this.state;
        if (tags.length == 0) return;
        const tag = tags[0];
        this.props.onCreate(tag);
    };

    handleTagItem = () => {
        const { tags = [], tag_type, tag_value } = this.state;

        if (tag_type == null) return;
        else if (tag_value == null) return;

        const newTags = [...tags];
        newTags.push({
            type: tag_type,
            value: tag_value
        });

        this.setState({
            tags: newTags,
            tag_type: null,
            tag_value: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    renderTagOverview() {
        const { tags = [] } = this.state;
        return (
            <div>
                {tags.map((tag, index) => {
                    return (
                        <div class={`ui blue label`}>
                            {tag.type} : {tag.value}
                            <i
                                onClick={() => {
                                    const newTags = [...tags];
                                    newTags.splice(index, 1);
                                    this.setState({
                                        tags: newTags
                                    });
                                }}
                                class="delete icon"
                            ></i>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { locale, isOpen, isLoading } = this.props;
        const { tags = [] } = this.state;
        return (
            <Modal
                id="create-tag-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-tag-modal-header">Create Tool Tag</Modal.Header>
                <Modal.Content id="create-tag-modal-content">
                    <div id={'tag-view-container'}>{this.renderTagOverview()}</div>
                    <Form size="large" loading={isLoading}>
                        <Form.Input
                            value={this.state.tag_type || ''}
                            type="text"
                            name="tag_type"
                            placeholder="Tag Type"
                            onChange={this.handleInputChange}
                        />
                        <Form.Input
                            value={this.state.tag_value || ''}
                            type="text"
                            name="tag_value"
                            placeholder="Tag Value"
                            onChange={this.handleInputChange}
                        />
                        <Button
                            disabled={this.state.tags.length > 0}
                            color="teal"
                            onClick={() => {
                                this.handleTagItem();
                            }}
                        >
                            {'Add Item'}
                        </Button>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-tag-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true || tags.length == 0} onClick={this.handleSubmit} color="teal">
                        Create Tag
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateTagModalV2;
