import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';

import './index.css';
import ContentService from 'service/content';
import { validateContent } from '../validateContent/contentValidator';

interface Props {
    locale: string;
    toolId: Number;
    isLoading: boolean;
    isOpen: boolean;
    showPublish: boolean;
    onClose: () => any;
    onPublish: () => any;
    onEdit: (any) => any;
}

class ValidateContentModal extends React.Component<Props> {
    state = {
        loading: false,
        toolType: '',
        toolId: null,
        hasPublished: false,
        validationResult: {}
    };

    resetState = () => {};

    componentDidMount = async () => {
        const { toolId, locale } = this.props;
        try {
            this.setState({
                loading: true
            });
            const { tool } = await ContentService.getTool(toolId, locale);
            const toolType = tool.type;
            const hasPublished = tool.has_published;
            this.setState({ toolType, hasPublished });
            const validateResult = await validateContent(tool);
            this.setState({
                loading: false,
                validationResult: validateResult
            });
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    };

    handleSubmit = async () => {};

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    isObject = (text) => {
        if (typeof text === 'object' && !Array.isArray(text) && text !== null) {
            return true;
        }
        return false;
    };

    renderObject = (obj) => {
        return (
            <div className="validation-item-object-root">
                {Object.keys(obj).map((key) => {
                    return (
                        <div className="validation-item-object-string">
                            <div className="validation-item-object-string-key">{key}</div>
                            {!!obj[key] && typeof obj[key] === 'object' ? (
                                <div className="validation-item-object-object">{this.renderObject(obj[key])}</div>
                            ) : (
                                obj[key]
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    validatedContentItem = (contentProperty, type = 'series', toolIndex = -1, exerciseIndex = -1) => {
        let item;

        if (type === 'series' || type === 'programme' || type === 'tool') {
            item = this.state.validationResult[contentProperty];
        } else if (type === 'recommendedTool') {
            const recommendedTools = this.state.validationResult.recommendedTools;
            const recommendedTool = recommendedTools[toolIndex];
            item = recommendedTool[contentProperty];
        } else if (type === 'exercise') {
            if (toolIndex > -1) {
                const recommendedTools = this.state.validationResult.recommendedTools;
                const recommendedTool = recommendedTools[toolIndex];
                const exercises = recommendedTool.exercises;
                const exercise = exercises[exerciseIndex];
                item = exercise[contentProperty];
            } else {
                const exercises = this.state.validationResult.exercises;
                const exercise = exercises[exerciseIndex];
                item = exercise[contentProperty];
            }
        }

        if (!item) return;
        const { isValid, text } = item;
        const isTextArray = Array.isArray(text);
        const isTextObject = this.isObject(text);
        return (
            <Card.Header id="validation-item-row">
                <div
                    id="validation-item-type"
                    className={`${
                        exerciseIndex > -1
                            ? 'validation-item-type-exercise'
                            : toolIndex > -1
                            ? 'validation-item-type-tool'
                            : 'validation-item-type-series'
                    }`}
                >
                    {`${type} ${exerciseIndex > -1 ? exerciseIndex + 1 : toolIndex > -1 ? toolIndex + 1 : ''} ${contentProperty}`}&nbsp;
                </div>
                <div id="validation-item-wrapper">
                    {!isTextArray && !isTextObject && <div id="">{text}</div>}
                    {isTextArray && (
                        <div id="validation-item-array-wrapper">
                            {text.map((textItem) => {
                                if (!textItem) {
                                    return <div />;
                                }
                                if (!!textItem.prompt) {
                                    return <div>{textItem.prompt}</div>;
                                } else if (this.isObject(textItem)) {
                                    return this.renderObject(textItem);
                                }
                            })}
                        </div>
                    )}
                    <Icon
                        name={`${isValid ? 'check circle' : 'cancel'}`}
                        className={`validate-icon ${isValid ? 'validate-success' : 'validate-error'}`}
                    />
                </div>
            </Card.Header>
        );
    };

    render() {
        const { isOpen, isLoading } = this.props;
        const { loading, toolType, validationResult } = this.state;
        return (
            <Modal
                id="create-tag-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={isLoading == true || loading} inverted>
                    <Loader active={isLoading == true || loading} />
                </Dimmer>
                <Modal.Header id="create-tag-modal-header">{loading ? 'Validating Content ...' : 'Content Validation Report'}</Modal.Header>
                <Modal.Content id="create-tag-modal-content">
                    <Card.Header id="validation-item-type">Content Type : {toolType}</Card.Header>
                    <br />
                    {!loading && !!this.state.validationResult.isValid && (
                        <div id="validation-item-result" className="green">
                            This Content is valid
                        </div>
                    )}
                    {!loading && !this.state.validationResult.isValid && (
                        <div id="validation-item-result" className="red">
                            This Content is not valid
                        </div>
                    )}
                    {(toolType === 'programme' || toolType === 'tool') && (
                        <>
                            {this.validatedContentItem('title', toolType)}
                            {this.validatedContentItem('description', toolType)}
                            {this.validatedContentItem('emoji', toolType)}
                            {this.validatedContentItem('time', toolType)}
                            {this.validatedContentItem('metadata', toolType)}
                            {this.validatedContentItem('technique', toolType)}
                            {validationResult.exercises?.length > 0 &&
                                validationResult.exercises.map((exercise, exeIndex) => (
                                    <>
                                        {this.validatedContentItem('title', 'exercise', -1, exeIndex)}
                                        {this.validatedContentItem('description', 'exercise', -1, exeIndex)}
                                        {this.validatedContentItem('time', 'exercise', -1, exeIndex)}
                                        {this.validatedContentItem('metadata', 'exercise', -1, exeIndex)}
                                        {this.validatedContentItem('contentType', 'exercise', -1, exeIndex)}
                                    </>
                                ))}
                        </>
                    )}
                    {toolType === 'series' && (
                        <>
                            {this.validatedContentItem('title')}
                            {this.validatedContentItem('description')}
                            {this.validatedContentItem('metadata')}
                            {this.validatedContentItem('time')}
                            {this.validatedContentItem('assessment')}
                            {this.validatedContentItem('assessmentQuestion')}

                            {validationResult.recommendedTools?.length > 0 &&
                                validationResult.recommendedTools.map((recommendedTool, index) => (
                                    <>
                                        {this.validatedContentItem('title', 'recommendedTool', index)}
                                        {this.validatedContentItem('description', 'recommendedTool', index)}
                                        {this.validatedContentItem('emoji', 'recommendedTool', index)}
                                        {this.validatedContentItem('time', 'recommendedTool', index)}
                                        {this.validatedContentItem('metadata', 'recommendedTool', index)}
                                        {this.validatedContentItem('technique', 'recommendedTool', index)}
                                        {recommendedTool.exercises?.length > 0 &&
                                            recommendedTool.exercises.map((exercise, exeIndex) => (
                                                <>
                                                    {this.validatedContentItem('title', 'exercise', index, exeIndex)}
                                                    {this.validatedContentItem('description', 'exercise', index, exeIndex)}
                                                    {this.validatedContentItem('time', 'exercise', index, exeIndex)}
                                                    {this.validatedContentItem('metadata', 'exercise', index, exeIndex)}
                                                    {this.validatedContentItem('contentType', 'exercise', index, exeIndex)}
                                                </>
                                            ))}
                                    </>
                                ))}
                        </>
                    )}
                </Modal.Content>
                <Modal.Actions id="create-tag-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    {this.props.showPublish && (
                        <Button
                            disabled={isLoading == true && loading}
                            onClick={async () => {
                                const { toolId } = this.props;
                                const { hasPublished } = this.state;
                                if (toolId) {
                                    this.props.onPublish(toolId, hasPublished);
                                }
                            }}
                            color="teal"
                        >
                            Publish
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ValidateContentModal;
