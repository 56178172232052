import {
    ORGANIZATIONS_IS_LOADING,
    ORGANIZATIONS_NOT_LOADING,
    ORGANIZATIONS_FETCHED,
    ORGANIZATION_EXEMPTIONS_IS_LOADING,
    ORGANIZATION_EXEMPTIONS_NOT_LOADING,
    ORGANIZATION_EXEMPTIONS_FETCHED
} from '../constants/organization';

const initialState = {
    organizations: {
        isLoading: false,
        data: []
    },
    exemptions: {
        isLoading: false,
        data: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ORGANIZATIONS_IS_LOADING:
            return {
                ...state,
                organizations: {
                    ...state.organizations,
                    isLoading: true
                }
            };
        case ORGANIZATIONS_NOT_LOADING:
            return {
                ...state,
                organizations: {
                    ...state.organizations,
                    isLoading: false
                }
            };
        case ORGANIZATIONS_FETCHED:
            return {
                ...state,
                organizations: {
                    ...state.exemptions,
                    data: action.payload.organizations
                }
            };
        case ORGANIZATION_EXEMPTIONS_IS_LOADING:
            return {
                ...state,
                exemptions: {
                    ...state.exemptions,
                    isLoading: true
                }
            };
        case ORGANIZATION_EXEMPTIONS_NOT_LOADING:
            return {
                ...state,
                exemptions: {
                    ...state.exemptions,
                    isLoading: false
                }
            };
        case ORGANIZATION_EXEMPTIONS_FETCHED:
            return {
                ...state,
                exemptions: {
                    ...state.exemptions,
                    data: action.payload.exemptions
                }
            };
        default:
            break;
    }
    return state;
}
