import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class AssessmentService {
    static getAssessmentSource(source) {
        return axios
            .get(
                Config.api.path + '/v4/assessment/question/source/get',
                {
                    params: {
                        source: source
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getAssessmentDimension(dimension) {
        return axios
            .get(
                Config.api.path + '/v4/assessment/question/dimension/get',
                {
                    params: {
                        dimension: dimension
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to fetch dimension assessment: ' + error);
                return null;
            });
    }

    static getAssessmentDimensionResult(dimension) {
        return axios
            .get(
                Config.api.path + '/v4/assessment/result/dimension/get',
                {
                    params: {
                        dimension: dimension
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to fetch dimension assessment result: ' + error);
                return null;
            });
    }

    static getAssessmentSourceResult(source) {
        return axios
            .get(
                Config.api.path + '/v4/assessment/result/source/get',
                {
                    params: {
                        source: source
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to get source assessment result: ' + error);
                return null;
            });
    }

    static createAssessmentSource(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment source', error);
                return null;
            });
    }

    static editAssessmentSource(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/edit`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to edit assessment source', error);
                return null;
            });
    }

    static deleteAssessmentSource(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to delete assessment source', error);
                return null;
            });
    }

    static publishAssessment(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/publish`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to publish assessment source', error);
                return null;
            });
    }

    static fetchAssessmentSources(source_type) {
        return axios
            .get(
                Config.api.path + `/v5/assessment/source/fetch`,
                {
                    params: {
                        source_type: source_type
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to fetch assessment sources', error);
                return null;
            });
    }

    static getAssessmentSourceByUuid(uuid) {
        return axios
            .get(
                Config.api.path + `/v5/assessment/source/get`,
                {
                    params: {
                        uuid: uuid
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to get assessment source by uuid', error);
                return null;
            });
    }

    static createAssessmentDimension(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension', error);
                return null;
            });
    }

    static editAssessmentDimension(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/edit`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to edit assessment dimension', error);
                return null;
            });
    }

    static deleteAssessmentDimension(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to edit assessment dimension', error);
                return null;
            });
    }

    static getAssessmentDimensionDetail(source_id, dimension_id) {
        return axios
            .get(
                Config.api.path + `/v5/assessment/source/dimension/get`,
                {
                    params: {
                        source_id: source_id,
                        dimension_id: dimension_id
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to get assessment dimension detail', error);
                return null;
            });
    }

    static createAssessmentDimensionModule(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/module/tag`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension category', error);
                return null;
            });
    }

    static removeAssessmentDimensionModule(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/module/remove`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension category', error);
                return null;
            });
    }

    static createAssessmentDimensionCategory(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/category/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension category', error);
                return null;
            });
    }

    static editAssessmentDimensionCategory(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/category/edit`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to edit assessment dimension category', error);
                return null;
            });
    }

    static deleteAssessmentDimensionCategory(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/category/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to edit assessment dimension category', error);
                return null;
            });
    }

    static createAssessmentDimensionQuestion(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/question/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension question', error);
                return null;
            });
    }

    static editAssessmentDimensionQuestion(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/question/edit`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension question', error);
                return null;
            });
    }

    static deleteAssessmentDimensionQuestion(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/question/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension question', error);
                return null;
            });
    }

    static getAssessmentDimensionCategoryDetail(source_id, dimension_id, category_id) {
        return axios
            .get(
                Config.api.path + `/v5/assessment/source/dimension/category/get`,
                {
                    params: {
                        source_id: source_id,
                        dimension_id: dimension_id,
                        category_id
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to get assessment dimension category detail', error);
                return null;
            });
    }

    static createAssessmentDimensionAnswer(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/answer/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to create assessment dimension answer', error);
                return null;
            });
    }

    static addAssessmentDimensionAnswer(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/answer/add`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to add assessment dimension answer', error);
                return null;
            });
    }

    static editAssessmentDimensionAnswer(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/answer/edit`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to add assessment dimension answer', error);
                return null;
            });
    }

    static deleteAssessmentDimensionAnswer(params) {
        return axios
            .post(Config.api.path + `/v5/assessment/source/dimension/answer/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Assessment Service] Failed to add assessment dimension answer', error);
                return null;
            });
    }

    static getAssessmentByName(type, value) {
        return axios
            .get(
                Config.api.path + `/v6/assessment/${type}/get_by_name`,
                {
                    params: {
                        [type]: value
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error(`[Assessment Service] Failed to get assessment ${type} by name`, error);
                return null;
            });
    }

    static asssessmentSync() {
        return axios
            .get(
                Config.api.path + `/v5/assessment/source/sync`,
                {
                    params: {}
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error(`[Assessment Service] Failed to sync`, error);
                return null;
            });
    }
}

export default AssessmentService;
