import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onCreate: (any) => any;
}

class CreateExerciseModal extends React.Component<Props> {
    state = {
        title: null,
        description_short: null,
        description_long: null,
        metadata: null,
        time: null,
        session_type: null,
        framework: null,
        thumbnail_url: null,
        cover_url: null
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'description_short':
                this.setState({
                    description_short: inputValue
                });
                break;
            case 'description_long':
                this.setState({
                    description_long: inputValue
                });
                break;
            case 'metadata':
                this.setState({
                    metadata: inputValue
                });
                break;
            case 'time':
                this.setState({
                    time: inputValue
                });
                break;
            case 'session_type':
                this.setState({
                    session_type: inputValue
                });
                break;
            case 'framework':
                this.setState({
                    framework: inputValue
                });
                break;
            case 'thumbnail_url':
                this.setState({
                    thumbnail_url: inputValue
                });
                break;
            case 'cover_url':
                this.setState({
                    cover_url: inputValue
                });
                break;
        }
    };

    resetState = () => {
        this.setState({
            title: null,
            description_short: null,
            description_long: null,
            metadata: null,
            time: null,
            session_type: null,
            icon_url: null,
            thumbnail_url: null,
            cover_url: null
        });
    };

    handleSubmit = () => {
        if (this.state.title == null) {
            return;
        }

        var params = {};
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.description_short != null) params['description_short'] = this.state.description_short;
        if (this.state.description_long != null) params['description_long'] = this.state.description_long;
        if (this.state.metadata != null) params['metadata'] = this.state.metadata;
        if (this.state.time != null) params['time'] = this.state.time;
        if (this.state.session_type != null) params['session_type'] = this.state.session_type;
        if (this.state.framework != null) params['framework'] = this.state.framework;
        if (this.state.icon_url != null) params['icon_url'] = this.state.icon_url;
        if (this.state.thumbnail_url != null) params['thumbnail_url'] = this.state.thumbnail_url;
        if (this.state.cover_url != null) params['cover_url'] = this.state.cover_url;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading } = this.props;
        return (
            <Modal closable={false} closeOnEscape={false} closeOnDimmerClick={false} open={isOpen} onClose={this.handleClose}>
                <Modal.Header>New Session</Modal.Header>
                <Modal.Content>
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.Input type="text" name="title" placeholder="Title" onChange={this.handleInputChange} />
                            {/* <Form.Input
                        type="text"
                        name="description_short"
                        placeholder="Short Description"
                        onChange={this.handleInputChange}
                    /> */}
                            <Form.TextArea
                                rows="2"
                                type="text"
                                name="description_long"
                                placeholder="Description"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input type="text" name="metadata" placeholder="Metadata" onChange={this.handleInputChange} />
                            <Form.Input type="text" name="time" placeholder="Time (minutes)" onChange={this.handleInputChange} />
                            <Form.Input
                                type="text"
                                name="session_type"
                                placeholder="Session Type (learn | assess | practice)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.framework || ''}
                                type="text"
                                name="framework"
                                placeholder="Framework ( self_grounding | self_reflection | cognition | action | information )"
                                onChange={this.handleInputChange}
                            />
                            {/* <Form.Input
                        type="text"
                        name="icon_url"
                        placeholder="Icon URL (Optional)"
                        onChange={this.handleInputChange}
                    />
                    <Form.Input
                        type="text"
                        name="thumbnail_url"
                        placeholder="Thumbnail URL (Optional)"
                        onChange={this.handleInputChange}
                    /> */}
                            <Form.Input type="text" name="cover_url" placeholder="Cover URL (Optional)" onChange={this.handleInputChange} />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Create Session
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateExerciseModal;
