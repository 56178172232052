import dashboard from './dashboard';
import customer from './customer';
import content from './content';
import humanSupport from './human-support';
import resources from './resources';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, content, humanSupport, resources]
    items: [content, customer, humanSupport, resources]
};

export default menuItems;
