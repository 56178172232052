import {
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED,
    CUSTOMER_ORGANIZATION_ACTIVATE_REQUESTED,
    CUSTOMER_ORGANIZATION_ACTIVATE_USER_REQUESTED,
    CUSTOMER_ORGANIZATION_ARCHIVE_REQUESTED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED,
    CUSTOMER_ORGANIZATION_CREATE_REQUESTED,
    CUSTOMER_ORGANIZATION_EDIT_REQUESTED,
    CUSTOMER_ORGANIZATION_EXPIRE_REQUESTED,
    CUSTOMER_ORGANIZATION_EXPIRE_USER_REQUESTED,
    CUSTOMER_ORGANIZATION_FETCH_REQUESTED,
    CUSTOMER_ORGANIZATION_GET_REQUESTED,
    CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED
} from 'redux/constants/customer';

// Action Creators

export function fetchOrganizationDetail(organization_id) {
    return {
        type: CUSTOMER_ORGANIZATION_GET_REQUESTED,
        payload: {
            organization_id
        }
    };
}

export function fetchOrganizationUsers(organization_id) {
    return {
        type: CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
        payload: {
            organization_id
        }
    };
}

export function fetchOrganizations(show_archive) {
    return {
        type: CUSTOMER_ORGANIZATION_FETCH_REQUESTED,
        payload: {
            show_archive
        }
    };
}

export function createNewOrganization(params) {
    return {
        type: CUSTOMER_ORGANIZATION_CREATE_REQUESTED,
        payload: {
            params
        }
    };
}

export function editOrganization(params) {
    return {
        type: CUSTOMER_ORGANIZATION_EDIT_REQUESTED,
        payload: {
            params
        }
    };
}

export function expireOrganization(organization_id, show_archive = false) {
    return {
        type: CUSTOMER_ORGANIZATION_EXPIRE_REQUESTED,
        payload: {
            organization_id,
            show_archive
        }
    };
}

export function archiveOrganization(organization_id, is_archived) {
    return {
        type: CUSTOMER_ORGANIZATION_ARCHIVE_REQUESTED,
        payload: {
            organization_id,
            is_archived
        }
    };
}

export function activateOrganization(organization_id, show_archive = false) {
    return {
        type: CUSTOMER_ORGANIZATION_ACTIVATE_REQUESTED,
        payload: {
            organization_id,
            show_archive
        }
    };
}

export function expireOrganizationUser(organization_id, user_id) {
    return {
        type: CUSTOMER_ORGANIZATION_EXPIRE_USER_REQUESTED,
        payload: {
            organization_id,
            user_id
        }
    };
}

export function activateOrganizationUser(organization_id, user_id) {
    return {
        type: CUSTOMER_ORGANIZATION_ACTIVATE_USER_REQUESTED,
        payload: {
            organization_id,
            user_id
        }
    };
}

export function getAccountsRegistered(organization_id, fromDate, toDate) {
    return {
        type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED,
        payload: {
            organization_id,
            fromDate,
            toDate
        }
    };
}

export function getMindHealthScoreStatistics(organization_id, fromDate, toDate) {
    return {
        type: CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED,
        payload: {
            organization_id,
            fromDate,
            toDate
        }
    };
}

export function getSupportEngagedStatistics(organization_id, fromDate, toDate) {
    return {
        type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED,
        payload: {
            organization_id,
            fromDate,
            toDate
        }
    };
}

export function getOrganizationCareCredits(organization_id, fromDate, toDate) {
    return {
        type: CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED,
        payload: {
            organization_id,
            fromDate,
            toDate
        }
    };
}
