import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class AuthenticationService {
    static authenticate() {
        return axios
            .get(
                Config.api.path + '/auth/login/',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                return true;
            })
            .catch((error) => {
                return false;
            });
    }

    static login(email, password) {
        return axios
            .post(Config.api.path + '/login/', { email: email, password: password }, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return true;
            })
            .catch((error) => {
                return false;
            });
    }

    static logout() {
        return axios
            .post(Config.api.path + '/logout/', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return true;
            })
            .catch((error) => {
                return false;
            });
    }

    static hasAccess() {
        return axios
            .get(Config.api.path + '/user/has_access', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return false;
            });
    }
}

export default AuthenticationService;
