import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    locale: string;
    card?: object;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditReflectionCardModal extends React.Component<Props> {
    state = {
        prompt: null
    };

    componentDidMount() {
        const { card } = this.props;

        if (card != null && card.content != null) {
            this.setState({
                prompt: card.content.prompt
            });
        }
    }

    resetState = () => {
        this.setState({
            prompt: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.prompt != null) params['prompt'] = this.state.prompt;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'prompt':
                this.setState({
                    prompt: inputValue
                });
                break;
        }
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-reflection-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-reflection-modal-header">Edit Reflection Card</Modal.Header>
                <Modal.Content id="content-reflection-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.prompt || ''}
                                type="text"
                                name="prompt"
                                placeholder="Prompt"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-reflection-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditReflectionCardModal;
