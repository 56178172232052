import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class OrganizationService {
    static deleteExemption(email) {
        return axios
            .post(
                Config.api.path + '/v1/account/access/delete',
                {
                    email: email
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                return false;
            });
    }

    static expireExemptionUser(user_id) {
        return axios
            .post(
                Config.api.path + '/v1/account/access/expire',
                {
                    user_id: user_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                return false;
            });
    }

    static fetchOrganizationExemption() {
        return axios
            .get(Config.api.path + '/v1/account/access/fetch', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static createOrganizationExemption(email, organization_id, access_type) {
        return axios
            .post(
                Config.api.path + '/v1/account/access/create',
                {
                    email,
                    organization_id,
                    access_type
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }
}

export default OrganizationService;
