import React from 'react';
import { Search, List, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import Select from 'react-dropdown-select';
import Dropdown from 'react-dropdown';
import SelectSearch from 'react-select-search';
// import 'react-dropdown/style.css';
import './react-select.css';

import './index.css';

class TagList extends React.Component {
    state = {
        value: '',
        filteredSources: []
    };

    renderTagList() {
        const { tags = [] } = this.props;

        const tagItems = tags.map((tag, index) => {
            return (
                <a
                    id={'tag-item'}
                    key={tag.id}
                    class="ui basic small label"
                    onClick={() => {
                        if (this.props.onSelect != null) {
                            this.props.onSelect(tag);
                        }
                    }}
                >
                    {tag.title}
                    <i
                        role={'button'}
                        tabIndex={0}
                        class="delete icon"
                        onClick={(e) => {
                            e.stopPropagation();
                            this.props.onRemove(tag);
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            this.props.onRemove(tag);
                        }}
                    ></i>
                </a>
            );
        });
        return tagItems;
    }

    render() {
        const { source = [], add_placeholder = 'Add...' } = this.props;
        return (
            <div>
                {this.renderTagList()}
                {source.length > 0 && (
                    <SelectSearch
                        options={source.map((item) => ({
                            ...item,
                            label: item.title,
                            value: item.title,
                            name: item.title
                        }))}
                        search
                        onChange={(selected) => {
                            const value = source.find((item) => item.title == selected);
                            this.props.onAdd(value);
                        }}
                        placeholder={add_placeholder}
                    />
                )}
            </div>
        );
    }
}

export default TagList;
