import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    onUpdate: (any) => any;
    onClose: () => any;
}

class EditActionExerciseModal extends React.Component<Props> {
    state = {
        action_exercise_id: null
    };

    resetState = () => {
        this.setState({
            action_exercise_id: null
        });
    };

    handleSubmit = () => {
        if (this.state.action_exercise_id != null) {
            this.props.onUpdate(parseInt(this.state.action_exercise_id));
            this.resetState();
        }
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'action_exercise_id':
                this.setState({
                    action_exercise_id: inputValue
                });
                break;
        }
    };

    render() {
        return (
            <Modal
                id="content-action-exercise-edit-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-action-exercise-edit-modal-header">Link Action Exercise</Modal.Header>
                <Modal.Content id="content-action-exercise-edit-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.action_exercise_id || ''}
                                type="text"
                                name="action_exercise_id"
                                placeholder="Exercise ID"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-action-exercise-edit-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={this.state.action_exercise_id == null} onClick={this.handleSubmit} color="teal">
                        Link Exercise
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditActionExerciseModal;
