import {
    ASSESSMENT_SOURCE_FETCH_REQUESTED,
    ASSESSMENT_SOURCE_FETCH_COMPLETED,
    ASSESSMENT_SOURCE_GET_REQUESTED,
    ASSESSMENT_SOURCE_GET_COMPLETED,
    ASSESSMENT_DIMENSION_CREATE_COMPLETED,
    ASSESSMENT_CATEGORY_CREATE_COMPLETED,
    ASSESSMENT_QUESTION_CREATE_COMPLETED,
    ASSESSMENT_ANSWER_ADD_COMPLETED,
    ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED,
    ASSESSMENT_DIMENSION_CREATE_REQUEST,
    ASSESSMENT_CATEGORY_CREATE_REQUEST,
    ASSESSMENT_QUESTION_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST,
    ASSESSMENT_ANSWER_ADD_REQUEST,
    ASSESSMENT_SOURCE_EDIT_COMPLETED,
    ASSESSMENT_SOURCE_EDIT_REQUESTED,
    ASSESSMENT_DIMENSION_EDIT_REQUEST,
    ASSESSMENT_DIMENSION_EDIT_COMPLETED,
    ASSESSMENT_CATEGORY_EDIT_REQUEST,
    ASSESSMENT_CATEGORY_EDIT_COMPLETED,
    ASSESSMENT_CATEGORY_DELETE_REQUEST,
    ASSESSMENT_CATEGORY_DELETE_COMPLETED,
    ASSESSMENT_QUESTION_EDIT_REQUEST,
    ASSESSMENT_QUESTION_EDIT_COMPLETED,
    ASSESSMENT_ANSWER_EDIT_REQUEST,
    ASSESSMENT_ANSWER_EDIT_COMPLETED,
    ASSESSMENT_DIMENSION_DELETE_REQUEST,
    ASSESSMENT_DIMENSION_DELETE_COMPLETED,
    ASSESSMENT_SOURCE_DELETE_REQUESTED,
    ASSESSMENT_SOURCE_DELETE_COMPLETED,
    ASSESSMENT_QUESTION_DELETE_REQUEST,
    ASSESSMENT_QUESTION_DELETE_COMPLETED,
    ASSESSMENT_ANSWER_DELETE_REQUEST,
    ASSESSMENT_ANSWER_DELETE_COMPLETED,
    ASSESSMENT_SOURCE_CREATE_REQUESTED,
    ASSESSMENT_SOURCE_CREATE_COMPLETED
} from '../constants/assessment';

const initialState = {
    assessments: {
        is_loading: false,
        data: []
    },
    current_assessment: {
        is_loading: false,
        data: {}
    },
    is_creating: false,
    is_deleting: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ASSESSMENT_SOURCE_GET_REQUESTED:
            return {
                ...state,
                current_assessment: {
                    is_loading: true,
                    data: {}
                }
            };
        case ASSESSMENT_SOURCE_GET_COMPLETED:
            return {
                ...state,
                current_assessment: {
                    is_loading: false,
                    data: action.payload.assessment
                }
            };
        case ASSESSMENT_SOURCE_FETCH_REQUESTED:
            return {
                ...state,
                assessments: {
                    is_loading: true,
                    data: []
                }
            };
        case ASSESSMENT_SOURCE_FETCH_COMPLETED:
            return {
                ...state,
                assessments: {
                    is_loading: false,
                    data: action.payload.assessments || []
                }
            };
        case ASSESSMENT_SOURCE_CREATE_REQUESTED:
        case ASSESSMENT_DIMENSION_CREATE_REQUEST:
        case ASSESSMENT_CATEGORY_CREATE_REQUEST:
        case ASSESSMENT_QUESTION_CREATE_REQUEST:
        case ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST:
        case ASSESSMENT_ANSWER_ADD_REQUEST:
        case ASSESSMENT_SOURCE_EDIT_REQUESTED:
        case ASSESSMENT_DIMENSION_EDIT_REQUEST:
        case ASSESSMENT_CATEGORY_EDIT_REQUEST:
        case ASSESSMENT_QUESTION_EDIT_REQUEST:
        case ASSESSMENT_ANSWER_EDIT_REQUEST:
            return {
                ...state,
                is_creating: true
            };
        case ASSESSMENT_DIMENSION_CREATE_COMPLETED:
        case ASSESSMENT_CATEGORY_CREATE_COMPLETED:
        case ASSESSMENT_QUESTION_CREATE_COMPLETED:
        case ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED:
        case ASSESSMENT_ANSWER_ADD_COMPLETED:
        case ASSESSMENT_SOURCE_EDIT_COMPLETED:
        case ASSESSMENT_DIMENSION_EDIT_COMPLETED:
        case ASSESSMENT_CATEGORY_EDIT_COMPLETED:
        case ASSESSMENT_QUESTION_EDIT_COMPLETED:
        case ASSESSMENT_ANSWER_EDIT_COMPLETED:
        case ASSESSMENT_SOURCE_CREATE_COMPLETED:
            return {
                ...state,
                is_creating: false
            };
        case ASSESSMENT_SOURCE_DELETE_REQUESTED:
        case ASSESSMENT_DIMENSION_DELETE_REQUEST:
        case ASSESSMENT_CATEGORY_DELETE_REQUEST:
        case ASSESSMENT_QUESTION_DELETE_REQUEST:
        case ASSESSMENT_ANSWER_DELETE_REQUEST:
            return {
                ...state,
                is_deleting: true
            };
        case ASSESSMENT_SOURCE_DELETE_COMPLETED:
        case ASSESSMENT_DIMENSION_DELETE_COMPLETED:
        case ASSESSMENT_CATEGORY_DELETE_COMPLETED:
        case ASSESSMENT_QUESTION_DELETE_COMPLETED:
        case ASSESSMENT_ANSWER_DELETE_COMPLETED:
            return {
                ...state,
                is_deleting: false
            };
    }
    return state;
}
