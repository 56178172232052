import {
    ORGANIZATIONS_REQUESTED,
    ORGANIZATION_EXEMPTIONS_REQUESTED,
    ORGANIZATION_EXEMPTION_CREATE_REQUESTED,
    ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED,
    ORGANIZATION_EXEMPTION_DELETE_REQUESTED
} from '../constants/organization';

// Action Creators
export function fetchEmailExemptions() {
    return {
        type: ORGANIZATION_EXEMPTIONS_REQUESTED
    };
}

export function fetchOrganizations() {
    return {
        type: ORGANIZATIONS_REQUESTED
    };
}

export function createEmailAccess(email, organization_id, access_type) {
    return {
        type: ORGANIZATION_EXEMPTION_CREATE_REQUESTED,
        payload: {
            email: email,
            organization_id: organization_id,
            access_type: access_type
        }
    };
}

export function expireUser(user_id, completion_handler) {
    return {
        type: ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED,
        payload: {
            user_id: user_id,
            completion_handler: completion_handler
        }
    };
}

export function deleteExemption(email, completion_handler) {
    return {
        type: ORGANIZATION_EXEMPTION_DELETE_REQUESTED,
        payload: {
            email: email,
            completion_handler: completion_handler
        }
    };
}
