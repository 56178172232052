// assets
import { IconDeviceMobile, IconTestPipe2, IconFocus2 } from '@tabler/icons';

// constant
const icons = {
    IconDeviceMobile,
    IconTestPipe2,
    IconFocus2
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const customer = {
    id: 'customer',
    title: 'Clients',
    type: 'group',
    children: [
        {
            id: 'app-customer',
            title: 'View Clients',
            type: 'item',
            url: '/customer/organization',
            icon: icons.IconDeviceMobile
        }
    ]
};

export default customer;
