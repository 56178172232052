import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class HumanCareService {
    static fetchCredits() {
        return axios
            .get(Config.api.path + '/v5/human-care/credit/fetch', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }

    static topUpCredit(organization_id, credit) {
        return axios
            .post(
                Config.api.path + '/v5/human-care/credit/transaction/purchase',
                {
                    organization_id: organization_id,
                    credit: credit
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                return false;
            });
    }
}

export default HumanCareService;
