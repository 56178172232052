import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED,
    CUSTOMER_ORGANIZATION_ACTIVATE_REQUESTED,
    CUSTOMER_ORGANIZATION_ACTIVATE_USER_COMPLETED,
    CUSTOMER_ORGANIZATION_ACTIVATE_USER_REQUESTED,
    CUSTOMER_ORGANIZATION_ARCHIVE_REQUESTED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED,
    CUSTOMER_ORGANIZATION_CREATE_REQUESTED,
    CUSTOMER_ORGANIZATION_EDIT_REQUESTED,
    CUSTOMER_ORGANIZATION_EXPIRE_REQUESTED,
    CUSTOMER_ORGANIZATION_EXPIRE_USER_COMPLETED,
    CUSTOMER_ORGANIZATION_EXPIRE_USER_REQUESTED,
    CUSTOMER_ORGANIZATION_FETCH_COMPLETED,
    CUSTOMER_ORGANIZATION_FETCH_IS_LOADING,
    CUSTOMER_ORGANIZATION_FETCH_NOT_LOADING,
    CUSTOMER_ORGANIZATION_FETCH_REQUESTED,
    CUSTOMER_ORGANIZATION_GET_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_IS_LOADING,
    CUSTOMER_ORGANIZATION_GET_NOT_LOADING,
    CUSTOMER_ORGANIZATION_GET_REQUESTED,
    CUSTOMER_ORGANIZATION_GET_USERS_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_USERS_IS_LOADING,
    CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_NOT_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED
} from 'redux/constants/customer';
import CustomerService from '../../service/customer';
import { MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE, MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE } from 'redux/constants/view/modal';

function* fetchOrganizationDetail(action) {
    try {
        const {
            payload: { organization_id }
        } = action;
        yield put({ type: CUSTOMER_ORGANIZATION_GET_IS_LOADING });
        const results = yield call(CustomerService.getOrganizationDetail, organization_id);
        yield put({ type: CUSTOMER_ORGANIZATION_GET_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_GET_COMPLETED,
                payload: {
                    organization: results.organization
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_GET_COMPLETED,
            payload: {
                organization: null
            }
        });
    }
}

function* fetchOrganizationUsers(action) {
    try {
        const {
            payload: { organization_id }
        } = action;
        yield put({ type: CUSTOMER_ORGANIZATION_GET_USERS_IS_LOADING });
        const results = yield call(CustomerService.getOrganizationUsers, organization_id);
        yield put({
            type: CUSTOMER_ORGANIZATION_GET_USERS_COMPLETED,
            payload: {
                organization_id: organization_id,
                users: results?.users || []
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_GET_USERS_COMPLETED,
            payload: {
                organization_id: organization_id,
                users: []
            }
        });
    }
}

function* fetchOrganizations(action) {
    try {
        yield put({ type: CUSTOMER_ORGANIZATION_FETCH_IS_LOADING });
        const {
            payload: { show_archive }
        } = action;
        const results = yield call(CustomerService.fetchOrganizations, show_archive);
        yield put({ type: CUSTOMER_ORGANIZATION_FETCH_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_FETCH_COMPLETED,
                payload: {
                    organizations: results.organizations
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* createNewOrganization(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { params } = payload;
            const success = yield call(CustomerService.createNewOrganization, params);
            // Hide create modal
            yield put({ type: MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE });
            if (success) {
                yield put({ type: CUSTOMER_ORGANIZATION_FETCH_REQUESTED });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* editOrganization(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { params } = payload;
            const { organization_id } = params;
            if (!organization_id) return;
            const success = yield call(CustomerService.editOrganization, params);
            // Hide create modal
            yield put({ type: MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE });
            if (success) {
                // yield put({ type: CUSTOMER_ORGANIZATION_FETCH_REQUESTED });
                yield put({
                    type: CUSTOMER_ORGANIZATION_GET_REQUESTED,
                    payload: {
                        organization_id
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* expireOrganization(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { organization_id, show_archive } = payload;
            if (!organization_id) return;
            const success = yield call(CustomerService.expireOrganizationUsers, organization_id);
            if (success) {
                // Hide Edit modal first
                yield put({
                    type: MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE
                });
                yield put({
                    type: CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
                    payload: {
                        organization_id
                    }
                });
                yield put({
                    type: CUSTOMER_ORGANIZATION_FETCH_REQUESTED,
                    payload: {
                        show_archive
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* archiveOrganization(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { organization_id, is_archived } = payload;
            if (!organization_id) return;
            const success = yield call(CustomerService.archiveOrganizationUsers, organization_id, is_archived);
            if (success) {
                // Hide Edit modal first
                yield put({
                    type: MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE
                });
                yield put({
                    type: CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
                    payload: {
                        organization_id
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* activateOrganization(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { organization_id, show_archive } = payload;
            if (!organization_id) return;

            const success = yield call(CustomerService.activateOrganizationUsers, organization_id);
            if (success) {
                // Hide Edit modal first
                yield put({
                    type: MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE
                });
                yield put({
                    type: CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED,
                    payload: {
                        organization_id
                    }
                });
                yield put({
                    type: CUSTOMER_ORGANIZATION_FETCH_REQUESTED,
                    payload: {
                        show_archive
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

// Organization User
function* expireOrganizationUser(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { organization_id, user_id } = payload;
            if (!organization_id || !user_id) return;
            const success = yield call(CustomerService.expireOrganizationUser, organization_id, user_id);
            if (success) {
                // find user from organization_users, set expires_at to new Date
                yield put({
                    type: CUSTOMER_ORGANIZATION_EXPIRE_USER_COMPLETED,
                    payload: {
                        organization_id,
                        user_id
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* activateOrganizationUser(action) {
    try {
        const { payload } = action;
        if (payload) {
            const { organization_id, user_id } = payload;
            if (!organization_id || !user_id) return;
            const success = yield call(CustomerService.activateOrganizationUser, organization_id, user_id);
            if (success) {
                // find user from organization_users, set expires_at to null
                yield put({
                    type: CUSTOMER_ORGANIZATION_ACTIVATE_USER_COMPLETED,
                    payload: {
                        organization_id,
                        user_id
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
    }
}

function* getAccountsRegistered(action) {
    const {
        payload: { organization_id, fromDate, toDate }
    } = action;
    if (!organization_id) return;
    try {
        const result = yield call(CustomerService.getRegisteredStatistics, organization_id, fromDate, toDate);
        if (result != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
                payload: {
                    organization_id,
                    accounts_registered: result
                }
            });
        } else {
            yield put({
                type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
                payload: {
                    organization_id,
                    accounts_registered: {}
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
            payload: {
                organization_id,
                accounts_registered: {}
            }
        });
    }
}

function* getMindHealthScoreStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        yield put({
            type: CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING
        });
        const result = yield call(CustomerService.getMindHealthScoreStatistics, organization_id, fromDate, toDate);
        if (result != null) {
            yield put({
                type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
                payload: {
                    mind_health_score: result
                }
            });
        } else {
            yield put({
                type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
                payload: {
                    mind_health_score: null
                }
            });
        }
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
            payload: {
                mind_health_score: null
            }
        });
    }
}

function* getSupportEngagedStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING
        });
        const result = yield call(CustomerService.getSupportEngagedStatistics, organization_id, fromDate, toDate);
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
            payload: {
                support_engaged: result
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
            payload: {
                support_engaged: null
            }
        });
    }
}

function* getCareCreditsStatistics(action) {
    try {
        const {
            payload: { organization_id, fromDate, toDate }
        } = action;
        const result = yield call(CustomerService.getCareCreditsStatistics, organization_id, fromDate, toDate);
        yield put({
            type: CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
            payload: {
                care_credit: result?.credit
            }
        });
    } catch (e) {
        console.error('[Customer Saga]', e);
        yield put({
            type: CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
            payload: {
                care_credit: null
            }
        });
    }
}

function* CustomerOrganizationSaga() {
    // Fetch, Get detail
    yield takeLatest(CUSTOMER_ORGANIZATION_FETCH_REQUESTED, fetchOrganizations);
    yield takeLatest(CUSTOMER_ORGANIZATION_GET_REQUESTED, fetchOrganizationDetail);
    yield takeLatest(CUSTOMER_ORGANIZATION_GET_USERS_REQUESTED, fetchOrganizationUsers);

    // Create, edit
    yield takeLatest(CUSTOMER_ORGANIZATION_CREATE_REQUESTED, createNewOrganization);
    yield takeLatest(CUSTOMER_ORGANIZATION_EDIT_REQUESTED, editOrganization);

    // Organization Action
    yield takeLatest(CUSTOMER_ORGANIZATION_EXPIRE_REQUESTED, expireOrganization);
    yield takeLatest(CUSTOMER_ORGANIZATION_ARCHIVE_REQUESTED, archiveOrganization);
    yield takeLatest(CUSTOMER_ORGANIZATION_ACTIVATE_REQUESTED, activateOrganization);

    // Individual user action
    yield takeLatest(CUSTOMER_ORGANIZATION_EXPIRE_USER_REQUESTED, expireOrganizationUser);
    yield takeLatest(CUSTOMER_ORGANIZATION_ACTIVATE_USER_REQUESTED, activateOrganizationUser);

    // Statistics
    yield takeLatest(CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_REQUESTED, getAccountsRegistered);
    yield takeLatest(CUSTOMER_ORGANIZATION_MIND_HEALTH_REQUESTED, getMindHealthScoreStatistics);
    yield takeLatest(CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_REQUESTED, getSupportEngagedStatistics);
    yield takeLatest(CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED, getCareCreditsStatistics);
}

export default CustomerOrganizationSaga;
