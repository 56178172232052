import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditAssessmentContentModal extends React.Component<Props> {
    state = {
        type: 'dimension',
        id: null
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                type: card.content.type != null ? card.content.type : 'dimension',
                id: card.content.id != null ? card.content.id : null
            });
        }
    }

    resetState = () => {
        this.setState({
            type: 'dimension',
            id: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.type != null) params['type'] = this.state.type;
        if (this.state.id != null) params['id'] = this.state.id.trim();
        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'id':
                this.setState({
                    id: inputValue
                });
                break;
        }
    };

    onTypeChanged = (value) => {
        this.setState({ type: value });
    };

    render() {
        return (
            <Modal closable={false} closeOnEscape={false} closeOnDimmerClick={false} open={this.props.isOpen} onClose={this.handleClose}>
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header>Edit Assessment Card</Modal.Header>
                <Modal.Content>
                    <RadioGroup value={this.state.type} onChange={this.onTypeChanged} horizontal>
                        <RadioButton value="dimension">Dimension</RadioButton>
                        <RadioButton value="source">Source</RadioButton>
                    </RadioGroup>
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.id || ''}
                                type="text"
                                name="id"
                                placeholder="Identifier"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={this.state.id == null} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditAssessmentContentModal;
