import { HUMAN_CARE_CREDITS_IS_LOADING, HUMAN_CARE_CREDITS_NOT_LOADING, HUMAN_CARE_CREDITS_FETCHED } from '../constants/human-care';

const initialState = {
    credits: {
        is_loading: false,
        data: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case HUMAN_CARE_CREDITS_IS_LOADING:
            return {
                ...state,
                credits: {
                    ...state.credits,
                    is_loading: true
                }
            };
        case HUMAN_CARE_CREDITS_NOT_LOADING:
            return {
                ...state,
                credits: {
                    ...state.credits,
                    is_loading: false
                }
            };
        case HUMAN_CARE_CREDITS_FETCHED:
            return {
                ...state,
                credits: {
                    ...state.credits,
                    data: action.payload.credits || []
                }
            };
        default:
            return state;
    }
}
