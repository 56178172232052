import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';

// Authentication
import ProtectedRoute from './protected';
import UnAuthorizedRoute from './unauthorized';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AppContent = Loadable(lazy(() => import('views/content/app-content')));
const ContentEditor = Loadable(lazy(() => import('views/content/tool')));
const AssessmentEditor = Loadable(lazy(() => import('views/assessment/source')));
const Practitioner = Loadable(lazy(() => import('views/human-support/practitioner')));
const HumanCareCredit = Loadable(lazy(() => import('views/human-support/credit')));
const AppAssessment = Loadable(lazy(() => import('views/assessment/app-assessment')));

const EAPAreas = Loadable(lazy(() => import('views/eap/areas')));
const EAPFocus = Loadable(lazy(() => import('views/eap/focus')));
const EAPFocusEditor = Loadable(lazy(() => import('views/eap/focus-edit')));

const EAPFramework = Loadable(lazy(() => import('views/eap/framework')));
const EAPFrameworkEditor = Loadable(lazy(() => import('views/eap/framework-edit')));
const EAPSpeciality = Loadable(lazy(() => import('views/eap/speciality')));
const EAPSpecialityEditor = Loadable(lazy(() => import('views/eap/speciality-edit')));

const Customer = Loadable(lazy(() => import('views/customer/customer-list')));
const CustomerDetail = Loadable(lazy(() => import('views/customer/customer-detail')));

// ==============================|| ROUTING RENDER ||============================== //

export default function AppRoutes() {
    return (
        <Routes>
            {/* Authenticated Routes */}
            <Route
                path={'/'}
                element={<MainLayout />}
                key="authenticated"
                children={[
                    <Route
                        path={'/'}
                        key="app-content"
                        element={
                            <ProtectedRoute>
                                {/* <DashboardDefault /> */}
                                <AppContent />
                            </ProtectedRoute>
                        }
                    />,
                    <Route
                        path={'dashboard'}
                        key="dashboard"
                        children={[
                            <Route
                                path={'default'}
                                element={
                                    <ProtectedRoute>
                                        <DashboardDefault />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'content'}
                        key="content"
                        children={[
                            <Route
                                path={'app-content'}
                                element={
                                    <ProtectedRoute>
                                        <AppContent />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'assessment/editor/:uuid'}
                                element={
                                    <ProtectedRoute>
                                        <AssessmentEditor />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'editor/:tool_id'}
                                element={
                                    <ProtectedRoute>
                                        <ContentEditor />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'app-assessment'}
                                element={
                                    <ProtectedRoute>
                                        <AppAssessment />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'customer'}
                        key="customer"
                        children={[
                            <Route
                                path={'organization'}
                                element={
                                    <ProtectedRoute>
                                        <Customer />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'organization/:id'}
                                element={
                                    <ProtectedRoute>
                                        <CustomerDetail />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />,
                    <Route
                        path={'human-support'}
                        key="human-support"
                        children={[
                            <Route
                                key={'practitioner'}
                                path={'practitioner'}
                                element={
                                    <ProtectedRoute>
                                        <Practitioner />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'areas'}
                                element={
                                    <ProtectedRoute>
                                        <EAPAreas />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'eap-focus'}
                                element={
                                    <ProtectedRoute>
                                        <EAPFocus />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'eap-focus/editor/:uuid'}
                                element={
                                    <ProtectedRoute>
                                        <EAPFocusEditor />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'eap-framework'}
                                element={
                                    <ProtectedRoute>
                                        <EAPFramework />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'eap-framework/editor/:uuid'}
                                element={
                                    <ProtectedRoute>
                                        <EAPFrameworkEditor />
                                    </ProtectedRoute>
                                }
                            />,

                            <Route
                                path={'eap-speciality'}
                                element={
                                    <ProtectedRoute>
                                        <EAPSpeciality />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                path={'eap-speciality/editor/:uuid'}
                                element={
                                    <ProtectedRoute>
                                        <EAPSpecialityEditor />
                                    </ProtectedRoute>
                                }
                            />,
                            <Route
                                key={'credit'}
                                path={'credit'}
                                element={
                                    <ProtectedRoute>
                                        <HumanCareCredit />
                                    </ProtectedRoute>
                                }
                            />
                        ]}
                    />
                ]}
            />
            {/* Unauthenticated Routes */}
            <Route
                path={'/'}
                key="unauthenticated"
                children={[
                    <Route
                        key={'login'}
                        path={'login'}
                        element={
                            <UnAuthorizedRoute>
                                <AuthLogin />
                            </UnAuthorizedRoute>
                        }
                    />
                ]}
            />
        </Routes>
    );
}
