import React from 'react';
import { Card, List, Button, Icon } from 'semantic-ui-react';
import Select from 'react-dropdown-select';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import './index.css';

export const sourceTypes = [
    {
        value: 1,
        label: 'Emotion',
        type: 'emotion'
    },
    {
        value: 2,
        label: 'Interpersonal',
        type: 'interpersonal'
    },
    {
        value: 3,
        label: 'Lifestyle',
        type: 'lifestyle'
    },
    {
        value: 4,
        label: 'Mental Health',
        type: 'mental-health'
    },
    {
        value: 5,
        label: 'Parenting',
        type: 'parenting'
    },
    {
        value: 6,
        label: 'Performance',
        type: 'performance'
    },
    {
        value: 7,
        label: 'Risk',
        type: 'risk'
    },
    {
        value: 8,
        label: 'Self',
        type: 'self'
    },
    {
        value: 9,
        label: 'Sleep',
        type: 'sleep'
    },
    {
        value: 10,
        label: 'Wellbeing',
        type: 'wellbeing'
    },
    {
        value: 11,
        label: 'Work',
        type: 'work'
    }
];

class ToolImmediateSelection extends React.Component {
    renderToolTypeSelector() {
        const { type } = this.props;
        const currentType = sourceTypes.find((item) => item.type == type);
        return (
            <div class="ui" id="immediate-button-container">
                <Card.Content style={{ flex: 1 }}>
                    <div id="tool-detail-header">Source Type</div>
                    <Card.Description>
                        <Dropdown
                            options={sourceTypes}
                            onChange={(selected) => {
                                const item = sourceTypes.find((item) => item.value == selected.value);
                                const { type } = item;
                                this.props.onSourceTypeUpdated(type);
                            }}
                            value={currentType != null ? currentType.label : null}
                            placeholder="Select an option"
                        />
                    </Card.Description>
                </Card.Content>
            </div>
        );
    }

    render() {
        return (
            <Card id="filter-list-menu">
                <Card.Content extra textAlign="left">
                    {this.renderToolTypeSelector()}
                    {this.props.children}
                </Card.Content>
            </Card>
        );
    }
}

export default ToolImmediateSelection;
