import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class EAPService {
    static fetchEapFocuses() {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/focus/fetch`,
                {
                    params: {}
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap focuses', error);
                return null;
            });
    }

    static getEapFocusDetail(focus_id) {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/focus/get`,
                {
                    params: {
                        focus_id
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap focuses', error);
                return null;
            });
    }

    static createEapFocus(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/focus/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap focus', error);
                return null;
            });
    }

    static updateEapFocus(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/focus/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap focus', error);
                return null;
            });
    }

    static removeEapFocus(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/focus/remove`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to remove eap focus', error);
                return null;
            });
    }

    static addTagFocusKeyword(focus_id, keyword) {
        return axios
            .post(
                Config.api.path + `/v4/eap/label/focus/keyword/add`,
                {
                    focus_id: focus_id,
                    keyword: keyword
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagFocusKeyword(focus_id, keyword) {
        return axios
            .post(
                Config.api.path + `/v4/eap/label/focus/keyword/remove`,
                {
                    focus_id: focus_id,
                    keyword: keyword
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    // Framework

    static fetchEapFrameworks() {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/framework/fetch`,
                {
                    params: {}
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap frameworks', error);
                return null;
            });
    }

    static getEapFrameworkDetail(framework_id) {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/framework/get`,
                {
                    params: {
                        framework_id
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap framework', error);
                return null;
            });
    }

    static createEapFramework(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/framework/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap framework', error);
                return null;
            });
    }

    static updateEapFramework(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/framework/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap framework', error);
                return null;
            });
    }

    static removeEapFramework(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/framework/remove`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to remove eap framework', error);
                return null;
            });
    }

    // Speciality

    static fetchEapSpecialities() {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/speciality/fetch`,
                {
                    params: {}
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap specialities', error);
                return null;
            });
    }

    static getEapSpecialityDetail(speciality_id) {
        return axios
            .get(
                Config.api.path + `/v4/eap/label/speciality/get`,
                {
                    params: {
                        speciality_id
                    }
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to fetch eap speciality', error);
                return null;
            });
    }

    static createEapSpeciality(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/speciality/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap speciality', error);
                return null;
            });
    }

    static updateEapSpeciality(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/speciality/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to create eap speciality', error);
                return null;
            });
    }

    static removeEapSpeciality(params) {
        return axios
            .post(Config.api.path + `/v4/eap/label/speciality/remove`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[EAP Service] Failed to remove eap speciality', error);
                return null;
            });
    }
}

export default EAPService;
