import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditCardReferenceModal extends React.Component<Props> {
    state = {
        type: 'none',
        value: null
    };

    resetState = () => {
        this.setState({
            type: 'none',
            value: null
        });
    };

    handleSubmit = () => {
        this.props.onEdit(this.state.type, this.state.value);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'value':
                this.setState({
                    value: parseInt(inputValue)
                });
                break;
        }
    };

    onReferenceChanged = (value) => {
        this.setState({ type: value });
    };

    render() {
        return (
            <Modal closable={false} closeOnEscape={false} closeOnDimmerClick={false} open={this.props.isOpen} onClose={this.handleClose}>
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header>Edit Card Reference</Modal.Header>
                <Modal.Content>
                    <RadioGroup value={this.state.type} onChange={this.onReferenceChanged} horizontal>
                        <RadioButton value="none">None</RadioButton>
                        <RadioButton value="card">Card</RadioButton>
                    </RadioGroup>
                    {this.state.type != 'none' && (
                        <Form size="large" loading={false}>
                            <Segment basic>
                                <Form.Input
                                    value={this.state.value || ''}
                                    type="text"
                                    name="value"
                                    placeholder="Value"
                                    onChange={this.handleInputChange}
                                />
                            </Segment>
                        </Form>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={this.state.type == null} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditCardReferenceModal;
