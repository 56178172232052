import { NAVIGATION_SET_REDIRECT_URL } from '../constants/navigation';

const initialState = {
    redirectUrl: ''
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case NAVIGATION_SET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload.redirectUrl
            };
        default:
            return state;
    }
    return state;
}
