import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import ReactDropzone from 'react-dropzone';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import ContentService from '../../../../../service/content';

import './index.css';

interface Props {
    locale: string;
    exercise?: object;
    card?: object;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditInformationCardModal extends React.Component<Props> {
    state = {
        is_uploading: false,
        display_type: null,
        title: null,
        learn_more: null,
        image_url: null,
        indicator: null,
        points: [],
        point_text: null,
        has_audio_disclaimer: false,
        audio_url: null,
        has_cc: false
    };

    componentDidMount() {
        const { card, exercise } = this.props;

        if (exercise != null) {
            this.setState({
                exercise_id: exercise.id
            });
        }

        if (card != null && card.content != null) {
            this.setState({
                display_type: card.content.display_type,
                indicator: card.content.indicator,
                title: card.content.title,
                learn_more: card.content.learn_more,
                points: card.content.points,
                image_url: card.content.image_url,
                has_audio_disclaimer: card.content.has_audio_disclaimer,
                audio_url: card.content.audio_url,
                has_cc: card.content.has_cc
            });
        }
    }

    resetState = () => {
        this.setState({
            is_uploading: false,
            display_type: null,
            indicator: null,
            title: null,
            learn_more: null,
            points: [],
            image_url: null,
            has_audio_disclaimer: false,
            audio_url: null,
            has_cc: false
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.display_type != null) params['display_type'] = this.state.display_type;
        if (this.state.indicator != null) params['indicator'] = this.state.indicator;
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.learn_more != null) params['learn_more'] = this.state.learn_more;
        if (this.state.points != null) params['points'] = this.state.points;
        if (this.state.image_url != null) params['image_url'] = this.state.image_url;
        if (this.state.has_audio_disclaimer != null) params['has_audio_disclaimer'] = this.state.has_audio_disclaimer;
        if (this.state.audio_url != null) params['audio_url'] = this.state.audio_url;
        if (this.state.has_cc != null) params['has_cc'] = this.state.has_cc;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'indicator':
                this.setState({
                    indicator: inputValue
                });
                break;
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'learn_more':
                this.setState({
                    learn_more: inputValue
                });
                break;
            case 'image_url':
                this.setState({
                    image_url: inputValue
                });
                break;
            case 'audio_url':
                this.setState({
                    audio_url: inputValue
                });
                break;
        }
    };

    onDropImage = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadExerciseImage(this.state.exercise_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        image_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    onDropAudio = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadToolAudio(this.state.exercise_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        audio_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    onDropCC = (files) => {
        if (files.length == 0) {
            return;
        }

        const subtitleFile = files[0];

        const reader = new FileReader();
        reader.readAsText(subtitleFile);
        reader.onload = () => {
            const srtString = reader.result;
            this.setState({ learn_more: srtString, has_cc: true });
        };
    };

    onDisplayTypeChanged = (value) => {
        this.setState({ display_type: value });
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-information-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-information-modal-header">Edit Information Card</Modal.Header>
                <Modal.Content id="content-information-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <div id={'type-container'}>
                                <RadioGroup value={this.state.display_type} onChange={this.onDisplayTypeChanged} horizontal>
                                    <RadioButton value="default">Default</RadioButton>
                                    <RadioButton value="simple">Simple</RadioButton>
                                </RadioGroup>
                            </div>
                            <Form.Input
                                value={this.state.indicator || ''}
                                type="text"
                                name="indicator"
                                placeholder="Indicator"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.title || ''}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.learn_more || ''}
                                rows={3}
                                type="text"
                                name="learn_more"
                                placeholder="Learn More Information"
                                onChange={this.handleInputChange}
                            />
                            <div id={'image-drop'}>
                                <div>
                                    <ReactDropzone onDrop={this.onDropImage}>
                                        {({ getRootProps, getInputProps, isDragActive }) => (
                                            <div
                                                id={isDragActive ? 'cover-image-drop-inner-selected' : 'cover-image-drop-inner'}
                                                {...getRootProps()}
                                            >
                                                {this.state.is_uploading == true ? (
                                                    <div class="ui active inverted dimmer">
                                                        <div class="ui loader"></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input {...getInputProps()} />
                                                        <p>Drop an optional image here, or click to select image</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ReactDropzone>
                                </div>
                            </div>
                            <Form.Input
                                value={this.state.image_url || ''}
                                type="text"
                                name="image_url"
                                placeholder="Image URL"
                                onChange={this.handleInputChange}
                            />
                            {this.state.points != null && (
                                <div>
                                    {this.state.points.map((point, index) => {
                                        return (
                                            <div id={'point-item'} className={'ui fluid action input'}>
                                                <input
                                                    type="text"
                                                    value={point}
                                                    onChange={(e) => {
                                                        const pointText = e.target.value;
                                                        const updatedPoints = this.state.points;
                                                        updatedPoints[index] = pointText;
                                                        this.setState({
                                                            points: updatedPoints
                                                        });
                                                    }}
                                                />
                                                <div
                                                    class="ui tiny button"
                                                    onClick={() => {
                                                        const updatedPoints = this.state.points || [];
                                                        updatedPoints.splice(index, 1);
                                                        this.setState({
                                                            points: updatedPoints
                                                        });
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <div class="ui fluid action input" id={'point-container'}>
                                <input
                                    type="text"
                                    value={this.state.point_text}
                                    placeholder="Add Bullet Point..."
                                    onChange={(e) => {
                                        this.setState({
                                            point_text: e.target.value
                                        });
                                    }}
                                />
                                <div
                                    class="ui tiny button"
                                    onClick={() => {
                                        const updatedPoints = this.state.points || [];
                                        updatedPoints.push(this.state.point_text);
                                        this.setState({
                                            point_text: '',
                                            points: updatedPoints
                                        });
                                    }}
                                >
                                    Add
                                </div>
                            </div>
                            <div id={'audio-drop'}>
                                <div>
                                    <ReactDropzone onDrop={this.onDropAudio}>
                                        {({ getRootProps, getInputProps, isDragActive }) => (
                                            <div
                                                id={isDragActive ? 'cover-image-drop-inner-selected' : 'cover-image-drop-inner'}
                                                {...getRootProps()}
                                            >
                                                {this.state.is_uploading == true ? (
                                                    <div class="ui active inverted dimmer">
                                                        <div class="ui loader"></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input {...getInputProps()} />
                                                        <p>Drop an optional audio here, or click to select audio</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ReactDropzone>
                                </div>
                            </div>
                            <Form.Input
                                value={this.state.audio_url || ''}
                                type="text"
                                name="audio_url"
                                placeholder="Audio URL"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_audio_disclaimer"
                                        checked={this.state.has_audio_disclaimer || false}
                                        onChange={() => {
                                            this.setState({
                                                has_audio_disclaimer: !this.state.has_audio_disclaimer
                                            });
                                        }}
                                    />
                                    <label>Has Audio Disclaimer</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_cc"
                                        checked={this.state.has_cc || false}
                                        onChange={() => {
                                            this.setState({
                                                has_cc: !this.state.has_cc
                                            });
                                        }}
                                    />
                                    <label>Has Closed Caption</label>
                                </div>
                            </div>
                            <div id={'cc-drop'}>
                                <div>
                                    <ReactDropzone onDrop={this.onDropCC}>
                                        {({ getRootProps, getInputProps, isDragActive }) => (
                                            <div
                                                id={isDragActive ? 'cover-image-drop-inner-selected' : 'cover-image-drop-inner'}
                                                {...getRootProps()}
                                            >
                                                {this.state.is_uploading == true ? (
                                                    <div class="ui active inverted dimmer">
                                                        <div class="ui loader"></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input {...getInputProps()} />
                                                        <p>Drop an optional subtitle file here, or click to select srt file</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ReactDropzone>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-information-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditInformationCardModal;
