import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class ContentService {
    static recommendTools(tags = [], language = 'en') {
        return axios
            .get(
                Config.api.path + '/v3/content/tool/recommend/tool',
                {
                    params: {
                        tags: tags,
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static recommendLessons(tags = [], language = 'en') {
        return axios
            .get(
                Config.api.path + '/v3/content/tool/recommend/lesson',
                {
                    params: {
                        tags: tags,
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createToolTag(tool_id, params = {}) {
        const newParams = {
            ...params,
            tool_id: tool_id
        };
        return axios
            .post(Config.api.path + `/v3/content/tool/tag/create`, newParams, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static deleteToolTag(tool_id, tag_id) {
        const params = {
            tool_id: tool_id,
            tag_id: tag_id
        };
        return axios
            .post(Config.api.path + `/v3/content/tool/tag/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static createGenericTag(tool_id, params = {}) {
        const newParams = {
            ...params,
            tool_id: tool_id
        };
        return axios
            .post(Config.api.path + `/v4/content/tool/tag/create`, newParams, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static deleteGenericTag(tool_id, tag_id) {
        const params = {
            tool_id: tool_id,
            tag_id: tag_id
        };
        return axios
            .post(Config.api.path + `/v4/content/tool/tag/delete`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static uploadExerciseImage(exercise_id, file, filename) {
        let data = new FormData();
        data.append('file', file);
        data.append('exercise_id', exercise_id);
        data.append('file_name', filename);
        return axios
            .post(Config.api.path + '/v2/content/upload/exercise/image', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static uploadToolImage(tool_id, file, filename) {
        let data = new FormData();
        data.append('file', file);
        data.append('tool_id', tool_id);
        data.append('file_name', filename);
        return axios
            .post(Config.api.path + '/v2/content/upload/tool/image', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static uploadToolAudio(exercise_id, file, filename) {
        let data = new FormData();
        data.append('file', file);
        data.append('exercise_id', exercise_id);
        data.append('file_name', filename);
        return axios
            .post(Config.api.path + '/v2/content/upload/content/audio', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static uploadExerciseVideo(exercise_id, file, filename) {
        let data = new FormData();
        data.append('file', file);
        data.append('exercise_id', exercise_id);
        data.append('file_name', filename);
        return axios
            .post(Config.api.path + '/v2/content/upload/exercise/video', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchUserFeedback() {
        return axios
            .get(Config.api.path + '/v2/content/exercise/feedback/fetch', {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createTool(language, params) {
        params['language'] = language;
        return axios
            .post(Config.api.path + `/v4/content/tool/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getTool(tool_id, language) {
        return axios
            .get(
                Config.api.path + '/v4/content/tool/get',
                {
                    params: {
                        tool_id: tool_id,
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateTool(tool_id, params) {
        params['tool_id'] = tool_id;
        return axios
            .post(Config.api.path + `/v4/content/tool/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchTools(language) {
        return axios
            .get(
                Config.api.path + '/v2/content/tool/fetch',
                {
                    params: {
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchToolsVersion(version = 1, language = 'en') {
        return axios
            .get(
                Config.api.path + '/v4/content/tool/fetch',
                {
                    params: {
                        version: version,
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteTool(tool_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/delete`,
                {
                    tool_id: tool_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchFocuses(language = 'en') {
        return axios
            .get(
                Config.api.path + '/v3/content/focus/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchProblems(language = 'en') {
        return axios
            .get(
                Config.api.path + '/v3/content/problem/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchSkills(language = 'en') {
        return axios
            .get(
                Config.api.path + '/v4/content/skill/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchContexts(language) {
        return axios
            .get(
                Config.api.path + '/v2/content/context/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchCategories(language = 'en') {
        return axios
            .get(
                Config.api.path + '/v4/content/category/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchCategoryHeaders(language = 'en') {
        return axios
            .get(
                Config.api.path + '/v4/content/category/header/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchIntentions(language) {
        return axios
            .get(
                Config.api.path + '/v2/content/intention/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchTechniques(language) {
        return axios
            .get(
                Config.api.path + '/v2/content/technique/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static addTagToolIntention(tool_id, intention_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/intention/add`,
                {
                    tool_id: tool_id,
                    intention_id: intention_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolIntention(tool_id, intention_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/intention/remove`,
                {
                    tool_id: tool_id,
                    intention_id: intention_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolFocus(tool_id, focus_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/focus/add`,
                {
                    tool_id: tool_id,
                    focus_id: focus_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolFocus(tool_id, focus_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/focus/remove`,
                {
                    tool_id: tool_id,
                    focus_id: focus_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolProblem(tool_id, problem_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/problem/add`,
                {
                    tool_id: tool_id,
                    problem_id: problem_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolProblem(tool_id, problem_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/problem/remove`,
                {
                    tool_id: tool_id,
                    problem_id: problem_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolSkill(tool_id, skill_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/tag/skill/add`,
                {
                    tool_id: tool_id,
                    skill_id: skill_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolSkill(tool_id, skill_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/tag/skill/remove`,
                {
                    tool_id: tool_id,
                    skill_id: skill_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolContext(tool_id, context_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/context/add`,
                {
                    tool_id: tool_id,
                    context_id: context_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolContext(tool_id, context_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/context/remove`,
                {
                    tool_id: tool_id,
                    context_id: context_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolAffect(tool_id, affect_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/tag/affect/add`,
                {
                    tool_id: tool_id,
                    affect_id: affect_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolAffect(tool_id, affect_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/tag/affect/remove`,
                {
                    tool_id: tool_id,
                    affect_id: affect_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolCategory(tool_id, category_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/category/add`,
                {
                    tool_id: tool_id,
                    category_id: category_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolCategory(tool_id, category_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/category/remove`,
                {
                    tool_id: tool_id,
                    category_id: category_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolCategoryHeader(tool_id, category_header_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/category_header/add`,
                {
                    tool_id: tool_id,
                    category_header_id: category_header_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolCategoryHeader(tool_id, category_header_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/category_header/remove`,
                {
                    tool_id: tool_id,
                    category_header_id: category_header_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static replaceTagToolTechnique(tool_id, technique_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/technique/replace`,
                {
                    tool_id: tool_id,
                    technique_id: technique_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolTechnique(tool_id) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/tag/technique/remove`,
                {
                    tool_id: tool_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static createGoal(language, params) {
        params['language'] = language;
        return axios
            .post(Config.api.path + `/v1/content/goal/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchSeriesById(series_id, tool_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/series/fetch`,
                {
                    series_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addToolToSeries(series_id, tool_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/series/create`,
                {
                    series_id,
                    tool_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeToolFromSeries(series_id, tool_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/tool/series/delete`,
                {
                    series_id,
                    tool_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static fetchGoals(language) {
        return axios
            .get(
                Config.api.path + '/v1/content/goal/fetch',
                {
                    params: {
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchGoalHeaders(goal_id) {
        return axios
            .get(
                Config.api.path + `/v1/content/goal/header/fetch?goal_id=${goal_id}`,
                {},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createGoalHeader(goal_id, params) {
        params['goal_id'] = goal_id;

        return axios
            .post(Config.api.path + `/v1/content/goal/header/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteGoalHeader(goal_header_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/goal/header/delete`,
                {
                    goal_header_id: goal_header_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static linkJourneyGoalHeader(goal_header_id, journey_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/goal/header/link`,
                {
                    goal_header_id: goal_header_id,
                    journey_id: journey_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static unlinkJourneyGoalHeader(journey_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/goal/header/unlink`,
                {
                    journey_id: journey_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchGoalJournies(goal_id) {
        return axios
            .get(Config.api.path + `/v1/content/journey/fetch?goal_id=${goal_id}`, {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchGoalExercises(goal_id) {
        return axios
            .get(Config.api.path + `/v1/content/exercise/fetch?goal_id=${goal_id}`, {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchGeneralExercises(language) {
        return axios
            .get(
                Config.api.path + `/v1/content/exercise/fetch`,
                {
                    params: {
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createJourney(goal_id, language, params) {
        params['goal_id'] = goal_id;
        params['language'] = language;
        return axios
            .post(Config.api.path + `/v1/content/journey/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateJourney(journey_id, params) {
        params['journey_id'] = journey_id;
        return axios
            .post(Config.api.path + `/v1/content/journey/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteJourney(journey_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/journey/delete`,
                {
                    journey_id: journey_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getJourney(journey_id) {
        return axios
            .get(
                Config.api.path + `/v1/content/journey/get?journey_id=${journey_id}`,
                {},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createExercise(goal_id, journey_id, tool_id, language, params) {
        if (goal_id != null) params['goal_id'] = goal_id;
        if (journey_id != null) params['journey_id'] = journey_id;
        if (tool_id != null) params['tool_id'] = tool_id;
        if (language != null) params['language'] = language;

        return axios
            .post(Config.api.path + `/v1/content/exercise/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateExercise(exercise_id, params) {
        params['exercise_id'] = exercise_id;
        return axios
            .post(Config.api.path + `/v1/content/exercise/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteExercise(exercise_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/delete`,
                {
                    exercise_id: exercise_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getExercise(exercise_id) {
        return axios
            .get(
                Config.api.path + `/v1/content/exercise/get?exercise_id=${exercise_id}`,
                {},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static moveExercise(exercise_id, to_order) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/move`,
                {
                    exercise_id: exercise_id,
                    to_order: to_order
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getExerciseContent(exercise_id) {
        return axios
            .get(
                Config.api.path + `/v1/content/content/get?exercise_id=${exercise_id}`,
                {},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static copyContentCard(from_exercise_id, to_exercise_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/copy`,
                {
                    from_exercise_id: from_exercise_id,
                    to_exercise_id: to_exercise_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static createContentCard(exercise_id, type) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/create`,
                {
                    exercise_id: exercise_id,
                    type: type
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentCard(exercise_id, index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/delete`,
                {
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static moveContentCard(exercise_id, from_index, to_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/move`,
                {
                    exercise_id: exercise_id,
                    from_index: from_index,
                    to_index: to_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentCardCompletion(exercise_id, index, type, data) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/completion/update`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    type: type,
                    data: data
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentCardReference(exercise_id, index, type, value) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/reference/update`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    type: type,
                    value: value
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentInformationCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/information/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentReflectionCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/reflection/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentAssessmentCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/assessment/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentAssessmentResultCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/assessment_result/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentSummaryCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/summary/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentToolCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/tool/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static addToolContentToolCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/tool/tool/add`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static removeToolContentToolCard(exercise_id, card_index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/tool/tool/remove`,
                {
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentOverviewSessionsCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/overview_sessions/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentQuestionnaireCard(exercise_id, card_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/questionnaire/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentChecklistCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/checklist/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentChecklistCardItem(exercise_id, card_index, item_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/checklist/item/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentChecklistCardItem(exercise_id, index, params = {}) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/checklist/item/create`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentChecklistCardItem(exercise_id, index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/checklist/item/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentCaptionCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/caption/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentTimerCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/timer/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentAudioCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/audio/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentTaskCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/task/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentVideoCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/video/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentCompleteCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/complete/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentReadCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/read/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentQuizCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quiz/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentQuizCardItem(exercise_id, card_index, item_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quiz/item/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentQuizCardItem(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quiz/item/create`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentQuizCardItem(exercise_id, index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quiz/item/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentInputCardItem(exercise_id, card_index, item_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/input/item/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentInputCardItem(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/input/item/create`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentInputCardItem(exercise_id, index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/input/item/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentQuoteCardItem(exercise_id, card_index, item_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quote/item/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentQuoteCardItem(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quote/item/create`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentQuoteCardItem(exercise_id, index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/quote/item/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentDocumentCardItem(exercise_id, index, type, data) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/document/item/create`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    type: type,
                    data: data
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentDocumentCardItem(exercise_id, index, item_index) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/document/item/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static updateContentDocumentCardItem(exercise_id, card_index, item_index, type, data) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/document/item/update`,
                {
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index,
                    type: type,
                    data: data
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentTag(exercise_id, journey_id, name) {
        var params = {
            name: name
        };
        if (exercise_id != null) params['exercise_id'] = exercise_id;
        if (journey_id != null) params['journey_id'] = journey_id;

        return axios
            .post(Config.api.path + `/v1/content/tag/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentTag(tag_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/tag/delete`,
                {
                    tag_id: tag_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentToolType(tool_id, tool_type) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/set_type`,
                {
                    tool_id: tool_id,
                    type: tool_type
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentToolPublish(tool_id, has_published) {
        return axios
            .post(
                Config.api.path + `/v2/content/tool/publish`,
                {
                    tool_id: tool_id,
                    has_published: has_published
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentToolArchive(tool_id, is_archived) {
        return axios
            .post(
                Config.api.path + `/v3/content/tool/archive`,
                {
                    tool_id: tool_id,
                    is_archived: is_archived
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentGoalPublish(goal_id, has_published) {
        return axios
            .post(
                Config.api.path + `/v1/content/goal/publish`,
                {
                    goal_id: goal_id,
                    has_published: has_published
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentJourneyPublish(journey_id, has_published) {
        return axios
            .post(
                Config.api.path + `/v1/content/journey/publish`,
                {
                    journey_id: journey_id,
                    has_published: has_published
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentExercisePublish(exercise_id, has_published) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/publish`,
                {
                    exercise_id: exercise_id,
                    has_published: has_published
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentGoalLock(goal_id, is_locked) {
        return axios
            .post(
                Config.api.path + `/v1/content/goal/lock`,
                {
                    goal_id: goal_id,
                    is_locked: is_locked
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentJourneyLock(journey_id, is_locked) {
        return axios
            .post(
                Config.api.path + `/v1/content/journey/lock`,
                {
                    journey_id: journey_id,
                    is_locked: is_locked
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentExerciseLock(exercise_id, is_locked) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/lock`,
                {
                    exercise_id: exercise_id,
                    is_locked: is_locked
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static linkExerciseActionExercise(exercise_id, action_exercise_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/action/link`,
                {
                    exercise_id: exercise_id,
                    action_exercise_id: action_exercise_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static unlinkExerciseActionExercise(exercise_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/exercise/action/unlink`,
                {
                    exercise_id: exercise_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchTechniqueTags() {
        return axios
            .get(Config.api.path + `/v3/content/technique/tag/fetch`, {}, { headers: { 'Content-Type': 'application/json' } })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static enableTechniqueTag(technique_id, tag_id, is_enabled) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/enable`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id,
                    is_enabled: is_enabled
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTechniqueTagPushMessage(technique_id, tag_id, language, message) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/push_message/add`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id,
                    language: language,
                    message: message
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTechniqueTagPushMessage(technique_id, tag_id, language, index) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/push_message/remove`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id,
                    language: language,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTechniqueTagRule(technique_id, tag_id, rule) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/rule/add`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id,
                    rule: rule
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTechniqueTagRule(technique_id, tag_id, index) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/rule/remove`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static deleteTechniqueTag(technique_id, tag_id) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/delete`,
                {
                    technique_id: technique_id,
                    tag_id: tag_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static createTechniqueTag(technique_id) {
        return axios
            .post(
                Config.api.path + `/v3/content/technique/tag/create`,
                {
                    technique_id: technique_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addContentLink(tool_id, lesson_id, reason) {
        return axios
            .post(
                Config.api.path + `/v3/content/tool/link/add`,
                {
                    tool_id: tool_id,
                    lesson_id: lesson_id,
                    reason: reason
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeContentLink(tool_id, lesson_id) {
        return axios
            .post(
                Config.api.path + `/v3/content/tool/link/remove`,
                {
                    tool_id: tool_id,
                    lesson_id: lesson_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static fetchLinkedContents(content_id) {
        return axios
            .get(
                Config.api.path + `/v3/content/tool/link/fetch?content_id=${content_id}`,
                {},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    // quotes
    static createQuote(language, params) {
        params['language'] = language;
        return axios
            .post(Config.api.path + `/v4/content/quotes/create`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static fetchQuotes(language) {
        return axios
            .get(
                Config.api.path + '/v4/content/quotes/fetch',
                {
                    params: {
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static getQuote(quote_id, language) {
        return axios
            .get(
                Config.api.path + '/v4/content/quotes/get',
                {
                    params: {
                        quote_id: quote_id,
                        language: language
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateQuote(quote_id, params) {
        params['quote_id'] = quote_id;
        return axios
            .post(Config.api.path + `/v4/content/quotes/update`, params, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteQuote(quote_id) {
        return axios
            .post(
                Config.api.path + `/v4/content/quotes/delete`,
                {
                    quote_id: quote_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentQuotePublish(quote_id, has_published) {
        return axios
            .post(
                Config.api.path + `/v4/content/quotes/publish`,
                {
                    quote_id: quote_id,
                    has_published: has_published
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static setContentQuoteArchive(quote_id, is_archived) {
        return axios
            .post(
                Config.api.path + `/v4/content/quotes/archive`,
                {
                    quote_id: quote_id,
                    is_archived: is_archived
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentGuideCard(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/guide/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static createContentGuideConcept(exercise_id, index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/guide/concept/create`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static updateContentGuideCardConcept(exercise_id, card_index, item_index, params) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/guide/concept/update`,
                {
                    ...params,
                    exercise_id: exercise_id,
                    index: card_index,
                    item_index: item_index
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static deleteContentGuideCardConcept(exercise_id, index, item_index, concept_id) {
        return axios
            .post(
                Config.api.path + `/v1/content/content/card/guide/concept/delete`,
                {
                    exercise_id: exercise_id,
                    index: index,
                    item_index: item_index,
                    concept_id: concept_id
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return null;
            });
    }

    static addTagToolKeyword(tool_id, keyword) {
        return axios
            .post(
                Config.api.path + `/v5/content/tool/tag/keyword/add`,
                {
                    tool_id: tool_id,
                    keyword: keyword
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolKeyword(tool_id, keyword) {
        return axios
            .post(
                Config.api.path + `/v5/content/tool/tag/keyword/remove`,
                {
                    tool_id: tool_id,
                    keyword: keyword
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static addTagToolTag(tool_id, type, value) {
        return axios
            .post(
                Config.api.path + `/v5/content/tool/tag/tag/add`,
                {
                    tool_id: tool_id,
                    type: type,
                    value: value
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }

    static removeTagToolTag(tool_id, type, value) {
        return axios
            .post(
                Config.api.path + `/v5/content/tool/tag/tag/remove`,
                {
                    tool_id: tool_id,
                    type: type,
                    value: value
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.success;
            })
            .catch((error) => {
                console.error('[Content Service]', error);
                return false;
            });
    }
}

export default ContentService;
