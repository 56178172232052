/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { connect } from 'react-redux';

class EditEAPFocusModal extends React.Component {
    state = {
        name: '',
        nameError: false,
        description_en: '',
        description_yue: '',
        description_zh: '',
        descriptionError: false
    };

    resetState = () => {
        this.setState({
            name: '',
            nameError: false,
            description_en: '',
            description_yue: '',
            description_zh: '',
            descriptionError: false
        });
    };

    componentDidMount() {
        const { eap, focus_id } = this.props;
        const {
            currentFocus: { data }
        } = eap;
        if (data && data?.id == focus_id) {
            this.setState({
                name: data.name,
                description_en: data.description_en,
                description_yue: data.description_yue,
                description_zh: data.description_zh
            });
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleSubmit = () => {
        const params = {};
        const { name, description_en, description_yue, description_zh } = this.state;
        if (!name) {
            this.setState({
                nameError: true
            });
            return;
        } else {
            this.setState({
                nameError: false
            });
        }
        if (!description_en && !description_yue && !description_zh) {
            this.setState({
                descriptionError: true
            });
            return;
        } else {
            this.setState({
                descriptionError: false
            });
        }
        params['name'] = name;
        params['focus_id'] = this.props.focus_id;
        params['description_en'] = description_en;
        params['description_yue'] = description_yue;
        params['description_zh'] = description_zh;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading } = this.props;

        return (
            <Modal
                id="create-focus-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-focus-modal-header">Edit EAP Focus</Modal.Header>
                <Modal.Content id="create-focus-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="section-header">
                                <Card.Header className="property-value">Focus Name *</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <Form.Input
                                        error={this.state.nameError}
                                        value={this.state.name || ''}
                                        id="name"
                                        type="text"
                                        name="name"
                                        placeholder="Name e.g: emotion_anxiety_worry"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.description_en || ''}
                                        rows="2"
                                        type="text"
                                        name="description_en"
                                        placeholder="Description English"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.description_yue || ''}
                                        rows="2"
                                        type="text"
                                        name="description_yue"
                                        placeholder="Description Cantonese"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.description_zh || ''}
                                        rows="2"
                                        type="text"
                                        name="description_zh"
                                        placeholder="Description Mandarin"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-focus-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Focus
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eap: state.data.eap
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditEAPFocusModal);

EditEAPFocusModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCreate: PropTypes.func
};
