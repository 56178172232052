import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer } from 'semantic-ui-react';

const quizIcon = require('assets/images/icons/icon-content-quiz.png');
const audioIcon = require('assets/images/icons/icon-content-audio.png');
const videoIcon = require('assets/images/icons/icon-content-video.png');
const inputIcon = require('assets/images/icons/icon-content-input.png');
const breatheIcon = require('assets/images/icons/icon-content-breathe.png');
const timerIcon = require('assets/images/icons/icon-content-timer.png');
const goalSettingIcon = require('assets/images/icons/icon-content-goal-setting.png');
const assessmentResultIcon = require('assets/images/icons/icon-content-assessment-dass21.png');
const reflectionIcon = require('assets/images/icons/icon-content-thought-framing.png');
const thoughtFramingIcon = require('assets/images/icons/icon-content-thought-framing.png');
const questionnaireIcon = require('assets/images/icons/icon-content-questionnaire.png');
const summaryIcon = require('assets/images/icons/icon-content-summary.png');
const quoteIcon = require('assets/images/icons/icon-content-quote.png');
const checklistIcon = require('assets/images/icons/icon-content-checklist.png');
const informationIcon = require('assets/images/icons/icon-content-information.png');
const toolIcon = require('assets/images/icons/icon-content-tool.png');
const overviewSessionsIcon = require('assets/images/icons/icon-content-overview_sessions.png');

import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onCreate: (string) => any;
    onClose: () => any;
}

class CreateContentCardModal extends React.Component<Props> {
    handleSubmit = (type) => {
        this.props.onCreate(type);
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        return (
            <Modal
                id="content-create-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-create-modal-header">New Card</Modal.Header>
                <Modal.Content id="content-create-modal-content">
                    <div className="ui centered five cards">
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('information');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Information</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={informationIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('audio');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Audio</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={audioIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('video');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Video</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={videoIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('input');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Input</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={inputIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('reflection');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Reflection</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={reflectionIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('quiz');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Quiz</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={quizIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('quote');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Quote</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={quoteIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('checklist');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Checklist</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={checklistIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('breathe');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Breathe</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={breatheIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('timer');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Timer</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={timerIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('summary');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Summary</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={summaryIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('task');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Task</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={goalSettingIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('thought_framing');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Thought Framing</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={thoughtFramingIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('assessment');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Assessment</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={questionnaireIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('assessment_result');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Assessment Result</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={assessmentResultIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('tool');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Tool Suggestions</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={toolIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('overview_sessions');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Programme Overview</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={overviewSessionsIcon} />
                            </Card.Content>
                        </Card>

                        <Card
                            link
                            onClick={() => {
                                this.handleSubmit('guide');
                            }}
                        >
                            <Card.Content>
                                <Card.Header id="content-card-title">Guide</Card.Header>
                            </Card.Content>
                            <Card.Content id={'content-card-content'}>
                                <Image centered id="content-card-image" src={overviewSessionsIcon} />
                            </Card.Content>
                        </Card>
                    </div>
                </Modal.Content>
                <Modal.Actions id="content-create-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateContentCardModal;
