import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
    const auth = useSelector((state) => state.authentication);
    return auth.isAuthenticated == true ? children : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
