import { call, put, takeLatest } from 'redux-saga/effects';
import {
    ORGANIZATION_EXEMPTIONS_REQUESTED,
    ORGANIZATION_EXEMPTIONS_IS_LOADING,
    ORGANIZATION_EXEMPTIONS_NOT_LOADING,
    ORGANIZATION_EXEMPTIONS_FETCHED,
    ORGANIZATION_EXEMPTION_CREATE_REQUESTED,
    ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED,
    ORGANIZATION_EXEMPTION_DELETE_REQUESTED
} from '../constants/organization';
import { MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE } from '../constants/view/modal';
import OrganizationService from '../../service/organization';

function* deleteExemption(action) {
    try {
        if (action.payload.email == null) {
            if (action.payload.completion_handler != null) {
                yield call(action.payload.completion_handler);
            }
            return;
        }
        const success = yield call(OrganizationService.deleteExemption, action.payload.email);
        if (success == true) {
            yield put({
                type: ORGANIZATION_EXEMPTIONS_REQUESTED
            });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler);
        }
    } catch (e) {
        console.error('[Organization Saga] ', e);
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler);
        }
    }
}

function* expireUser(action) {
    try {
        if (action.payload.user_id == null) {
            if (action.payload.completion_handler != null) {
                yield call(action.payload.completion_handler);
            }
            return;
        }
        const success = yield call(OrganizationService.expireExemptionUser, action.payload.user_id);
        if (success == true) {
            yield put({
                type: ORGANIZATION_EXEMPTIONS_REQUESTED
            });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler);
        }
    } catch (e) {
        console.error('[Organization Saga] ', e);
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler);
        }
    }
}

function* fetchAccessExemption(action) {
    try {
        yield put({ type: ORGANIZATION_EXEMPTIONS_IS_LOADING });
        const result = yield call(OrganizationService.fetchOrganizationExemption);
        yield put({ type: ORGANIZATION_EXEMPTIONS_NOT_LOADING });
        if (result != null) {
            yield put({
                type: ORGANIZATION_EXEMPTIONS_FETCHED,
                payload: {
                    exemptions: result.exemptions
                }
            });
        }
    } catch (e) {
        console.error('[Organization Saga] ', e);
    }
}

function* createAccessExemption(action) {
    if (action.payload.email == null) {
        console.log('[Organization Saga] No Email Detected');
        return;
    }
    if (action.payload.organization_id == null) {
        console.log('[Organization Saga] No Organization ID Detected');
        return;
    }

    try {
        yield call(
            OrganizationService.createOrganizationExemption,
            action.payload.email,
            action.payload.organization_id,
            action.payload.access_type
        );
        const result = yield put({ type: ORGANIZATION_EXEMPTIONS_REQUESTED });
        if (result != null) {
            yield put({ type: MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE });
        }
    } catch (e) {
        console.error('[Organization Saga] ', e);
    }
}

function* OrganizationSaga() {
    yield takeLatest(ORGANIZATION_EXEMPTIONS_REQUESTED, fetchAccessExemption);
    yield takeLatest(ORGANIZATION_EXEMPTION_CREATE_REQUESTED, createAccessExemption);
    yield takeLatest(ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED, expireUser);
    yield takeLatest(ORGANIZATION_EXEMPTION_DELETE_REQUESTED, deleteExemption);
}

export default OrganizationSaga;
