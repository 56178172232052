import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment, Label } from 'semantic-ui-react';

import './index.css';

class AddGuideConceptContentModal extends React.Component {
    state = {
        conceptTitle: null,
        hintTitle: null,
        hintSuggestions: [null]
    };

    resetState = () => {
        this.setState({
            conceptTitle: null,
            hintTitle: null,
            hintSuggestions: [null]
        });
    };

    checkFieldsValid = () => {
        const { conceptTitle, hintTitle, hintSuggestions } = this.state;
        if (!conceptTitle) {
            return false;
        }
        if (!hintTitle) {
            return false;
        }
        const suggestions = hintSuggestions.filter((suggestion) => !!suggestion);
        if (!suggestions.length > 0) {
            return false;
        }
        return true;
    };

    handleSubmit = () => {
        if (!this.checkFieldsValid()) return;
        var params = {};
        params['concept_title'] = this.state.conceptTitle;
        params['hint_title'] = this.state.hintTitle;
        const suggestions = this.state.hintSuggestions.filter((suggestion) => !!suggestion);
        if (suggestions.length > 0) {
            params['suggestions'] = suggestions;
        }
        this.props.onAdd(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleSuggestionChange = (event) => {
        const { name, value } = event.target;
        const updatedSuggestions = [...this.state.hintSuggestions];
        updatedSuggestions[name] = value;
        this.setState({
            hintSuggestions: updatedSuggestions
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    onAddNewSuggestion = () => {
        const updatedSuggestions = [...this.state.hintSuggestions];
        updatedSuggestions.push(null);
        this.setState({
            hintSuggestions: updatedSuggestions
        });
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-guide-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-guide-modal-header">Add Concept</Modal.Header>
                <Modal.Content id="content-guide-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.conceptTitle || ''}
                                type="text"
                                name="conceptTitle"
                                placeholder="Concept title"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.hintTitle || ''}
                                type="text"
                                name="hintTitle"
                                placeholder="Hint title"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <label>Hint suggestions</label>
                                <Button onClick={this.onAddNewSuggestion} color="teal">
                                    Add Suggestion
                                </Button>
                            </div>
                            <div className=" field">
                                {this.state.hintSuggestions.map((suggestion, index) => (
                                    <Form.Input
                                        value={this.state.hintSuggestions[index] || ''}
                                        type="text"
                                        key={index.toString()}
                                        name={index}
                                        placeholder={'Suggestion ' + (index + 1)}
                                        onChange={this.handleSuggestionChange}
                                    />
                                ))}
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-guide-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Add Concept
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddGuideConceptContentModal;
