import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    locale: string;
    card?: object;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditCaptionCardModal extends React.Component<Props> {
    state = {
        title: null,
        subtitle: null,
        image_url: null
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                title: card.content.title,
                subtitle: card.content.subtitle,
                image_url: card.content.image_url
            });
        }
    }

    resetState = () => {
        this.setState({
            title: null,
            subtitle: null,
            image_url: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.subtitle != null) params['subtitle'] = this.state.subtitle;
        if (this.state.image_url != null) params['image_url'] = this.state.image_url;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'subtitle':
                this.setState({
                    subtitle: inputValue
                });
                break;
            case 'image_url':
                this.setState({
                    image_url: inputValue
                });
                break;
        }
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-modal-header">Edit Caption Card</Modal.Header>
                <Modal.Content id="content-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.TextArea
                                value={this.state.title || ''}
                                rows={3}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.subtitle || ''}
                                rows={3}
                                type="text"
                                name="subtitle"
                                placeholder="Subtitle"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.image_url || ''}
                                type="text"
                                name="image_url"
                                placeholder="Image URL"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditCaptionCardModal;
