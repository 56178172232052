/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { sourceTypes } from 'components/assessment/immediateSelection';
import { connect } from 'react-redux';

class CreateDimensionQuestionModal extends React.Component {
    state = {
        questions: []
    };

    resetState = () => {
        this.setState({
            questions: []
        });
    };

    handleSubmit = () => {
        const params = {};
        const { questions } = this.state;

        const validQuestions = [];
        if (questions.length > 0) {
            questions.forEach((question) => {
                const validQuestion = {};
                const { uuid, id, prompt_en, prompt_yue, prompt_zh, answer_id } = question;
                if (id) {
                    validQuestion.id = id;
                } else {
                    return;
                }
                if (answer_id) {
                    validQuestion.answer_id = answer_id;
                } else {
                    return;
                }
                validQuestion.uuid = uuid;
                validQuestion.prompt_en = prompt_en;
                validQuestion.prompt_yue = prompt_yue;
                validQuestion.prompt_zh = prompt_zh;

                validQuestions.push(validQuestion);
            });
        }
        if (validQuestions.length > 0) {
            params['questions'] = validQuestions;
            params['assessment_id'] = this.props.assessment_id;
            params['dimension_id'] = this.props.dimension_id;
            this.props.onCreate(params);
        }
    };

    handleCategoryChange = (event, index) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        const { questions } = this.state;
        let new_questions = [...questions];
        let current_question = questions[index];
        current_question[name] = inputValue;
        this.setState({
            questions: new_questions
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    handleAddCategories = () => {
        const { questions } = this.state;
        let new_questions = [...questions];
        new_questions.push({
            uuid: uuidv4(), // some random string uuid
            id: '',
            prompt_en: '',
            prompt_yue: '',
            prompt_zh: '',
            answer_id: ''
        });
        this.setState({
            questions: new_questions
        });
    };

    handleRemoveCategory = (index) => {
        const { questions } = this.state;
        let new_questions = [...questions];
        new_questions.splice(index, 1);
        this.setState({
            questions: new_questions
        });
    };

    clearResult = () => {
        this.setState({
            questions: []
        });
    };

    render() {
        const { isOpen, isLoading, assessment } = this.props;
        const { questions } = this.state;
        const {
            current_assessment: { data, is_loading }
        } = assessment;
        if (is_loading) return;
        return (
            <Modal
                id="create-assessment-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-assessment-modal-header">Create Questions</Modal.Header>
                <Modal.Content id="create-assessment-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="sub-item">
                                <div className="section-header">
                                    <Card.Header className="property-sub-value">Add questions</Card.Header>
                                    <Icon id="category-add" name={'add'} onClick={this.handleAddCategories} />
                                </div>
                                {questions.map((question, index) => (
                                    <div className="category-container" key={index.toString()}>
                                        <div className="category-clear">
                                            <Icon name={'close'} onClick={() => this.handleRemoveCategory(index)} />
                                        </div>
                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="6" sm="6">
                                                <Form.Input
                                                    value={question.id || ''}
                                                    type="text"
                                                    name="id"
                                                    placeholder="Id"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={question.prompt_en || ''}
                                                    type="text"
                                                    name="prompt_en"
                                                    placeholder="Prompt English"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={question.prompt_yue || ''}
                                                    type="text"
                                                    name="prompt_yue"
                                                    placeholder="Prompt Cantonese"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={question.prompt_zh || ''}
                                                    type="text"
                                                    name="prompt_zh"
                                                    placeholder="Prompt Mandarin"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="6" sm="6">
                                                <FormControl fullWidth>
                                                    <InputLabel id="result-score-type">Select Answer</InputLabel>
                                                    <Select
                                                        labelId="result-score-type"
                                                        id="result-score-type"
                                                        value={question.answer_id || ''}
                                                        label="Select Answer"
                                                        name="answer_id"
                                                        onChange={(event) => this.handleCategoryChange(event, index)}
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {data?.answers?.map((answer, index) => (
                                                            <MenuItem value={answer.uuid}>{answer.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-assessment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Create
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDimensionQuestionModal);

CreateDimensionQuestionModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
