import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    locale: string;
    card?: object;
    itemIndex?: number;
    isOpen: boolean;
    isLoading: boolean;
    onAdd: (any) => any;
    onClose: () => any;
}

class AddQuoteCardModal extends React.Component<Props> {
    state = {
        text: null,
        author: null
    };

    componentDidMount() {
        const { card, itemIndex } = this.props;
        if (card != null && card.content != null && card.content.items != null && itemIndex != null) {
            this.setState({
                text: card.content.items[itemIndex].text,
                author: card.content.items[itemIndex].author
            });
        }
    }

    resetState = () => {
        this.setState({
            text: null,
            author: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.text != null) params['text'] = this.state.text;
        if (this.state.author != null) params['author'] = this.state.author;

        this.props.onAdd(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'text':
                this.setState({
                    text: inputValue
                });
                break;
            case 'author':
                this.setState({
                    author: inputValue
                });
                break;
        }
    };

    render() {
        const { locale } = this.props;
        return (
            <Modal
                id="content-quote-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-quote-modal-header">Add Quote Card</Modal.Header>
                <Modal.Content id="content-quote-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.text || ''}
                                type="text"
                                name="text"
                                placeholder="Text"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.author || ''}
                                type="text"
                                name="author"
                                placeholder="Author"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-quote-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddQuoteCardModal;
