import {
    CONTENT_USER_FEEDBACK_IS_LOADING,
    CONTENT_USER_FEEDBACK_NOT_LOADING,
    CONTENT_USER_FEEDBACK_FETCHED,
    CONTENT_FOCUS_FETCHED,
    CONTENT_CONTEXT_IS_LOADING,
    CONTENT_CONTEXT_NOT_LOADING,
    CONTENT_CONTEXT_FETCHED,
    CONTENT_CATEGORY_IS_LOADING,
    CONTENT_CATEGORY_NOT_LOADING,
    CONTENT_CATEGORY_FETCHED,
    CONTENT_CATEGORY_HEADER_IS_LOADING,
    CONTENT_CATEGORY_HEADER_NOT_LOADING,
    CONTENT_CATEGORY_HEADER_FETCHED,
    CONTENT_PROBLEM_IS_LOADING,
    CONTENT_PROBLEM_NOT_LOADING,
    CONTENT_PROBLEM_FETCHED,
    CONTENT_TECHNIQUE_IS_LOADING,
    CONTENT_TECHNIQUE_NOT_LOADING,
    CONTENT_TECHNIQUE_FETCHED,
    CONTENT_INTENTION_IS_LOADING,
    CONTENT_INTENTION_NOT_LOADING,
    CONTENT_INTENTION_FETCHED,
    CONTENT_TOOL_IS_LOADING,
    CONTENT_TOOL_NOT_LOADING,
    CONTENT_TOOL_FETCHED,
    CONTENT_TOOL_DELETE_COMPLETED,
    CONTENT_GOALS_IS_LOADING,
    CONTENT_GOALS_NOT_LOADING,
    CONTENT_GOALS_FETCHED,
    CONTENT_GOAL_HEADERS_IS_LOADING,
    CONTENT_GOAL_HEADERS_NOT_LOADING,
    CONTENT_GOAL_HEADERS_FETCHED,
    CONTENT_GOAL_HEADER_DELETE_COMPLETED,
    CONTENT_GOAL_JOURNIES_IS_LOADING,
    CONTENT_GOAL_JOURNIES_NOT_LOADING,
    CONTENT_GOAL_JOURNIES_FETCHED,
    CONTENT_JOURNEY_IS_LOADING,
    CONTENT_JOURNEY_NOT_LOADING,
    CONTENT_JOURNEY_FETCHED,
    CONTENT_JOURNEY_DELETE_COMPLETED,
    CONTENT_GOAL_EXERCISES_IS_LOADING,
    CONTENT_GOAL_EXERCISES_NOT_LOADING,
    CONTENT_GOAL_EXERCISES_FETCHED,
    CONTENT_EXERCISE_IS_LOADING,
    CONTENT_EXERCISE_NOT_LOADING,
    CONTENT_EXERCISE_FETCHED,
    CONTENT_EXERCISES_FETCHED,
    CONTENT_EXERCISE_DELETE_COMPLETED,
    CONTENT_EXERCISE_CONTENT_IS_LOADING,
    CONTENT_EXERCISE_CONTENT_NOT_LOADING,
    CONTENT_EXERCISE_CONTENT_FETCHED,
    CONTENT_EXERCISE_MOVE_REQESTED,
    CONTENT_GOAL_PUBLISH_REQUESTED,
    CONTENT_JOURNEY_PUBLISH_REQUESTED,
    CONTENT_TOOL_PUBLISH_REQUESTED,
    CONTENT_EXERCISE_PUBLISH_REQUESTED,
    CONTENT_GOAL_LOCK_REQUESTED,
    CONTENT_JOURNEY_LOCK_REQUESTED,
    CONTENT_EXERCISE_LOCK_REQUESTED,
    CONTENT_TECHNIQUE_TAG_IS_LOADING,
    CONTENT_TECHNIQUE_TAG_NOT_LOADING,
    CONTENT_TECHNIQUE_TAG_FETCHED,
    CONTENT_QUOTE_FETCHED,
    CONTENT_QUOTE_NOT_LOADING,
    CONTENT_QUOTE_IS_LOADING,
    CONTENT_QUOTE_DELETE_COMPLETED,
    CONTENT_SKILL_IS_LOADING,
    CONTENT_SKILL_NOT_LOADING,
    CONTENT_SKILL_FETCHED,
    CONTENT_TOOL_TAG_KEYWORD_IS_LOADING,
    CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING
} from '../constants/content';

const initialState = {
    tools: {
        is_loading: false,
        data: []
    },
    moments: {
        is_loading: false,
        data: []
    },
    contexts: {
        is_loading: false,
        data: []
    },
    techniques: {
        is_loading: false,
        data: []
    },
    intentions: {
        is_loading: false,
        data: []
    },
    categories: {
        is_loading: false,
        data: []
    },
    category_headers: {
        is_loading: false,
        data: []
    },
    goals: {
        isLoading: false,
        data: []
    },
    problems: {
        is_loading: false,
        data: []
    },
    skills: {
        is_loading: false,
        data: []
    },
    goal_headers: {
        isLoading: false,
        data: []
    },
    journies: {
        isLoading: false,
        data: []
    },
    exercises: {
        isLoading: false,
        data: []
    },
    contents: {
        isLoading: false,
        data: []
    },
    user_feedbacks: {
        is_loading: false,
        data: []
    },
    technique_tags: {
        is_loading: false,
        data: []
    },
    focuses: {
        data: []
    },
    quotes: {
        is_loading: false,
        data: []
    },
    keyword_loading: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CONTENT_TECHNIQUE_TAG_IS_LOADING:
            return {
                ...state,
                technique_tags: {
                    ...state.technique_tags,
                    is_loading: true
                }
            };
        case CONTENT_TECHNIQUE_TAG_NOT_LOADING:
            return {
                ...state,
                technique_tags: {
                    ...state.technique_tags,
                    is_loading: false
                }
            };
        case CONTENT_TECHNIQUE_TAG_FETCHED:
            return {
                ...state,
                technique_tags: {
                    ...state.technique_tags,
                    data: action.payload.tags || []
                }
            };
        case CONTENT_USER_FEEDBACK_IS_LOADING:
            return {
                ...state,
                user_feedbacks: {
                    ...state.user_feedbacks,
                    is_loading: true
                }
            };
        case CONTENT_USER_FEEDBACK_NOT_LOADING:
            return {
                ...state,
                user_feedbacks: {
                    ...state.user_feedbacks,
                    is_loading: false
                }
            };
        case CONTENT_USER_FEEDBACK_FETCHED:
            return {
                ...state,
                user_feedbacks: {
                    ...state.user_feedbacks,
                    data: action.payload.user_feedbacks || []
                }
            };
        case CONTENT_CONTEXT_IS_LOADING:
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    is_loading: true
                }
            };
        case CONTENT_CONTEXT_NOT_LOADING:
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    is_loading: false
                }
            };
        case CONTENT_CONTEXT_FETCHED:
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    data: action.payload.contexts
                }
            };
        case CONTENT_CATEGORY_IS_LOADING:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    is_loading: true
                }
            };
        case CONTENT_CATEGORY_NOT_LOADING:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    is_loading: false
                }
            };
        case CONTENT_CATEGORY_FETCHED:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: action.payload.categories
                }
            };
        case CONTENT_CATEGORY_HEADER_IS_LOADING:
            return {
                ...state,
                category_headers: {
                    ...state.category_headers,
                    is_loading: true
                }
            };
        case CONTENT_CATEGORY_HEADER_NOT_LOADING:
            return {
                ...state,
                category_headers: {
                    ...state.category_headers,
                    is_loading: false
                }
            };
        case CONTENT_CATEGORY_HEADER_FETCHED:
            return {
                ...state,
                category_headers: {
                    ...state.category_headers,
                    data: action.payload.category_headers
                }
            };
        case CONTENT_TECHNIQUE_IS_LOADING:
            return {
                ...state,
                techniques: {
                    ...state.techniques,
                    is_loading: true
                }
            };
        case CONTENT_TECHNIQUE_NOT_LOADING:
            return {
                ...state,
                techniques: {
                    ...state.techniques,
                    is_loading: false
                }
            };
        case CONTENT_TECHNIQUE_FETCHED:
            return {
                ...state,
                techniques: {
                    ...state.techniques,
                    data: action.payload.techniques
                }
            };
        case CONTENT_INTENTION_IS_LOADING:
            return {
                ...state,
                intentions: {
                    ...state.intentions,
                    is_loading: true
                }
            };
        case CONTENT_INTENTION_NOT_LOADING:
            return {
                ...state,
                intentions: {
                    ...state.intentions,
                    is_loading: false
                }
            };
        case CONTENT_INTENTION_FETCHED:
            return {
                ...state,
                intentions: {
                    ...state.intentions,
                    data: action.payload.intentions
                }
            };
        case CONTENT_PROBLEM_IS_LOADING:
            return {
                ...state,
                problems: {
                    ...state.problems,
                    is_loading: true
                }
            };
        case CONTENT_PROBLEM_NOT_LOADING:
            return {
                ...state,
                problems: {
                    ...state.problems,
                    is_loading: false
                }
            };
        case CONTENT_PROBLEM_FETCHED:
            return {
                ...state,
                problems: {
                    ...state.problems,
                    data: action.payload.problems
                }
            };
        case CONTENT_SKILL_IS_LOADING:
            return {
                ...state,
                skills: {
                    ...state.skills,
                    is_loading: true
                }
            };
        case CONTENT_SKILL_NOT_LOADING:
            return {
                ...state,
                skills: {
                    ...state.skills,
                    is_loading: false
                }
            };
        case CONTENT_SKILL_FETCHED:
            return {
                ...state,
                skills: {
                    ...state.skills,
                    data: action.payload.skills
                }
            };
        case CONTENT_TOOL_IS_LOADING:
            return {
                ...state,
                tools: {
                    ...state.tools,
                    is_loading: true
                }
            };
        case CONTENT_TOOL_NOT_LOADING:
            return {
                ...state,
                tools: {
                    ...state.tools,
                    is_loading: false
                }
            };
        case CONTENT_TOOL_FETCHED: {
            // Replace existing duplicate journies
            var newTools = [...state.tools.data];
            action.payload.tools.map((tool) => {
                const index = newTools.findIndex((existingTool) => {
                    return tool.id == existingTool.id;
                });
                if (index != -1) {
                    newTools[index] = tool;
                } else {
                    newTools.push(tool);
                }
            });
            return {
                ...state,
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_TOOL_DELETE_COMPLETED: {
            const newTools = [...state.tools.data];
            return {
                ...state,
                tools: {
                    ...state.tools,
                    data: newTools.filter((tool) => {
                        return tool.id != action.payload.tool_id;
                    })
                }
            };
        }
        case CONTENT_FOCUS_FETCHED:
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    data: action.payload.focuses
                }
            };
        case CONTENT_GOALS_IS_LOADING:
            return {
                ...state,
                goals: {
                    ...state.goals,
                    isLoading: true
                }
            };
        case CONTENT_GOALS_NOT_LOADING:
            return {
                ...state,
                goals: {
                    ...state.goals,
                    isLoading: false
                }
            };
        case CONTENT_GOALS_FETCHED:
            return {
                ...state,
                goals: {
                    ...state.goals,
                    data: action.payload.goals
                }
            };
        case CONTENT_GOAL_HEADERS_IS_LOADING:
            return {
                ...state,
                goal_headers: {
                    ...state.goal_headers,
                    isLoading: true
                }
            };
        case CONTENT_GOAL_HEADERS_NOT_LOADING:
            return {
                ...state,
                goal_headers: {
                    ...state.goal_headers,
                    isLoading: false
                }
            };
        case CONTENT_GOAL_HEADERS_FETCHED: {
            // Replace existing duplicate goal headers
            var newGoalHeaders = [...state.goal_headers.data];
            action.payload.goal_headers.map((goal_header) => {
                const index = newGoalHeaders.findIndex((existingGoalHeader) => {
                    return goal_header.id == existingGoalHeader.id;
                });
                if (index != -1) {
                    newGoalHeaders[index] = goal_header;
                } else {
                    newGoalHeaders.push(goal_header);
                }
            });
            return {
                ...state,
                goal_headers: {
                    ...state.goal_headers,
                    data: newGoalHeaders
                }
            };
        }
        case CONTENT_GOAL_HEADER_DELETE_COMPLETED: {
            var oldGoalHeaders = [...state.goal_headers.data];
            var newGoalHeaders = oldGoalHeaders.filter((goalHeader) => {
                return goalHeader.id != action.payload.goal_header_id;
            });
            return {
                ...state,
                goal_headers: {
                    ...state.goal_headers,
                    data: newGoalHeaders
                }
            };
        }
        case CONTENT_GOAL_JOURNIES_IS_LOADING:
            return {
                ...state,
                journies: {
                    ...state.journies,
                    isLoading: true
                }
            };
        case CONTENT_GOAL_JOURNIES_NOT_LOADING:
            return {
                ...state,
                journies: {
                    ...state.journies,
                    isLoading: false
                }
            };
        case CONTENT_GOAL_JOURNIES_FETCHED: {
            // Replace existing duplicate journies
            var newJournies = [...state.journies.data];
            action.payload.journies.map((journey) => {
                const index = newJournies.findIndex((existingJourney) => {
                    return journey.id == existingJourney.id;
                });
                if (index != -1) {
                    newJournies[index] = journey;
                } else {
                    newJournies.push(journey);
                }
            });
            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJournies
                }
            };
        }
        case CONTENT_GOAL_EXERCISES_IS_LOADING:
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    isLoading: true
                }
            };
        case CONTENT_GOAL_EXERCISES_NOT_LOADING:
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    isLoading: false
                }
            };
        case CONTENT_GOAL_EXERCISES_FETCHED: {
            // Replace existing duplicate journies
            var newExercises = [...state.exercises.data];
            action.payload.exercises.map((exercise) => {
                const index = newExercises.findIndex((existingExercise) => {
                    return exercise.id == existingExercise.id;
                });
                if (index != -1) {
                    newExercises[index] = exercise;
                } else {
                    newExercises.push(exercise);
                }
            });
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises
                }
            };
        }
        case CONTENT_JOURNEY_IS_LOADING:
            return {
                ...state,
                journies: {
                    ...state.journies,
                    isLoading: true
                }
            };
        case CONTENT_JOURNEY_NOT_LOADING:
            return {
                ...state,
                journies: {
                    ...state.journies,
                    isLoading: false
                }
            };
        case CONTENT_JOURNEY_FETCHED: {
            const journey = action.payload.journey;
            const newJournies = [...state.journies.data];
            const index = newJournies.findIndex((existingJourney) => {
                return journey.id == existingJourney.id;
            });
            if (index != -1) {
                newJournies[index] = journey;
            } else {
                newJournies.push(journey);
            }
            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJournies
                }
            };
        }
        case CONTENT_JOURNEY_DELETE_COMPLETED: {
            const newJournies = [...state.journies.data];
            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJournies.filter((journey) => {
                        return journey.id != action.payload.journey_id;
                    })
                }
            };
        }
        case CONTENT_EXERCISE_IS_LOADING:
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    isLoading: true
                }
            };
        case CONTENT_EXERCISE_NOT_LOADING:
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    isLoading: false
                }
            };
        case CONTENT_EXERCISE_FETCHED: {
            const exercise = action.payload.exercise;
            const newExercises = [...state.exercises.data];
            const exerciseIndex = newExercises.findIndex((existingExercise) => {
                return exercise.id == existingExercise.id;
            });
            if (exerciseIndex != -1) {
                newExercises[exerciseIndex] = exercise;
            } else {
                newExercises.push(exercise);
            }
            const newJournies = [...state.journies.data];
            newJournies.forEach((journey) => {
                if (journey.exercises != null) {
                    const index = journey.exercises.findIndex((existingExercise) => {
                        return exercise.id == existingExercise.id;
                    });
                    if (index != -1) {
                        journey.exercises[index] = exercise;
                    }
                }
            });
            const newTools = [...state.tools.data];
            newTools.forEach((tool) => {
                if (tool.exercises != null) {
                    const index = tool.exercises.findIndex((existingExercise) => {
                        return exercise.id == existingExercise.id;
                    });
                    if (index != -1) {
                        tool.exercises[index] = exercise;
                    }
                }
            });
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises
                },
                journies: {
                    ...state.journies,
                    data: newJournies
                },
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_EXERCISES_FETCHED: {
            // Replace existing duplicate exercises
            var newExercises = [...state.exercises.data];
            action.payload.exercises.forEach((exercise) => {
                const index = newExercises.findIndex((existingExercise) => {
                    return exercise.id == existingExercise.id;
                });
                if (index != -1) {
                    newExercises[index] = exercise;
                } else {
                    newExercises.push(exercise);
                }
            });
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises
                }
            };
        }
        case CONTENT_EXERCISE_MOVE_REQESTED: {
            if (action.payload.exercise_id == null || action.payload.to_order == null) {
                return;
            }
            const targetExerciseId = action.payload.exercise_id;
            // Index is -1 Item Order
            const targetIndex = action.payload.to_order - 1;

            const newJournies = [...state.journies.data];
            newJournies.forEach((journey) => {
                if (journey.exercises != null) {
                    const index = journey.exercises.findIndex((existingExercise) => {
                        return targetExerciseId == existingExercise.id;
                    });
                    if (index != -1) {
                        // Change the order
                        const targetExercise = journey.exercises[index];
                        journey.exercises.splice(index, 1);
                        journey.exercises.splice(targetIndex, 0, targetExercise);
                    }
                }
            });

            const newTools = [...state.tools.data];
            newTools.forEach((tool) => {
                if (tool.exercises != null) {
                    const index = tool.exercises.findIndex((existingExercise) => {
                        return targetExerciseId == existingExercise.id;
                    });
                    if (index != -1) {
                        // Change the order
                        const targetExercise = tool.exercises[index];
                        tool.exercises.splice(index, 1);
                        tool.exercises.splice(targetIndex, 0, targetExercise);
                    }
                }
            });

            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJournies
                },
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_EXERCISE_DELETE_COMPLETED: {
            const newExercises = [...state.exercises.data];
            const newJournies = [...state.journies.data];
            const newTools = [...state.tools.data];
            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises.filter((exercise) => {
                        return exercise.id != action.payload.exercise_id;
                    })
                },
                journies: {
                    ...state.journies,
                    data: newJournies.map((journey) => {
                        if (journey.exercises != null) {
                            return {
                                ...journey,
                                exercises: journey.exercises.filter((exercise) => {
                                    return exercise.id != action.payload.exercise_id;
                                })
                            };
                        }
                        return journey;
                    })
                },
                tools: {
                    ...state.tools,
                    data: newTools.map((tool) => {
                        if (tool.exercises != null) {
                            return {
                                ...tool,
                                exercises: tool.exercises.filter((exercise) => {
                                    return exercise.id != action.payload.exercise_id;
                                })
                            };
                        }
                        return tool;
                    })
                }
            };
        }
        case CONTENT_EXERCISE_CONTENT_IS_LOADING:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    isLoading: true
                }
            };
        case CONTENT_EXERCISE_CONTENT_NOT_LOADING:
            return {
                ...state,
                contents: {
                    ...state.contents,
                    isLoading: false
                }
            };
        case CONTENT_EXERCISE_CONTENT_FETCHED: {
            const content = action.payload.content;
            const newContents = [...state.contents.data];
            const index = newContents.findIndex((existingContent) => {
                return content.exercise_id == existingContent.exercise_id;
            });
            if (index != -1) {
                newContents[index] = content;
            } else {
                newContents.push(content);
            }
            return {
                ...state,
                contents: {
                    ...state.contents,
                    data: newContents
                }
            };
        }
        case CONTENT_GOAL_PUBLISH_REQUESTED: {
            var newGoals = [...state.goals.data];
            const index = newGoals.findIndex((existingGoal) => {
                return action.payload.goal_id == existingGoal.id;
            });
            if (index != -1) {
                newGoals[index].has_published = action.payload.has_published;
            }
            return {
                ...state,
                goals: {
                    ...state.goals,
                    data: newGoals
                }
            };
        }
        case CONTENT_JOURNEY_PUBLISH_REQUESTED: {
            var newJourneys = [...state.journies.data];
            const index = newJourneys.findIndex((existingJourney) => {
                return action.payload.journey_id == existingJourney.id;
            });
            if (index != -1) {
                newJourneys[index].has_published = action.payload.has_published;
            }
            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJourneys
                }
            };
        }
        case CONTENT_TOOL_PUBLISH_REQUESTED: {
            var newTools = [...state.tools.data];
            const index = newTools.findIndex((existingJourney) => {
                return action.payload.tool_id == existingJourney.id;
            });
            if (index != -1) {
                newTools[index].has_published = action.payload.has_published;
            }
            return {
                ...state,
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_EXERCISE_PUBLISH_REQUESTED: {
            // Update Exercises
            var newExercises = [...state.exercises.data];
            const index = newExercises.findIndex((exercise) => {
                return action.payload.exercise_id == exercise.id;
            });
            if (index != -1) {
                newExercises[index].has_published = action.payload.has_published;
            }

            // Update Journey Exercises
            const newJournies = [...state.journies.data];
            newJournies.forEach((journey) => {
                if (journey.exercises != null) {
                    const index = journey.exercises.findIndex((existingExercise) => {
                        return action.payload.exercise_id == existingExercise.id;
                    });
                    if (index != -1) {
                        journey.exercises[index].has_published = action.payload.has_published;
                    }
                }
            });

            // Update Tool Exercises
            const newTools = [...state.tools.data];
            newTools.forEach((tool) => {
                if (tool.exercises != null) {
                    const index = tool.exercises.findIndex((existingExercise) => {
                        return action.payload.exercise_id == existingExercise.id;
                    });
                    if (index != -1) {
                        tool.exercises[index].has_published = action.payload.has_published;
                    }
                }
            });

            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises
                },
                journies: {
                    ...state.journies,
                    data: newJournies
                },
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_GOAL_LOCK_REQUESTED: {
            var newGoals = [...state.goals.data];
            const index = newGoals.findIndex((existingGoal) => {
                return action.payload.goal_id == existingGoal.id;
            });
            if (index != -1) {
                newGoals[index].is_locked = action.payload.is_locked;
            }
            return {
                ...state,
                goals: {
                    ...state.goals,
                    data: newGoals
                }
            };
        }
        case CONTENT_JOURNEY_LOCK_REQUESTED: {
            var newJourneys = [...state.journies.data];
            const index = newJourneys.findIndex((existingJourney) => {
                return action.payload.journey_id == existingJourney.id;
            });
            if (index != -1) {
                newJourneys[index].is_locked = action.payload.is_locked;
            }
            return {
                ...state,
                journies: {
                    ...state.journies,
                    data: newJourneys
                }
            };
        }
        case CONTENT_EXERCISE_LOCK_REQUESTED: {
            // Update Exercises
            var newExercises = [...state.exercises.data];
            const index = newExercises.findIndex((exercise) => {
                return action.payload.exercise_id == exercise.id;
            });
            if (index != -1) {
                newExercises[index].is_locked = action.payload.is_locked;
            }

            // Update Journey Exercises
            const newJournies = [...state.journies.data];
            newJournies.forEach((journey) => {
                if (journey.exercises != null) {
                    const index = journey.exercises.findIndex((existingExercise) => {
                        return action.payload.exercise_id == existingExercise.id;
                    });
                    if (index != -1) {
                        journey.exercises[index].is_locked = action.payload.is_locked;
                    }
                }
            });

            // Update Tool Exercises
            const newTools = [...state.tools.data];
            newTools.forEach((tool) => {
                if (tool.exercises != null) {
                    const index = tool.exercises.findIndex((existingExercise) => {
                        return action.payload.exercise_id == existingExercise.id;
                    });
                    if (index != -1) {
                        tool.exercises[index].is_locked = action.payload.is_locked;
                    }
                }
            });

            return {
                ...state,
                exercises: {
                    ...state.exercises,
                    data: newExercises
                },
                journies: {
                    ...state.journies,
                    data: newJournies
                },
                tools: {
                    ...state.tools,
                    data: newTools
                }
            };
        }
        case CONTENT_QUOTE_IS_LOADING:
            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    is_loading: true
                }
            };
        case CONTENT_QUOTE_NOT_LOADING:
            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    is_loading: false
                }
            };
        case CONTENT_QUOTE_FETCHED: {
            // Replace existing duplicate journies
            var newQuotes = [...state.quotes.data];
            action.payload.quotes.map((quote) => {
                const index = newQuotes.findIndex((existingQuote) => {
                    return quote.id == existingQuote.id;
                });
                if (index != -1) {
                    newQuotes[index] = quote;
                } else {
                    newQuotes.push(quote);
                }
            });
            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    data: newQuotes
                }
            };
        }
        case CONTENT_QUOTE_DELETE_COMPLETED: {
            const newQuotes = [...state.quotes.data];
            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    data: newQuotes.filter((quote) => {
                        return quote.id != action.payload.quote_id;
                    })
                }
            };
        }

        case CONTENT_TOOL_TAG_KEYWORD_IS_LOADING: {
            return {
                ...state,
                keyword_loading: true
            };
        }

        case CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING: {
            return {
                ...state,
                keyword_loading: false
            };
        }
    }
    return state;
}
