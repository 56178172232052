import {
    EAP_FOCUS_CREATE_REQUESTED,
    EAP_FOCUS_FETCH_REQUESTED,
    EAP_FOCUS_GET_BY_ID_REQUESTED,
    EAP_FOCUS_REMOVE_REQUESTED,
    EAP_FOCUS_TAG_KEYWORD_ADD_REQUESTED,
    EAP_FOCUS_TAG_KEYWORD_REMOVE_REQUESTED,
    EAP_FOCUS_UPDATE_REQUESTED,
    EAP_FRAMEWORK_CREATE_REQUESTED,
    EAP_FRAMEWORK_FETCH_REQUESTED,
    EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
    EAP_FRAMEWORK_REMOVE_REQUESTED,
    EAP_FRAMEWORK_UPDATE_REQUESTED,
    EAP_SPECIALITY_CREATE_REQUESTED,
    EAP_SPECIALITY_FETCH_REQUESTED,
    EAP_SPECIALITY_GET_BY_ID_REQUESTED,
    EAP_SPECIALITY_REMOVE_REQUESTED,
    EAP_SPECIALITY_UPDATE_REQUESTED
} from 'redux/constants/eap';

export function fetchEAPFocuses() {
    return {
        type: EAP_FOCUS_FETCH_REQUESTED
    };
}

export function getEAPFocusDetail(focus_id) {
    return {
        type: EAP_FOCUS_GET_BY_ID_REQUESTED,
        payload: {
            focus_id
        }
    };
}

export function createEAPFocus(params) {
    return {
        type: EAP_FOCUS_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function editEAPFocus(params) {
    return {
        type: EAP_FOCUS_UPDATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function removeEAPFocus(focus_id) {
    return {
        type: EAP_FOCUS_REMOVE_REQUESTED,
        payload: {
            focus_id
        }
    };
}

export function addEAPFocusKeyword(focus_id, keyword) {
    return {
        type: EAP_FOCUS_TAG_KEYWORD_ADD_REQUESTED,
        payload: {
            focus_id,
            keyword
        }
    };
}

export function removeEAPFocusKeyword(focus_id, keyword) {
    return {
        type: EAP_FOCUS_TAG_KEYWORD_REMOVE_REQUESTED,
        payload: {
            focus_id,
            keyword
        }
    };
}

// EAP Framework

export function fetchEAPFrameworks() {
    return {
        type: EAP_FRAMEWORK_FETCH_REQUESTED
    };
}

export function getEAPFrameworkDetail(framework_id) {
    return {
        type: EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
        payload: {
            framework_id
        }
    };
}

export function createEAPFramework(params) {
    return {
        type: EAP_FRAMEWORK_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function editEAPFramework(params) {
    return {
        type: EAP_FRAMEWORK_UPDATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function removeEAPFramework(framework_id) {
    return {
        type: EAP_FRAMEWORK_REMOVE_REQUESTED,
        payload: {
            framework_id
        }
    };
}

// EAP Speciality

export function fetchEAPSpecialities() {
    return {
        type: EAP_SPECIALITY_FETCH_REQUESTED
    };
}

export function getEAPSpecialityDetail(speciality_id) {
    return {
        type: EAP_SPECIALITY_GET_BY_ID_REQUESTED,
        payload: {
            speciality_id
        }
    };
}

export function createEAPSpeciality(params) {
    return {
        type: EAP_SPECIALITY_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function editEAPSpeciality(params) {
    return {
        type: EAP_SPECIALITY_UPDATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function removeEAPSpeciality(speciality_id) {
    return {
        type: EAP_SPECIALITY_REMOVE_REQUESTED,
        payload: {
            speciality_id
        }
    };
}
