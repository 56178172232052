import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import ReactDropzone from 'react-dropzone';
import ContentService from '../../../../service/content';

import './index.css';

interface Props {
    locale: string;
    exercise?: object;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class EditExerciseModal extends React.Component<Props> {
    state = {
        title: null,
        description_short: null,
        description_long: null,
        metadata: null,
        time: null,
        session_type: null,
        framework: null,
        thumbnail_url: null,
        icon_url: null,
        cover_url: null
    };

    componentDidMount() {
        const { exercise } = this.props;
        if (exercise != null) {
            this.setState({
                exercise_id: exercise.id,
                title: exercise.title,
                description_short: exercise.description_short,
                description_long: exercise.description_long,
                metadata: exercise.metadata,
                time: exercise.time,
                session_type: exercise.session_type,
                framework: exercise.framework,
                thumbnail_url: exercise.thumbnail_url,
                icon_url: exercise.icon_url,
                cover_url: exercise.cover_url
            });
        }
    }

    onDrop = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadExerciseImage(this.state.exercise_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        cover_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    resetState = () => {
        this.setState({
            title: null,
            description_short: null,
            description_long: null,
            metadata: null,
            time: null,
            session_type: null,
            framework: null,
            icon_url: null,
            thumbnail_url: null,
            cover_url: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'description_short':
                this.setState({
                    description_short: inputValue
                });
                break;
            case 'description_long':
                this.setState({
                    description_long: inputValue
                });
                break;
            case 'metadata':
                this.setState({
                    metadata: inputValue
                });
                break;
            case 'time':
                this.setState({
                    time: inputValue
                });
                break;
            case 'session_type':
                this.setState({
                    session_type: inputValue
                });
                break;
            case 'framework':
                this.setState({
                    framework: inputValue
                });
                break;
            case 'thumbnail_url':
                this.setState({
                    thumbnail_url: inputValue
                });
                break;
            case 'icon_url':
                this.setState({
                    icon_url: inputValue
                });
                break;
            case 'cover_url':
                this.setState({
                    cover_url: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.description_short != null) params['description_short'] = this.state.description_short;
        if (this.state.description_long != null) params['description_long'] = this.state.description_long;
        if (this.state.metadata != null) params['metadata'] = this.state.metadata;
        if (this.state.time != null) params['time'] = this.state.time;
        if (this.state.session_type != null) params['session_type'] = this.state.session_type;
        if (this.state.framework != null) params['framework'] = this.state.framework;
        if (this.state.icon_url != null) params['icon_url'] = this.state.icon_url;
        if (this.state.thumbnail_url != null) params['thumbnail_url'] = this.state.thumbnail_url;
        if (this.state.cover_url != null) params['cover_url'] = this.state.cover_url;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading, locale } = this.props;
        return (
            <Modal
                id="edit-exercise-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="edit-exercise-modal-header">Edit Exercise</Modal.Header>
                <Modal.Content id="edit-exercise-modal-content">
                    <div id={'image-drop'}>
                        <div>
                            <ReactDropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div
                                        id={isDragActive ? 'cover-image-drop-inner-selected' : 'cover-image-drop-inner'}
                                        {...getRootProps()}
                                    >
                                        {this.state.is_uploading == true ? (
                                            <div class="ui active inverted dimmer">
                                                <div class="ui loader"></div>
                                            </div>
                                        ) : (
                                            <div>
                                                <input {...getInputProps()} />
                                                <p>Drop the cover image here, or click to select image</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ReactDropzone>
                        </div>
                    </div>
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.Input
                                id={'title-input'}
                                value={this.state.title || ''}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            {/* <Form.Input
                        value={this.state.description_short || ""}
                        type="text"
                        name="description_short"
                        placeholder="Short Description"
                        onChange={this.handleInputChange}
                    /> */}
                            <Form.TextArea
                                value={this.state.description_long || ''}
                                rows="2"
                                type="text"
                                name="description_long"
                                placeholder="Description"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.metadata || ''}
                                type="text"
                                name="metadata"
                                placeholder="Metadata"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.time || ''}
                                type="text"
                                name="time"
                                placeholder="Time (minutes)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.session_type || ''}
                                type="text"
                                name="session_type"
                                placeholder="Session Type (learn | assess | practice)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.framework || ''}
                                type="text"
                                name="framework"
                                placeholder="Framework ( self_grounding | self_reflection | cognition | action | information )"
                                onChange={this.handleInputChange}
                            />
                            {/* <Form.Input
                        value={this.state.icon_url || ""}
                        type="text"
                        name="icon_url"
                        placeholder="Icon URL (Optional)"
                        onChange={this.handleInputChange}
                    />
                    <Form.Input
                        value={this.state.thumbnail_url || ""}
                        type="text"
                        name="thumbnail_url"
                        placeholder="Thumbnail URL (Optional)"
                        onChange={this.handleInputChange}
                    /> */}
                            <Form.Input
                                value={this.state.cover_url || ''}
                                type="text"
                                name="cover_url"
                                placeholder="Cover URL (Optional)"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-exercise-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Exercise
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditExerciseModal;
