import {
    ASSESSMENT_SOURCE_CREATE_REQUESTED,
    ASSESSMENT_SOURCE_GET_REQUESTED,
    ASSESSMENT_SOURCE_FETCH_REQUESTED,
    ASSESSMENT_SOURCE_DELETE_REQUESTED,
    ASSESSMENT_SOURCE_DELETE_COMPLETED,
    ASSESSMENT_SOURCE_EDIT_REQUESTED,
    ASSESSMENT_DIMENSION_CREATE_REQUEST,
    ASSESSMENT_CATEGORY_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST,
    ASSESSMENT_ANSWER_ADD_REQUEST,
    ASSESSMENT_QUESTION_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_EDIT_REQUEST,
    ASSESSMENT_CATEGORY_EDIT_REQUEST,
    ASSESSMENT_CATEGORY_DELETE_REQUEST,
    ASSESSMENT_QUESTION_EDIT_REQUEST,
    ASSESSMENT_QUESTION_DELETE_REQUEST,
    ASSESSMENT_ANSWER_EDIT_REQUEST,
    ASSESSMENT_ANSWER_DELETE_REQUEST,
    ASSESSMENT_DIMENSION_DELETE_REQUEST,
    ASSESSMENT_DIMENSION_MODULE_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_MODULE_REMOVE_REQUEST
} from '../constants/assessment';

// Action Creators

export function fetchAssessmentSources(source_type) {
    return {
        type: ASSESSMENT_SOURCE_FETCH_REQUESTED,
        payload: {
            source_type
        }
    };
}

export function createAssessmentSource(params) {
    return {
        type: ASSESSMENT_SOURCE_CREATE_REQUESTED,
        payload: {
            params: params
        }
    };
}

export function editAssessmentSource(params) {
    return {
        type: ASSESSMENT_SOURCE_EDIT_REQUESTED,
        payload: {
            params
        }
    };
}

export function deleteAssessmentSource(params) {
    return {
        type: ASSESSMENT_SOURCE_DELETE_REQUESTED,
        payload: {
            params
        }
    };
}

export function getAssessmentSourceByUuid(assessment_id) {
    return {
        type: ASSESSMENT_SOURCE_GET_REQUESTED,
        payload: {
            assessment_id: assessment_id
        }
    };
}

export function createAssessmentDimension(assessment_id, params) {
    return {
        type: ASSESSMENT_DIMENSION_CREATE_REQUEST,
        payload: {
            assessment_id: assessment_id,
            params: params
        }
    };
}

export function editAssessmentDimension(params) {
    return {
        type: ASSESSMENT_DIMENSION_EDIT_REQUEST,
        payload: {
            params: params
        }
    };
}

export function deleteAssessmentDimension(params) {
    return {
        type: ASSESSMENT_DIMENSION_DELETE_REQUEST,
        payload: {
            params
        }
    };
}

export function createDimensionModule(params) {
    return {
        type: ASSESSMENT_DIMENSION_MODULE_CREATE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function removeDimensionModule(params) {
    return {
        type: ASSESSMENT_DIMENSION_MODULE_REMOVE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function createDimensionCategory(params) {
    return {
        type: ASSESSMENT_CATEGORY_CREATE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function editDimensionCategory(params) {
    return {
        type: ASSESSMENT_CATEGORY_EDIT_REQUEST,
        payload: {
            params: params
        }
    };
}

export function deleteDimensionCategory(params) {
    return {
        type: ASSESSMENT_CATEGORY_DELETE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function createDimensionQuestion(params) {
    return {
        type: ASSESSMENT_QUESTION_CREATE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function editDimensionQuestion(params) {
    return {
        type: ASSESSMENT_QUESTION_EDIT_REQUEST,
        payload: {
            params: params
        }
    };
}

export function deleteDimensionQuestion(params) {
    return {
        type: ASSESSMENT_QUESTION_DELETE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function createDimensionAnswer(params) {
    return {
        type: ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST,
        payload: {
            params: params
        }
    };
}

export function addAssessmentAnswer(params) {
    return {
        type: ASSESSMENT_ANSWER_ADD_REQUEST,
        payload: {
            params: params
        }
    };
}

export function editDimensionAnswer(params) {
    return {
        type: ASSESSMENT_ANSWER_EDIT_REQUEST,
        payload: {
            params: params
        }
    };
}

export function deleteDimensionAnswer(params) {
    return {
        type: ASSESSMENT_ANSWER_DELETE_REQUEST,
        payload: {
            params
        }
    };
}
