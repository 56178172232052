import {
    EAP_FOCUS_FETCH_COMPLETED,
    EAP_FOCUS_FETCH_REQUESTED,
    EAP_FOCUS_GET_BY_ID_COMPLETED,
    EAP_FOCUS_GET_BY_ID_REQUESTED,
    EAP_FRAMEWORK_FETCH_COMPLETED,
    EAP_FRAMEWORK_FETCH_REQUESTED,
    EAP_FRAMEWORK_GET_BY_ID_COMPLETED,
    EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
    EAP_SPECIALITY_FETCH_COMPLETED,
    EAP_SPECIALITY_FETCH_REQUESTED,
    EAP_SPECIALITY_GET_BY_ID_COMPLETED,
    EAP_SPECIALITY_GET_BY_ID_REQUESTED
} from 'redux/constants/eap';
import { CONTENT_TOOL_TAG_KEYWORD_IS_LOADING, CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING } from '../constants/content';

const initialState = {
    focuses: {
        is_loading: false,
        data: []
    },
    currentFocus: {
        is_loading: false,
        data: {}
    },
    is_creating: false,
    is_deleting: false,
    keyword_loading: false,
    frameworks: {
        is_loading: false,
        data: []
    },
    currentFramework: {
        is_loading: false,
        data: {}
    },
    specialities: {
        is_loading: false,
        data: []
    },
    currentSpeciality: {
        is_loading: false,
        data: {}
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case EAP_FOCUS_FETCH_REQUESTED:
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    is_loading: true
                }
            };
        case EAP_FOCUS_FETCH_COMPLETED:
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    is_loading: false,
                    data: action.payload.labels || []
                }
            };
        case EAP_FOCUS_GET_BY_ID_REQUESTED:
            return {
                ...state,
                currentFocus: {
                    is_loading: true,
                    data: {}
                }
            };
        case EAP_FOCUS_GET_BY_ID_COMPLETED:
            return {
                ...state,
                currentFocus: {
                    is_loading: false,
                    data: action.payload.focus
                }
            };

        case CONTENT_TOOL_TAG_KEYWORD_IS_LOADING: {
            return {
                ...state,
                keyword_loading: true
            };
        }

        case CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING: {
            return {
                ...state,
                keyword_loading: false
            };
        }

        // Frameworks
        case EAP_FRAMEWORK_FETCH_REQUESTED:
            return {
                ...state,
                frameworks: {
                    ...state.frameworks,
                    is_loading: true
                }
            };
        case EAP_FRAMEWORK_FETCH_COMPLETED:
            return {
                ...state,
                frameworks: {
                    ...state.frameworks,
                    is_loading: false,
                    data: action.payload.labels || []
                }
            };
        case EAP_FRAMEWORK_GET_BY_ID_REQUESTED:
            return {
                ...state,
                currentFramework: {
                    is_loading: true,
                    data: {}
                }
            };
        case EAP_FRAMEWORK_GET_BY_ID_COMPLETED:
            return {
                ...state,
                currentFramework: {
                    is_loading: false,
                    data: action.payload.framework
                }
            };

        // Specialities
        case EAP_SPECIALITY_FETCH_REQUESTED:
            return {
                ...state,
                specialities: {
                    ...state.specialities,
                    is_loading: true
                }
            };
        case EAP_SPECIALITY_FETCH_COMPLETED:
            return {
                ...state,
                specialities: {
                    ...state.specialities,
                    is_loading: false,
                    data: action.payload.labels || []
                }
            };
        case EAP_SPECIALITY_GET_BY_ID_REQUESTED:
            return {
                ...state,
                currentSpeciality: {
                    is_loading: true,
                    data: {}
                }
            };
        case EAP_SPECIALITY_GET_BY_ID_COMPLETED:
            return {
                ...state,
                currentSpeciality: {
                    is_loading: false,
                    data: action.payload.speciality
                }
            };
    }
    return state;
}
