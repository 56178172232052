import { call, put, takeLatest } from 'redux-saga/effects';
import { SETTING_SET_LOCALE } from '../constants/setting';
import { CONTENT_GOALS_REQUESTED, CONTENT_QUOTE_FETCH_REQUESTED, CONTENT_TOOL_FETCH_REQUESTED } from '../constants/content';
import {
    VIEW_CONTENT_DESELECT_GOAL_ID,
    VIEW_CONTENT_DESELECT_JOURNEY_ID,
    VIEW_CONTENT_DESELECT_EXERCISE_ID,
    VIEW_CONTENT_DESELECT_CONTENT_CARD_INDEX
} from '../constants/view/content';

function* setLocale(action) {
    try {
        yield put({ type: VIEW_CONTENT_DESELECT_GOAL_ID });
        yield put({ type: VIEW_CONTENT_DESELECT_JOURNEY_ID });
        yield put({ type: VIEW_CONTENT_DESELECT_EXERCISE_ID });
        yield put({ type: VIEW_CONTENT_DESELECT_CONTENT_CARD_INDEX });

        yield put({ type: CONTENT_GOALS_REQUESTED });
        yield put({
            type: CONTENT_TOOL_FETCH_REQUESTED,
            payload: {
                version: 1
            }
        });
        yield put({
            type: CONTENT_TOOL_FETCH_REQUESTED,
            payload: {
                version: 2
            }
        });
    } catch (e) {
        console.error('[Activity Saga]', e);
    }
}

function* SettingSaga() {
    yield takeLatest(SETTING_SET_LOCALE, setLocale);
}

export default SettingSaga;
