/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { sourceTypes } from 'components/assessment/immediateSelection';
import { connect } from 'react-redux';

class EditDimensionCategoryModal extends React.Component {
    state = {
        result_categories: [],
        category: {},
        nameError: false
    };

    componentDidMount() {
        const { assessment, assessment_id, dimension_id, category_id } = this.props;
        const {
            current_assessment: { data: currentAssessmentData }
        } = assessment;
        if (currentAssessmentData && currentAssessmentData.uuid === assessment_id) {
            const { dimensions } = currentAssessmentData;
            const targetDimension = dimensions.find((item) => item.uuid === dimension_id);
            if (targetDimension) {
                const {
                    result: { categories }
                } = targetDimension;
                const targetCategory = categories.find((item) => item.uuid === category_id);
                this.setState({
                    category: targetCategory
                });
            }
        }
    }

    resetState = () => {
        this.setState({
            result_categories: []
        });
    };

    handleSubmit = () => {
        const params = {};
        const { category } = this.state;
        const { assessment_id, dimension_id, category_id } = this.props;

        const validCategory = {};
        const {
            uuid,
            name,
            score_min,
            score_max,
            scale_min,
            scale_max,
            description_en,
            description_yue,
            description_zh,
            assessment_en,
            assessment_yue,
            assessment_zh
        } = category;
        if (name) {
            this.setState({
                nameError: false
            });
            validCategory.name = name;
        } else {
            this.setState({
                nameError: true
            });
            return;
        }
        validCategory.uuid = uuid;
        validCategory.score_min = null;
        validCategory.score_max = null;
        if (score_min && score_max) {
            if (parseInt(score_min) < parseInt(score_max)) {
                validCategory.score_min = parseInt(score_min);
                validCategory.score_max = parseInt(score_max);
            }
        }
        validCategory.scale_min = null;
        validCategory.scale_max = null;
        if (scale_min && scale_max) {
            if (parseInt(scale_min) < parseInt(scale_max)) {
                validCategory.scale_min = parseInt(scale_min);
                validCategory.scale_max = parseInt(scale_max);
            }
        }
        validCategory.description_en = description_en;
        validCategory.description_yue = description_yue;
        validCategory.description_zh = description_zh;

        validCategory.assessment_en = assessment_en;
        validCategory.assessment_yue = assessment_yue;
        validCategory.assessment_zh = assessment_zh;

        if (assessment_id && dimension_id && category_id) {
            params['category'] = validCategory;
            params['assessment_id'] = assessment_id;
            params['dimension_id'] = dimension_id;
            params['category_id'] = category_id;
            this.props.onCreate(params);
        }
    };

    handleCategoryChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        const { category } = this.state;
        const new_category = { ...category };
        new_category[name] = inputValue;
        this.setState({
            category: new_category
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    clearResult = () => {
        this.setState({
            result_score: {
                direction: null,
                type: null,
                min: null,
                max: null,
                operations: [],
                scale_type: null,
                scale_limit: null
            }
        });
    };

    render() {
        const { isOpen, isLoading } = this.props;
        const { category: result_category } = this.state;
        return (
            <Modal
                id="create-assessment-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-assessment-modal-header">Edit Category</Modal.Header>
                <Modal.Content id="create-assessment-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="sub-item">
                                <div className="category-container">
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="6" sm="6">
                                            <Form.Input
                                                value={result_category.name || ''}
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                error={this.state.nameError}
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.score_min ?? ''}
                                                type="number"
                                                name="score_min"
                                                placeholder="Score Min"
                                                max={result_category.score_max}
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.score_max ?? ''}
                                                type="number"
                                                name="score_max"
                                                placeholder="Score Max"
                                                min={result_category.score_min}
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.scale_min || ''}
                                                type="number"
                                                name="scale_min"
                                                placeholder="Scale Min"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.scale_max || ''}
                                                type="number"
                                                name="scale_max"
                                                placeholder="Scale Max"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.description_en || ''}
                                                rows="2"
                                                type="text"
                                                name="description_en"
                                                placeholder="Description English"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.description_yue || ''}
                                                rows="2"
                                                type="text"
                                                name="description_yue"
                                                placeholder="Description Cantonese"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.description_zh || ''}
                                                rows="2"
                                                type="text"
                                                name="description_zh"
                                                placeholder="Description Mandarin"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.assessment_en || ''}
                                                rows="2"
                                                type="text"
                                                name="assessment_en"
                                                placeholder="Assessment English"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.assessment_yue || ''}
                                                rows="2"
                                                type="text"
                                                name="assessment_yue"
                                                placeholder="Assessment Cantonese"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={result_category.assessment_zh || ''}
                                                rows="2"
                                                type="text"
                                                name="assessment_zh"
                                                placeholder="Assessment Mandarin"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-assessment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditDimensionCategoryModal);

EditDimensionCategoryModal.propTypes = {
    assessment: PropTypes.object,
    assessment_id: PropTypes.string,
    dimension_id: PropTypes.string,
    category_id: PropTypes.string,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onCreate: PropTypes.func,
    onClose: PropTypes.func
};
