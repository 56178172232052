export const VIEW_CONTENT_SELECT_TOOLKIT = 'view/content/SELECT_TOOLKIT';
export const VIEW_CONTENT_DESELECT_TOOLKIT = 'view/content/DESELECT_TOOLKIT';

export const VIEW_CONTENT_SELECT_GOAL_ID = 'view/content/SELECT_GOAL_ID';
export const VIEW_CONTENT_DESELECT_GOAL_ID = 'view/content/DESELECT_GOAL_ID';

export const VIEW_CONTENT_SELECT_JOURNEY_ID = 'view/content/SELECT_JOURNEY_ID';
export const VIEW_CONTENT_DESELECT_JOURNEY_ID = 'view/content/DESELECT_JOURNEY_ID';

export const VIEW_CONTENT_SELECT_EXERCISE_ID = 'view/content/SELECT_EXERCISE_ID';
export const VIEW_CONTENT_DESELECT_EXERCISE_ID = 'view/content/DESELECT_EXERCISE_ID';

export const VIEW_CONTENT_SELECT_CONTENT_CARD_INDEX = 'view/content/SELECT_CONTENT_CARD_INDEX';
export const VIEW_CONTENT_DESELECT_CONTENT_CARD_INDEX = 'view/content/DESELECT_CONTENT_CARD_INDEX';

export const VIEW_CONTENT_CONTENT_IS_TOOLS = 'view/content/CONTENT_IS_TOOLS';
export const VIEW_CONTENT_CONTENT_IS_QUOTES = 'view/content/CONTENT_IS_QUOTES';
export const VIEW_CONTENT_CONTENT_TYPE = 'view/content/CONTENT_TYPE';
