export const EAP_FOCUS_CREATE_REQUESTED = 'eap/FOCUS_CREATE_REQUESTED';
export const EAP_FOCUS_CREATE_COMPLETED = 'eap/FOCUS_CREATE_COMPLETED';
export const EAP_FOCUS_UPDATE_REQUESTED = 'eap/FOCUS_UPDATE_REQUESTED';
export const EAP_FOCUS_UPDATE_COMPLETED = 'eap/FOCUS_UPDATE_COMPLETED';
export const EAP_FOCUS_FETCH_REQUESTED = 'eap/FOCUS_FETCH_REQUESTED';
export const EAP_FOCUS_FETCH_COMPLETED = 'eap/FOCUS_FETCH_COMPLETED';
export const EAP_FOCUS_GET_BY_ID_REQUESTED = 'eap/FOCUS_GET_BY_ID_REQUESTED';
export const EAP_FOCUS_GET_BY_ID_COMPLETED = 'eap/FOCUS_GET_BY_ID_COMPLETED';
export const EAP_FOCUS_REMOVE_REQUESTED = 'eap/FOCUS_REMOVE_REQUESTED';
export const EAP_FOCUS_REMOVE_COMPLETED = 'eap/FOCUS_REMOVE_COMPLETED';
export const EAP_FOCUS_TAG_KEYWORD_ADD_REQUESTED = 'eap/FOCUS_TAG_KEYWORD_ADD_REQUESTED';
export const EAP_FOCUS_TAG_KEYWORD_REMOVE_REQUESTED = 'eap/FOCUS_TAG_KEYWORD_REMOVE_REQUESTED';

// EAP Framework
export const EAP_FRAMEWORK_CREATE_REQUESTED = 'eap/FRAMEWORK_CREATE_REQUESTED';
export const EAP_FRAMEWORK_CREATE_COMPLETED = 'eap/FRAMEWORK_CREATE_COMPLETED';
export const EAP_FRAMEWORK_UPDATE_REQUESTED = 'eap/FRAMEWORK_UPDATE_REQUESTED';
export const EAP_FRAMEWORK_UPDATE_COMPLETED = 'eap/FRAMEWORK_UPDATE_COMPLETED';
export const EAP_FRAMEWORK_FETCH_REQUESTED = 'eap/FRAMEWORK_FETCH_REQUESTED';
export const EAP_FRAMEWORK_FETCH_COMPLETED = 'eap/FRAMEWORK_FETCH_COMPLETED';
export const EAP_FRAMEWORK_GET_BY_ID_REQUESTED = 'eap/FRAMEWORK_GET_BY_ID_REQUESTED';
export const EAP_FRAMEWORK_GET_BY_ID_COMPLETED = 'eap/FRAMEWORK_GET_BY_ID_COMPLETED';
export const EAP_FRAMEWORK_REMOVE_REQUESTED = 'eap/FRAMEWORK_REMOVE_REQUESTED';
export const EAP_FRAMEWORK_REMOVE_COMPLETED = 'eap/FRAMEWORK_REMOVE_COMPLETED';

// EAP Speciality
export const EAP_SPECIALITY_CREATE_REQUESTED = 'eap/SPECIALITY_CREATE_REQUESTED';
export const EAP_SPECIALITY_CREATE_COMPLETED = 'eap/SPECIALITY_CREATE_COMPLETED';
export const EAP_SPECIALITY_UPDATE_REQUESTED = 'eap/SPECIALITY_UPDATE_REQUESTED';
export const EAP_SPECIALITY_UPDATE_COMPLETED = 'eap/SPECIALITY_UPDATE_COMPLETED';
export const EAP_SPECIALITY_FETCH_REQUESTED = 'eap/SPECIALITY_FETCH_REQUESTED';
export const EAP_SPECIALITY_FETCH_COMPLETED = 'eap/SPECIALITY_FETCH_COMPLETED';
export const EAP_SPECIALITY_GET_BY_ID_REQUESTED = 'eap/SPECIALITY_GET_BY_ID_REQUESTED';
export const EAP_SPECIALITY_GET_BY_ID_COMPLETED = 'eap/SPECIALITY_GET_BY_ID_COMPLETED';
export const EAP_SPECIALITY_REMOVE_REQUESTED = 'eap/SPECIALITY_REMOVE_REQUESTED';
export const EAP_SPECIALITY_REMOVE_COMPLETED = 'eap/SPECIALITY_REMOVE_COMPLETED';
