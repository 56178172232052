import { AUTHENTICATION_REQUESTED, AUTHENTICATION_LOGIN_REQUESTED, AUTHENTICATION_LOGOUT_REQUESTED } from '../constants/authentication';

// Action Creators
export function authenticate() {
    return {
        type: AUTHENTICATION_REQUESTED
    };
}

export function login(email, password) {
    return {
        type: AUTHENTICATION_LOGIN_REQUESTED,
        payload: {
            email: email,
            password: password
        }
    };
}

export function logout() {
    return {
        type: AUTHENTICATION_LOGOUT_REQUESTED
    };
}
