import {
    MODAL_CONTENT_TOOL_CREATE_SHOW,
    MODAL_CONTENT_TOOL_CREATE_HIDE,
    MODAL_CONTENT_TOOL_EDIT_SHOW,
    MODAL_CONTENT_TOOL_EDIT_HIDE,
    MODAL_CONTENT_GOAL_CREATE_SHOW,
    MODAL_CONTENT_GOAL_CREATE_HIDE,
    MODAL_CONTENT_GOAL_HEADER_CREATE_SHOW,
    MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE,
    MODAL_CONTENT_GOAL_HEADER_LINK_SHOW,
    MODAL_CONTENT_GOAL_HEADER_LINK_HIDE,
    MODAL_CONTENT_GOAL_HEADER_UNLINK_SHOW,
    MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE,
    MODAL_CONTENT_JOURNEY_GOAL_EDIT_SHOW,
    MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE,
    MODAL_CONTENT_JOURNEY_CREATE_SHOW,
    MODAL_CONTENT_JOURNEY_CREATE_HIDE,
    MODAL_CONTENT_JOURNEY_EDIT_SHOW,
    MODAL_CONTENT_JOURNEY_EDIT_HIDE,
    MODAL_CONTENT_EXERCISE_CREATE_SHOW,
    MODAL_CONTENT_EXERCISE_CREATE_HIDE,
    MODAL_CONTENT_EXERCISE_EDIT_SHOW,
    MODAL_CONTENT_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_CREATE_SHOW,
    MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_READ_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_SHOW,
    MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_MOVE_CARD_SHOW,
    MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE,
    MODAL_ACCESS_CREATE_EMAIL_ACCESS_SHOW,
    MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE,
    MODAL_CONTENT_EXERCISE_CHAT_EDIT_SHOW,
    MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE,
    MODAL_CONTENT_ACTION_EXERCISE_EDIT_SHOW,
    MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COPY_SHOW,
    MODAL_CONTENT_CONTENT_CARD_COPY_HIDE,
    MODAL_CONTENT_CREATE_TOOL_TAG_SHOW,
    MODAL_CONTENT_CREATE_TOOL_TAG_HIDE,
    MODAL_CONTENT_LINK_ADD_SHOW,
    MODAL_CONTENT_LINK_ADD_HIDE,
    MODAL_CONTENT_QUOTE_CREATE_SHOW,
    MODAL_CONTENT_QUOTE_CREATE_HIDE,
    MODAL_CONTENT_QUOTE_EDIT_SHOW,
    MODAL_CONTENT_QUOTE_EDIT_HIDE,
    MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW,
    MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_EDIT_SHOW,
    MODAL_CONTENT_TOOL_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_ADD_SHOW,
    MODAL_CONTENT_TOOL_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW,
    MODAL_CONTENT_VALIDATE_SHOW,
    MODAL_CONTENT_VALIDATE_HIDE,
    // assessment
    MODAL_ASSESSMENT_SOURCE_CREATE_HIDE,
    MODAL_ASSESSMENT_SOURCE_CREATE_SHOW,
    MODAL_ASSESSMENT_DIMENSION_CREATE_SHOW,
    MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE,
    MODAL_DIMENSION_CATEGORY_CREATE_SHOW,
    MODAL_DIMENSION_CATEGORY_CREATE_HIDE,
    MODAL_DIMENSION_ANSWER_CREATE_SHOW,
    MODAL_DIMENSION_ANSWER_CREATE_HIDE,
    MODAL_ANSWER_ADD_SHOW,
    MODAL_ANSWER_ADD_HIDE,
    MODAL_DIMENSION_QUESTION_CREATE_SHOW,
    MODAL_DIMENSION_QUESTION_CREATE_HIDE,
    MODAL_ASSESSMENT_SOURCE_EDIT_SHOW,
    MODAL_ASSESSMENT_SOURCE_EDIT_HIDE,
    MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW,
    MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE,
    MODAL_DIMENSION_CATEGORY_EDIT_SHOW,
    MODAL_DIMENSION_CATEGORY_EDIT_HIDE,
    MODAL_DIMENSION_QUESTION_EDIT_SHOW,
    MODAL_DIMENSION_QUESTION_EDIT_HIDE,
    MODAL_ANSWER_EDIT_HIDE,
    MODAL_ANSWER_EDIT_SHOW,
    MODAL_EAP_FOCUS_CREATE_SHOW,
    MODAL_EAP_FOCUS_CREATE_HIDE,
    MODAL_EAP_FOCUS_EDIT_SHOW,
    MODAL_EAP_FOCUS_EDIT_HIDE,
    MODAL_EAP_FRAMEWORK_CREATE_SHOW,
    MODAL_EAP_FRAMEWORK_EDIT_HIDE,
    MODAL_EAP_FRAMEWORK_EDIT_SHOW,
    MODAL_EAP_FRAMEWORK_CREATE_HIDE,
    MODAL_EAP_SPECIALITY_CREATE_SHOW,
    MODAL_EAP_SPECIALITY_CREATE_HIDE,
    MODAL_EAP_SPECIALITY_EDIT_SHOW,
    MODAL_EAP_SPECIALITY_EDIT_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_CREATE_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_EDIT_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE
} from '../../constants/view/modal';

// Action Creators

export function showContentLinkModal(tool_id, lesson_id, completion_handler) {
    return {
        type: MODAL_CONTENT_LINK_ADD_SHOW,
        payload: {
            tool_id: tool_id,
            lesson_id: lesson_id,
            completion_handler: completion_handler
        }
    };
}

export function hideContentLinkModal() {
    return {
        type: MODAL_CONTENT_LINK_ADD_HIDE
    };
}

export function showContentToolTagModal(tool_id) {
    return {
        type: MODAL_CONTENT_CREATE_TOOL_TAG_SHOW,
        payload: {
            tool_id: tool_id
        }
    };
}

export function hideContentToolTagModal() {
    return {
        type: MODAL_CONTENT_CREATE_TOOL_TAG_HIDE
    };
}

export function showValidationContentModal(tool_id, show_publish = false) {
    return {
        type: MODAL_CONTENT_VALIDATE_SHOW,
        payload: {
            tool_id: tool_id,
            show_publish: show_publish
        }
    };
}

export function hideValidationContentModal() {
    return {
        type: MODAL_CONTENT_VALIDATE_HIDE
    };
}

export function showCopyContentModal(from_exercise_id) {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_COPY_SHOW,
        payload: {
            from_exercise_id: from_exercise_id
        }
    };
}

export function hideCopyContentModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_COPY_HIDE
    };
}

export function showCreateToolModal(version = 1) {
    return {
        type: MODAL_CONTENT_TOOL_CREATE_SHOW,
        payload: {
            version: version
        }
    };
}

export function hideCreateToolModal() {
    return {
        type: MODAL_CONTENT_TOOL_CREATE_HIDE
    };
}

// quotes

export function showCreateQuoteModal() {
    return {
        type: MODAL_CONTENT_QUOTE_CREATE_SHOW
    };
}

export function hideCreateQuoteModal() {
    return {
        type: MODAL_CONTENT_QUOTE_CREATE_HIDE
    };
}

export function showEditQuoteModal(quote_id) {
    return {
        type: MODAL_CONTENT_QUOTE_EDIT_SHOW,
        payload: {
            quote_id: quote_id
        }
    };
}

export function hideEditQuoteModal() {
    return {
        type: MODAL_CONTENT_QUOTE_EDIT_HIDE
    };
}

export function showEditToolModal(tool_id) {
    return {
        type: MODAL_CONTENT_TOOL_EDIT_SHOW,
        payload: {
            tool_id: tool_id
        }
    };
}

export function hideEditToolModal() {
    return {
        type: MODAL_CONTENT_TOOL_EDIT_HIDE
    };
}

export function showCreateGoalModal() {
    return {
        type: MODAL_CONTENT_GOAL_CREATE_SHOW
    };
}

export function hideCreateGoalModal() {
    return {
        type: MODAL_CONTENT_GOAL_CREATE_HIDE
    };
}

export function showCreateGoalHeaderModal(goal_id) {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_CREATE_SHOW,
        payload: {
            goal_id: goal_id
        }
    };
}

export function hideCreateGoalHeaderModal() {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE
    };
}

export function showLinkJourneyGoalHeaderModal(goal_header_id) {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_LINK_SHOW,
        payload: {
            goal_header_id: goal_header_id
        }
    };
}

export function hideLinkJourneyGoalHeaderModal() {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_LINK_HIDE
    };
}

export function showEditJourneyGoalModal(journey_id) {
    return {
        type: MODAL_CONTENT_JOURNEY_GOAL_EDIT_SHOW,
        payload: {
            journey_id: journey_id
        }
    };
}

export function hideEditJourneyGoalModal() {
    return {
        type: MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE
    };
}

export function showUnlinkJourneyGoalHeaderModal(journey_id) {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_UNLINK_SHOW,
        payload: {
            journey_id: journey_id
        }
    };
}

export function hideUnlinkJourneyGoalHeaderModal() {
    return {
        type: MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE
    };
}

export function showCreateJourneyModal(goal_id) {
    return {
        type: MODAL_CONTENT_JOURNEY_CREATE_SHOW,
        payload: {
            goal_id: goal_id
        }
    };
}

export function hideCreateJourneyModal() {
    return {
        type: MODAL_CONTENT_JOURNEY_CREATE_HIDE
    };
}

export function showEditJourneyModal(journey_id) {
    return {
        type: MODAL_CONTENT_JOURNEY_EDIT_SHOW,
        payload: {
            journey_id: journey_id
        }
    };
}

export function hideEditJourneyModal() {
    return {
        type: MODAL_CONTENT_JOURNEY_EDIT_HIDE
    };
}

export function showCreateExerciseModal(goal_id, journey_id, tool_id) {
    return {
        type: MODAL_CONTENT_EXERCISE_CREATE_SHOW,
        payload: {
            goal_id: goal_id,
            journey_id: journey_id,
            tool_id: tool_id
        }
    };
}

export function hideCreateExerciseModal() {
    return {
        type: MODAL_CONTENT_EXERCISE_CREATE_HIDE
    };
}

export function showEditExerciseModal(exercise_id) {
    return {
        type: MODAL_CONTENT_EXERCISE_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function hideEditExerciseModal() {
    return {
        type: MODAL_CONTENT_EXERCISE_EDIT_HIDE
    };
}

export function showCreateContentCardModal(exercise_id) {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_CREATE_SHOW,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function hideCreateContentCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE
    };
}

export function showEditContentCardCompletionModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentCardCompletionModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE
    };
}

export function showEditContentCardReferenceModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentCardReferenceModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE
    };
}

export function showEditContentQuestionnaireCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentQuestionnaireCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE
    };
}

export function showEditContentChecklistCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentChecklistCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE
    };
}

export function showEditContentAssessmentCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentAssessmentCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE
    };
}

export function showEditContentAssessmentResultCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentAssessmentResultCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE
    };
}

export function showEditContentChecklistCardItemModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditContentChecklistCardItemModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE
    };
}

export function showEditContentReflectionCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentReflectionCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE
    };
}

export function showEditContentQuizCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentQuizCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE
    };
}

export function showAddContentQuizCardItemModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideAddContentQuizCardItemModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE
    };
}

export function showAddContentToolCardToolModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_TOOL_CARD_ADD_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideAddContentToolCardToolModal() {
    return {
        type: MODAL_CONTENT_TOOL_CARD_ADD_HIDE
    };
}

export function showEditContentQuizCardItemModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditContentQuizCardItemModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE
    };
}

export function showEditContentCaptionCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentCaptionCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE
    };
}

export function showEditContentInformationCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentInformationCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE
    };
}

export function showEditContentAudioCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentAudioCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE
    };
}

export function showEditContentVideoCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentVideoCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE
    };
}

export function showEditContentTaskCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentTaskCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE
    };
}

export function showEditContentCompleteCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW
    };
}

export function hideEditContentCompleteCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE
    };
}

export function showAddItemContentDocumentCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideAddItemContentDocumentCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE
    };
}

export function showEditTextItemContentDocumentCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditTextItemContentDocumentCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function showEditImageItemContentDocumentCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditImageItemContentDocumentCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function showEditContentTimerCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentTimerCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE
    };
}

export function showEditContentReadCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_READ_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentReadCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE
    };
}

export function showEditContentInputCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditContentInputCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE
    };
}

export function showAddContentInputCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideAddContentInputCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE
    };
}

export function showAddContentQuoteCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideAddContentQuoteCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE
    };
}

export function hideEditContentQuoteCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE
    };
}

export function showEditContentQuoteCardModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function showMoveContentCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_MOVE_CARD_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideMoveContentCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE
    };
}

export function showAccessCreateEmailAccessModal() {
    return {
        type: MODAL_ACCESS_CREATE_EMAIL_ACCESS_SHOW
    };
}

export function hideAccessCreateEmailAccessModal() {
    return {
        type: MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE
    };
}

export function showContentExerciseChatEditModal(exercise_id) {
    return {
        type: MODAL_CONTENT_EXERCISE_CHAT_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function hideContentExerciseChatEditModal() {
    return {
        type: MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE
    };
}

export function showContentExerciseActionExerciseEditModal(exercise_id) {
    return {
        type: MODAL_CONTENT_ACTION_EXERCISE_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id
        }
    };
}

export function hideContentExerciseActionExerciseEditModal() {
    return {
        type: MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE
    };
}

export function showContentSummaryCardEditModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideContentSummaryCardEditModal() {
    return {
        type: MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE
    };
}

export function showContentOverviewSessionsCardEditModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideContentOverviewSessionsCardEditModal() {
    return {
        type: MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE
    };
}

export function showContentToolCardEditModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_TOOL_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideContentToolCardEditModal() {
    return {
        type: MODAL_CONTENT_TOOL_CARD_EDIT_HIDE
    };
}

export function showAddContentGuideCardConceptModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideAddContentGuideCardConceptModal() {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE
    };
}

export function showEditContentGuideCardModal(exercise_id, card_index) {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index
        }
    };
}

export function hideEditContentGuideCardModal() {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE
    };
}

export function showEditContentGuideCardConceptModal(exercise_id, card_index, item_index) {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW,
        payload: {
            exercise_id: exercise_id,
            card_index: card_index,
            item_index: item_index
        }
    };
}

export function hideEditContentGuideCardConceptModal() {
    return {
        type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE
    };
}

// assessment modal

export function showCreateAssessmentSourceModal() {
    return {
        type: MODAL_ASSESSMENT_SOURCE_CREATE_SHOW,
        payload: {}
    };
}

export function hideCreateAssessmentSourceModal() {
    return {
        type: MODAL_ASSESSMENT_SOURCE_CREATE_HIDE
    };
}

export function showEditAssessmentSourceModal(assessment_id) {
    return {
        type: MODAL_ASSESSMENT_SOURCE_EDIT_SHOW,
        payload: {
            assessment_id
        }
    };
}

export function hideEditAssessmentSourceModal() {
    return {
        type: MODAL_ASSESSMENT_SOURCE_EDIT_HIDE,
        payload: {}
    };
}

export function showCreateAssessmentDimensionModal(assessment_id) {
    return {
        type: MODAL_ASSESSMENT_DIMENSION_CREATE_SHOW,
        payload: {
            assessment_id
        }
    };
}

export function hideCreateAssessmentDimensionModal() {
    return {
        type: MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE
    };
}

export function showEditAssessmentDimensionModal(assessment_id, dimension_id) {
    return {
        type: MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW,
        payload: {
            assessment_id,
            dimension_id
        }
    };
}

export function hideEditAssessmentDimensionModal() {
    return {
        type: MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE,
        payload: {}
    };
}

export function showCreateDimensionCategoryModal(assessment_id, dimension_id) {
    return {
        type: MODAL_DIMENSION_CATEGORY_CREATE_SHOW,
        payload: {
            assessment_id,
            dimension_id
        }
    };
}

export function hideCreateDimensionCategoryModal() {
    return {
        type: MODAL_DIMENSION_CATEGORY_CREATE_HIDE
    };
}

export function showEditDimensionCategoryModal(assessment_id, dimension_id, category_id) {
    return {
        type: MODAL_DIMENSION_CATEGORY_EDIT_SHOW,
        payload: {
            assessment_id,
            dimension_id,
            category_id
        }
    };
}

export function hideEditDimensionCategoryModal() {
    return {
        type: MODAL_DIMENSION_CATEGORY_EDIT_HIDE
    };
}

export function showCreateDimensionQuestionModal(assessment_id, dimension_id) {
    return {
        type: MODAL_DIMENSION_QUESTION_CREATE_SHOW,
        payload: {
            assessment_id,
            dimension_id
        }
    };
}

export function hideCreateDimensionQuestionModal() {
    return {
        type: MODAL_DIMENSION_QUESTION_CREATE_HIDE
    };
}

export function showEditDimensionQuestionModal(assessment_id, dimension_id, question_id) {
    return {
        type: MODAL_DIMENSION_QUESTION_EDIT_SHOW,
        payload: {
            assessment_id,
            dimension_id,
            question_id
        }
    };
}

export function hideEditDimensionQuestionModal() {
    return {
        type: MODAL_DIMENSION_QUESTION_EDIT_HIDE
    };
}

export function showCreateDimensionAnswerModal(assessment_id) {
    return {
        type: MODAL_DIMENSION_ANSWER_CREATE_SHOW,
        payload: {
            assessment_id
        }
    };
}

export function hideCreateDimensionAnswerModal() {
    return {
        type: MODAL_DIMENSION_ANSWER_CREATE_HIDE
    };
}

export function showEditAnswerModal(assessment_id, answer_id) {
    return {
        type: MODAL_ANSWER_EDIT_SHOW,
        payload: {
            assessment_id,
            answer_id
        }
    };
}

export function hideEditAnswerModal() {
    return {
        type: MODAL_ANSWER_EDIT_HIDE
    };
}

export function showAddAnswerModal(assessment_id, answer_id) {
    return {
        type: MODAL_ANSWER_ADD_SHOW,
        payload: {
            assessment_id,
            answer_id
        }
    };
}

export function hideAddAnswerModal() {
    return {
        type: MODAL_ANSWER_ADD_HIDE
    };
}

// eap focus

export function showCreateEAPFocusModal() {
    return {
        type: MODAL_EAP_FOCUS_CREATE_SHOW,
        payload: {}
    };
}

export function hideCreateEAPFocusModal() {
    return {
        type: MODAL_EAP_FOCUS_CREATE_HIDE
    };
}

export function showEditEAPFocusModal(focus_id) {
    return {
        type: MODAL_EAP_FOCUS_EDIT_SHOW,
        payload: {
            focus_id
        }
    };
}

export function hideEditEAPFocusModal() {
    return {
        type: MODAL_EAP_FOCUS_EDIT_HIDE,
        payload: {}
    };
}

// eap framework
export function showCreateEAPFrameworkModal() {
    return {
        type: MODAL_EAP_FRAMEWORK_CREATE_SHOW,
        payload: {}
    };
}

export function hideCreateEAPFrameworkModal() {
    return {
        type: MODAL_EAP_FRAMEWORK_CREATE_HIDE
    };
}

export function showEditEAPFrameworkModal(framework_id) {
    return {
        type: MODAL_EAP_FRAMEWORK_EDIT_SHOW,
        payload: {
            framework_id
        }
    };
}

export function hideEditEAPFrameworkModal() {
    return {
        type: MODAL_EAP_FRAMEWORK_EDIT_HIDE,
        payload: {}
    };
}

// eap speciality
export function showCreateEAPSpecialityModal() {
    return {
        type: MODAL_EAP_SPECIALITY_CREATE_SHOW,
        payload: {}
    };
}

export function hideCreateEAPSpecialityModal() {
    return {
        type: MODAL_EAP_SPECIALITY_CREATE_HIDE
    };
}

export function showEditEAPSpecialityModal(speciality_id) {
    return {
        type: MODAL_EAP_SPECIALITY_EDIT_SHOW,
        payload: {
            speciality_id
        }
    };
}

export function hideEditEAPSpecialityModal() {
    return {
        type: MODAL_EAP_SPECIALITY_EDIT_HIDE,
        payload: {}
    };
}

// Organization
export function showCreateOrganizationModal() {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_CREATE_SHOW,
        payload: {}
    };
}

export function hideCreateOrganizationModal() {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE
    };
}

export function showEditOrganizationModal(organization_id) {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_EDIT_SHOW,
        payload: {
            organization_id
        }
    };
}

export function hideEditOrganizationModal() {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE
    };
}

export function showOrganizationDeployment() {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW
    };
}

export function hideOrganizationDeployment() {
    return {
        type: MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE
    };
}
