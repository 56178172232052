import Config from '../config';

const axios = require('axios');
axios.defaults.withCredentials = true;

class MoodService {
    static fetchAffects() {
        return axios
            .get(
                Config.api.path + '/v3/mood/affect/fetch',
                {
                    params: {
                        language: 'en'
                    }
                },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                return null;
            });
    }
}

export default MoodService;
