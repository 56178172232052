import {
    VIEW_CONTENT_SELECT_GOAL_ID,
    VIEW_CONTENT_DESELECT_GOAL_ID,
    VIEW_CONTENT_SELECT_TOOLKIT,
    VIEW_CONTENT_DESELECT_TOOLKIT,
    VIEW_CONTENT_SELECT_EXERCISE_ID,
    VIEW_CONTENT_DESELECT_EXERCISE_ID,
    VIEW_CONTENT_SELECT_JOURNEY_ID,
    VIEW_CONTENT_DESELECT_JOURNEY_ID,
    VIEW_CONTENT_SELECT_CONTENT_CARD_INDEX,
    VIEW_CONTENT_DESELECT_CONTENT_CARD_INDEX,
    VIEW_CONTENT_CONTENT_IS_TOOLS,
    VIEW_CONTENT_CONTENT_IS_QUOTES,
    VIEW_CONTENT_CONTENT_TYPE
} from '../../constants/view/content';

const initialState = {
    content_type: 'series',
    is_content_tools: false,
    is_content_quotes: false,
    is_toolkit_selected: false,
    selected_goal_id: null,
    selected_journey_id: null,
    selected_exercise_id: null,
    selected_exercise_content_card_index: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case VIEW_CONTENT_CONTENT_TYPE:
            return {
                ...state,
                content_type: action.payload.type
            };
        case VIEW_CONTENT_CONTENT_IS_TOOLS:
            return {
                ...state,
                is_content_tools: action.payload.is_tools
            };
        case VIEW_CONTENT_CONTENT_IS_QUOTES:
            return {
                ...state,
                is_content_quotes: action.payload.is_quotes
            };
        case VIEW_CONTENT_SELECT_TOOLKIT:
            return {
                ...state,
                is_toolkit_selected: true
            };
        case VIEW_CONTENT_DESELECT_TOOLKIT:
            return {
                ...state,
                is_toolkit_selected: false
            };
        case VIEW_CONTENT_SELECT_GOAL_ID:
            return {
                ...state,
                selected_goal_id: action.payload.goal_id
            };
        case VIEW_CONTENT_DESELECT_GOAL_ID:
            return {
                ...state,
                selected_goal_id: null
            };
        case VIEW_CONTENT_SELECT_JOURNEY_ID:
            return {
                ...state,
                selected_journey_id: action.payload.journey_id
            };
        case VIEW_CONTENT_DESELECT_JOURNEY_ID:
            return {
                ...state,
                selected_journey_id: null
            };
        case VIEW_CONTENT_SELECT_EXERCISE_ID:
            return {
                ...state,
                selected_exercise_id: action.payload.exercise_id
            };
        case VIEW_CONTENT_DESELECT_EXERCISE_ID:
            return {
                ...state,
                selected_exercise_id: null
            };
        case VIEW_CONTENT_SELECT_CONTENT_CARD_INDEX:
            return {
                ...state,
                selected_exercise_content_card_index: action.payload.index
            };
        case VIEW_CONTENT_DESELECT_CONTENT_CARD_INDEX:
            return {
                ...state,
                selected_exercise_content_card_index: null
            };
        default:
            return { ...state };
    }
}
