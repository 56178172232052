import { MOOD_AFFECT_IS_LOADING, MOOD_AFFECT_NOT_LOADING, MOOD_AFFECT_FETCHED } from '../constants/mood';

const initialState = {
    affects: {
        is_loading: false,
        data: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case MOOD_AFFECT_IS_LOADING:
            return {
                ...state,
                affects: {
                    ...state.affects,
                    is_loading: true
                }
            };
        case MOOD_AFFECT_NOT_LOADING:
            return {
                ...state,
                affects: {
                    ...state.affects,
                    is_loading: false
                }
            };
        case MOOD_AFFECT_FETCHED:
            return {
                ...state,
                affects: {
                    ...state.affects,
                    data: action.payload.affects || []
                }
            };
        default:
            return state;
    }
    return state;
}
