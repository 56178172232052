export const MODAL_CONTENT_TOOL_EDIT_SHOW = 'modal/CONTENT_TOOL_EDIT_SHOW';
export const MODAL_CONTENT_TOOL_EDIT_HIDE = 'modal/CONTENT_TOOL_EDIT_HIDE';

export const MODAL_CONTENT_TOOL_CREATE_SHOW = 'modal/CONTENT_TOOL_CREATE_SHOW';
export const MODAL_CONTENT_TOOL_CREATE_HIDE = 'modal/CONTENT_TOOL_CREATE_HIDE';

export const MODAL_CONTENT_QUOTE_CREATE_SHOW = 'modal/CONTENT_QUOTE_CREATE_SHOW';
export const MODAL_CONTENT_QUOTE_CREATE_HIDE = 'modal/CONTENT_QUOTE_CREATE_HIDE';

export const MODAL_CONTENT_QUOTE_EDIT_SHOW = 'modal/CONTENT_QUOTE_EDIT_SHOW';
export const MODAL_CONTENT_QUOTE_EDIT_HIDE = 'modal/CONTENT_QUOTE_EDIT_HIDE';

export const MODAL_CONTENT_GOAL_CREATE_SHOW = 'modal/CONTENT_GOAL_CREATE_SHOW';
export const MODAL_CONTENT_GOAL_CREATE_HIDE = 'modal/CONTENT_GOAL_CREATE_HIDE';

export const MODAL_CONTENT_GOAL_HEADER_CREATE_SHOW = 'modal/CONTENT_GOAL_HEADER_CREATE_SHOW';
export const MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE = 'modal/CONTENT_GOAL_HEADER_CREATE_HIDE';

export const MODAL_CONTENT_GOAL_HEADER_LINK_SHOW = 'modal/CONTENT_GOAL_HEADER_LINK_SHOW';
export const MODAL_CONTENT_GOAL_HEADER_LINK_HIDE = 'modal/CONTENT_GOAL_HEADER_LINK_HIDE';

export const MODAL_CONTENT_GOAL_HEADER_UNLINK_SHOW = 'modal/CONTENT_GOAL_HEADER_UNLINK_SHOW';
export const MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE = 'modal/CONTENT_GOAl_HEADER_UNLINK_HIDE';

export const MODAL_CONTENT_JOURNEY_GOAL_EDIT_SHOW = 'modal/CONTENT_JOURNEY_GOAL_EDIT_SHOW';
export const MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE = 'modal/CONTENT_JOURNEY_GOAL_EDIT_HIDE';

export const MODAL_CONTENT_JOURNEY_CREATE_SHOW = 'modal/CONTENT_JOURNEY_CREATE_SHOW';
export const MODAL_CONTENT_JOURNEY_CREATE_HIDE = 'modal/CONTENT_JOURNEY_CREATE_HIDE';

export const MODAL_CONTENT_JOURNEY_EDIT_SHOW = 'modal/CONTENT_JOURNEY_EDIT_SHOW';
export const MODAL_CONTENT_JOURNEY_EDIT_HIDE = 'modal/CONTENT_JOURNEY_EDIT_HIDE';

export const MODAL_CONTENT_EXERCISE_CREATE_SHOW = 'modal/CONTENT_EXERCISE_CREATE_SHOW';
export const MODAL_CONTENT_EXERCISE_CREATE_HIDE = 'modal/CONTENT_EXERCISE_CREATE_HIDE';

export const MODAL_CONTENT_EXERCISE_EDIT_SHOW = 'modal/CONTENT_EXERCISE_EDIT_SHOW';
export const MODAL_CONTENT_EXERCISE_EDIT_HIDE = 'modal/CONTENT_EXERCISE_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_CARD_COPY_SHOW = 'modal/CONTENT_CONTENT_CARD_COPY_SHOW';
export const MODAL_CONTENT_CONTENT_CARD_COPY_HIDE = 'modal/CONTENT_CONTENT_CARD_COPY_HIDE';

export const MODAL_CONTENT_CONTENT_CARD_CREATE_SHOW = 'modal/CONTENT_CONTENT_CARD_CREATE_SHOW';
export const MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE = 'modal/CONTENT_CONTENT_CARD_CREATE_HIDE';

export const MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW = 'modal/CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE = 'modal/CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW = 'modal/CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE = 'modal/CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_TASK_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_TASK_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE';
export const MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW = 'modal/CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE = 'modal/CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE';

export const MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE';
export const MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW = 'modal/CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE = 'modal/CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE';
export const MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW = 'modal/CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE = 'modal/CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE';

export const MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE = 'modal/COTNENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_READ_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_READ_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_READ_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE';

export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE';
export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW';
export const MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE = 'modal/CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE';

export const MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE';
export const MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_SHOW = 'modal/CONTENT_CONTENT_INPUT_CARD_ADD_SHOW';
export const MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE = 'modal/CONTENT_CONTENT_INPUT_CARD_ADD_HIDE';

export const MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE';
export const MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW = 'modal/CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW';
export const MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE = 'modal/CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE';

export const MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE';

export const MODAL_CONTENT_CONTENT_MOVE_CARD_SHOW = 'modal/CONTENT_CONTENT_MOVE_CARD_SHOW';
export const MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE = 'modal/CONTENT_CONTENT_MOVE_CARD_HIDE';

export const MODAL_ACCESS_CREATE_EMAIL_ACCESS_SHOW = 'modal/ACCESS_CREATE_EMAIL_ACCESS_SHOW';
export const MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE = 'modal_ACCESS_CREATE_EMAIL_ACCESS_HIDE';

export const MODAL_CONTENT_EXERCISE_CHAT_EDIT_SHOW = 'modal/CONTENT_EXERCISE_CHAT_EDIT_SHOW';
export const MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE = 'modal/CONTENT_EXERCISE_CHAT_EDIT_HIDE';

export const MODAL_CONTENT_ACTION_EXERCISE_EDIT_SHOW = 'modal/CONTENT_ACTION_EXERCISE_EDIT_SHOW';
export const MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE = 'modal/CONTENT_ACTION_EXERCISE_EDIT_HIDE';

export const MODAL_CONTENT_CREATE_TOOL_TAG_SHOW = 'modal/CONTENT_CREATE_TOOL_TAG_SHOW';
export const MODAL_CONTENT_CREATE_TOOL_TAG_HIDE = 'modal/CONTENT_CREATE_TOOL_TAG_HIDE';

export const MODAL_CONTENT_VALIDATE_SHOW = 'modal/CONTENT_VALIDATE_SHOW';
export const MODAL_CONTENT_VALIDATE_HIDE = 'modal/CONTENT_VALIDATE_HIDE';

export const MODAL_CONTENT_LINK_ADD_SHOW = 'modal/CONTENT_LINK_ADD_SHOW';
export const MODAL_CONTENT_LINK_ADD_HIDE = 'modal/CONTENT_LINK_ADD_HIDE';

export const MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW = 'modal/CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW';
export const MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE = 'modal/CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE';

export const MODAL_CONTENT_TOOL_CARD_EDIT_SHOW = 'modal/CONTENT_TOOL_CARD_EDIT_SHOW';
export const MODAL_CONTENT_TOOL_CARD_EDIT_HIDE = 'modal/CONTENT_TOOL_CARD_EDIT_HIDE';

export const MODAL_CONTENT_TOOL_CARD_ADD_SHOW = 'modal/CONTENT_TOOL_CARD_ADD_SHOW';
export const MODAL_CONTENT_TOOL_CARD_ADD_HIDE = 'modal/CONTENT_TOOL_CARD_ADD_HIDE';

export const MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW = 'modal/CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW';
export const MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE = 'modal/CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE';
export const MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW = 'modal/CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW';
export const MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE = 'modal/CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE';
export const MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW = 'modal/CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW';
export const MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE = 'modal/CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE';

// asessment
export const MODAL_ASSESSMENT_SOURCE_CREATE_SHOW = 'modal/ASSESSMENT_SOURCE_CREATE_SHOW';
export const MODAL_ASSESSMENT_SOURCE_CREATE_HIDE = 'modal/ASSESSMENT_SOURCE_CREATE_HIDE';

export const MODAL_ASSESSMENT_SOURCE_EDIT_SHOW = 'modal/ASSESSMENT_SOURCE_EDIT_SHOW';
export const MODAL_ASSESSMENT_SOURCE_EDIT_HIDE = 'modal/ASSESSMENT_SOURCE_EDIT_HIDE';

export const MODAL_ASSESSMENT_DIMENSION_CREATE_SHOW = 'modal/ASSESSMENT_DIMENSION_CREATE_SHOW';
export const MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE = 'modal/ASSESSMENT_DIMENSION_CREATE_HIDE';

export const MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW = 'modal/ASSESSMENT_DIMENSION_EDIT_SHOW';
export const MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE = 'modal/ASSESSMENT_DIMENSION_EDIT_HIDE';

export const MODAL_DIMENSION_CATEGORY_CREATE_SHOW = 'modal/DIMENSION_CATEGORY_CREATE_SHOW';
export const MODAL_DIMENSION_CATEGORY_CREATE_HIDE = 'modal/DIMENSION_CATEGORY_CREATE_HIDE';

export const MODAL_DIMENSION_CATEGORY_EDIT_SHOW = 'modal/DIMENSION_CATEGORY_EDIT_SHOW';
export const MODAL_DIMENSION_CATEGORY_EDIT_HIDE = 'modal/DIMENSION_CATEGORY_EDIT_HIDE';

export const MODAL_DIMENSION_QUESTION_CREATE_SHOW = 'modal/DIMENSION_QUESTION_CREATE_SHOW';
export const MODAL_DIMENSION_QUESTION_CREATE_HIDE = 'modal/DIMENSION_QUESTION_CREATE_HIDE';

export const MODAL_DIMENSION_QUESTION_EDIT_SHOW = 'modal/DIMENSION_QUESTION_EDIT_SHOW';
export const MODAL_DIMENSION_QUESTION_EDIT_HIDE = 'modal/DIMENSION_QUESTION_EDIT_HIDE';

export const MODAL_DIMENSION_ANSWER_CREATE_SHOW = 'modal/DIMENSION_ANSWER_CREATE_SHOW';
export const MODAL_DIMENSION_ANSWER_CREATE_HIDE = 'modal/DIMENSION_ANSWER_CREATE_HIDE';

export const MODAL_ANSWER_ADD_SHOW = 'modal/ANSWER_ADD_SHOW';
export const MODAL_ANSWER_ADD_HIDE = 'modal/ANSWER_ADD_HIDE';

export const MODAL_ANSWER_EDIT_SHOW = 'modal/ANSWER_EDIT_SHOW';
export const MODAL_ANSWER_EDIT_HIDE = 'modal/ANSWER_EdIT_HIDE';

// EAP Focus
export const MODAL_EAP_FOCUS_CREATE_SHOW = 'modal/EAP_FOCUS_CREATE_SHOW';
export const MODAL_EAP_FOCUS_CREATE_HIDE = 'modal/EAP_FOCUS_CREATE_HIDE';

export const MODAL_EAP_FOCUS_EDIT_SHOW = 'modal/EAP_FOCUS_EDIT_SHOW';
export const MODAL_EAP_FOCUS_EDIT_HIDE = 'modal/EAP_FOCUS_EDIT_HIDE';

// export const MODAL_EAP_FOCUS_KEYWORD_CREATE_SHOW = 'modal/ASSESSMENT_DIMENSION_CREATE_SHOW';
// export const MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE = 'modal/ASSESSMENT_DIMENSION_CREATE_HIDE';

// export const MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW = 'modal/ASSESSMENT_DIMENSION_EDIT_SHOW';
// export const MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE = 'modal/ASSESSMENT_DIMENSION_EDIT_HIDE';

export const MODAL_EAP_FRAMEWORK_CREATE_SHOW = 'modal/EAP_FRAMEWORK_CREATE_SHOW';
export const MODAL_EAP_FRAMEWORK_CREATE_HIDE = 'modal/EAP_FRAMEWORK_CREATE_HIDE';

export const MODAL_EAP_FRAMEWORK_EDIT_SHOW = 'modal/EAP_FRAMEWORK_EDIT_SHOW';
export const MODAL_EAP_FRAMEWORK_EDIT_HIDE = 'modal/EAP_FRAMEWORK_EDIT_HIDE';

export const MODAL_EAP_SPECIALITY_CREATE_SHOW = 'modal/EAP_SPECIALITY_CREATE_SHOW';
export const MODAL_EAP_SPECIALITY_CREATE_HIDE = 'modal/EAP_SPECIALITY_CREATE_HIDE';

export const MODAL_EAP_SPECIALITY_EDIT_SHOW = 'modal/EAP_SPECIALITY_EDIT_SHOW';
export const MODAL_EAP_SPECIALITY_EDIT_HIDE = 'modal/EAP_SPECIALITY_EDIT_HIDE';

// Organization
export const MODAL_CUSTOMER_ORGANIZATION_CREATE_SHOW = 'modal/CUSTOMER_ORGANIZATION_CREATE_SHOW';
export const MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE = 'modal/CUSTOMER_ORGANIZATION_CREATE_HIDE';

export const MODAL_CUSTOMER_ORGANIZATION_EDIT_SHOW = 'modal/CUSTOMER_ORGANIZATION_EDIT_SHOW';
export const MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE = 'modal/CUSTOMER_ORGANIZATION_EDIT_HIDE';

export const MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW = 'modal/CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW';
export const MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE = 'modal/CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE';
