import { call, select, put, takeLatest, take, takeEvery } from 'redux-saga/effects';
import {
    CONTENT_TOOL_SET_TYPE_REQUESTED,
    CONTENT_USER_FEEDBACK_REQUESTED,
    CONTENT_USER_FEEDBACK_IS_LOADING,
    CONTENT_USER_FEEDBACK_NOT_LOADING,
    CONTENT_USER_FEEDBACK_FETCHED,
    CONTENT_TOOL_CREATE_REQUESTED,
    CONTENT_TOOL_GET_REQUESTED,
    CONTENT_CONTEXT_FETCH_REQUESTED,
    CONTENT_CONTEXT_IS_LOADING,
    CONTENT_CONTEXT_NOT_LOADING,
    CONTENT_CONTEXT_FETCHED,
    CONTENT_FOCUS_FETCH_REQUESTED,
    CONTENT_FOCUS_FETCHED,
    CONTENT_CATEGORY_FETCH_REQUESTED,
    CONTENT_CATEGORY_IS_LOADING,
    CONTENT_CATEGORY_NOT_LOADING,
    CONTENT_CATEGORY_FETCHED,
    CONTENT_CATEGORY_HEADER_FETCH_REQUESTED,
    CONTENT_CATEGORY_HEADER_IS_LOADING,
    CONTENT_CATEGORY_HEADER_NOT_LOADING,
    CONTENT_CATEGORY_HEADER_FETCHED,
    CONTENT_INTENTION_FETCH_REQUESTED,
    CONTENT_INTENTION_IS_LOADING,
    CONTENT_INTENTION_NOT_LOADING,
    CONTENT_INTENTION_FETCHED,
    CONTENT_TECHNIQUE_FETCH_REQUESTED,
    CONTENT_TECHNIQUE_IS_LOADING,
    CONTENT_TECHNIQUE_NOT_LOADING,
    CONTENT_TECHNIQUE_FETCHED,
    CONTENT_PROBLEM_FETCH_REQUESTED,
    CONTENT_PROBLEM_IS_LOADING,
    CONTENT_PROBLEM_NOT_LOADING,
    CONTENT_PROBLEM_FETCHED,
    CONTENT_SKILL_FETCH_REQUESTED,
    CONTENT_SKILL_IS_LOADING,
    CONTENT_SKILL_NOT_LOADING,
    CONTENT_SKILL_FETCHED,
    CONTENT_TOOL_FETCH_REQUESTED,
    CONTENT_TOOL_EDIT_REQUESTED,
    CONTENT_TOOL_IS_LOADING,
    CONTENT_TOOL_NOT_LOADING,
    CONTENT_TOOL_FETCHED,
    CONTENT_TOOL_DELETE_REQUESTED,
    CONTENT_TOOL_DELETE_COMPLETED,
    CONTENT_TOOL_TAG_FOCUS_ADD_REQUESTED,
    CONTENT_TOOL_TAG_FOCUS_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_PROBLEM_ADD_REQUESTED,
    CONTENT_TOOL_TAG_PROBLEM_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_SKILL_ADD_REQUESTED,
    CONTENT_TOOL_TAG_SKILL_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_INTENTION_ADD_REQUESTED,
    CONTENT_TOOL_TAG_INTENTION_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_CONTEXT_ADD_REQUESTED,
    CONTENT_TOOL_TAG_CONTEXT_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED,
    CONTENT_TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED,
    CONTENT_GOALS_REQUESTED,
    CONTENT_GOALS_IS_LOADING,
    CONTENT_GOALS_NOT_LOADING,
    CONTENT_GOALS_FETCHED,
    CONTENT_GOAL_CREATE_REQUESTED,
    CONTENT_GOAL_HEADERS_REQUESTED,
    CONTENT_GOAL_HEADERS_IS_LOADING,
    CONTENT_GOAL_HEADERS_NOT_LOADING,
    CONTENT_GOAL_HEADERS_FETCHED,
    CONTENT_GOAL_HEADER_CREATE_REQUESTED,
    CONTENT_GOAL_HEADER_DELETE_REQUESTED,
    CONTENT_GOAL_HEADER_DELETE_COMPLETED,
    CONTENT_GOAL_HEADER_LINK_REQUESTED,
    CONTENT_GOAL_HEADER_UNLINK_REQUESTED,
    CONTENT_GENERAL_EXERCISES_REQUESTED,
    CONTENT_GOAL_JOURNIES_REQUESTED,
    CONTENT_GOAL_JOURNIES_IS_LOADING,
    CONTENT_GOAL_JOURNIES_NOT_LOADING,
    CONTENT_GOAL_JOURNIES_FETCHED,
    CONTENT_GOAL_EXERCISES_REQUESTED,
    CONTENT_GOAL_EXERCISES_IS_LOADING,
    CONTENT_GOAL_EXERCISES_NOT_LOADING,
    CONTENT_GOAL_EXERCISES_FETCHED,
    CONTENT_JOURNEY_REQUESTED,
    CONTENT_JOURNEY_IS_LOADING,
    CONTENT_JOURNEY_NOT_LOADING,
    CONTENT_JOURNEY_FETCHED,
    CONTENT_EXERCISE_REQUESTED,
    CONTENT_EXERCISE_IS_LOADING,
    CONTENT_EXERCISE_NOT_LOADING,
    CONTENT_EXERCISE_FETCHED,
    CONTENT_EXERCISES_FETCHED,
    CONTENT_EXERCISE_MOVE_REQESTED,
    CONTENT_JOURNEY_CREATE_REQUESTED,
    CONTENT_JOURNEY_EDIT_REQUESTED,
    CONTENT_JOURNEY_GOAL_EDIT_REQUESTED,
    CONTENT_JOURNEY_DELETE_REQUESTED,
    CONTENT_JOURNEY_DELETE_COMPLETED,
    CONTENT_EXERCISE_CREATE_REQUESTED,
    CONTENT_EXERCISE_EDIT_REQUESTED,
    CONTENT_EXERCISE_DELETE_REQUESTED,
    CONTENT_EXERCISE_DELETE_COMPLETED,
    CONTENT_EXERCISE_CONTENT_REQUESTED,
    CONTENT_EXERCISE_CONTENT_IS_LOADING,
    CONTENT_EXERCISE_CONTENT_NOT_LOADING,
    CONTENT_EXERCISE_CONTENT_FETCHED,
    CONTENT_CONTENT_CARD_COPY_REQUESTED,
    CONTENT_CONTENT_CARD_CREATE_REQUESTED,
    CONTENT_CONTENT_CARD_DELETE_REQUESTED,
    CONTENT_CONTENT_CARD_MOVE_REQUESTED,
    CONTENT_CONTENT_CARD_COMPLETION_EDIT_REQUESTED,
    CONTENT_CONTENT_CARD_REFERENCE_EDIT_REQUESTED,
    CONTENT_CONTENT_CAPTION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_INFORMATION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_AUDIO_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_VIDEO_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TASK_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_COMPLETE_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED,
    CONTENT_CONTENT_READ_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TIMER_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED,
    CONTENT_CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED,
    CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_SUMMARY_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_REFLECTION_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_EDIT_REQUESTED,
    CONTENT_TAG_CREATE_REQUESTED,
    CONTENT_TAG_DELETE_REQUESTED,
    CONTENT_GOAL_PUBLISH_REQUESTED,
    CONTENT_JOURNEY_PUBLISH_REQUESTED,
    CONTENT_TOOL_PUBLISH_REQUESTED,
    CONTENT_EXERCISE_PUBLISH_REQUESTED,
    CONTENT_TOOL_ARCHIVE_REQUESTED,
    CONTENT_GOAL_LOCK_REQUESTED,
    CONTENT_JOURNEY_LOCK_REQUESTED,
    CONTENT_EXERCISE_LOCK_REQUESTED,
    CONTENT_EXERCISE_ACTION_EXERCISE_LINK_REQUESTED,
    CONTENT_EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED,
    CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED,
    CONTENT_TOOL_TAG_CREATE_REQUESTED,
    CONTENT_TOOL_TAG_DELETE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED,
    CONTENT_TECHNIQUE_TAG_IS_LOADING,
    CONTENT_TECHNIQUE_TAG_NOT_LOADING,
    CONTENT_TECHNIQUE_TAG_FETCHED,
    CONTENT_TECHNIQUE_TAG_ENABLE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_REMOVE_RULE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_ADD_RULE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_CREATE_REQUESTED,
    CONTENT_TECHNIQUE_TAG_DELETE_REQUESTED,
    CONTENT_LINK_ADD_REQUESTED,
    CONTENT_LINK_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_AFFECT_ADD_REQUESTED,
    CONTENT_TOOL_TAG_AFFECT_REMOVE_REQUESTED,
    CONTENT_QUOTE_CREATE_REQUESTED,
    CONTENT_QUOTE_FETCH_REQUESTED,
    CONTENT_QUOTE_IS_LOADING,
    CONTENT_QUOTE_NOT_LOADING,
    CONTENT_QUOTE_FETCHED,
    CONTENT_QUOTE_GET_REQUESTED,
    CONTENT_QUOTE_EDIT_REQUESTED,
    CONTENT_QUOTE_DELETE_REQUESTED,
    CONTENT_QUOTE_DELETE_COMPLETED,
    CONTENT_QUOTE_PUBLISH_REQUESTED,
    CONTENT_QUOTE_ARCHIVE_REQUESTED,
    CONTENT_GENERIC_TAG_DELETE_REQUESTED,
    CONTENT_GENERIC_TAG_CREATE_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED,
    CONTENT_CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED,
    CONTENT_SERIES_TOOLS_ADD_REQUESTED,
    CONTENT_SERIES_TOOLS_REMOVE_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED,
    CONTENT_CONTENT_GUIDE_CARD_EDIT_REQUESTED,
    CONTENT_TOOL_TAG_KEYWORD_ADD_REQUESTED,
    CONTENT_TOOL_TAG_KEYWORD_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_KEYWORD_IS_LOADING,
    CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING,
    CONTENT_TOOL_TAG_TAG_ADD_REQUESTED,
    CONTENT_TOOL_TAG_TAG_REMOVE_REQUESTED,
    CONTENT_TOOL_TAG_TAG_IS_LOADING,
    CONTENT_TOOL_TAG_TAG_NOT_LOADING
} from '../constants/content';
import {
    VIEW_CONTENT_SELECT_GOAL_ID,
    VIEW_CONTENT_SELECT_EXERCISE_ID,
    VIEW_CONTENT_SELECT_TOOLKIT,
    VIEW_CONTENT_SELECT_CONTENT_CARD_INDEX
} from '../constants/view/content';
import {
    MODAL_CONTENT_TOOL_CREATE_HIDE,
    MODAL_CONTENT_TOOL_EDIT_HIDE,
    MODAL_CONTENT_JOURNEY_CREATE_HIDE,
    MODAL_CONTENT_JOURNEY_EDIT_HIDE,
    MODAL_CONTENT_EXERCISE_CREATE_HIDE,
    MODAL_CONTENT_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE,
    MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE,
    MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE,
    MODAL_CONTENT_GOAL_HEADER_LINK_HIDE,
    MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE,
    MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE,
    MODAL_CONTENT_GOAL_CREATE_HIDE,
    MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE,
    MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COPY_HIDE,
    MODAL_CONTENT_CREATE_TOOL_TAG_HIDE,
    MODAL_CONTENT_LINK_ADD_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_QUOTE_CREATE_HIDE,
    MODAL_CONTENT_QUOTE_EDIT_HIDE,
    MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE
} from '../constants/view/modal';
import ContentService from '../../service/content';

function* addContentLink(action) {
    try {
        const success = yield call(ContentService.addContentLink, action.payload.tool_id, action.payload.lesson_id, action.payload.reason);
        if (success == true) {
            yield put({ type: MODAL_CONTENT_LINK_ADD_HIDE });
            if (action.payload.completion_handler != null) {
                yield call(action.payload.completion_handler);
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeContentLink(action) {
    try {
        const success = yield call(ContentService.removeContentLink, action.payload.tool_id, action.payload.lesson_id);
        if (success == true) {
            if (action.payload.completion_handler != null) {
                yield call(action.payload.completion_handler);
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createToolTag(action) {
    try {
        const success = yield call(ContentService.createToolTag, action.payload.tool_id, action.payload.params);
        if (success == true) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
            yield put({ type: MODAL_CONTENT_CREATE_TOOL_TAG_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteToolTag(action) {
    try {
        const success = yield call(ContentService.deleteToolTag, action.payload.tool_id, action.payload.tag_id);
        if (success == true) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createGenericTag(action) {
    try {
        const success = yield call(ContentService.createGenericTag, action.payload.tool_id, action.payload.params);
        if (success == true) {
            if (action.payload.params.is_tool) {
                yield put({
                    type: CONTENT_TOOL_GET_REQUESTED,
                    payload: { tool_id: action.payload.tool_id }
                });
            } else {
                yield put({
                    type: CONTENT_QUOTE_GET_REQUESTED,
                    payload: { quote_id: action.payload.tool_id }
                });
            }
            yield put({ type: MODAL_CONTENT_CREATE_TOOL_TAG_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteGenericTag(action) {
    try {
        const success = yield call(ContentService.deleteGenericTag, action.payload.tool_id, action.payload.tag_id);
        if (success == true) {
            if (action.payload.is_tool) {
                yield put({
                    type: CONTENT_TOOL_GET_REQUESTED,
                    payload: { tool_id: action.payload.tool_id }
                });
            } else {
                yield put({
                    type: CONTENT_QUOTE_GET_REQUESTED,
                    payload: { quote_id: action.payload.tool_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchUserFeedback(action) {
    try {
        yield put({ type: CONTENT_USER_FEEDBACK_IS_LOADING });
        const results = yield call(ContentService.fetchUserFeedback);
        yield put({ type: CONTENT_USER_FEEDBACK_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_USER_FEEDBACK_FETCHED,
                payload: {
                    user_feedbacks: results.feedbacks
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* setToolType(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.tool_type != 'JOURNEY' && action.payload.tool_type != 'IMMEDIATE') {
        console.log('[Content Saga] Invalid Tool Type Detected');
        return;
    }

    try {
        const success = yield call(ContentService.setContentToolType, action.payload.tool_id, action.payload.tool_type);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createTool(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(ContentService.createTool, language, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_TOOL_CREATE_HIDE });
            // Auto Fetch
            yield put({
                type: CONTENT_TOOL_FETCH_REQUESTED,
                payload: {
                    version: 1
                }
            });
            yield put({
                type: CONTENT_TOOL_FETCH_REQUESTED,
                payload: {
                    version: 2
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolCategoryTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.category_id == null) {
        console.log('[Content Saga] No Category Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolCategory, action.payload.tool_id, action.payload.category_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolCategoryTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.category_id == null) {
        console.log('[Content Saga] No Category Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolCategory, action.payload.tool_id, action.payload.category_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolCategoryHeaderTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.category_header_id == null) {
        console.log('[Content Saga] No Category Header Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolCategoryHeader, action.payload.tool_id, action.payload.category_header_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolCategoryHeaderTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.category_header_id == null) {
        console.log('[Content Saga] No Category Header Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolCategoryHeader, action.payload.tool_id, action.payload.category_header_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolFocusTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.focus_id == null) {
        console.log('[Content Saga] No Focus Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolFocus, action.payload.tool_id, action.payload.focus_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolFocusTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.focus_id == null) {
        console.log('[Content Saga] No Focus Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolFocus, action.payload.tool_id, action.payload.focus_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolProblemTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.problem_id == null) {
        console.log('[Content Saga] No Problem Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolProblem, action.payload.tool_id, action.payload.problem_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolProblemTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.problem_id == null) {
        console.log('[Content Saga] No Problem Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolProblem, action.payload.tool_id, action.payload.problem_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolSkillTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.skill_id == null) {
        console.log('[Content Saga] No Skill Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolSkill, action.payload.tool_id, action.payload.skill_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolSkillTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.skill_id == null) {
        console.log('[Content Saga] No Skill Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolSkill, action.payload.tool_id, action.payload.skill_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolContextTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.context_id == null) {
        console.log('[Content Saga] No Context Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolContext, action.payload.tool_id, action.payload.context_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolContextTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.context_id == null) {
        console.log('[Content Saga] No Context Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolContext, action.payload.tool_id, action.payload.context_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolAffectTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.affect_id == null) {
        console.log('[Content Saga] No Affect Detected');
        return;
    }
    try {
        const success = yield call(ContentService.addTagToolAffect, action.payload.tool_id, action.payload.affect_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolAffectTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.affect_id == null) {
        console.log('[Content Saga] No Affect Detected');
        return;
    }
    try {
        const success = yield call(ContentService.removeTagToolAffect, action.payload.tool_id, action.payload.affect_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolIntentionTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.intention_id == null) {
        console.log('[Content Saga] No Intention Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addTagToolIntention, action.payload.tool_id, action.payload.intention_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolIntentionTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.intention_id == null) {
        console.log('[Content Saga] No Intention Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolIntention, action.payload.tool_id, action.payload.intention_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* replaceToolTechniqueTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.technique_id == null) {
        console.log('[Content Saga] No Technique Detected');
        return;
    }

    try {
        const success = yield call(ContentService.replaceTagToolTechnique, action.payload.tool_id, action.payload.technique_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolTechniqueTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolTechnique, action.payload.tool_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchSeriesById(action) {
    if (action.payload.series_id == null) {
        console.log('[Content Saga] No Series Detected');
        return;
    }

    try {
        const success = yield call(ContentService.fetchSeriesById, action.payload.series_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.series_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addSeriesTools(action) {
    if (action.payload.series_id == null) {
        console.log('[Content Saga] No Series Detected');
        return;
    }

    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        const success = yield call(ContentService.addToolToSeries, action.payload.series_id, action.payload.tool_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.series_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeSeriesTools(action) {
    if (action.payload.series_id == null) {
        console.log('[Content Saga] No Series Detected');
        return;
    }

    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeToolFromSeries, action.payload.series_id, action.payload.tool_id);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.series_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateTool(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        const results = yield call(ContentService.updateTool, action.payload.tool_id, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_TOOL_EDIT_HIDE });
            yield put({ type: MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE });
            // Auto Fetch
            if (results != null) {
                yield put({
                    type: CONTENT_TOOL_GET_REQUESTED,
                    payload: { tool_id: action.payload.tool_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteTool(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        const results = yield call(ContentService.deleteTool, action.payload.tool_id);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_TOOL_DELETE_COMPLETED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchContexts(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_CONTEXT_IS_LOADING });
        const results = yield call(ContentService.fetchContexts, language);
        yield put({ type: CONTENT_CONTEXT_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_CONTEXT_FETCHED,
                payload: {
                    contexts: results.contexts
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchTechniques(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_TECHNIQUE_IS_LOADING });
        const results = yield call(ContentService.fetchTechniques, language);
        yield put({ type: CONTENT_TECHNIQUE_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_TECHNIQUE_FETCHED,
                payload: {
                    techniques: results.techniques
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchCategories(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_CATEGORY_IS_LOADING });
        const results = yield call(ContentService.fetchCategories, language);
        yield put({ type: CONTENT_CATEGORY_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_CATEGORY_FETCHED,
                payload: {
                    categories: results.categories
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchCategoryHeaders(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_CATEGORY_HEADER_IS_LOADING });
        const results = yield call(ContentService.fetchCategoryHeaders, language);
        yield put({ type: CONTENT_CATEGORY_HEADER_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_CATEGORY_HEADER_FETCHED,
                payload: {
                    category_headers: results.category_headers
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchFocuses(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(ContentService.fetchFocuses, language);
        if (results != null) {
            yield put({
                type: CONTENT_FOCUS_FETCHED,
                payload: {
                    focuses: results.focuses
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchProblems(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_PROBLEM_IS_LOADING });
        const results = yield call(ContentService.fetchProblems, language);
        yield put({ type: CONTENT_PROBLEM_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_PROBLEM_FETCHED,
                payload: {
                    problems: results.problems
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchSkills(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_SKILL_IS_LOADING });
        const results = yield call(ContentService.fetchSkills, language);
        yield put({ type: CONTENT_SKILL_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_SKILL_FETCHED,
                payload: {
                    skills: results.skills
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchIntentions(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_INTENTION_IS_LOADING });
        const results = yield call(ContentService.fetchIntentions, language);
        yield put({ type: CONTENT_INTENTION_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_INTENTION_FETCHED,
                payload: {
                    intentions: results.intentions
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* getTool(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_TOOL_IS_LOADING });
        const results = yield call(ContentService.getTool, action.payload.tool_id, language);
        yield put({ type: CONTENT_TOOL_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_TOOL_FETCHED,
                payload: {
                    tools: [results.tool]
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchTools(action) {
    try {
        {
            const version = action.payload != null ? action.payload.version || 1 : 1;
            yield put({ type: CONTENT_TOOL_IS_LOADING });
            const zh_results = yield call(ContentService.fetchToolsVersion, version, 'zh');
            yield put({ type: CONTENT_TOOL_NOT_LOADING });
            if (zh_results != null) {
                yield put({
                    type: CONTENT_TOOL_FETCHED,
                    payload: {
                        tools: zh_results.tools
                    }
                });
            }
            yield put({ type: CONTENT_TOOL_IS_LOADING });
            const yue_results = yield call(ContentService.fetchToolsVersion, version, 'yue');
            yield put({ type: CONTENT_TOOL_NOT_LOADING });
            if (yue_results != null) {
                yield put({
                    type: CONTENT_TOOL_FETCHED,
                    payload: {
                        tools: yue_results.tools
                    }
                });
            }
            yield put({ type: CONTENT_TOOL_IS_LOADING });
            const en_results = yield call(ContentService.fetchToolsVersion, version, 'en');
            yield put({ type: CONTENT_TOOL_NOT_LOADING });
            if (en_results != null) {
                yield put({
                    type: CONTENT_TOOL_FETCHED,
                    payload: {
                        tools: en_results.tools
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createGoal(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(ContentService.createGoal, language, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_GOAL_CREATE_HIDE });
            // Auto Fetch
            yield put({ type: CONTENT_GOALS_REQUESTED });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchGoals(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_GOALS_IS_LOADING });
        const results = yield call(ContentService.fetchGoals, language);
        yield put({ type: CONTENT_GOALS_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_GOALS_FETCHED,
                payload: {
                    goals: results.goals
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createGoalHeader(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        const results = yield call(ContentService.createGoalHeader, action.payload.goal_id, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE });
            yield put({
                type: CONTENT_GOAL_HEADERS_REQUESTED,
                payload: { goal_id: action.payload.goal_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchGoalHeaders(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_GOAL_HEADERS_IS_LOADING });
        const results = yield call(ContentService.fetchGoalHeaders, action.payload.goal_id);
        yield put({ type: CONTENT_GOAL_HEADERS_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_GOAL_HEADERS_FETCHED,
                payload: {
                    goal_headers: results.goal_headers
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchGoalJournies(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_GOAL_JOURNIES_IS_LOADING });
        const results = yield call(ContentService.fetchGoalJournies, action.payload.goal_id);
        yield put({ type: CONTENT_GOAL_JOURNIES_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_GOAL_JOURNIES_FETCHED,
                payload: {
                    journies: results.journies
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteGoalHeader(action) {
    if (action.payload.goal_header_id == null) {
        console.log('[Content Saga] No Goal Header Detected');
        return;
    }

    const goal_header_id = action.payload.goal_header_id;

    try {
        const results = yield call(ContentService.deleteGoalHeader, goal_header_id);
        if (results != null) {
            // Auto Fetch
            const state = yield select();
            const goal_header = state.data.content.goal_headers.data.find((goal_header) => goal_header.id == goal_header_id);
            if (goal_header != null && goal_header.goal != null && goal_header.goal.id != null) {
                yield put({
                    type: CONTENT_GOAL_JOURNIES_REQUESTED,
                    payload: { goal_id: goal_header.goal.id }
                });
            }
            yield put({
                type: CONTENT_GOAL_HEADER_DELETE_COMPLETED,
                payload: { goal_header_id: goal_header_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* linkJourneyGoalHeader(action) {
    if (action.payload.goal_header_id == null) {
        console.log('[Content Saga] No Goal Header Detected');
        return;
    }
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        const results = yield call(ContentService.linkJourneyGoalHeader, action.payload.goal_header_id, action.payload.journey_id);
        if (results != null) {
            // Auto Fetch
            yield put({ type: MODAL_CONTENT_GOAL_HEADER_LINK_HIDE });
            const state = yield select();
            const journey = state.data.content.journies.data.find((journey) => journey.id == action.payload.journey_id);
            if (journey != null) {
                yield put({
                    type: CONTENT_JOURNEY_REQUESTED,
                    payload: { journey_id: journey.id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* unlinkJourneyGoalHeader(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        const results = yield call(ContentService.unlinkJourneyGoalHeader, action.payload.journey_id);
        if (results != null) {
            yield put({ type: MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE });
            // Auto Fetch
            yield put({
                type: CONTENT_JOURNEY_REQUESTED,
                payload: { journey_id: action.payload.journey_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchGeneralExercises(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        const results = yield call(ContentService.fetchGeneralExercises, language);
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_EXERCISES_FETCHED,
                payload: {
                    exercises: results.exercises
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchGoalExercises(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_GOAL_EXERCISES_IS_LOADING });
        const results = yield call(ContentService.fetchGoalExercises, action.payload.goal_id);
        yield put({ type: CONTENT_GOAL_EXERCISES_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_GOAL_EXERCISES_FETCHED,
                payload: {
                    exercises: results.exercises
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* getJourney(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_JOURNEY_IS_LOADING });
        const results = yield call(ContentService.getJourney, action.payload.journey_id);
        yield put({ type: CONTENT_JOURNEY_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_JOURNEY_FETCHED,
                payload: {
                    journey: results.journey
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* getExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        const results = yield call(ContentService.getExercise, action.payload.exercise_id);
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_EXERCISE_FETCHED,
                payload: {
                    exercise: results.exercise
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createJourney(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(ContentService.createJourney, action.payload.goal_id, language, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_JOURNEY_CREATE_HIDE });
            // Auto Fetch
            if (results.journey != null) {
                yield put({
                    type: CONTENT_JOURNEY_REQUESTED,
                    payload: { journey_id: results.journey.id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateJourney(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        const results = yield call(ContentService.updateJourney, action.payload.journey_id, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_JOURNEY_EDIT_HIDE });
            // Auto Fetch
            yield put({
                type: CONTENT_JOURNEY_REQUESTED,
                payload: { journey_id: action.payload.journey_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateJourneyGoal(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        const results = yield call(ContentService.updateJourney, action.payload.journey_id, { goal_id: action.payload.goal_id });
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE });
            // Auto Fetch
            yield put({
                type: CONTENT_JOURNEY_REQUESTED,
                payload: { journey_id: action.payload.journey_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteJourney(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        const results = yield call(ContentService.deleteJourney, action.payload.journey_id);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_JOURNEY_DELETE_COMPLETED,
                payload: { journey_id: action.payload.journey_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createExercise(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(
            ContentService.createExercise,
            action.payload.goal_id,
            action.payload.journey_id,
            action.payload.tool_id,
            language,
            action.payload.params
        );
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_EXERCISE_CREATE_HIDE });
            // Auto Fetch
            if (results.exercise != null) {
                yield put({
                    type: CONTENT_EXERCISE_REQUESTED,
                    payload: { exercise_id: results.exercise.id }
                });
                // Update Journey Internal Exercise
                if (action.payload.journey_id != null) {
                    yield put({
                        type: CONTENT_JOURNEY_REQUESTED,
                        payload: { journey_id: action.payload.journey_id }
                    });
                }
                if (action.payload.tool_id != null) {
                    yield put({
                        type: CONTENT_TOOL_GET_REQUESTED,
                        payload: { tool_id: action.payload.tool_id }
                    });
                }
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        const results = yield call(ContentService.updateExercise, action.payload.exercise_id, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_EXERCISE_EDIT_HIDE });
            yield put({ type: MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE });
            // Auto Fetch
            if (results != null) {
                yield put({
                    type: CONTENT_EXERCISE_REQUESTED,
                    payload: { exercise_id: action.payload.exercise_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        const results = yield call(ContentService.deleteExercise, action.payload.exercise_id);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_DELETE_COMPLETED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* moveJourneyExercise(action) {
    if (action.payload.exercise_id == null || action.payload.to_order == null) {
        console.log('[Content Saga] No Exercise Move Operation Params Detected');
        return;
    }

    try {
        yield call(ContentService.moveExercise, action.payload.exercise_id, action.payload.to_order);
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* getExerciseContent(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_CONTENT_IS_LOADING });
        const results = yield call(ContentService.getExerciseContent, action.payload.exercise_id);
        yield put({ type: CONTENT_EXERCISE_CONTENT_NOT_LOADING });
        if (results != null && results.content != null) {
            yield put({
                type: CONTENT_EXERCISE_CONTENT_FETCHED,
                payload: {
                    content: results.content
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchTechniqueTags(action) {
    try {
        yield put({ type: CONTENT_TECHNIQUE_TAG_IS_LOADING });
        const results = yield call(ContentService.fetchTechniqueTags);
        yield put({ type: CONTENT_TECHNIQUE_TAG_NOT_LOADING });
        if (results != null && results.tags != null) {
            yield put({
                type: CONTENT_TECHNIQUE_TAG_FETCHED,
                payload: {
                    tags: results.tags
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createTechniqueTag(action) {
    if (action.payload.technique_id == null) {
        console.log('[Content Saga] No Technique ID Detected');
        return;
    }

    try {
        const success = yield call(ContentService.createTechniqueTag, action.payload.technique_id);
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteTechniqueTag(action) {
    if (action.payload.technique_id == null) {
        console.log('[Content Saga] No Technique ID Detected');
        return;
    }

    try {
        const success = yield call(ContentService.deleteTechniqueTag, action.payload.technique_id, action.payload.tag_id);
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* enableTechniqueTag(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Technique Tag ID Detected');
        return;
    }

    try {
        const success = yield call(
            ContentService.enableTechniqueTag,
            action.payload.technique_id,
            action.payload.tag_id,
            action.payload.is_enabled
        );
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addTechniqueTagPushMessage(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Technique Tag ID Detected');
        return;
    }

    try {
        const success = yield call(
            ContentService.addTechniqueTagPushMessage,
            action.payload.technique_id,
            action.payload.tag_id,
            action.payload.language,
            action.payload.message
        );
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeTechniqueTagPushMessage(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Technique Tag ID Detected');
        return;
    }

    try {
        const success = yield call(
            ContentService.removeTechniqueTagPushMessage,
            action.payload.technique_id,
            action.payload.tag_id,
            action.payload.language,
            action.payload.index
        );
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeTechniqueTagRule(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Technique Tag ID Detected');
        return;
    }

    try {
        const success = yield call(
            ContentService.removeTechniqueTagRule,
            action.payload.technique_id,
            action.payload.tag_id,
            action.payload.index
        );
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addTechniqueTagRule(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Technique Tag ID Detected');
        return;
    }

    try {
        const success = yield call(
            ContentService.addTechniqueTagRule,
            action.payload.technique_id,
            action.payload.tag_id,
            action.payload.rule
        );
        if (success) {
            yield put({ type: CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED });
        }
        if (action.payload.completion_handler != null) {
            yield call(action.payload.completion_handler, success);
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* copyContentCard(action) {
    if (action.payload.from_exercise_id == null || action.payload.to_exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        const success = yield call(ContentService.copyContentCard, action.payload.from_exercise_id, action.payload.to_exercise_id);
        if (success) {
            yield put({ type: MODAL_CONTENT_CONTENT_CARD_COPY_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        const results = yield call(ContentService.createContentCard, action.payload.exercise_id, action.payload.type);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        const results = yield call(ContentService.deleteContentCard, action.payload.exercise_id, action.payload.index);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* moveContentCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.from_index == null) {
        console.log('[Content Saga] No From Index Detected');
        return;
    }
    if (action.payload.to_index == null) {
        console.log('[Content Saga] No To Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.moveContentCard,
            action.payload.exercise_id,
            action.payload.from_index,
            action.payload.to_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE });
            yield put({
                type: VIEW_CONTENT_SELECT_CONTENT_CARD_INDEX,
                payload: {
                    index: action.payload.to_index
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentCardReference(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentCardReference,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.type,
            action.payload.value
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentCardCompletion(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentCardCompletion,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.type,
            action.payload.data
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentTimerCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentTimerCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentCaptionCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentCaptionCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentInformationCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentInformationCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentAudioCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentAudioCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentVideoCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentVideoCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentTaskCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentTaskCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentCompleteCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentCompleteCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentCardRead(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentReadCard,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentContentQuizCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Card Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentQuizCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentQuizCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentQuizCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentQuizCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentQuizCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentQuizCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentQuizCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentInputCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentInputCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentInputCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentInputCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentInputCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentInputCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentQuoteCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentQuoteCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentQuoteCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentQuoteCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentQuoteCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentQuoteCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentChecklistCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentChecklistCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentAssessmentCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.updateContentAssessmentCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentAssessmentResultCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.updateContentAssessmentResultCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({
                type: MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentSummaryCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentSummaryCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentReflectionCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentReflectionCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentChecklistCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentChecklistCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentChecklistCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentChecklistCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentChecklistCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentChecklistCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentQuestionnaireCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.updateContentQuestionnaireCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentOverviewSessionsCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.updateContentOverviewSessionsCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentToolCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.updateContentToolCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_TOOL_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolContentToolCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.addToolContentToolCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_TOOL_CARD_ADD_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* removeToolContentToolCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }
    try {
        const results = yield call(
            ContentService.removeToolContentToolCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentDocumentCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentDocumentCardItem,
            action.payload.exercise_id,
            action.payload.index,
            action.payload.type,
            action.payload.data
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentDocumentCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentDocumentCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentDocumentCardItem(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentDocumentCardItem,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.type,
            action.payload.data
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({
                type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE
            });
            yield put({
                type: MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createTag(action) {
    if (action.payload.exercise_id == null && action.payload.journey_id == null) {
        console.log('[Content Saga] No Content Detected');
        return;
    }
    if (action.payload.exercise_id != null && action.payload.journey_id != null) {
        console.log('[Content Saga] Conflict Content Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.createContentTag,
            action.payload.exercise_id,
            action.payload.journey_id,
            action.payload.name
        );
        if (results != null) {
            // Auto Fetch
            if (action.payload.exercise_id != null) {
                yield put({
                    type: CONTENT_EXERCISE_REQUESTED,
                    payload: { exercise_id: action.payload.exercise_id }
                });
            }
            if (action.payload.journey_id) {
                yield put({
                    type: CONTENT_JOURNEY_REQUESTED,
                    payload: { journey_id: action.payload.journey_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteTag(action) {
    if (action.payload.tag_id == null) {
        console.log('[Content Saga] No Tag Detected');
        return;
    }
    try {
        const results = yield call(ContentService.deleteContentTag, action.payload.tag_id);
        if (results != null) {
            // Auto Fetch
            if (action.payload.exercise_id != null) {
                yield put({
                    type: CONTENT_EXERCISE_REQUESTED,
                    payload: { exercise_id: action.payload.exercise_id }
                });
            }
            if (action.payload.journey_id) {
                yield put({
                    type: CONTENT_JOURNEY_REQUESTED,
                    payload: { journey_id: action.payload.journey_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* publishGoal(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_GOALS_IS_LOADING });
        yield call(ContentService.setContentGoalPublish, action.payload.goal_id, action.payload.has_published);
        yield put({ type: CONTENT_GOALS_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* publishJourney(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_JOURNEY_IS_LOADING });
        yield call(ContentService.setContentJourneyPublish, action.payload.journey_id, action.payload.has_published);
        yield put({ type: CONTENT_JOURNEY_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* archiveTool(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_TOOL_IS_LOADING });
        yield call(ContentService.setContentToolArchive, action.payload.tool_id, action.payload.is_archived);
        yield put({
            type: CONTENT_TOOL_GET_REQUESTED,
            payload: { tool_id: action.payload.tool_id }
        });
        yield put({ type: CONTENT_TOOL_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* publishTool(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_TOOL_IS_LOADING });
        yield call(ContentService.setContentToolPublish, action.payload.tool_id, action.payload.has_published);
        yield put({ type: CONTENT_TOOL_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* publishExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        yield call(ContentService.setContentExercisePublish, action.payload.exercise_id, action.payload.has_published);
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* lockGoal(action) {
    if (action.payload.goal_id == null) {
        console.log('[Content Saga] No Goal Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_GOALS_IS_LOADING });
        yield call(ContentService.setContentGoalLock, action.payload.goal_id, action.payload.is_locked);
        yield put({ type: CONTENT_GOALS_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* lockJourney(action) {
    if (action.payload.journey_id == null) {
        console.log('[Content Saga] No Journey Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_JOURNEY_IS_LOADING });
        yield call(ContentService.setContentJourneyLock, action.payload.journey_id, action.payload.is_locked);
        yield put({ type: CONTENT_JOURNEY_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* lockExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        yield call(ContentService.setContentExerciseLock, action.payload.exercise_id, action.payload.is_locked);
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* linkExerciseActionExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        yield call(ContentService.linkExerciseActionExercise, action.payload.exercise_id, action.payload.action_exercise_id);
        if (action.payload.exercise_id != null) {
            yield put({
                type: CONTENT_EXERCISE_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
        // Hide Edit Modal
        yield put({ type: MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE });
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* unlinkExerciseActionExercise(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_EXERCISE_IS_LOADING });
        yield call(ContentService.unlinkExerciseActionExercise, action.payload.exercise_id);
        if (action.payload.exercise_id != null) {
            yield put({
                type: CONTENT_EXERCISE_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
        yield put({ type: CONTENT_EXERCISE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

// quote
function* createQuote(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        const results = yield call(ContentService.createQuote, language, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_QUOTE_CREATE_HIDE });
            // Auto Fetch
            yield put({ type: CONTENT_QUOTE_FETCH_REQUESTED });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* fetchQuotes(action) {
    try {
        {
            yield put({ type: CONTENT_QUOTE_IS_LOADING });
            const zh_results = yield call(ContentService.fetchQuotes, 'zh');
            yield put({ type: CONTENT_QUOTE_NOT_LOADING });
            if (zh_results != null) {
                yield put({
                    type: CONTENT_QUOTE_FETCHED,
                    payload: {
                        quotes: zh_results.quotes
                    }
                });
            }
            yield put({ type: CONTENT_QUOTE_IS_LOADING });
            const yue_results = yield call(ContentService.fetchQuotes, 'yue');
            yield put({ type: CONTENT_QUOTE_NOT_LOADING });
            if (yue_results != null) {
                yield put({
                    type: CONTENT_QUOTE_FETCHED,
                    payload: {
                        quotes: yue_results.quotes
                    }
                });
            }
            yield put({ type: CONTENT_QUOTE_IS_LOADING });
            const en_results = yield call(ContentService.fetchQuotes, 'en');
            yield put({ type: CONTENT_QUOTE_NOT_LOADING });
            if (en_results != null) {
                yield put({
                    type: CONTENT_QUOTE_FETCHED,
                    payload: {
                        quotes: en_results.quotes
                    }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* getQuote(action) {
    try {
        const state = yield select();
        const language = state.data.setting.locale;
        yield put({ type: CONTENT_QUOTE_IS_LOADING });
        const results = yield call(ContentService.getQuote, action.payload.quote_id, language);
        yield put({ type: CONTENT_QUOTE_NOT_LOADING });
        if (results != null) {
            yield put({
                type: CONTENT_QUOTE_FETCHED,
                payload: {
                    quotes: [results.quote]
                }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateQuote(action) {
    if (action.payload.quote_id == null) {
        console.log('[Content Saga] No Quote Detected');
        return;
    }

    try {
        const results = yield call(ContentService.updateQuote, action.payload.quote_id, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_CONTENT_QUOTE_EDIT_HIDE });
            // Auto Fetch
            if (results != null) {
                yield put({
                    type: CONTENT_QUOTE_GET_REQUESTED,
                    payload: { quote_id: action.payload.quote_id }
                });
            }
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteQuote(action) {
    if (action.payload.quote_id == null) {
        console.log('[Content Saga] No Quote Detected');
        return;
    }

    try {
        const results = yield call(ContentService.deleteQuote, action.payload.quote_id);
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_QUOTE_DELETE_COMPLETED,
                payload: { quote_id: action.payload.quote_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* publishQuote(action) {
    if (action.payload.quote_id == null) {
        console.log('[Content Saga] No Quote Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_QUOTE_IS_LOADING });
        yield call(ContentService.setContentQuotePublish, action.payload.quote_id, action.payload.has_published);
        yield put({ type: CONTENT_QUOTE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* archiveQuote(action) {
    if (action.payload.quote_id == null) {
        console.log('[Content Saga] No Quote Detected');
        return;
    }

    try {
        yield put({ type: CONTENT_QUOTE_IS_LOADING });
        yield call(ContentService.setContentQuoteArchive, action.payload.quote_id, action.payload.is_archived);
        yield put({
            type: CONTENT_QUOTE_GET_REQUESTED,
            payload: { quote_id: action.payload.quote_id }
        });
        yield put({ type: CONTENT_QUOTE_NOT_LOADING });
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* createContentGuideCardConcept(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    try {
        console.log('create content guide card: ', action);
        const results = yield call(
            ContentService.createContentGuideConcept,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentContentGuideCard(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Card Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentGuideCard,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* updateContentGuideCardConcept(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.updateContentGuideCardConcept,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.params
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
            yield put({ type: MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* deleteContentGuideCardConcept(action) {
    if (action.payload.exercise_id == null) {
        console.log('[Content Saga] No Exercise Detected');
        return;
    }
    if (action.payload.card_index == null) {
        console.log('[Content Saga] No Index Detected');
        return;
    }
    if (action.payload.item_index == null) {
        console.log('[Content Saga] No Item Index Detected');
        return;
    }

    try {
        const results = yield call(
            ContentService.deleteContentGuideCardConcept,
            action.payload.exercise_id,
            action.payload.card_index,
            action.payload.item_index,
            action.payload.concept_id
        );
        if (results != null) {
            // Auto Fetch
            yield put({
                type: CONTENT_EXERCISE_CONTENT_REQUESTED,
                payload: { exercise_id: action.payload.exercise_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolKeywordTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.keyword == null) {
        console.log('[Content Saga] No Keyword Detected');
        return;
    }

    yield put({
        type: CONTENT_TOOL_TAG_KEYWORD_IS_LOADING
    });

    try {
        const success = yield call(ContentService.addTagToolKeyword, action.payload.tool_id, action.payload.keyword);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
    yield put({
        type: CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING
    });
}

function* removeToolKeywordTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.keyword == null) {
        console.log('[Content Saga] No Keyword Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolKeyword, action.payload.tool_id, action.payload.keyword);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* addToolTagTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.type == null || action.payload.value == null) {
        console.log('[Content Saga] No Type or Value Detected');
        return;
    }

    yield put({
        type: CONTENT_TOOL_TAG_TAG_IS_LOADING
    });

    try {
        const success = yield call(ContentService.addTagToolTag, action.payload.tool_id, action.payload.type, action.payload.value);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
            yield put({ type: MODAL_CONTENT_CREATE_TOOL_TAG_HIDE });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
    yield put({
        type: CONTENT_TOOL_TAG_TAG_NOT_LOADING
    });
}

function* removeToolTagTag(action) {
    if (action.payload.tool_id == null) {
        console.log('[Content Saga] No Tool Detected');
        return;
    }

    if (action.payload.type == null || action.payload.value == null) {
        console.log('[Content Saga] No Type or Value Detected');
        return;
    }

    try {
        const success = yield call(ContentService.removeTagToolTag, action.payload.tool_id, action.payload.type, action.payload.value);
        if (success) {
            yield put({
                type: CONTENT_TOOL_GET_REQUESTED,
                payload: { tool_id: action.payload.tool_id }
            });
        }
    } catch (e) {
        console.error('[Content Saga]', e);
    }
}

function* ContentSaga() {
    yield takeLatest(CONTENT_TOOL_SET_TYPE_REQUESTED, setToolType);
    yield takeLatest(CONTENT_USER_FEEDBACK_REQUESTED, fetchUserFeedback);
    yield takeLatest(CONTENT_TOOL_CREATE_REQUESTED, createTool);
    yield takeLatest(CONTENT_CONTEXT_FETCH_REQUESTED, fetchContexts);
    yield takeLatest(CONTENT_TECHNIQUE_FETCH_REQUESTED, fetchTechniques);
    yield takeLatest(CONTENT_INTENTION_FETCH_REQUESTED, fetchIntentions);
    yield takeLatest(CONTENT_CATEGORY_FETCH_REQUESTED, fetchCategories);
    yield takeLatest(CONTENT_CATEGORY_HEADER_FETCH_REQUESTED, fetchCategoryHeaders);
    yield takeLatest(CONTENT_FOCUS_FETCH_REQUESTED, fetchFocuses);
    yield takeLatest(CONTENT_PROBLEM_FETCH_REQUESTED, fetchProblems);
    yield takeLatest(CONTENT_SKILL_FETCH_REQUESTED, fetchSkills);
    yield takeLatest(CONTENT_TOOL_EDIT_REQUESTED, updateTool);
    yield takeLatest(CONTENT_TOOL_GET_REQUESTED, getTool);
    yield takeEvery(CONTENT_TOOL_FETCH_REQUESTED, fetchTools);
    yield takeLatest(CONTENT_TOOL_DELETE_REQUESTED, deleteTool);
    yield takeLatest(CONTENT_TOOL_TAG_CONTEXT_ADD_REQUESTED, addToolContextTag);
    yield takeLatest(CONTENT_TOOL_TAG_CONTEXT_REMOVE_REQUESTED, removeToolContextTag);
    yield takeLatest(CONTENT_TOOL_TAG_AFFECT_ADD_REQUESTED, addToolAffectTag);
    yield takeLatest(CONTENT_TOOL_TAG_AFFECT_REMOVE_REQUESTED, removeToolAffectTag);
    yield takeLatest(CONTENT_TOOL_TAG_CATEGORY_ADD_REQUESTED, addToolCategoryTag);
    yield takeLatest(CONTENT_TOOL_TAG_CATEGORY_REMOVE_REQUESTED, removeToolCategoryTag);
    yield takeLatest(CONTENT_TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED, addToolCategoryHeaderTag);
    yield takeLatest(CONTENT_TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED, removeToolCategoryHeaderTag);
    yield takeLatest(CONTENT_TOOL_TAG_FOCUS_ADD_REQUESTED, addToolFocusTag);
    yield takeLatest(CONTENT_TOOL_TAG_FOCUS_REMOVE_REQUESTED, removeToolFocusTag);
    yield takeLatest(CONTENT_TOOL_TAG_PROBLEM_ADD_REQUESTED, addToolProblemTag);
    yield takeLatest(CONTENT_TOOL_TAG_PROBLEM_REMOVE_REQUESTED, removeToolProblemTag);
    yield takeLatest(CONTENT_TOOL_TAG_SKILL_ADD_REQUESTED, addToolSkillTag);
    yield takeLatest(CONTENT_TOOL_TAG_SKILL_REMOVE_REQUESTED, removeToolSkillTag);
    yield takeLatest(CONTENT_TOOL_TAG_INTENTION_ADD_REQUESTED, addToolIntentionTag);
    yield takeLatest(CONTENT_TOOL_TAG_INTENTION_REMOVE_REQUESTED, removeToolIntentionTag);
    yield takeLatest(CONTENT_TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED, replaceToolTechniqueTag);
    yield takeLatest(CONTENT_TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED, removeToolTechniqueTag);

    yield takeLatest(CONTENT_SERIES_TOOLS_ADD_REQUESTED, addSeriesTools);
    yield takeLatest(CONTENT_SERIES_TOOLS_REMOVE_REQUESTED, removeSeriesTools);

    yield takeLatest(CONTENT_GOALS_REQUESTED, fetchGoals);
    yield takeLatest(CONTENT_GOAL_CREATE_REQUESTED, createGoal);
    yield takeLatest(CONTENT_GOAL_HEADERS_REQUESTED, fetchGoalHeaders);
    yield takeLatest(CONTENT_GOAL_JOURNIES_REQUESTED, fetchGoalJournies);
    yield takeLatest(CONTENT_GOAL_HEADER_CREATE_REQUESTED, createGoalHeader);
    yield takeLatest(CONTENT_GOAL_HEADER_DELETE_REQUESTED, deleteGoalHeader);
    yield takeLatest(CONTENT_GOAL_HEADER_LINK_REQUESTED, linkJourneyGoalHeader);
    yield takeLatest(CONTENT_GOAL_HEADER_UNLINK_REQUESTED, unlinkJourneyGoalHeader);
    yield takeLatest(CONTENT_GENERAL_EXERCISES_REQUESTED, fetchGeneralExercises);
    yield takeLatest(CONTENT_JOURNEY_REQUESTED, getJourney);
    yield takeLatest(CONTENT_GOAL_EXERCISES_REQUESTED, fetchGoalExercises);
    yield takeLatest(CONTENT_EXERCISE_REQUESTED, getExercise);
    yield takeLatest(CONTENT_JOURNEY_CREATE_REQUESTED, createJourney);
    yield takeLatest(CONTENT_JOURNEY_EDIT_REQUESTED, updateJourney);
    yield takeLatest(CONTENT_JOURNEY_GOAL_EDIT_REQUESTED, updateJourneyGoal);
    yield takeLatest(CONTENT_JOURNEY_DELETE_REQUESTED, deleteJourney);
    yield takeLatest(CONTENT_EXERCISE_CREATE_REQUESTED, createExercise);
    yield takeLatest(CONTENT_EXERCISE_EDIT_REQUESTED, updateExercise);
    yield takeLatest(CONTENT_EXERCISE_DELETE_REQUESTED, deleteExercise);
    yield takeLatest(CONTENT_EXERCISE_CONTENT_REQUESTED, getExerciseContent);
    yield takeLatest(CONTENT_EXERCISE_MOVE_REQESTED, moveJourneyExercise);
    yield takeLatest(CONTENT_CONTENT_CARD_COPY_REQUESTED, copyContentCard);
    yield takeLatest(CONTENT_CONTENT_CARD_CREATE_REQUESTED, createContentCard);
    yield takeLatest(CONTENT_CONTENT_CARD_DELETE_REQUESTED, deleteContentCard);
    yield takeLatest(CONTENT_CONTENT_CARD_MOVE_REQUESTED, moveContentCard);
    yield takeLatest(CONTENT_CONTENT_TIMER_CARD_EDIT_REQUESTED, updateContentTimerCard);
    yield takeLatest(CONTENT_CONTENT_CARD_COMPLETION_EDIT_REQUESTED, updateContentCardCompletion);
    yield takeLatest(CONTENT_CONTENT_CARD_REFERENCE_EDIT_REQUESTED, updateContentCardReference);
    yield takeLatest(CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED, updateContentAssessmentCard);
    yield takeLatest(CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED, updateContentAssessmentResultCard);
    yield takeLatest(CONTENT_CONTENT_SUMMARY_CARD_EDIT_REQUESTED, updateContentSummaryCard);
    yield takeLatest(CONTENT_CONTENT_REFLECTION_CARD_EDIT_REQUESTED, updateContentReflectionCard);
    yield takeLatest(CONTENT_CONTENT_CAPTION_CARD_EDIT_REQUESTED, updateContentCaptionCard);
    yield takeLatest(CONTENT_CONTENT_INFORMATION_CARD_EDIT_REQUESTED, updateContentInformationCard);
    yield takeLatest(CONTENT_CONTENT_AUDIO_CARD_EDIT_REQUESTED, updateContentAudioCard);
    yield takeLatest(CONTENT_CONTENT_VIDEO_CARD_EDIT_REQUESTED, updateContentVideoCard);
    yield takeLatest(CONTENT_CONTENT_TASK_CARD_EDIT_REQUESTED, updateContentTaskCard);
    yield takeLatest(CONTENT_CONTENT_COMPLETE_CARD_EDIT_REQUESTED, updateContentCompleteCard);
    yield takeLatest(CONTENT_CONTENT_READ_CARD_EDIT_REQUESTED, updateContentCardRead);
    yield takeLatest(CONTENT_CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED, updateContentInputCardItem);
    yield takeLatest(CONTENT_CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED, createContentInputCardItem);
    yield takeLatest(CONTENT_CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED, deleteContentInputCardItem);
    yield takeLatest(CONTENT_CONTENT_QUIZ_CARD_EDIT_REQUESTED, updateContentContentQuizCard);
    yield takeLatest(CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED, updateContentQuizCardItem);
    yield takeLatest(CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED, createContentQuizCardItem);
    yield takeLatest(CONTENT_CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED, deleteContentQuizCardItem);
    yield takeLatest(CONTENT_CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED, createContentQuoteCardItem);
    yield takeLatest(CONTENT_CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED, updateContentQuoteCardItem);
    yield takeLatest(CONTENT_CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED, deleteContentQuoteCardItem);
    yield takeLatest(CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED, createContentDocumentCardItem);
    yield takeLatest(CONTENT_CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED, deleteContentDocumentCardItem);
    yield takeLatest(CONTENT_CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED, updateContentDocumentCardItem);
    yield takeLatest(CONTENT_CONTENT_CHECKLIST_CARD_EDIT_REQUESTED, updateContentChecklistCard);
    yield takeLatest(CONTENT_CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED, createContentChecklistCardItem);
    yield takeLatest(CONTENT_CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED, deleteContentChecklistCardItem);
    yield takeLatest(CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED, updateContentChecklistCardItem);
    yield takeLatest(CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED, updateContentQuestionnaireCard);
    yield takeLatest(CONTENT_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED, updateContentOverviewSessionsCard);
    yield takeLatest(CONTENT_CONTENT_TOOL_CARD_EDIT_REQUESTED, updateContentToolCard);
    yield takeLatest(CONTENT_CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED, addToolContentToolCard);
    yield takeLatest(CONTENT_CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED, removeToolContentToolCard);
    yield takeLatest(CONTENT_TAG_CREATE_REQUESTED, createTag);
    yield takeLatest(CONTENT_TAG_DELETE_REQUESTED, deleteTag);
    yield takeLatest(CONTENT_GOAL_PUBLISH_REQUESTED, publishGoal);
    yield takeLatest(CONTENT_JOURNEY_PUBLISH_REQUESTED, publishJourney);
    yield takeLatest(CONTENT_TOOL_PUBLISH_REQUESTED, publishTool);
    yield takeLatest(CONTENT_EXERCISE_PUBLISH_REQUESTED, publishExercise);
    yield takeLatest(CONTENT_TOOL_ARCHIVE_REQUESTED, archiveTool);
    yield takeLatest(CONTENT_GOAL_LOCK_REQUESTED, lockGoal);
    yield takeLatest(CONTENT_JOURNEY_LOCK_REQUESTED, lockJourney);
    yield takeLatest(CONTENT_EXERCISE_LOCK_REQUESTED, lockExercise);
    yield takeLatest(CONTENT_EXERCISE_ACTION_EXERCISE_LINK_REQUESTED, linkExerciseActionExercise);
    yield takeLatest(CONTENT_EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED, unlinkExerciseActionExercise);
    yield takeLatest(CONTENT_TOOL_TAG_CREATE_REQUESTED, createToolTag);
    yield takeLatest(CONTENT_TOOL_TAG_DELETE_REQUESTED, deleteToolTag);
    yield takeLatest(CONTENT_GENERIC_TAG_CREATE_REQUESTED, createGenericTag);
    yield takeLatest(CONTENT_GENERIC_TAG_DELETE_REQUESTED, deleteGenericTag);

    // Side Effects
    yield takeLatest(VIEW_CONTENT_SELECT_TOOLKIT, fetchGeneralExercises);
    yield takeLatest(VIEW_CONTENT_SELECT_GOAL_ID, fetchGoalJournies);
    yield takeLatest(VIEW_CONTENT_SELECT_GOAL_ID, fetchGoalHeaders);
    yield takeLatest(VIEW_CONTENT_SELECT_GOAL_ID, fetchGoalExercises);
    yield takeLatest(VIEW_CONTENT_SELECT_EXERCISE_ID, getExerciseContent);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED, fetchTechniqueTags);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_ENABLE_REQUESTED, enableTechniqueTag);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED, addTechniqueTagPushMessage);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED, removeTechniqueTagPushMessage);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_REMOVE_RULE_REQUESTED, removeTechniqueTagRule);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_ADD_RULE_REQUESTED, addTechniqueTagRule);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_CREATE_REQUESTED, createTechniqueTag);
    yield takeLatest(CONTENT_TECHNIQUE_TAG_DELETE_REQUESTED, deleteTechniqueTag);
    yield takeLatest(CONTENT_LINK_ADD_REQUESTED, addContentLink);
    yield takeLatest(CONTENT_LINK_REMOVE_REQUESTED, removeContentLink);

    // quotes
    yield takeLatest(CONTENT_QUOTE_CREATE_REQUESTED, createQuote);
    yield takeLatest(CONTENT_QUOTE_FETCH_REQUESTED, fetchQuotes);
    yield takeLatest(CONTENT_QUOTE_GET_REQUESTED, getQuote);
    yield takeLatest(CONTENT_QUOTE_EDIT_REQUESTED, updateQuote);
    yield takeLatest(CONTENT_QUOTE_DELETE_REQUESTED, deleteQuote);

    yield takeLatest(CONTENT_QUOTE_PUBLISH_REQUESTED, publishQuote);
    yield takeLatest(CONTENT_QUOTE_ARCHIVE_REQUESTED, archiveQuote);

    // guide card
    yield takeLatest(CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED, createContentGuideCardConcept);
    yield takeLatest(CONTENT_CONTENT_GUIDE_CARD_EDIT_REQUESTED, updateContentContentGuideCard);

    yield takeLatest(CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED, updateContentGuideCardConcept);
    yield takeLatest(CONTENT_CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED, deleteContentGuideCardConcept);

    // tool keyword
    yield takeLatest(CONTENT_TOOL_TAG_KEYWORD_ADD_REQUESTED, addToolKeywordTag);
    yield takeLatest(CONTENT_TOOL_TAG_KEYWORD_REMOVE_REQUESTED, removeToolKeywordTag);

    yield takeLatest(CONTENT_TOOL_TAG_TAG_ADD_REQUESTED, addToolTagTag);
    yield takeLatest(CONTENT_TOOL_TAG_TAG_REMOVE_REQUESTED, removeToolTagTag);
}

export default ContentSaga;
