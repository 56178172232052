import { call, put, takeLatest } from 'redux-saga/effects';
import {
    HUMAN_CARE_CREDITS_REQUESTED,
    HUMAN_CARE_CREDITS_IS_LOADING,
    HUMAN_CARE_CREDITS_NOT_LOADING,
    HUMAN_CARE_CREDITS_FETCHED,
    HUMAN_CARE_CREDIT_TOPUP_REQUESTED
} from '../constants/human-care';
import HumanCareService from '../../service/human-care';
import { CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED } from 'redux/constants/customer';

function* fetchCredits(action) {
    try {
        yield put({ type: HUMAN_CARE_CREDITS_IS_LOADING });
        const results = yield call(HumanCareService.fetchCredits);
        yield put({ type: HUMAN_CARE_CREDITS_NOT_LOADING });
        if (results != null) {
            yield put({
                type: HUMAN_CARE_CREDITS_FETCHED,
                payload: {
                    credits: results.credits
                }
            });
        }
    } catch (e) {
        console.error('[HumanCare Saga]', e);
    }
}

function* topUpCredit(action) {
    try {
        const success = yield call(HumanCareService.topUpCredit, action.payload.organization_id, action.payload.credit);
        if (success) {
            yield put({ type: HUMAN_CARE_CREDITS_REQUESTED });
            // For customer organization care credits
            yield put({
                type: CUSTOMER_ORGANIZATION_CARE_CREDITS_REQUESTED,
                payload: {
                    organization_id: action.payload.organization_id
                }
            });
        }
    } catch (e) {
        console.error('[HumanCare Saga]', e);
    }
}

function* HumanCareSaga() {
    yield takeLatest(HUMAN_CARE_CREDITS_REQUESTED, fetchCredits);
    yield takeLatest(HUMAN_CARE_CREDIT_TOPUP_REQUESTED, topUpCredit);
}

export default HumanCareSaga;
