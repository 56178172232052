/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuidv4, validate } from 'uuid';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { sourceTypes } from 'components/assessment/immediateSelection';
import { connect } from 'react-redux';

class EditDimensionAnswerModal extends React.Component {
    state = {
        name: '',
        answers: []
    };

    componentDidMount() {
        const { assessment, assessment_id, answer_id } = this.props;
        const {
            current_assessment: { data: currentAssessmentData }
        } = assessment;
        if (currentAssessmentData && currentAssessmentData.uuid === assessment_id) {
            const { answers } = currentAssessmentData;
            const targetAnswer = answers.find((item) => item.uuid === answer_id);
            if (targetAnswer) {
                this.setState({
                    name: targetAnswer.name,
                    answers: targetAnswer.answers
                });
            }
        }
    }

    resetState = () => {
        this.setState({
            name: '',
            answers: []
        });
    };

    handleSubmit = () => {
        const params = {};
        const { name, answers } = this.state;
        if (!name) {
            return;
        }
        const validAnswers = [];
        if (answers.length > 0) {
            answers.forEach((answer) => {
                const validAnswer = {};
                const { uuid, description_en, description_yue, description_zh, score, category } = answer;
                if (!score && !category) {
                    // Either score or category should be provided
                    return;
                } else {
                    validAnswer.score = score;
                    validAnswer.category = category;
                }
                validAnswer.uuid = uuid;
                validAnswer.description_en = description_en;
                validAnswer.description_yue = description_yue;
                validAnswer.description_zh = description_zh;

                validAnswers.push(validAnswer);
            });
        }
        params['name'] = name;
        params['answers'] = validAnswers;
        params['assessment_id'] = this.props.assessment_id;
        params['answer_id'] = this.props.answer_id;
        this.props.onCreate(params);
    };

    handleCategoryChange = (event, index) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        const { answers } = this.state;
        let new_answers = [...answers];
        let current_answer = new_answers[index];
        current_answer[name] = inputValue;
        this.setState({
            answers: new_answers
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleAddAnswer = () => {
        const { answers } = this.state;
        let new_answers = [...answers];
        new_answers.push({
            uuid: uuidv4(), // some random string uuid
            description_en: '',
            description_yue: '',
            description_zh: '',
            score: null,
            category: ''
        });
        this.setState({
            answers: new_answers
        });
    };

    handleRemoveAnswer = (index) => {
        const { answers } = this.state;
        let new_answers = [...answers];
        new_answers.splice(index, 1);
        this.setState({
            answers: new_answers
        });
    };

    clearResult = () => {
        this.setState({
            result_score: {
                direction: null,
                type: null,
                min: null,
                max: null,
                operations: [],
                scale_type: null,
                scale_limit: null
            }
        });
    };

    render() {
        const { isOpen, isLoading } = this.props;
        const { answers } = this.state;
        return (
            <Modal
                id="create-assessment-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-assessment-modal-header">Edit Answers</Modal.Header>
                <Modal.Content id="create-assessment-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="sub-item">
                                <div className="section-header">
                                    <Card.Header className="property-value">Answer Name*</Card.Header>
                                </div>
                                <Grid container className="grid-item-container" spacing={2}>
                                    <Grid item xs={12} md={6} sm={6}>
                                        <Form.Input
                                            value={this.state.name || ''}
                                            id="name"
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                </Grid>

                                <div className="section-header">
                                    <Card.Header className="property-sub-value">Edit Answer</Card.Header>
                                    <Icon id="category-add" name={'add'} onClick={this.handleAddAnswer} />
                                </div>
                                {answers.map((answer, index) => (
                                    <div className="category-container" key={index.toString()}>
                                        <div className="category-clear">
                                            <Icon name={'close'} onClick={() => this.handleRemoveAnswer(index)} />
                                        </div>
                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="12" sm="12">
                                                <div>Either score or category must be specified *.</div>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={answer.description_en || ''}
                                                    rows="2"
                                                    type="text"
                                                    name="description_en"
                                                    placeholder="Description English"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={answer.description_yue || ''}
                                                    rows="2"
                                                    type="text"
                                                    name="description_yue"
                                                    placeholder="Description Cantonese"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                            <Grid item xs="12" md="4" sm="6">
                                                <Form.Input
                                                    value={answer.description_zh || ''}
                                                    rows="2"
                                                    type="text"
                                                    name="description_zh"
                                                    placeholder="Description Mandarin"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container className="grid-item-container" spacing={3}>
                                            <Grid item xs="12" md="6" sm="6">
                                                <Form.Input
                                                    value={answer.score || ''}
                                                    type="number"
                                                    name="score"
                                                    placeholder="Score"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                            <Grid item xs="12" md="6" sm="6">
                                                <Form.Input
                                                    value={answer.category || ''}
                                                    type="text"
                                                    name="category"
                                                    placeholder="Category"
                                                    onChange={(event) => this.handleCategoryChange(event, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-assessment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditDimensionAnswerModal);

EditDimensionAnswerModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
