import React from 'react';
import { connect } from 'react-redux';
import {
    hideCreateToolModal,
    hideEditToolModal,
    hideEditQuoteModal,
    hideCreateContentCardModal,
    hideEditExerciseModal,
    hideCreateExerciseModal,
    hideEditContentCardCompletionModal,
    hideEditContentCardReferenceModal,
    hideEditContentCaptionCardModal,
    hideEditContentInformationCardModal,
    hideEditContentReflectionCardModal,
    hideEditContentAudioCardModal,
    hideEditContentVideoCardModal,
    hideEditContentTaskCardModal,
    hideEditContentCompleteCardModal,
    hideEditContentReadCardModal,
    hideEditContentTimerCardModal,
    hideAddItemContentDocumentCardModal,
    hideEditContentChecklistCardItemModal,
    hideEditContentAssessmentCardModal,
    hideEditContentAssessmentResultCardModal,
    hideEditTextItemContentDocumentCardModal,
    hideEditImageItemContentDocumentCardModal,
    hideEditContentInputCardModal,
    hideAddContentInputCardModal,
    hideEditContentQuizCardModal,
    hideAddContentQuizCardItemModal,
    hideEditContentQuizCardItemModal,
    hideEditContentQuoteCardModal,
    hideAddContentQuoteCardModal,
    hideMoveContentCardModal,
    hideCopyContentModal,
    hideContentExerciseChatEditModal,
    hideEditContentChecklistCardModal,
    hideContentExerciseActionExerciseEditModal,
    hideContentSummaryCardEditModal,
    hideEditContentQuestionnaireCardModal,
    hideContentToolTagModal,
    hideValidationContentModal,
    hideContentLinkModal,
    hideCreateQuoteModal,
    hideContentOverviewSessionsCardEditModal,
    hideContentToolCardEditModal,
    hideAddContentToolCardToolModal,
    hideAddContentGuideCardConceptModal,
    hideEditContentGuideCardModal,
    hideEditContentGuideCardConceptModal,
    // assessment
    hideCreateAssessmentSourceModal,
    hideCreateAssessmentDimensionModal,
    hideCreateDimensionCategoryModal,
    hideCreateDimensionAnswerModal,
    hideAddAnswerModal,
    hideCreateDimensionQuestionModal,
    hideEditAssessmentSourceModal,
    hideEditAssessmentDimensionModal,
    hideEditDimensionCategoryModal,
    hideEditDimensionQuestionModal,
    hideEditAnswerModal,
    hideCreateEAPFocusModal,
    hideEditEAPFocusModal,
    hideCreateEAPFrameworkModal,
    hideEditEAPFrameworkModal,
    hideCreateEAPSpecialityModal,
    hideEditEAPSpecialityModal,
    hideCreateOrganizationModal,
    hideEditOrganizationModal,
    hideOrganizationDeployment
} from '../../redux/actions/view/modal';
import {
    createContentTool,
    updateContentTool,
    createContentGoal,
    createContentGoalHeader,
    linkContentJourneyGoalHeader,
    unlinkContentJourneyGoalHeader,
    createContentJourney,
    updateContentJourney,
    updateContentJourneyGoal,
    createContentExercise,
    updateContentExercise,
    moveContentContentCard,
    createContentContentCard,
    updateContentContentCardCompletion,
    updateContentContentCardReference,
    updateContentContentCaptionCard,
    updateContentContentInformationCard,
    updateContentContentAudioCard,
    updateContentContentVideoCard,
    updateContentContentTaskCard,
    updateContentContentCompleteCard,
    updateContentContentReadCard,
    updateContentContentTimerCard,
    updateContentContentReflectionCard,
    updateContentContentAssessmentCard,
    updateContentContentAssessmentResultCard,
    updateContentContentOverviewSessionsCard,
    updateContentContentToolCard,
    updateContentContentInputCardItem,
    createContentContentInputCardItem,
    deleteContentContentInputCardItem,
    updateContentContentQuizCard,
    createContentContentQuizCardItem,
    updateContentContentQuizCardItem,
    updateContentContentQuoteCardItem,
    createContentContentQuoteCardItem,
    deleteContentContentQuoteCardItem,
    createContentContentDocumentCardItem,
    updateContentContentDocumentCardItem,
    linkContentExerciseActionExercise,
    updateContentContentChecklistCard,
    updateContentContentChecklistCardItem,
    updateContentContentSummaryCard,
    updateContentContentQuestionnaireCard,
    copyContentContentCard,
    createContentToolTag,
    createContentGenericTag,
    addContentLink,
    createContentQuote,
    updateContentQuote,
    addToolContentContentToolCard,
    removeToolContentContentToolCard,
    createContentGuideConcept,
    updateContentContentGuideCard,
    updateContentContentGuideCardConcept,
    setContentToolPublish,
    addContentToolTag
} from '../../redux/actions/content';
import {
    createAssessmentSource,
    createAssessmentDimension,
    createDimensionCategory,
    createDimensionAnswer,
    addAssessmentAnswer,
    createDimensionQuestion,
    editAssessmentSource,
    editAssessmentDimension,
    editDimensionCategory,
    editDimensionQuestion,
    editDimensionAnswer
} from '../../redux/actions/assessment';
import {
    createEAPFocus,
    createEAPFramework,
    createEAPSpeciality,
    editEAPFocus,
    editEAPFramework,
    editEAPSpeciality
} from '../../redux/actions/eap';
import { createEmailAccess } from '../../redux/actions/organization';

import CreateToolModal from '../../components/content/toolkit/createToolModal';
import EditToolModal from '../../components/content/toolkit/editToolModal';
import CreateExerciseModal from '../../components/content/exercise/createExerciseModal';
import EditExerciseModal from '../../components/content/exercise/editExerciseModal';
import CreateContentCardModal from '../../components/content/card/createContentCardModal';
import EditContentCardModal from '../../components/content/card/editContentCardCompletionModal';
import EditCardReferenceModal from '../../components/content/card/editCardReferenceModal';
import EditInformationContentModal from '../../components/content/card/content/editInformationContentCardModal';
import EditCaptionContentModal from '../../components/content/card/content/editCaptionContentModal';
import EditAudioContentModal from '../../components/content/card/content/editAudioContentModal';
import EditVideoContentModal from '../../components/content/card/content/editVideoContentModal';
import EditTaskContentModal from '../../components/content/card/content/editTaskContentModal';
import EditReadContentModal from '../../components/content/card/content/editReadContentModal';
import EditInputContentModal from '../../components/content/card/content/editInputContentModal';
import EditReflectionContentModal from '../../components/content/card/content/editReflectionContentModal';
import EditTimerContentModal from '../../components/content/card/content/editTimerContentModal';
import AddInputContentModal from '../../components/content/card/content/addInputContentModal';
import EditQuoteContentModal from '../../components/content/card/content/editQuoteContentModal';
import AddQuoteContentModal from '../../components/content/card/content/addQuoteContentModal';
import AddItemDocumentContentModal from '../../components/content/card/content/addItemDocumentContentModal';
import EditTextItemDocumentContentCard from '../../components/content/card/content/editTextItemDocumentContentModal';
import EditImageItemDocumentContentCard from '../../components/content/card/content/editImageItemDocumentContentModal';
import MoveContentCardModal from '../../components/content/card/moveCardModal';
import EditExerciseChatModal from '../../components/content/exercise/editExerciseChatModal';
import EditActionExerciseModal from '../../components/content/exercise/editActionExerciseModal';
import EditChecklistContentModal from '../../components/content/card/content/editChecklistContentModal';
import EditChecklistItemContentModal from '../../components/content/card/content/editChecklistItemContentModal';
import EditAssessmentContentModal from '../../components/content/card/content/editAssessmentContentModal';
import EditAssessmentResultContentModal from '../../components/content/card/content/editAssessmentResultContentModal';
import EditSummaryContentModal from '../../components/content/card/content/editSummaryContentModal';
import EditQuestionnaireContentModal from '../../components/content/card/content/editQuestionnaireContentModal';
import CopyContentCardModal from '../../components/content/card/copyContentCardModal';
import CreateToolTagModal from '../../components/content/toolkit/tag/createTagModal_v2';
import AddLinkModal from '../../components/content/toolkit/link/addLinkModal';
import EditQuizContentModal from '../../components/content/card/content/editQuizContentModal';
import AddQuizItemContentModal from '../../components/content/card/content/addQuizItemContentModal';
import EditQuizItemContentModal from '../../components/content/card/content/editQuizItemContentModal';
import CreateQuoteModal from '../../components/content/quotekit/createQuoteModal';
import EditQuoteModal from '../../components/content/quotekit/editQuoteModal';
import EditOverviewSessionsContentModal from 'components/content/card/content/editOverviewSessionsContentModal';
import EditToolContentModal from 'components/content/card/content/editToolContentModal';
import AddToolToolContentModal from 'components/content/card/content/addToolToolContentModal';
import AddGuideConceptContentModal from 'components/content/card/content/addGuideConceptContentModal ';
import EditGuideCardModal from 'components/content/card/content/editGuideContentModal';
import EditGuideConceptContentModal from 'components/content/card/content/editGuideConceptContentModal';
import ValidateContentModal from 'components/content/toolkit/validateContentModal';
import CreateAssessmentSourceModal from 'components/assessment/modal/createAssessmentSourceModal';
import CreateAssessmentDimensioneModal from 'components/assessment/modal/createAssessmentDimensionModal';
import CreateDimensionCategoryModal from 'components/assessment/modal/createDimensionCategoryModal';
import CreateDimensionAnswerModal from 'components/assessment/modal/createDimensionAnswerModal';
import AddAnswerModal from 'components/assessment/modal/addAnswerModal';
import CreateDimensionQuestionModal from 'components/assessment/modal/createDimensionQuestionModal';
import EditAssessmentSourceModal from 'components/assessment/modal/editAssessmentSourceModal';
import EditAssessmentDimensioneModal from 'components/assessment/modal/editAssessmentDimensionModal';
import EditDimensionCategoryModal from 'components/assessment/modal/editDimensionCategoryModal';
import EditDimensionQuestionModal from 'components/assessment/modal/editDimensionQuestionModal';
import EditDimensionAnswerModal from 'components/assessment/modal/editDimensionAnswerModal';
import CreateEAPFocusModal from 'components/eap/modal/createEAPFocusModal';
import EditEAPFocusModal from 'components/eap/modal/editEAPFocusModal';
import CreateEAPFrameworkModal from 'components/eap/modal/createEAPFrameworkModal';
import EditEAPFrameworkModal from 'components/eap/modal/editEAPFrameworkModal';
import EditEAPSpecialityModal from 'components/eap/modal/editEAPSpecialityModal';
import CreateEAPSpecialityModal from 'components/eap/modal/createEAPSpecialityModall';
import {
    activateOrganization,
    archiveOrganization,
    createNewOrganization,
    editOrganization,
    expireOrganization
} from 'redux/actions/customer';
import CreateCustomerOrganizationModal from 'components/customer/modal/createCustomerOrganizationModal';
import EditCustomerOrganizationModal from 'components/customer/modal/editCustomerOrganizationModal';
import OrganizationDeploymentModal from 'components/customer/modal/deploymentLinkModal';

class ModalController extends React.Component {
    getJourney(journey_id) {
        const journey = this.props.content.journies.data.find((journey) => {
            return journey.id == journey_id;
        });
        return journey;
    }

    getExercise(exercise_id) {
        var exercise = this.props.content.exercises.data.find((exercise) => {
            return exercise.id == exercise_id;
        });
        if (!exercise) {
            // Extract exercise from journey if all exercises are not fetched
            const journey = this.props.content.journies.data.find((journey) => {
                if (!journey.exercises) {
                    return false;
                }
                var hasExercise = false;
                for (const exercise of journey.exercises) {
                    if (exercise.id == exercise_id) hasExercise = true;
                }
                return hasExercise;
            });
            if (journey != null) {
                const journeyExercises = journey.exercises || [];
                exercise = journeyExercises.find((exercise) => {
                    return exercise.id == this.props.contentView.selected_exercise_id;
                });
            }

            // Extract exercise from tools if all exercises are not fetched
            const tool = this.props.content.tools.data.find((tool) => {
                if (!tool.exercises) {
                    return false;
                }
                var hasExercise = false;
                for (const exercise of tool.exercises) {
                    if (exercise.id == exercise_id) hasExercise = true;
                }
                return hasExercise;
            });
            if (tool != null) {
                const toolExercises = tool.exercises || [];
                exercise = toolExercises.find((exercise) => {
                    return exercise.id == this.props.contentView.selected_exercise_id;
                });
            }
        }
        return exercise;
    }

    getTool(tool_id) {
        const tool = this.props.content.tools.data.find((tool) => {
            return tool.id == tool_id;
        });
        return tool;
    }

    getQuote(quote_id) {
        const quote = this.props.content.quotes.data.find((quote) => {
            return quote.id == quote_id;
        });
        return quote;
    }

    getExerciseContent(exercise_id) {
        const exerciseContent = this.props.content.contents.data.find((content) => {
            return content.exercise_id == exercise_id;
        });
        return exerciseContent;
    }

    render() {
        const {
            createContentTool,
            updateContentTool,
            copyContentContentCard,
            createContentGoalHeader,
            linkContentJourneyGoalHeader,
            createContentJourney,
            updateContentJourney,
            createContentExercise,
            updateContentExercise,
            createContentContentCard,
            updateContentContentCardCompletion,
            updateContentContentCardReference,
            updateContentContentCaptionCard,
            updateContentContentInformationCard,
            updateContentContentAudioCard,
            updateContentContentVideoCard,
            updateContentContentTaskCard,
            updateContentContentReflectionCard,
            updateContentContentCompleteCard,
            updateContentContentQuizCard,
            createContentContentQuizCardItem,
            updateContentContentQuizCardItem,
            updateContentContentReadCard,
            updateContentContentInputCardItem,
            createContentContentInputCardItem,
            updateContentContentQuoteCardItem,
            createContentContentQuoteCardItem,
            updateContentContentChecklistCard,
            updateContentContentChecklistCardItem,
            updateContentContentTimerCard,
            createContentContentDocumentCardItem,
            updateContentContentDocumentCardItem,
            updateContentContentSummaryCard,
            updateContentContentQuestionnaireCard,
            updateContentContentAssessmentCard,
            updateContentContentAssessmentResultCard,
            updateContentContentToolCard,
            updateContentContentOverviewSessionsCard,
            createEmailAccess,
            createContentToolTag,
            createContentGenericTag,
            createContentQuote,
            updateContentQuote,
            addToolContentContentToolCard,
            createContentGuideConcept,
            updateContentContentGuideCard,
            updateContentContentGuideCardConcept
        } = this.props;

        return (
            <div>
                {this.props.modal.content_link_modal.is_visible && (
                    <AddLinkModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.content_link_modal.is_visible}
                        tool_id={this.props.modal.content_link_modal.tool_id}
                        lesson_id={this.props.modal.content_link_modal.lesson_id}
                        onAdd={(params) => {
                            this.props.addContentLink(
                                params.tool_id,
                                params.lesson_id,
                                params.reason,
                                this.props.modal.content_link_modal.completion_handler
                            );
                        }}
                        onClose={() => {
                            this.props.hideContentLinkModal();
                        }}
                    />
                )}
                {this.props.modal.create_tool_tag_modal.is_visible && (
                    <CreateToolTagModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_tool_tag_modal.is_visible}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideContentToolTagModal();
                        }}
                        onCreate={(params) => {
                            if (this.props.modal.create_tool_tag_modal.tool_id == null) return;
                            this.props.addContentToolTag(this.props.modal.create_tool_tag_modal.tool_id, params.type, params.value);
                        }}
                    />
                )}
                {this.props.modal.validate_content_modal.is_visible && (
                    <ValidateContentModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.validate_content_modal.is_visible}
                        toolId={this.props.modal.validate_content_modal.tool_id}
                        showPublish={this.props.modal.validate_content_modal.show_publish}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideValidationContentModal();
                        }}
                        onPublish={(toolId, has_published) => {
                            this.props.setContentToolPublish(toolId, !has_published);
                            this.props.hideValidationContentModal();
                        }}
                        onCreate={(params) => {}}
                    />
                )}
                {this.props.modal.copy_content_card.is_visible && (
                    <CopyContentCardModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.copy_content_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideCopyContentModal();
                        }}
                        onCopy={(to_exercise_id) => {
                            copyContentContentCard(this.props.modal.copy_content_card.from_exercise_id, to_exercise_id);
                        }}
                    />
                )}
                {this.props.modal.create_tool_modal.is_visible && (
                    <CreateToolModal
                        locale={this.props.setting.locale}
                        version={this.props.modal.create_tool_modal.version}
                        isOpen={this.props.modal.create_tool_modal.is_visible}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideCreateToolModal();
                        }}
                        onCreate={(params) => {
                            createContentTool(params);
                        }}
                    />
                )}
                {this.props.modal.edit_tool_modal.is_visible && (
                    <EditToolModal
                        locale={this.props.setting.locale}
                        tool={this.getTool(this.props.modal.edit_tool_modal.tool_id)}
                        isOpen={this.props.modal.edit_tool_modal.is_visible}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideEditToolModal();
                        }}
                        onEdit={(params) => {
                            if (this.props.modal.edit_tool_modal.tool_id == null) {
                                return;
                            }
                            updateContentTool(this.props.modal.edit_tool_modal.tool_id, params);
                        }}
                    />
                )}
                {this.props.modal.create_quote_modal.is_visible && (
                    <CreateQuoteModal
                        locale={this.props.setting.locale}
                        version={this.props.modal.create_quote_modal.version}
                        isOpen={this.props.modal.create_quote_modal.is_visible}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideCreateQuoteModal();
                        }}
                        onCreate={(params) => {
                            createContentQuote(params);
                        }}
                    />
                )}
                {this.props.modal.edit_quote_modal.is_visible && (
                    <EditQuoteModal
                        locale={this.props.setting.locale}
                        quote={this.getQuote(this.props.modal.edit_quote_modal.quote_id)}
                        isOpen={this.props.modal.edit_quote_modal.is_visible}
                        isLoading={this.props.content.tools.isLoading}
                        onClose={() => {
                            this.props.hideEditQuoteModal();
                        }}
                        onEdit={(params) => {
                            if (this.props.modal.edit_quote_modal.quote_id == null) {
                                return;
                            }
                            updateContentQuote(this.props.modal.edit_quote_modal.quote_id, params);
                        }}
                    />
                )}
                {this.props.modal.edit_action_exercise_modal.is_visible && (
                    <EditActionExerciseModal
                        isOpen={this.props.modal.edit_action_exercise_modal.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideContentExerciseActionExerciseEditModal();
                        }}
                        onUpdate={(action_exercise_id) => {
                            this.props.linkContentExerciseActionExercise(
                                this.props.modal.edit_action_exercise_modal.exercise_id,
                                action_exercise_id
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_summary_card.is_visible && (
                    <EditSummaryContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_summary_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_summary_card.exercise_id).cards[
                                this.props.modal.edit_content_summary_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_summary_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideContentSummaryCardEditModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_summary_card.exercise_id == null ||
                                this.props.modal.edit_content_summary_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentSummaryCard(
                                this.props.modal.edit_content_summary_card.exercise_id,
                                this.props.modal.edit_content_summary_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_reflection_card.is_visible == true && (
                    <EditReflectionContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_reflection_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_reflection_card.exercise_id).cards[
                                this.props.modal.edit_content_reflection_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_reflection_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentReflectionCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_reflection_card.exercise_id == null ||
                                this.props.modal.edit_content_reflection_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentReflectionCard(
                                this.props.modal.edit_content_reflection_card.exercise_id,
                                this.props.modal.edit_content_reflection_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_quiz_card.is_visible == true && (
                    <EditQuizContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_quiz_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_quiz_card.exercise_id).cards[
                                this.props.modal.edit_content_quiz_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_quiz_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentQuizCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_quiz_card.exercise_id == null ||
                                this.props.modal.edit_content_quiz_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentQuizCard(
                                this.props.modal.edit_content_quiz_card.exercise_id,
                                this.props.modal.edit_content_quiz_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_content_quiz_item_card.is_visible == true && (
                    <AddQuizItemContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.add_content_quiz_item_card.exercise_id).cards[
                                this.props.modal.add_content_quiz_item_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.add_content_quiz_item_card.item_index}
                        isOpen={this.props.modal.add_content_quiz_item_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddContentQuizCardItemModal();
                        }}
                        onAdd={(params) => {
                            if (
                                this.props.modal.add_content_quiz_item_card.exercise_id == null ||
                                this.props.modal.add_content_quiz_item_card.card_index == null
                            ) {
                                return;
                            }
                            createContentContentQuizCardItem(
                                this.props.modal.add_content_quiz_item_card.exercise_id,
                                this.props.modal.add_content_quiz_item_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_quiz_item_card.is_visible == true && (
                    <EditQuizItemContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_quiz_item_card.exercise_id).cards[
                                this.props.modal.edit_content_quiz_item_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_quiz_item_card.item_index}
                        isOpen={this.props.modal.edit_content_quiz_item_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentQuizCardItemModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_quiz_item_card.exercise_id == null ||
                                this.props.modal.edit_content_quiz_item_card.card_index == null ||
                                this.props.modal.edit_content_quiz_item_card.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentQuizCardItem(
                                this.props.modal.edit_content_quiz_item_card.exercise_id,
                                this.props.modal.edit_content_quiz_item_card.card_index,
                                this.props.modal.edit_content_quiz_item_card.item_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_questionnaire_card.is_visible && (
                    <EditQuestionnaireContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_questionnaire_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_questionnaire_card.exercise_id).cards[
                                this.props.modal.edit_content_questionnaire_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_questionnaire_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentQuestionnaireCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_questionnaire_card.exercise_id == null ||
                                this.props.modal.edit_content_questionnaire_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentQuestionnaireCard(
                                this.props.modal.edit_content_questionnaire_card.exercise_id,
                                this.props.modal.edit_content_questionnaire_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_checklist_card.is_visible && (
                    <EditChecklistContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_checklist_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_checklist_card.exercise_id).cards[
                                this.props.modal.edit_content_checklist_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_checklist_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentChecklistCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_checklist_card.exercise_id == null ||
                                this.props.modal.edit_content_checklist_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentChecklistCard(
                                this.props.modal.edit_content_checklist_card.exercise_id,
                                this.props.modal.edit_content_checklist_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_assessment_card.is_visible && (
                    <EditAssessmentContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_assessment_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_assessment_card.exercise_id).cards[
                                this.props.modal.edit_content_assessment_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_assessment_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentAssessmentCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_assessment_card.exercise_id == null ||
                                this.props.modal.edit_content_assessment_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentAssessmentCard(
                                this.props.modal.edit_content_assessment_card.exercise_id,
                                this.props.modal.edit_content_assessment_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_assessment_result_card.is_visible && (
                    <EditAssessmentResultContentModal
                        exercise={this.getExercise(this.props.modal.edit_content_assessment_result_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_assessment_result_card.exercise_id).cards[
                                this.props.modal.edit_content_assessment_result_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_assessment_result_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentAssessmentResultCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_assessment_result_card.exercise_id == null ||
                                this.props.modal.edit_content_assessment_result_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentAssessmentResultCard(
                                this.props.modal.edit_content_assessment_result_card.exercise_id,
                                this.props.modal.edit_content_assessment_result_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_checklist_item_card.is_visible && (
                    <EditChecklistItemContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_checklist_item_card.exercise_id).cards[
                                this.props.modal.edit_content_checklist_item_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_checklist_item_card.item_index}
                        isOpen={this.props.modal.edit_content_checklist_item_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentChecklistCardItemModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_checklist_item_card.exercise_id == null ||
                                this.props.modal.edit_content_checklist_item_card.card_index == null ||
                                this.props.modal.edit_content_checklist_item_card.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentChecklistCardItem(
                                this.props.modal.edit_content_checklist_item_card.exercise_id,
                                this.props.modal.edit_content_checklist_item_card.card_index,
                                this.props.modal.edit_content_checklist_item_card.item_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_exercise_chat_modal.is_visible && (
                    <EditExerciseChatModal
                        exercise={this.getExercise(this.props.modal.edit_exercise_chat_modal.exercise_id)}
                        isOpen={this.props.modal.edit_exercise_chat_modal.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideContentExerciseChatEditModal();
                        }}
                        onEdit={(params) => {
                            this.props.updateContentExercise(this.props.modal.edit_exercise_chat_modal.exercise_id, params);
                        }}
                    />
                )}
                {this.props.modal.is_create_exercise_modal.is_visible && (
                    <CreateExerciseModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.is_create_exercise_modal.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideCreateExerciseModal();
                        }}
                        onCreate={(params) => {
                            createContentExercise(
                                this.props.modal.is_create_exercise_modal.goal_id,
                                this.props.modal.is_create_exercise_modal.journey_id,
                                this.props.modal.is_create_exercise_modal.tool_id,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.is_edit_exercise_modal.is_visible && (
                    <EditExerciseModal
                        locale={this.props.setting.locale}
                        exercise={this.getExercise(this.props.modal.is_edit_exercise_modal.exercise_id)}
                        isOpen={this.props.modal.is_edit_exercise_modal.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditExerciseModal();
                        }}
                        onEdit={(params) => {
                            if (this.props.modal.is_edit_exercise_modal.exercise_id == null) {
                                return;
                            }
                            updateContentExercise(this.props.modal.is_edit_exercise_modal.exercise_id, params);
                        }}
                    />
                )}
                {this.props.modal.is_create_content_card.is_visible && (
                    <CreateContentCardModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.is_create_content_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideCreateContentCardModal();
                        }}
                        onCreate={(type) => {
                            if (this.props.modal.is_create_content_card.exercise_id == null) {
                                return;
                            }
                            createContentContentCard(this.props.modal.is_create_content_card.exercise_id, type);
                        }}
                    />
                )}
                {this.props.modal.edit_content_card_completion.is_visible && (
                    <EditContentCardModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_content_card_completion.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentCardCompletionModal();
                        }}
                        onEdit={(type, data) => {
                            if (
                                this.props.modal.edit_content_card_completion.exercise_id == null ||
                                this.props.modal.edit_content_card_completion.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentCardCompletion(
                                this.props.modal.edit_content_card_completion.exercise_id,
                                this.props.modal.edit_content_card_completion.card_index,
                                type,
                                data
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_card_reference.is_visible && (
                    <EditCardReferenceModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_content_card_reference.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentCardReferenceModal();
                        }}
                        onEdit={(type, value) => {
                            if (
                                this.props.modal.edit_content_card_reference.exercise_id == null ||
                                this.props.modal.edit_content_card_reference.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentCardReference(
                                this.props.modal.edit_content_card_reference.exercise_id,
                                this.props.modal.edit_content_card_reference.card_index,
                                type,
                                value
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_information_card.is_visible && (
                    <EditInformationContentModal
                        locale={this.props.setting.locale}
                        exercise={this.getExercise(this.props.modal.edit_content_information_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_information_card.exercise_id).cards[
                                this.props.modal.edit_content_information_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_information_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentInformationCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_information_card.exercise_id == null ||
                                this.props.modal.edit_content_information_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentInformationCard(
                                this.props.modal.edit_content_information_card.exercise_id,
                                this.props.modal.edit_content_information_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.is_edit_content_caption_card.is_visible && (
                    <EditCaptionContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.is_edit_content_caption_card.exercise_id).cards[
                                this.props.modal.is_edit_content_caption_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.is_edit_content_caption_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentCaptionCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.is_edit_content_caption_card.exercise_id == null ||
                                this.props.modal.is_edit_content_caption_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentCaptionCard(
                                this.props.modal.is_edit_content_caption_card.exercise_id,
                                this.props.modal.is_edit_content_caption_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_audio_card.is_visible && (
                    <EditAudioContentModal
                        locale={this.props.setting.locale}
                        exercise_id={this.props.modal.edit_content_audio_card.exercise_id}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_audio_card.exercise_id).cards[
                                this.props.modal.edit_content_audio_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_audio_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentAudioCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_audio_card.exercise_id == null ||
                                this.props.modal.edit_content_audio_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentAudioCard(
                                this.props.modal.edit_content_audio_card.exercise_id,
                                this.props.modal.edit_content_audio_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_video_card.is_visible && (
                    <EditVideoContentModal
                        locale={this.props.setting.locale}
                        exercise_id={this.props.modal.edit_content_video_card.exercise_id}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_video_card.exercise_id).cards[
                                this.props.modal.edit_content_video_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_video_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentVideoCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_video_card.exercise_id == null ||
                                this.props.modal.edit_content_video_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentVideoCard(
                                this.props.modal.edit_content_video_card.exercise_id,
                                this.props.modal.edit_content_video_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_task_card.is_visible && (
                    <EditTaskContentModal
                        locale={this.props.setting.locale}
                        exercise_id={this.props.modal.edit_content_task_card.exercise_id}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_task_card.exercise_id).cards[
                                this.props.modal.edit_content_task_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_task_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentTaskCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_task_card.exercise_id == null ||
                                this.props.modal.edit_content_task_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentTaskCard(
                                this.props.modal.edit_content_task_card.exercise_id,
                                this.props.modal.edit_content_task_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {/* TODO: Remove Content Completion Card
                { this.props.modal.is_edit_content_complete_card_visible &&
                <EditCompleteContentModal
                    locale={this.props.setting.locale}
                    card={card}
                    isOpen={this.props.modal.is_edit_content_complete_card_visible}
                    isLoading={this.props.content.contents.isLoading}
                    onClose={() => {
                        this.props.hideEditContentCompleteCardModal()
                    }}
                    onEdit={params => {
                        if (this.props.contentView.selected_exercise_id == null || this.props.contentView.selected_exercise_content_card_index == null) {
                            return
                        }
                        updateContentContentCompleteCard(
                            this.props.contentView.selected_exercise_id,
                            this.props.contentView.selected_exercise_content_card_index,
                            params
                        )
                    }}
                />
                } */}
                {this.props.modal.edit_content_read_card.is_visible && (
                    <EditReadContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_read_card.exercise_id).cards[
                                this.props.modal.edit_content_read_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_read_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentReadCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_read_card.exercise_id == null ||
                                this.props.modal.edit_content_read_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentReadCard(
                                this.props.modal.edit_content_read_card.exercise_id,
                                this.props.modal.edit_content_read_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_input_card.is_visible && (
                    <EditInputContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_input_card.exercise_id).cards[
                                this.props.modal.edit_content_input_card.card_index
                            ]
                        }
                        allCards={this.getExerciseContent(this.props.modal.edit_content_input_card.exercise_id).cards}
                        itemIndex={this.props.modal.edit_content_input_card.item_index}
                        isOpen={this.props.modal.edit_content_input_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentInputCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_input_card.exercise_id == null ||
                                this.props.modal.edit_content_input_card.card_index == null ||
                                this.props.modal.edit_content_input_card.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentInputCardItem(
                                this.props.modal.edit_content_input_card.exercise_id,
                                this.props.modal.edit_content_input_card.card_index,
                                this.props.modal.edit_content_input_card.item_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_content_input_card.is_visible && (
                    <AddInputContentModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.add_content_input_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddContentInputCardModal();
                        }}
                        allCards={this.getExerciseContent(this.props.modal.add_content_input_card.exercise_id).cards}
                        onAdd={(params) => {
                            if (
                                this.props.modal.add_content_input_card.exercise_id == null ||
                                this.props.modal.add_content_input_card.card_index == null
                            ) {
                                return;
                            }
                            createContentContentInputCardItem(
                                this.props.modal.add_content_input_card.exercise_id,
                                this.props.modal.add_content_input_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_timer_card.is_visible && (
                    <EditTimerContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_timer_card.exercise_id).cards[
                                this.props.modal.edit_content_timer_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_timer_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentTimerCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_timer_card.exercise_id == null ||
                                this.props.modal.edit_content_timer_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentTimerCard(
                                this.props.modal.edit_content_timer_card.exercise_id,
                                this.props.modal.edit_content_timer_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_quote_card.is_visible && (
                    <EditQuoteContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_quote_card.exercise_id).cards[
                                this.props.modal.edit_content_quote_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_quote_card.item_index}
                        isOpen={this.props.modal.edit_content_quote_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentQuoteCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_quote_card.exercise_id == null ||
                                this.props.modal.edit_content_quote_card.card_index == null ||
                                this.props.modal.edit_content_quote_card.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentQuoteCardItem(
                                this.props.modal.edit_content_quote_card.exercise_id,
                                this.props.modal.edit_content_quote_card.card_index,
                                this.props.modal.edit_content_quote_card.item_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_content_quote_card.is_visible && (
                    <AddQuoteContentModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.add_content_quote_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddContentQuoteCardModal();
                        }}
                        onAdd={(params) => {
                            if (
                                this.props.modal.add_content_quote_card.exercise_id == null ||
                                this.props.modal.add_content_quote_card.card_index == null
                            ) {
                                return;
                            }
                            createContentContentQuoteCardItem(
                                this.props.modal.add_content_quote_card.exercise_id,
                                this.props.modal.add_content_quote_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_item_content_document_card.is_visible && (
                    <AddItemDocumentContentModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.add_item_content_document_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddItemContentDocumentCardModal();
                        }}
                        onAdd={(type, data) => {
                            if (
                                this.props.modal.add_item_content_document_card.exercise_id == null ||
                                this.props.modal.add_item_content_document_card.card_index == null
                            ) {
                                return;
                            }
                            createContentContentDocumentCardItem(
                                this.props.modal.add_item_content_document_card.exercise_id,
                                this.props.modal.add_item_content_document_card.card_index,
                                type,
                                data
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_document_card_text.is_visible && (
                    <EditTextItemDocumentContentCard
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_document_card_text.exercise_id).cards[
                                this.props.modal.edit_content_document_card_text.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_document_card_text.item_index}
                        isOpen={this.props.modal.edit_content_document_card_text.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditTextItemContentDocumentCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_document_card_text.exercise_id == null ||
                                this.props.modal.edit_content_document_card_text.card_index == null ||
                                this.props.modal.edit_content_document_card_text.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentDocumentCardItem(
                                this.props.modal.edit_content_document_card_text.exercise_id,
                                this.props.modal.edit_content_document_card_text.card_index,
                                this.props.modal.edit_content_document_card_text.item_index,
                                'text',
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_document_card_image.is_visible && (
                    <EditImageItemDocumentContentCard
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_document_card_image.exercise_id).cards[
                                this.props.modal.edit_content_document_card_image.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_document_card_image.item_index}
                        isOpen={this.props.modal.edit_content_document_card_image.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditImageItemContentDocumentCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_document_card_image.exercise_id == null ||
                                this.props.modal.edit_content_document_card_image.card_index == null ||
                                this.props.modal.edit_content_document_card_image.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentDocumentCardItem(
                                this.props.modal.edit_content_document_card_image.exercise_id,
                                this.props.modal.edit_content_document_card_image.card_index,
                                this.props.modal.edit_content_document_card_image.item_index,
                                'image',
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.move_content_card.is_visible && (
                    <MoveContentCardModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.move_content_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideMoveContentCardModal();
                        }}
                        onMove={(to_position) => {
                            if (
                                this.props.modal.move_content_card.exercise_id == null ||
                                this.props.modal.move_content_card.card_index == null
                            ) {
                                return;
                            }
                            const to_index = to_position - 1;
                            this.props.moveContentContentCard(
                                this.props.modal.move_content_card.exercise_id,
                                this.props.modal.move_content_card.card_index,
                                to_index
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_overview_sessions_card.is_visible && (
                    <EditOverviewSessionsContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_overview_sessions_card.exercise_id).cards[
                                this.props.modal.edit_content_overview_sessions_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_overview_sessions_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideContentOverviewSessionsCardEditModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_overview_sessions_card.exercise_id == null ||
                                this.props.modal.edit_content_overview_sessions_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentOverviewSessionsCard(
                                this.props.modal.edit_content_overview_sessions_card.exercise_id,
                                this.props.modal.edit_content_overview_sessions_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_tool_card.is_visible && (
                    <EditToolContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_tool_card.exercise_id).cards[
                                this.props.modal.edit_content_tool_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_tool_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideContentToolCardEditModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_tool_card.exercise_id == null ||
                                this.props.modal.edit_content_tool_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentToolCard(
                                this.props.modal.edit_content_tool_card.exercise_id,
                                this.props.modal.edit_content_tool_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_content_tool_card_tool.is_visible && (
                    <AddToolToolContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.add_content_tool_card_tool.exercise_id).cards[
                                this.props.modal.add_content_tool_card_tool.card_index
                            ]
                        }
                        isOpen={this.props.modal.add_content_tool_card_tool.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddContentToolCardToolModal();
                        }}
                        onAdd={(params) => {
                            if (
                                this.props.modal.add_content_tool_card_tool.exercise_id == null ||
                                this.props.modal.add_content_tool_card_tool.card_index == null
                            ) {
                                return;
                            }
                            addToolContentContentToolCard(
                                this.props.modal.add_content_tool_card_tool.exercise_id,
                                this.props.modal.add_content_tool_card_tool.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_guide_card.is_visible == true && (
                    <EditGuideCardModal
                        exercise={this.getExercise(this.props.modal.edit_content_guide_card.exercise_id)}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_guide_card.exercise_id).cards[
                                this.props.modal.edit_content_guide_card.card_index
                            ]
                        }
                        isOpen={this.props.modal.edit_content_guide_card.is_visible}
                        isLoading={this.props.content.exercises.isLoading}
                        onClose={() => {
                            this.props.hideEditContentGuideCardModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_guide_card.exercise_id == null ||
                                this.props.modal.edit_content_guide_card.card_index == null
                            ) {
                                return;
                            }
                            updateContentContentGuideCard(
                                this.props.modal.edit_content_guide_card.exercise_id,
                                this.props.modal.edit_content_guide_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.add_content_guide_concept_card.is_visible == true && (
                    <AddGuideConceptContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.add_content_guide_concept_card.exercise_id).cards[
                                this.props.modal.add_content_guide_concept_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.add_content_guide_concept_card.item_index}
                        isOpen={this.props.modal.add_content_guide_concept_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideAddContentGuideCardConceptModal();
                        }}
                        onAdd={(params) => {
                            if (
                                this.props.modal.add_content_guide_concept_card.exercise_id == null ||
                                this.props.modal.add_content_guide_concept_card.card_index == null
                            ) {
                                return;
                            }
                            createContentGuideConcept(
                                this.props.modal.add_content_guide_concept_card.exercise_id,
                                this.props.modal.add_content_guide_concept_card.card_index,
                                params
                            );
                        }}
                    />
                )}
                {this.props.modal.edit_content_guide_concept_card.is_visible == true && (
                    <EditGuideConceptContentModal
                        locale={this.props.setting.locale}
                        card={
                            this.getExerciseContent(this.props.modal.edit_content_guide_concept_card.exercise_id).cards[
                                this.props.modal.edit_content_guide_concept_card.card_index
                            ]
                        }
                        itemIndex={this.props.modal.edit_content_guide_concept_card.item_index}
                        isOpen={this.props.modal.edit_content_guide_concept_card.is_visible}
                        isLoading={this.props.content.contents.isLoading}
                        onClose={() => {
                            this.props.hideEditContentGuideCardConceptModal();
                        }}
                        onEdit={(params) => {
                            if (
                                this.props.modal.edit_content_guide_concept_card.exercise_id == null ||
                                this.props.modal.edit_content_guide_concept_card.card_index == null ||
                                this.props.modal.edit_content_guide_concept_card.item_index == null
                            ) {
                                return;
                            }
                            updateContentContentGuideCardConcept(
                                this.props.modal.edit_content_guide_concept_card.exercise_id,
                                this.props.modal.edit_content_guide_concept_card.card_index,
                                this.props.modal.edit_content_guide_concept_card.item_index,
                                params
                            );
                        }}
                    />
                )}
                {/* Assessment stuff */}
                {this.props.modal.create_assessment_source_modal.is_visible && (
                    <CreateAssessmentSourceModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_assessment_source_modal.is_visible}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateAssessmentSourceModal();
                        }}
                        onCreate={(params) => {
                            this.props.createAssessmentSource(params);
                        }}
                    />
                )}
                {this.props.modal.edit_assessment_source_modal.is_visible && (
                    <EditAssessmentSourceModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_assessment_source_modal.is_visible}
                        assessment_id={this.props.modal.edit_assessment_source_modal.assessment_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditAssessmentSourceModal();
                        }}
                        onCreate={(params) => {
                            this.props.editAssessmentSource(params);
                        }}
                    />
                )}
                {this.props.modal.create_assessment_dimension_modal.is_visible && (
                    <CreateAssessmentDimensioneModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_assessment_dimension_modal.is_visible}
                        assessment_id={this.props.modal.create_assessment_dimension_modal.assessment_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateAssessmentDimensionModal();
                        }}
                        onCreate={(id, params) => {
                            this.props.createAssessmentDimension(id, params);
                        }}
                    />
                )}
                {this.props.modal.edit_assessment_dimension_modal.is_visible && (
                    <EditAssessmentDimensioneModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_assessment_dimension_modal.is_visible}
                        assessment_id={this.props.modal.edit_assessment_dimension_modal.assessment_id}
                        dimension_id={this.props.modal.edit_assessment_dimension_modal.dimension_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditAssessmentDimensionModal();
                        }}
                        onCreate={(params) => {
                            this.props.editAssessmentDimension(params);
                        }}
                    />
                )}
                {this.props.modal.create_dimension_category_modal.is_visible && (
                    <CreateDimensionCategoryModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_dimension_category_modal.is_visible}
                        assessment_id={this.props.modal.create_dimension_category_modal.assessment_id}
                        dimension_id={this.props.modal.create_dimension_category_modal.dimension_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateDimensionCategoryModal();
                        }}
                        onCreate={(params) => {
                            this.props.createDimensionCategory(params);
                        }}
                    />
                )}
                {this.props.modal.edit_dimension_category_modal.is_visible && (
                    <EditDimensionCategoryModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_dimension_category_modal.is_visible}
                        assessment_id={this.props.modal.edit_dimension_category_modal.assessment_id}
                        dimension_id={this.props.modal.edit_dimension_category_modal.dimension_id}
                        category_id={this.props.modal.edit_dimension_category_modal.category_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditDimensionCategoryModal();
                        }}
                        onCreate={(params) => {
                            this.props.editDimensionCategory(params);
                        }}
                    />
                )}
                {this.props.modal.create_dimension_question_modal.is_visible && (
                    <CreateDimensionQuestionModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_dimension_question_modal.is_visible}
                        assessment_id={this.props.modal.create_dimension_question_modal.assessment_id}
                        dimension_id={this.props.modal.create_dimension_question_modal.dimension_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateDimensionQuestionModal();
                        }}
                        onCreate={(params) => {
                            this.props.createDimensionQuestion(params);
                        }}
                    />
                )}
                {this.props.modal.edit_dimension_question_modal.is_visible && (
                    <EditDimensionQuestionModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_dimension_question_modal.is_visible}
                        assessment_id={this.props.modal.edit_dimension_question_modal.assessment_id}
                        dimension_id={this.props.modal.edit_dimension_question_modal.dimension_id}
                        question_id={this.props.modal.edit_dimension_question_modal.question_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditDimensionQuestionModal();
                        }}
                        onCreate={(params) => {
                            this.props.editDimensionQuestion(params);
                        }}
                    />
                )}
                {this.props.modal.create_dimension_answer_modal.is_visible && (
                    <CreateDimensionAnswerModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_dimension_answer_modal.is_visible}
                        assessment_id={this.props.modal.create_dimension_answer_modal.assessment_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateDimensionAnswerModal();
                        }}
                        onCreate={(params) => {
                            this.props.createDimensionAnswer(params);
                        }}
                    />
                )}
                {this.props.modal.edit_answer_modal.is_visible && (
                    <EditDimensionAnswerModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_answer_modal.is_visible}
                        assessment_id={this.props.modal.edit_answer_modal.assessment_id}
                        answer_id={this.props.modal.edit_answer_modal.answer_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditDimensionAnswerModal();
                        }}
                        onCreate={(params) => {
                            this.props.editDimensionAnswer(params);
                        }}
                    />
                )}
                {this.props.modal.add_answer_modal.is_visible && (
                    <AddAnswerModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.add_answer_modal.is_visible}
                        assessment_id={this.props.modal.add_answer_modal.assessment_id}
                        answer_id={this.props.modal.add_answer_modal.answer_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideAddAnswerModal();
                        }}
                        onCreate={(params) => {
                            this.props.addAssessmentAnswer(params);
                        }}
                    />
                )}
                {/* EAP Focus */}
                {this.props.modal.create_eap_focus_modal.is_visible && (
                    <CreateEAPFocusModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_eap_focus_modal.is_visible}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateEAPFocusModal();
                        }}
                        onCreate={(params) => {
                            this.props.createEAPFocus(params);
                        }}
                    />
                )}
                {this.props.modal.edit_eap_focus_modal.is_visible && (
                    <EditEAPFocusModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_eap_focus_modal.is_visible}
                        focus_id={this.props.modal.edit_eap_focus_modal.focus_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditEAPFocusModal();
                        }}
                        onCreate={(params) => {
                            this.props.editEAPFocus(params);
                        }}
                    />
                )}
                {/* EAP Framework */}
                {this.props.modal.create_eap_framework_modal.is_visible && (
                    <CreateEAPFrameworkModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_eap_framework_modal.is_visible}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateEAPFrameworkModal();
                        }}
                        onCreate={(params) => {
                            this.props.createEAPFramework(params);
                        }}
                    />
                )}
                {this.props.modal.edit_eap_framework_modal.is_visible && (
                    <EditEAPFrameworkModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_eap_framework_modal.is_visible}
                        framework_id={this.props.modal.edit_eap_framework_modal.framework_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditEAPFrameworkModal();
                        }}
                        onCreate={(params) => {
                            this.props.editEAPFramework(params);
                        }}
                    />
                )}
                {/* EAP Speciality */}
                {this.props.modal.create_eap_speciality_modal.is_visible && (
                    <CreateEAPSpecialityModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_eap_speciality_modal.is_visible}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateEAPSpecialityModal();
                        }}
                        onCreate={(params) => {
                            this.props.createEAPSpeciality(params);
                        }}
                    />
                )}
                {this.props.modal.edit_eap_speciality_modal.is_visible && (
                    <EditEAPSpecialityModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_eap_speciality_modal.is_visible}
                        speciality_id={this.props.modal.edit_eap_speciality_modal.speciality_id}
                        isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideEditEAPSpecialityModal();
                        }}
                        onCreate={(params) => {
                            this.props.editEAPSpeciality(params);
                        }}
                    />
                )}
                {/* Customer Organization */}
                {this.props.modal.create_customer_organization_modal.is_visible && (
                    <CreateCustomerOrganizationModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.create_customer_organization_modal.is_visible}
                        // isLoading={this.props.assessment.is_creating}
                        onClose={() => {
                            this.props.hideCreateCustomerOrganizationModal();
                        }}
                        onCreate={(params) => {
                            this.props.createCustomerOrganization(params);
                        }}
                    />
                )}
                {/* edit_customer_organization_modal */}
                {this.props.modal.edit_customer_organization_modal.is_visible && (
                    <EditCustomerOrganizationModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.edit_customer_organization_modal.is_visible}
                        organization_id={this.props.modal.edit_customer_organization_modal.organization_id}
                        onClose={() => {
                            this.props.hideEditCustomerOrganizationModal();
                        }}
                        onCreate={(params) => {
                            // Edit Customer Organization
                            this.props.editCustomerOrganization(params);
                        }}
                        onArchive={(organization_id, is_archived) => {
                            this.props.archiveCustomerOrganization(organization_id, is_archived);
                        }}
                        onExpire={(organization_id) => {
                            // expire whole users in the organization
                            this.props.expireCustomerOrganization(organization_id);
                        }}
                        onActivate={(organization_id) => {
                            // active whole users in the organization
                            this.props.activateCustomerOrganization(organization_id);
                        }}
                    />
                )}

                {/* deployment link guide modal */}
                {this.props.modal.customer_deployment_modal.is_visible && (
                    <OrganizationDeploymentModal
                        locale={this.props.setting.locale}
                        isOpen={this.props.modal.customer_deployment_modal.is_visible}
                        onClose={() => {
                            this.props.hideDeploymentModal();
                        }}
                        onCreate={(params) => {
                            // Edit Customer Organization
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.view.modal,
        contentView: state.view.content,
        content: state.data.content,
        setting: state.data.setting,
        organization: state.data.organization,
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({
    addContentLink: (tool_id, lesson_id, reason, completion_handler) =>
        dispatch(addContentLink(tool_id, lesson_id, reason, completion_handler)),
    hideContentLinkModal: () => dispatch(hideContentLinkModal()),
    hideCopyContentModal: () => dispatch(hideCopyContentModal()),
    hideCreateToolModal: () => dispatch(hideCreateToolModal()),
    hideCreateQuoteModal: () => dispatch(hideCreateQuoteModal()),
    hideEditToolModal: () => dispatch(hideEditToolModal()),
    hideEditQuoteModal: () => dispatch(hideEditQuoteModal()),
    hideContentExerciseChatEditModal: () => dispatch(hideContentExerciseChatEditModal()),
    hideEditExerciseModal: () => dispatch(hideEditExerciseModal()),
    hideCreateExerciseModal: () => dispatch(hideCreateExerciseModal()),
    hideCreateContentCardModal: () => dispatch(hideCreateContentCardModal()),
    hideEditContentCardCompletionModal: () => dispatch(hideEditContentCardCompletionModal()),
    hideEditContentCardReferenceModal: () => dispatch(hideEditContentCardReferenceModal()),
    hideEditContentCaptionCardModal: () => dispatch(hideEditContentCaptionCardModal()),
    hideEditContentInformationCardModal: () => dispatch(hideEditContentInformationCardModal()),
    hideEditContentAudioCardModal: () => dispatch(hideEditContentAudioCardModal()),
    hideEditContentVideoCardModal: () => dispatch(hideEditContentVideoCardModal()),
    hideEditContentTaskCardModal: () => dispatch(hideEditContentTaskCardModal()),
    hideEditContentCompleteCardModal: () => dispatch(hideEditContentCompleteCardModal()),
    hideEditContentReadCardModal: () => dispatch(hideEditContentReadCardModal()),
    hideEditContentInputCardModal: () => dispatch(hideEditContentInputCardModal()),
    hideAddContentInputCardModal: () => dispatch(hideAddContentInputCardModal()),
    hideEditContentQuestionnaireCardModal: () => dispatch(hideEditContentQuestionnaireCardModal()),
    hideEditContentQuizCardModal: () => dispatch(hideEditContentQuizCardModal()),
    hideEditContentGuideCardModal: () => dispatch(hideEditContentGuideCardModal()),
    hideAddContentQuizCardItemModal: () => dispatch(hideAddContentQuizCardItemModal()),
    hideAddContentGuideCardConceptModal: () => dispatch(hideAddContentGuideCardConceptModal()),
    hideEditContentQuizCardItemModal: () => dispatch(hideEditContentQuizCardItemModal()),
    hideEditContentReflectionCardModal: () => dispatch(hideEditContentReflectionCardModal()),
    hideEditContentTimerCardModal: () => dispatch(hideEditContentTimerCardModal()),
    hideEditContentQuoteCardModal: () => dispatch(hideEditContentQuoteCardModal()),
    hideAddContentQuoteCardModal: () => dispatch(hideAddContentQuoteCardModal()),
    hideContentSummaryCardEditModal: () => dispatch(hideContentSummaryCardEditModal()),
    hideEditContentChecklistCardModal: () => dispatch(hideEditContentChecklistCardModal()),
    hideAddItemContentDocumentCardModal: () => dispatch(hideAddItemContentDocumentCardModal()),
    hideEditTextItemContentDocumentCardModal: () => dispatch(hideEditTextItemContentDocumentCardModal()),
    hideEditImageItemContentDocumentCardModal: () => dispatch(hideEditImageItemContentDocumentCardModal()),
    hideMoveContentCardModal: () => dispatch(hideMoveContentCardModal()),
    hideContentExerciseActionExerciseEditModal: () => dispatch(hideContentExerciseActionExerciseEditModal()),
    hideEditContentChecklistCardItemModal: () => dispatch(hideEditContentChecklistCardItemModal()),
    hideEditContentAssessmentCardModal: () => dispatch(hideEditContentAssessmentCardModal()),
    hideEditContentAssessmentResultCardModal: () => dispatch(hideEditContentAssessmentResultCardModal()),
    hideContentOverviewSessionsCardEditModal: () => dispatch(hideContentOverviewSessionsCardEditModal()),
    hideContentToolCardEditModal: () => dispatch(hideContentToolCardEditModal()),
    hideAddContentToolCardToolModal: () => dispatch(hideAddContentToolCardToolModal()),
    linkContentExerciseActionExercise: (exercise_id, action_exercise_id) =>
        dispatch(linkContentExerciseActionExercise(exercise_id, action_exercise_id)),
    copyContentContentCard: (from_exercise_id, to_exercise_id) => dispatch(copyContentContentCard(from_exercise_id, to_exercise_id)),
    createContentTool: (params) => dispatch(createContentTool(params)),
    updateContentTool: (tool_id, params) => dispatch(updateContentTool(tool_id, params)),
    createContentGoal: (params) => dispatch(createContentGoal(params)),
    createContentGoalHeader: (goal_id, params) => dispatch(createContentGoalHeader(goal_id, params)),
    linkContentJourneyGoalHeader: (goal_header_id, journey_id) => dispatch(linkContentJourneyGoalHeader(goal_header_id, journey_id)),
    unlinkContentJourneyGoalHeader: (journey_id) => dispatch(unlinkContentJourneyGoalHeader(journey_id)),
    createContentJourney: (goal_id, params) => dispatch(createContentJourney(goal_id, params)),
    updateContentJourney: (journey_id, params) => dispatch(updateContentJourney(journey_id, params)),
    updateContentJourneyGoal: (journey_id, goal_id) => dispatch(updateContentJourneyGoal(journey_id, goal_id)),
    createContentExercise: (goal_id, journey_id, tool_id, params) => dispatch(createContentExercise(goal_id, journey_id, tool_id, params)),
    updateContentExercise: (exercise_id, params) => dispatch(updateContentExercise(exercise_id, params)),
    createContentContentCard: (exercise_id, type) => dispatch(createContentContentCard(exercise_id, type)),
    updateContentContentCardCompletion: (exercise_id, index, type, data) =>
        dispatch(updateContentContentCardCompletion(exercise_id, index, type, data)),
    updateContentContentCardReference: (exercise_id, index, type, value) =>
        dispatch(updateContentContentCardReference(exercise_id, index, type, value)),
    updateContentContentAssessmentCard: (exercise_id, index, params) =>
        dispatch(updateContentContentAssessmentCard(exercise_id, index, params)),
    updateContentContentToolCard: (exercise_id, index, params) => dispatch(updateContentContentToolCard(exercise_id, index, params)),
    updateContentContentOverviewSessionsCard: (exercise_id, index, params) =>
        dispatch(updateContentContentOverviewSessionsCard(exercise_id, index, params)),
    updateContentContentAssessmentResultCard: (exercise_id, index, params) =>
        dispatch(updateContentContentAssessmentResultCard(exercise_id, index, params)),
    updateContentContentTimerCard: (exercise_id, index, params) => dispatch(updateContentContentTimerCard(exercise_id, index, params)),
    updateContentContentCaptionCard: (exercise_id, index, params) => dispatch(updateContentContentCaptionCard(exercise_id, index, params)),
    updateContentContentInformationCard: (exercise_id, index, params) =>
        dispatch(updateContentContentInformationCard(exercise_id, index, params)),
    updateContentContentReflectionCard: (exercise_id, index, params) =>
        dispatch(updateContentContentReflectionCard(exercise_id, index, params)),
    updateContentContentAudioCard: (exercise_id, index, params) => dispatch(updateContentContentAudioCard(exercise_id, index, params)),
    updateContentContentVideoCard: (exercise_id, index, params) => dispatch(updateContentContentVideoCard(exercise_id, index, params)),
    updateContentContentTaskCard: (exercise_id, index, params) => dispatch(updateContentContentTaskCard(exercise_id, index, params)),
    updateContentContentCompleteCard: (exercise_id, index, params) =>
        dispatch(updateContentContentCompleteCard(exercise_id, index, params)),
    updateContentContentReadCard: (exercise_id, index, params) => dispatch(updateContentContentReadCard(exercise_id, index, params)),
    updateContentContentQuizCard: (exercise_id, index, params) => dispatch(updateContentContentQuizCard(exercise_id, index, params)),
    createContentContentQuizCardItem: (exercise_id, index, params) =>
        dispatch(createContentContentQuizCardItem(exercise_id, index, params)),
    updateContentContentQuizCardItem: (exercise, card_index, item_index, params) =>
        dispatch(updateContentContentQuizCardItem(exercise, card_index, item_index, params)),
    updateContentContentInputCardItem: (exercise_id, card_index, item_index, params) =>
        dispatch(updateContentContentInputCardItem(exercise_id, card_index, item_index, params)),
    createContentContentInputCardItem: (exercise_id, card_index, params) =>
        dispatch(createContentContentInputCardItem(exercise_id, card_index, params)),
    deleteContentContentInputCardItem: (exercise_id, card_index, item_index) =>
        dispatch(deleteContentContentInputCardItem(exercise_id, card_index, item_index)),
    updateContentContentQuoteCardItem: (exercise_id, card_index, item_index, params) =>
        dispatch(updateContentContentQuoteCardItem(exercise_id, card_index, item_index, params)),
    createContentContentQuoteCardItem: (exercise_id, card_index, params) =>
        dispatch(createContentContentQuoteCardItem(exercise_id, card_index, params)),
    deleteContentContentQuoteCardItem: (exercise_id, card_index, item_index) =>
        dispatch(deleteContentContentQuoteCardItem(exercise_id, card_index, item_index)),
    createContentContentDocumentCardItem: (exercise_id, index, type, data) =>
        dispatch(createContentContentDocumentCardItem(exercise_id, index, type, data)),
    updateContentContentDocumentCardItem: (exercise_id, index, item_index, type, data) =>
        dispatch(updateContentContentDocumentCardItem(exercise_id, index, item_index, type, data)),
    moveContentContentCard: (exercise_id, from_timestamp, to_timestamp) =>
        dispatch(moveContentContentCard(exercise_id, from_timestamp, to_timestamp)),
    updateContentContentChecklistCard: (exercise_id, card_index, params) =>
        dispatch(updateContentContentChecklistCard(exercise_id, card_index, params)),
    updateContentContentChecklistCardItem: (exercise_id, card_index, item_index, params) =>
        dispatch(updateContentContentChecklistCardItem(exercise_id, card_index, item_index, params)),
    updateContentContentSummaryCard: (exercise_id, card_index, params) =>
        dispatch(updateContentContentSummaryCard(exercise_id, card_index, params)),
    updateContentContentQuestionnaireCard: (exercise_id, card_index, params) =>
        dispatch(updateContentContentQuestionnaireCard(exercise_id, card_index, params)),
    createEmailAccess: (email, organization_id, access_type) => dispatch(createEmailAccess(email, organization_id, access_type)),
    hideContentToolTagModal: () => dispatch(hideContentToolTagModal()),
    hideValidationContentModal: () => dispatch(hideValidationContentModal()),
    setContentToolPublish: (toolId, has_published) => dispatch(setContentToolPublish(toolId, has_published)),
    createContentToolTag: (tool_id, params) => dispatch(createContentToolTag(tool_id, params)),
    createContentGenericTag: (tool_id, params) => dispatch(createContentGenericTag(tool_id, params)),
    createContentQuote: (params) => dispatch(createContentQuote(params)),
    updateContentQuote: (quote_id, params) => dispatch(updateContentQuote(quote_id, params)),
    addToolContentContentToolCard: (exercise_id, card_index, params) =>
        dispatch(addToolContentContentToolCard(exercise_id, card_index, params)),
    removeToolContentContentToolCard: (exercise_id, card_index, item_index) =>
        dispatch(removeToolContentContentToolCard(exercise_id, card_index, item_index)),
    createContentGuideConcept: (exercise_id, index, params) => dispatch(createContentGuideConcept(exercise_id, index, params)),
    updateContentContentGuideCard: (exercise_id, index, params) => dispatch(updateContentContentGuideCard(exercise_id, index, params)),
    updateContentContentGuideCardConcept: (exercise_id, index, item_index, params) =>
        dispatch(updateContentContentGuideCardConcept(exercise_id, index, item_index, params)),
    hideEditContentGuideCardConceptModal: () => dispatch(hideEditContentGuideCardConceptModal()),
    // Assessment
    hideCreateAssessmentSourceModal: () => dispatch(hideCreateAssessmentSourceModal()),
    hideEditAssessmentSourceModal: () => dispatch(hideEditAssessmentSourceModal()),
    hideCreateAssessmentDimensionModal: () => dispatch(hideCreateAssessmentDimensionModal()),
    hideEditAssessmentDimensionModal: () => dispatch(hideEditAssessmentDimensionModal()),
    createAssessmentSource: (params) => dispatch(createAssessmentSource(params)),
    editAssessmentSource: (params) => dispatch(editAssessmentSource(params)),
    createAssessmentDimension: (id, params) => dispatch(createAssessmentDimension(id, params)),
    editAssessmentDimension: (params) => dispatch(editAssessmentDimension(params)),
    createDimensionCategory: (params) => dispatch(createDimensionCategory(params)),
    editDimensionCategory: (params) => dispatch(editDimensionCategory(params)),
    hideCreateDimensionCategoryModal: () => dispatch(hideCreateDimensionCategoryModal()),
    hideEditDimensionCategoryModal: () => dispatch(hideEditDimensionCategoryModal()),
    createDimensionQuestion: (params) => dispatch(createDimensionQuestion(params)),
    hideCreateDimensionQuestionModal: () => dispatch(hideCreateDimensionQuestionModal()),
    editDimensionQuestion: (params) => dispatch(editDimensionQuestion(params)),
    hideEditDimensionQuestionModal: () => dispatch(hideEditDimensionQuestionModal()),
    createDimensionAnswer: (params) => dispatch(createDimensionAnswer(params)),
    hideCreateDimensionAnswerModal: () => dispatch(hideCreateDimensionAnswerModal()),
    addAssessmentAnswer: (params) => dispatch(addAssessmentAnswer(params)),
    hideAddAnswerModal: () => dispatch(hideAddAnswerModal()),
    hideEditDimensionAnswerModal: () => dispatch(hideEditAnswerModal()),
    editDimensionAnswer: (params) => dispatch(editDimensionAnswer(params)),
    // EAP Focus
    hideCreateEAPFocusModal: () => dispatch(hideCreateEAPFocusModal()),
    hideEditEAPFocusModal: () => dispatch(hideEditEAPFocusModal()),
    createEAPFocus: (params) => dispatch(createEAPFocus(params)),
    editEAPFocus: (params) => dispatch(editEAPFocus(params)),
    addContentToolTag: (tool_id, type, value) => dispatch(addContentToolTag(tool_id, type, value)),

    // EAP Framework
    hideCreateEAPFrameworkModal: () => dispatch(hideCreateEAPFrameworkModal()),
    hideEditEAPFrameworkModal: () => dispatch(hideEditEAPFrameworkModal()),
    createEAPFramework: (params) => dispatch(createEAPFramework(params)),
    editEAPFramework: (params) => dispatch(editEAPFramework(params)),

    // EAP Speciality
    hideCreateEAPSpecialityModal: () => dispatch(hideCreateEAPSpecialityModal()),
    hideEditEAPSpecialityModal: () => dispatch(hideEditEAPSpecialityModal()),
    createEAPSpeciality: (params) => dispatch(createEAPSpeciality(params)),
    editEAPSpeciality: (params) => dispatch(editEAPSpeciality(params)),

    // EAP Focus
    hideCreateCustomerOrganizationModal: () => dispatch(hideCreateOrganizationModal()),
    createCustomerOrganization: (params) => dispatch(createNewOrganization(params)),
    hideEditCustomerOrganizationModal: () => dispatch(hideEditOrganizationModal()),
    editCustomerOrganization: (params) => dispatch(editOrganization(params)),
    archiveCustomerOrganization: (organization_id, is_archived) => dispatch(archiveOrganization(organization_id, is_archived)),
    expireCustomerOrganization: (organization_id) => dispatch(expireOrganization(organization_id)),
    activateCustomerOrganization: (organization_id) => dispatch(activateOrganization(organization_id)),
    hideDeploymentModal: () => dispatch(hideOrganizationDeployment())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalController);
