// assets
import { IconUsers, IconCoin, IconFocus2 } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconCoin,
    IconFocus2
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const humanSupport = {
    id: 'human-support',
    title: 'Human Support',
    type: 'group',
    children: [
        {
            id: 'practitioner',
            title: 'Practitioners',
            type: 'item',
            url: '/human-support/practitioner',
            icon: icons.IconUsers
        },
        {
            id: 'credit',
            title: 'Credits',
            type: 'item',
            url: '/human-support/credit',
            icon: icons.IconCoin
        },
        {
            id: 'eap-areas',
            title: 'Human Care Areas',
            type: 'item',
            url: '/human-support/areas',
            icon: icons.IconFocus2
        }
    ]
};

export default humanSupport;
