/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { sourceTypes } from 'components/assessment/immediateSelection';
import { connect } from 'react-redux';

class EditDimensionQuestionModal extends React.Component {
    state = {
        questions: [],
        question: {},
        idError: false,
        answerError: false
    };

    resetState = () => {
        this.setState({
            questions: [],
            question: {}
        });
    };

    componentDidMount() {
        const { assessment, assessment_id, dimension_id, question_id } = this.props;
        const {
            current_assessment: { data: currentAssessmentData }
        } = assessment;
        if (currentAssessmentData && currentAssessmentData.uuid === assessment_id) {
            const { dimensions } = currentAssessmentData;
            const targetDimension = dimensions.find((item) => item.uuid === dimension_id);
            if (targetDimension) {
                const { questions } = targetDimension;
                const targetQuestion = questions.find((item) => item.uuid === question_id);
                const { answer_id: targetAnswerId } = targetQuestion;
                const { answers: allAnswers } = currentAssessmentData;
                let answer_id;
                if (allAnswers && allAnswers.length > 0) {
                    const targetAnswer = allAnswers.find((item) => item.uuid === targetAnswerId);
                    if (targetAnswer) {
                        answer_id = targetAnswer.uuid;
                    }
                }

                this.setState({
                    question: {
                        ...targetQuestion,
                        answer_id: answer_id
                    }
                });
            }
        }
    }

    handleSubmit = () => {
        const params = {};
        const { question } = this.state;

        const validQuestion = {};
        const { uuid, id, prompt_en, prompt_yue, prompt_zh, answer_id } = question;
        if (id) {
            this.setState({
                idError: false
            });
            validQuestion.id = id;
        } else {
            this.setState({
                idError: true
            });
            return;
        }
        if (answer_id) {
            this.setState({
                answerError: false
            });
            validQuestion.answer_id = answer_id;
        } else {
            this.setState({
                answerError: true
            });
            return;
        }
        validQuestion.uuid = uuid;
        validQuestion.prompt_en = prompt_en;
        validQuestion.prompt_yue = prompt_yue;
        validQuestion.prompt_zh = prompt_zh;

        params['question'] = validQuestion;
        params['assessment_id'] = this.props.assessment_id;
        params['dimension_id'] = this.props.dimension_id;
        params['question_id'] = this.props.question_id;
        this.props.onCreate(params);
    };

    handleCategoryChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        const { question } = this.state;
        let new_question = { ...question };
        new_question[name] = inputValue;
        this.setState({
            question: new_question
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    clearResult = () => {
        this.setState({
            questions: []
        });
    };

    render() {
        const { isOpen, isLoading, assessment } = this.props;
        const { question } = this.state;
        const {
            current_assessment: { data, is_loading }
        } = assessment;
        if (is_loading) return;
        return (
            <Modal
                id="create-assessment-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-assessment-modal-header">Edit Question</Modal.Header>
                <Modal.Content id="create-assessment-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="sub-item">
                                <div className="category-container">
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="6" sm="6">
                                            <Form.Input
                                                value={question.id || ''}
                                                type="text"
                                                name="id"
                                                error={this.state.idError}
                                                placeholder="Id"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={question.prompt_en || ''}
                                                type="text"
                                                name="prompt_en"
                                                placeholder="Prompt English"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={question.prompt_yue || ''}
                                                type="text"
                                                name="prompt_yue"
                                                placeholder="Prompt Cantonese"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="4" sm="6">
                                            <Form.Input
                                                value={question.prompt_zh || ''}
                                                type="text"
                                                name="prompt_zh"
                                                placeholder="Prompt Mandarin"
                                                onChange={(event) => this.handleCategoryChange(event)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="6" sm="6">
                                            <FormControl fullWidth>
                                                <InputLabel id="result-score-type">Select Answer</InputLabel>
                                                <Select
                                                    labelId="result-score-type"
                                                    id="result-score-type"
                                                    error={this.state.answerError}
                                                    value={question.answer_id || ''}
                                                    label="Select Answer"
                                                    name="answer_id"
                                                    onChange={(event) => this.handleCategoryChange(event)}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {data?.answers?.map((answer, index) => (
                                                        <MenuItem value={answer.uuid}>{answer.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-assessment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditDimensionQuestionModal);

EditDimensionQuestionModal.propTypes = {
    assessment: PropTypes.object,
    assessment_id: PropTypes.string,
    dimension_id: PropTypes.string,
    question_id: PropTypes.string,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onCreate: PropTypes.func,
    onClose: PropTypes.func
};
