import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    locale: string;
    version: any;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class CreateQuoteModal extends React.Component<Props> {
    state = {
        message: null,
        author: null
    };

    resetState = () => {
        this.setState({
            author: null,
            message: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'author':
                this.setState({
                    author: inputValue
                });
                break;
            case 'message':
                this.setState({
                    message: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        params['version'] = this.props.version;
        if (this.state.author != null) params['author'] = this.state.author;
        if (this.state.message != null) params['message'] = this.state.message;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { locale, isOpen, isLoading } = this.props;
        return (
            <Modal
                id="create-tool-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-tool-modal-header">Create Quote</Modal.Header>
                <Modal.Content id="create-tool-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.author || ''}
                                type="text"
                                name="author"
                                placeholder="Author"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.message || ''}
                                rows="2"
                                type="text"
                                name="message"
                                placeholder="Message"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-tool-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Create Quote
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateQuoteModal;
