/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import { sourceTypes } from 'components/assessment/immediateSelection';
import { connect } from 'react-redux';

class EditAssessmentSourceModal extends React.Component {
    state = {
        have_scale: false,
        have_operation: false,
        id: '',
        information: {
            icon_name: null,
            color: null,
            title_en: null,
            title_yue: null,
            title_zh: null,
            description_en: null,
            description_yue: null,
            description_zh: null
        },
        dimensions: [],
        result_score: {
            direction: null,
            type: null,
            min: null,
            max: null,
            operations: [],
            scale_type: null,
            scale_limit: null
        }
    };

    componentDidMount() {
        const { assessment, assessment_id } = this.props;
        const {
            current_assessment: { data: currentAssessmentData }
        } = assessment;
        if (currentAssessmentData && currentAssessmentData.uuid === assessment_id) {
            const {
                result: { score },
                information,
                source_type
            } = currentAssessmentData;
            const { operations, scale } = score;
            let have_scale = false;
            if (scale.limit && scale.type) {
                have_scale = true;
            }
            const operation = operations[0];
            let have_operation = false;
            if (operation.type && operation.value) {
                have_operation = true;
            }
            this.setState({
                have_scale: have_scale,
                have_operation: have_operation,
                id: currentAssessmentData.id,
                source_type: source_type,
                information: {
                    icon_name: information.icon_name,
                    color: information.color,
                    title_en: information.title.en,
                    title_yue: information.title.yue,
                    title_zh: information.title.zh,
                    description_en: information.description.en,
                    description_yue: information.description.yue,
                    description_zh: information.description.zh
                },
                result_score: {
                    direction: score.direction,
                    type: score.type,
                    min: score.min,
                    max: score.max,
                    operations: have_operation ? score.operations : [],
                    scale_type: have_scale ? score.scale?.type : null,
                    scale_limit: have_scale ? score.scale?.limit : null
                }
            });
        }
    }

    resetState = () => {
        this.setState({
            source_type: '',
            id: '',
            information: {
                icon_name: null,
                color: null,
                title_en: null,
                title_yue: null,
                title_zh: null,
                description_en: null,
                description_yue: null,
                description_zh: null
            },
            dimensions: [],
            result_score: {
                direction: null,
                type: null,
                min: null,
                max: null,
                operations: [],
                scale_type: null,
                scale_limit: null
            }
        });
    };

    handleOperator = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        const {
            result_score: { operations }
        } = this.state;
        let newOperation;
        if (operations.length > 0) {
            newOperation = { ...operations[0] };
            newOperation[name] = value;
        } else {
            newOperation = {};
            newOperation[name] = value;
        }
        this.setState({
            result_score: {
                ...this.state.result_score,
                operations: [newOperation]
            }
        });
    };

    handleInputChange = (event, parentName = null) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        if (parentName) {
            switch (parentName) {
                case 'information':
                    this.setState({
                        information: {
                            ...this.state.information,
                            [name]: inputValue
                        }
                    });
                    break;

                case 'result_score':
                    this.setState({
                        result_score: {
                            ...this.state.result_score,
                            [name]: inputValue
                        }
                    });
                    break;

                default:
                    break;
            }
        } else {
            this.setState({
                [name]: inputValue
            });
        }
    };

    handleSubmit = () => {
        const params = {};
        const {
            source_type,
            id,
            information: { icon_name, color, title_en, title_yue, title_zh, description_en, description_yue, description_zh },
            result_score: { direction, type, min, max, operations, scale_type, scale_limit }
        } = this.state;
        const { assessment_id } = this.props;
        if (source_type) {
            params['source_type'] = source_type;
        } else {
            return;
        }
        if (id) {
            params['id'] = id.trim();
        } else {
            return;
        }
        if (direction) {
            params['direction'] = direction;
        }
        if (type) {
            params['type'] = type;
        }
        if (min) {
            params['min'] = min;
        }
        if (max) {
            params['max'] = max;
        }
        if (scale_type) {
            params['scale_type'] = scale_type;
        }
        if (scale_limit) {
            params['scale_limit'] = scale_limit;
        }
        if (operations.length > 0) {
            const operation = operations[0];
            if (operation.type && operation.value) {
                params['operations'] = operations;
            }
        }

        if (icon_name) {
            params['icon_name'] = icon_name;
        }
        if (color) {
            params['color'] = color;
        }
        if (title_en) {
            params['title_en'] = title_en;
        }
        if (title_yue) {
            params['title_yue'] = title_yue;
        }
        if (title_zh) {
            params['title_zh'] = title_zh;
        }
        if (description_en) {
            params['description_en'] = description_en;
        }
        if (description_yue) {
            params['description_yue'] = description_yue;
        }
        if (description_zh) {
            params['description_zh'] = description_zh;
        }
        params['assessment_id'] = assessment_id;
        this.props.onCreate(params);
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    onToolTypeUpdated = (value) => {
        this.setState({ tool_type_is_immediate: value == 'tool', is_series: value == 'series', content_type: value });
    };

    handleHaveScale = () => {
        if (this.state.have_scale) {
            this.setState({
                have_scale: false,
                result_score: {
                    ...this.state.result_score,
                    scale_type: null,
                    scale_limit: null
                }
            });
        } else {
            this.setState({
                have_scale: true
            });
        }
    };

    handleHaveOperation = () => {
        if (this.state.have_operation) {
            this.setState({
                have_operation: false,
                result_score: {
                    ...this.state.result_score,
                    operations: []
                }
            });
        } else {
            this.setState({
                have_operation: true
            });
        }
    };

    clearResult = () => {
        this.setState({
            result_score: {
                direction: null,
                type: null,
                min: null,
                max: null,
                operations: [],
                scale_type: null,
                scale_limit: null
            }
        });
    };

    render() {
        const { isOpen, isLoading } = this.props;
        const {
            have_scale,
            have_operation,
            result_score: { operations }
        } = this.state;
        return (
            <Modal
                id="create-assessment-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-assessment-modal-header">Edit Assessment Source</Modal.Header>
                <Modal.Content id="create-assessment-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div className="section-header">
                                <Card.Header className="property-value">Choose Source Type</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <FormControl
                                        fullWidth
                                        required
                                        // error={!this.state.source_type}
                                    >
                                        <InputLabel id="source-type">Source type</InputLabel>
                                        <Select
                                            labelId="source-type"
                                            id="source-type"
                                            value={this.state.source_type || ''}
                                            label="Source type"
                                            name="source_type"
                                            onChange={(event) => this.handleInputChange(event)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>

                                            {sourceTypes.map((sourceType, index) => (
                                                <MenuItem key={index.toString()} value={sourceType.type}>
                                                    {sourceType.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <div className="section-header">
                                <Card.Header className="property-value">Assessment Id *</Card.Header>
                            </div>
                            <Grid container className="grid-item-container">
                                <Grid item xs={12} md={6} sm={6}>
                                    <Form.Input
                                        value={this.state.id || ''}
                                        id="id"
                                        type="text"
                                        name="id"
                                        placeholder="Id"
                                        onChange={(event) => this.handleInputChange(event)}
                                    />
                                </Grid>
                            </Grid>
                            <div className="section-header">
                                <Card.Header className="property-value">Result</Card.Header>
                                <Icon id="result-clear" name="close" onClick={this.clearResult} />
                            </div>
                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="3" sm="6">
                                    <FormControl fullWidth>
                                        <InputLabel id="result-score-direction">Direction</InputLabel>
                                        <Select
                                            labelId="result-score-direction"
                                            id="result-score-direction"
                                            value={this.state.result_score.direction || ''}
                                            label="direction"
                                            name="direction"
                                            onChange={(event) => this.handleInputChange(event, 'result_score')}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'positive'}>Positive</MenuItem>
                                            <MenuItem value={'negative'}>Negative</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs="12" md="3" sm="6">
                                    <FormControl fullWidth>
                                        <InputLabel id="result-score-type">Type</InputLabel>
                                        <Select
                                            labelId="result-score-type"
                                            id="result-score-type"
                                            value={this.state.result_score.type || ''}
                                            label="type"
                                            name="type"
                                            onChange={(event) => this.handleInputChange(event, 'result_score')}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'sum'}>Sum</MenuItem>
                                            <MenuItem value={'top'}>Top</MenuItem>
                                            <MenuItem value={'top_category_count'}>Top Category Count</MenuItem>
                                            <MenuItem value={'dimension'}>Dimension</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs="12" md="3" sm="6">
                                    <Form.Input
                                        value={this.state.result_score.min ?? ''}
                                        type="number"
                                        name="min"
                                        placeholder="Min"
                                        onChange={(event) => this.handleInputChange(event, 'result_score')}
                                    />
                                </Grid>

                                <Grid item xs="12" md="3" sm="6">
                                    <Form.Input
                                        value={this.state.result_score.max ?? ''}
                                        type="number"
                                        name="max"
                                        min={this.state.result_score.min ?? 0}
                                        placeholder="Max"
                                        onChange={(event) => this.handleInputChange(event, 'result_score')}
                                    />
                                </Grid>
                            </Grid>
                            <div className="sub-item">
                                <div className="section-header">
                                    <Card.Header className="property-sub-value">Have scale ?</Card.Header>
                                    <Icon id="result-clear" name={have_scale ? 'close' : 'add'} onClick={this.handleHaveScale} />
                                </div>
                                {have_scale && (
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="3" sm="6">
                                            <Form.Input
                                                value={this.state.result_score.scale_type || ''}
                                                type="text"
                                                name="scale_type"
                                                placeholder="Scale Type"
                                                onChange={(event) => this.handleInputChange(event, 'result_score')}
                                            />
                                        </Grid>
                                        <Grid item xs="12" md="3" sm="6">
                                            <FormControl fullWidth>
                                                <InputLabel id="result-score-scale-limit">Scale Limit</InputLabel>
                                                <Select
                                                    labelId="result-score-scale-limit"
                                                    id="result-score-scale-limit"
                                                    value={this.state.result_score.scale_limit || ''}
                                                    label="scale_limit"
                                                    name="scale_limit"
                                                    onChange={(event) => this.handleInputChange(event, 'result_score')}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={'min'}>Min</MenuItem>
                                                    <MenuItem value={'max'}>Max</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>

                            <div className="sub-item">
                                <div className="section-header">
                                    <Card.Header className="property-sub-value">Have operator ?</Card.Header>
                                    <Icon
                                        id="result-clear"
                                        name={have_operation > 0 ? 'close' : 'add'}
                                        onClick={this.handleHaveOperation}
                                    />
                                </div>
                                {have_operation && (
                                    <Grid container className="grid-item-container" spacing={3}>
                                        <Grid item xs="12" md="3" sm="6">
                                            <FormControl fullWidth>
                                                <InputLabel id="result-score-operator-value">Operator Type</InputLabel>
                                                <Select
                                                    labelId="result-score-operator-value"
                                                    id="result-score-operator-value"
                                                    value={operations.length > 0 ? operations[0].type : ''}
                                                    label="operator_type"
                                                    name="type"
                                                    onChange={this.handleOperator}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={'multiply'}>Multiply</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs="12" md="3" sm="6">
                                            <Form.Input
                                                value={operations.length > 0 ? operations[0].value : ''}
                                                type="number"
                                                name="value"
                                                placeholder="Operator Value"
                                                onChange={this.handleOperator}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                            <div className="section-header">
                                <Card.Header className="property-value">Information</Card.Header>
                            </div>
                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="3" sm="6">
                                    <Form.Input
                                        value={this.state.information.icon_name || ''}
                                        type="text"
                                        name="icon_name"
                                        placeholder="Icon name"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                                <Grid item xs="12" md="3" sm="6">
                                    <Form.Input
                                        value={this.state.information.color || ''}
                                        type="text"
                                        name="color"
                                        placeholder="Color"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.title_en || ''}
                                        type="text"
                                        name="title_en"
                                        placeholder="Title English"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.title_yue || ''}
                                        type="text"
                                        name="title_yue"
                                        placeholder="Title Cantonese"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.title_zh || ''}
                                        rows="2"
                                        type="text"
                                        name="title_zh"
                                        placeholder="Title Mandarin"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="grid-item-container" spacing={3}>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.description_en || ''}
                                        rows="2"
                                        type="text"
                                        name="description_en"
                                        placeholder="Description English"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.description_yue || ''}
                                        rows="2"
                                        type="text"
                                        name="description_yue"
                                        placeholder="Description Cantonese"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                                <Grid item xs="12" md="4" sm="6">
                                    <Form.Input
                                        value={this.state.information.description_zh || ''}
                                        rows="2"
                                        type="text"
                                        name="description_zh"
                                        placeholder="Description Mandarin"
                                        onChange={(event) => this.handleInputChange(event, 'information')}
                                    />
                                </Grid>
                            </Grid>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-assessment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Source
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.data.assessment
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditAssessmentSourceModal);

EditAssessmentSourceModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
