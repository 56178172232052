export const ORGANIZATIONS_REQUESTED = 'organization/ORGANIZATIONS_REQUESTED';
export const ORGANIZATIONS_IS_LOADING = 'organization/ORGANIZATIONS_IS_LOADING';
export const ORGANIZATIONS_NOT_LOADING = 'organization/ORGANIZATIONS_NOT_LOADING';
export const ORGANIZATIONS_FETCHED = 'organization/ORGANIZATIONS_FETCHED';

export const ORGANIZATION_EXEMPTIONS_REQUESTED = 'organization/ORGANIZATION_EXEMPTIONS_REQUESTED';
export const ORGANIZATION_EXEMPTIONS_IS_LOADING = 'organization/ORGANIZATION_EXEMPTIONS_IS_LOADING';
export const ORGANIZATION_EXEMPTIONS_NOT_LOADING = 'organization/ORGANIZATION_EXEMPTIONS_NOT_LOADING';
export const ORGANIZATION_EXEMPTIONS_FETCHED = 'organization/ORGANIZATION_EXEMPTIONS_FETCHED';

export const ORGANIZATION_EXEMPTION_CREATE_REQUESTED = 'organization/ORGANIZATION_EXEMPTION_CREATE_REQUESTED';

export const ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED = 'organization/ORGANIZATION_EXEMPTION_EXPIRE_USER_REQUESTED';
export const ORGANIZATION_EXEMPTION_DELETE_REQUESTED = 'organization/ORGANIZATION_EXEMPTION_DELETE_REQUESTED';
