import {
    MODAL_CONTENT_TOOL_CREATE_SHOW,
    MODAL_CONTENT_TOOL_CREATE_HIDE,
    MODAL_CONTENT_TOOL_EDIT_SHOW,
    MODAL_CONTENT_TOOL_EDIT_HIDE,
    MODAL_CONTENT_GOAL_CREATE_SHOW,
    MODAL_CONTENT_GOAL_CREATE_HIDE,
    MODAL_CONTENT_GOAL_HEADER_CREATE_SHOW,
    MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE,
    MODAL_CONTENT_GOAL_HEADER_LINK_SHOW,
    MODAL_CONTENT_GOAL_HEADER_LINK_HIDE,
    MODAL_CONTENT_GOAL_HEADER_UNLINK_SHOW,
    MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE,
    MODAL_CONTENT_JOURNEY_CREATE_SHOW,
    MODAL_CONTENT_JOURNEY_CREATE_HIDE,
    MODAL_CONTENT_JOURNEY_EDIT_SHOW,
    MODAL_CONTENT_JOURNEY_EDIT_HIDE,
    MODAL_CONTENT_JOURNEY_GOAL_EDIT_SHOW,
    MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE,
    MODAL_CONTENT_EXERCISE_CREATE_SHOW,
    MODAL_CONTENT_EXERCISE_CREATE_HIDE,
    MODAL_CONTENT_EXERCISE_EDIT_SHOW,
    MODAL_CONTENT_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_CREATE_SHOW,
    MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_READ_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_SHOW,
    MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW,
    MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE,
    MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_MOVE_CARD_SHOW,
    MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE,
    MODAL_ACCESS_CREATE_EMAIL_ACCESS_SHOW,
    MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE,
    MODAL_CONTENT_EXERCISE_CHAT_EDIT_SHOW,
    MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE,
    MODAL_CONTENT_ACTION_EXERCISE_EDIT_SHOW,
    MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE,
    MODAL_CONTENT_CONTENT_CARD_COPY_SHOW,
    MODAL_CONTENT_CONTENT_CARD_COPY_HIDE,
    MODAL_CONTENT_CREATE_TOOL_TAG_SHOW,
    MODAL_CONTENT_CREATE_TOOL_TAG_HIDE,
    MODAL_CONTENT_LINK_ADD_SHOW,
    MODAL_CONTENT_LINK_ADD_HIDE,
    MODAL_CONTENT_QUOTE_CREATE_SHOW,
    MODAL_CONTENT_QUOTE_CREATE_HIDE,
    MODAL_CONTENT_QUOTE_EDIT_SHOW,
    MODAL_CONTENT_QUOTE_EDIT_HIDE,
    MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW,
    MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_EDIT_SHOW,
    MODAL_CONTENT_TOOL_CARD_EDIT_HIDE,
    MODAL_CONTENT_TOOL_CARD_ADD_SHOW,
    MODAL_CONTENT_TOOL_CARD_ADD_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW,
    MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE,
    MODAL_CONTENT_VALIDATE_SHOW,
    MODAL_CONTENT_VALIDATE_HIDE,

    // assessment
    MODAL_ASSESSMENT_SOURCE_CREATE_SHOW,
    MODAL_ASSESSMENT_SOURCE_CREATE_HIDE,
    MODAL_ASSESSMENT_DIMENSION_CREATE_SHOW,
    MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE,
    MODAL_DIMENSION_CATEGORY_CREATE_SHOW,
    MODAL_DIMENSION_CATEGORY_CREATE_HIDE,
    MODAL_DIMENSION_ANSWER_CREATE_SHOW,
    MODAL_DIMENSION_ANSWER_CREATE_HIDE,
    MODAL_ANSWER_ADD_SHOW,
    MODAL_ANSWER_ADD_HIDE,
    MODAL_DIMENSION_QUESTION_CREATE_SHOW,
    MODAL_DIMENSION_QUESTION_CREATE_HIDE,
    MODAL_ASSESSMENT_SOURCE_EDIT_SHOW,
    MODAL_ASSESSMENT_SOURCE_EDIT_HIDE,
    MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW,
    MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE,
    MODAL_DIMENSION_CATEGORY_EDIT_SHOW,
    MODAL_DIMENSION_CATEGORY_EDIT_HIDE,
    MODAL_DIMENSION_QUESTION_EDIT_SHOW,
    MODAL_DIMENSION_QUESTION_EDIT_HIDE,
    MODAL_ANSWER_EDIT_SHOW,
    MODAL_ANSWER_EDIT_HIDE,
    MODAL_EAP_FOCUS_CREATE_SHOW,
    MODAL_EAP_FOCUS_CREATE_HIDE,
    MODAL_EAP_FOCUS_EDIT_SHOW,
    MODAL_EAP_FOCUS_EDIT_HIDE,
    MODAL_EAP_FRAMEWORK_CREATE_SHOW,
    MODAL_EAP_FRAMEWORK_CREATE_HIDE,
    MODAL_EAP_FRAMEWORK_EDIT_SHOW,
    MODAL_EAP_FRAMEWORK_EDIT_HIDE,
    MODAL_EAP_SPECIALITY_CREATE_SHOW,
    MODAL_EAP_SPECIALITY_CREATE_HIDE,
    MODAL_EAP_SPECIALITY_EDIT_SHOW,
    MODAL_EAP_SPECIALITY_EDIT_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_CREATE_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_EDIT_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE,
    MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW,
    MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE
} from '../../constants/view/modal';

const initialState = {
    content_link_modal: {
        is_visible: false,
        tool_id: null,
        lesson_id: null,
        completion_handler: null
    },
    create_tool_modal: {
        is_visible: false,
        version: null
    },
    edit_tool_modal: {
        is_visible: false,
        tool_id: null
    },
    create_goal_modal: {
        is_visible: false
    },
    create_goal_header_modal: {
        is_visible: false,
        goal_id: null
    },
    link_goal_header_modal: {
        is_visible: false,
        goal_header_id: null
    },
    unlink_goal_header_modal: {
        is_visible: false
    },
    edit_journey_goal_modal: {
        is_visible: false,
        journey_id: null
    },
    is_create_journey_modal: {
        is_visible: false,
        goal_id: null
    },
    is_edit_journey_modal: {
        is_visible: false,
        journey_id: null
    },
    is_create_exercise_modal: {
        is_visible: false,
        goal_id: null,
        journey_id: null,
        tool_id: null
    },
    is_edit_exercise_modal: {
        is_visible: false,
        exercise_id: null
    },
    copy_content_card: {
        is_visible: false,
        from_exercise_id: null
    },
    is_create_content_card: {
        is_visible: false,
        exercise_id: null
    },
    edit_content_card_completion: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_card_reference: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_information_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_reflection_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_quiz_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    add_content_quiz_item_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_content_quiz_item_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_content_checklist_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_assessment_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_assessment_result_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_summary_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_checklist_item_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    is_edit_content_caption_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_audio_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_video_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_task_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_questionnaire_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    // TODO: Remove Content Completion
    is_edit_content_complete_card_visible: false,
    edit_content_read_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    add_item_content_document_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_timer_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_document_card_text: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_content_document_card_image: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_content_input_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    add_content_input_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    move_content_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    is_create_email_access_visible: false,
    add_content_quote_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_quote_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_exercise_chat_modal: {
        is_visible: false,
        exercise_id: null
    },
    edit_action_exercise_modal: {
        is_visible: false,
        exercise_id: null
    },
    create_tool_tag_modal: {
        is_visible: false,
        tool_id: null
    },
    validate_content_modal: {
        is_visible: false,
        tool_id: null,
        show_publish: false
    },
    // quotes
    create_quote_modal: {
        is_visible: false,
        version: null
    },
    edit_quote_modal: {
        is_visible: false,
        quote_id: null
    },
    edit_content_overview_sessions_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_tool_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    add_content_tool_card_tool: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    add_content_guide_concept_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    edit_content_guide_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null
    },
    edit_content_guide_concept_card: {
        is_visible: false,
        exercise_id: null,
        card_index: null,
        item_index: null
    },
    // assessment
    create_assessment_source_modal: {
        is_visible: false
    },
    edit_assessment_source_modal: {
        is_visible: false,
        assessment_id: null
    },
    create_assessment_dimension_modal: {
        is_visible: false,
        assessment_id: null
    },
    edit_assessment_dimension_modal: {
        is_visible: false,
        assessment_id: null,
        dimension_id: null
    },
    create_dimension_category_modal: {
        is_visible: false,
        assessment_id: null,
        dimension_id: null
    },
    edit_dimension_category_modal: {
        is_visible: false,
        assessment_id: null,
        dimension_id: null,
        category_id: null
    },
    create_dimension_question_modal: {
        is_visible: false,
        assessment_id: null,
        dimension_id: null
    },
    edit_dimension_question_modal: {
        is_visible: false,
        assessment_id: null,
        dimension_id: null,
        question_id: null
    },
    create_dimension_answer_modal: {
        is_visible: false,
        assessment_id: null
    },
    add_answer_modal: {
        is_visible: false,
        assessment_id: null,
        answer_id: null
    },
    edit_answer_modal: {
        is_visible: false,
        assessment_id: null,
        answer_id: null
    },
    create_eap_focus_modal: {
        is_visible: false
    },
    edit_eap_focus_modal: {
        is_visible: false,
        focus_id: null
    },
    create_eap_framework_modal: {
        is_visible: false
    },
    edit_eap_framework_modal: {
        is_visible: false,
        framework_id: null
    },
    create_eap_speciality_modal: {
        is_visible: false
    },
    edit_eap_speciality_modal: {
        is_visible: false,
        speciality_id: null
    },
    create_customer_organization_modal: {
        is_visible: false
    },
    edit_customer_organization_modal: {
        is_visible: false,
        organization_id: null
    },
    customer_deployment_modal: {
        is_visible: false
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case MODAL_CONTENT_LINK_ADD_SHOW:
            return {
                ...state,
                content_link_modal: {
                    ...state.content_link_modal,
                    is_visible: true,
                    tool_id: action.payload.tool_id,
                    lesson_id: action.payload.lesson_id,
                    completion_handler: action.payload.completion_handler
                }
            };
        case MODAL_CONTENT_LINK_ADD_HIDE:
            return {
                ...state,
                content_link_modal: {
                    ...state.content_link_modal,
                    is_visible: false,
                    tool_id: null,
                    lesson_id: null,
                    completion_handler: null
                }
            };
        case MODAL_CONTENT_CREATE_TOOL_TAG_SHOW:
            return {
                ...state,
                create_tool_tag_modal: {
                    ...state.create_tool_tag_modal,
                    is_visible: true,
                    tool_id: action.payload.tool_id
                }
            };
        case MODAL_CONTENT_CREATE_TOOL_TAG_HIDE:
            return {
                ...state,
                create_tool_tag_modal: {
                    ...state.create_tool_tag_modal,
                    is_visible: false,
                    tool_id: null
                }
            };

        case MODAL_CONTENT_VALIDATE_SHOW:
            return {
                ...state,
                validate_content_modal: {
                    ...state.validate_content_modal,
                    is_visible: true,
                    tool_id: action.payload.tool_id,
                    show_publish: action.payload.show_publish
                }
            };
        case MODAL_CONTENT_VALIDATE_HIDE:
            return {
                ...state,
                validate_content_modal: {
                    ...state.validate_content_modal,
                    is_visible: false,
                    tool_id: null,
                    show_publish: false
                }
            };
        case MODAL_CONTENT_TOOL_CREATE_SHOW:
            return {
                ...state,
                create_tool_modal: {
                    ...state.create_tool_modal,
                    is_visible: true,
                    version: action.payload.version
                }
            };
        case MODAL_CONTENT_TOOL_CREATE_HIDE:
            return {
                ...state,
                create_tool_modal: {
                    ...state.create_tool_modal,
                    is_visible: false,
                    version: null
                }
            };

        case MODAL_CONTENT_QUOTE_CREATE_SHOW:
            return {
                ...state,
                create_quote_modal: {
                    ...state.create_quote_modal,
                    is_visible: true
                }
            };
        case MODAL_CONTENT_QUOTE_CREATE_HIDE:
            return {
                ...state,
                create_quote_modal: {
                    ...state.create_quote_modal,
                    is_visible: false
                }
            };
        case MODAL_CONTENT_QUOTE_EDIT_SHOW:
            return {
                ...state,
                edit_quote_modal: {
                    ...state.edit_quote_modal,
                    is_visible: true,
                    quote_id: action.payload.quote_id
                }
            };
        case MODAL_CONTENT_QUOTE_EDIT_HIDE:
            return {
                ...state,
                edit_quote_modal: {
                    ...state.edit_quote_modal,
                    is_visible: false,
                    quote_id: null
                }
            };
        case MODAL_CONTENT_TOOL_EDIT_SHOW:
            return {
                ...state,
                edit_tool_modal: {
                    ...state.edit_tool_modal,
                    is_visible: true,
                    tool_id: action.payload.tool_id
                }
            };
        case MODAL_CONTENT_TOOL_EDIT_HIDE:
            return {
                ...state,
                edit_tool_modal: {
                    ...state.edit_tool_modal,
                    is_visible: false,
                    tool_id: null
                }
            };
        case MODAL_CONTENT_GOAL_CREATE_SHOW:
            return {
                ...state,
                create_goal_modal: {
                    ...state.create_goal_modal,
                    is_visible: true
                }
            };
        case MODAL_CONTENT_GOAL_CREATE_HIDE:
            return {
                ...state,
                create_goal_modal: {
                    ...state.create_goal_modal,
                    is_visible: false
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_CREATE_SHOW:
            return {
                ...state,
                create_goal_header_modal: {
                    ...state.create_goal_header_modal,
                    is_visible: true,
                    goal_id: action.payload.goal_id
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_CREATE_HIDE:
            return {
                ...state,
                create_goal_header_modal: {
                    ...state.create_goal_header_modal,
                    is_visible: false,
                    goal_id: null
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_LINK_SHOW:
            return {
                ...state,
                link_goal_header_modal: {
                    ...state.link_goal_header_modal,
                    is_visible: true,
                    goal_header_id: action.payload.goal_header_id,
                    journey_id: action.payload.journey_id
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_LINK_HIDE:
            return {
                ...state,
                link_goal_header_modal: {
                    ...state.link_goal_header_modal,
                    is_visible: false,
                    goal_header_id: null,
                    journey_id: null
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_UNLINK_SHOW:
            return {
                ...state,
                unlink_goal_header_modal: {
                    ...state.unlink_goal_header_modal,
                    is_visible: true,
                    journey_id: action.payload.journey_id
                }
            };
        case MODAL_CONTENT_GOAL_HEADER_UNLINK_HIDE:
            return {
                ...state,
                unlink_goal_header_modal: {
                    ...state.unlink_goal_header_modal,
                    is_visible: false,
                    journey_id: null
                }
            };
        case MODAL_CONTENT_JOURNEY_GOAL_EDIT_SHOW:
            return {
                ...state,
                edit_journey_goal_modal: {
                    ...state.edit_journey_goal_modal,
                    is_visible: true,
                    journey_id: action.payload.journey_id
                }
            };
        case MODAL_CONTENT_JOURNEY_GOAL_EDIT_HIDE:
            return {
                ...state,
                edit_journey_goal_modal: {
                    ...state.edit_journey_goal_modal,
                    is_visible: false,
                    journey_id: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_questionnaire_card: {
                    ...state.edit_content_questionnaire_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_questionnaire_card: {
                    ...state.edit_content_questionnaire_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_JOURNEY_CREATE_SHOW:
            return {
                ...state,
                is_create_journey_modal: {
                    ...state.is_create_journey_modal,
                    is_visible: true,
                    goal_id: action.payload.goal_id
                }
            };
        case MODAL_CONTENT_JOURNEY_CREATE_HIDE:
            return {
                ...state,
                is_create_journey_modal: {
                    ...state.is_create_journey_modal,
                    is_visible: false,
                    goal_id: null
                }
            };
        case MODAL_CONTENT_JOURNEY_EDIT_SHOW:
            return {
                ...state,
                is_edit_journey_modal: {
                    ...state.is_edit_journey_modal,
                    is_visible: true,
                    journey_id: action.payload.journey_id
                }
            };
        case MODAL_CONTENT_JOURNEY_EDIT_HIDE:
            return {
                ...state,
                is_edit_journey_modal: {
                    ...state.is_edit_journey_modal,
                    is_visible: false,
                    journey_id: null
                }
            };
        case MODAL_CONTENT_EXERCISE_CREATE_SHOW:
            return {
                ...state,
                is_create_exercise_modal: {
                    is_visible: true,
                    goal_id: action.payload.goal_id,
                    journey_id: action.payload.journey_id,
                    tool_id: action.payload.tool_id
                }
            };
        case MODAL_CONTENT_EXERCISE_CREATE_HIDE:
            return {
                ...state,
                is_create_exercise_modal: {
                    is_visible: false,
                    goal_id: null,
                    journey_id: null,
                    tool_id: null
                }
            };
        case MODAL_CONTENT_EXERCISE_EDIT_SHOW:
            return {
                ...state,
                is_edit_exercise_modal: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id
                }
            };
        case MODAL_CONTENT_EXERCISE_EDIT_HIDE:
            return {
                ...state,
                is_edit_exercise_modal: {
                    is_visible: false,
                    exercise_id: null
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_COPY_SHOW:
            return {
                ...state,
                copy_content_card: {
                    is_visible: true,
                    from_exercise_id: action.payload.from_exercise_id
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_COPY_HIDE:
            return {
                ...state,
                copy_content_card: {
                    is_visible: false,
                    from_exercise_id: null
                }
            };
        case MODAL_CONTENT_EXERCISE_CHAT_EDIT_SHOW:
            return {
                ...state,
                edit_exercise_chat_modal: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id
                }
            };
        case MODAL_CONTENT_EXERCISE_CHAT_EDIT_HIDE:
            return {
                ...state,
                edit_exercise_chat_modal: {
                    is_visible: false,
                    exercise_id: null
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_CREATE_SHOW:
            return {
                ...state,
                is_create_content_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_CREATE_HIDE:
            return {
                ...state,
                is_create_content_card: {
                    is_visible: false,
                    exercise_id: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_quiz_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_quiz_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_SHOW:
            return {
                ...state,
                add_content_quiz_item_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_HIDE:
            return {
                ...state,
                add_content_quiz_item_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_SHOW:
            return {
                ...state,
                edit_content_quiz_item_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_HIDE:
            return {
                ...state,
                edit_content_quiz_item_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_SHOW:
            return {
                ...state,
                edit_content_card_completion: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_COMPLETION_EDIT_HIDE:
            return {
                ...state,
                edit_content_card_completion: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_SHOW:
            return {
                ...state,
                edit_content_card_reference: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_CARD_REFERENCE_EDIT_HIDE:
            return {
                ...state,
                edit_content_card_reference: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_information_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_INFORMATION_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_information_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_SHOW:
            return {
                ...state,
                is_edit_content_caption_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_summary_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_SUMMARY_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_summary_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_checklist_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_checklist_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_reflection_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_REFLECTION_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_reflection_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_overview_sessions_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_overview_sessions_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_TOOL_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_tool_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_TOOL_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_tool_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_TOOL_CARD_ADD_SHOW:
            return {
                ...state,
                add_content_tool_card_tool: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_TOOL_CARD_ADD_HIDE:
            return {
                ...state,
                add_content_tool_card_tool: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_assessment_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_assessment_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_assessment_result_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_assessment_result_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_SHOW:
            return {
                ...state,
                edit_content_checklist_item_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_HIDE:
            return {
                ...state,
                edit_content_checklist_item_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_CAPTION_CARD_EDIT_HIDE:
            return {
                ...state,
                is_edit_content_caption_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_audio_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_AUDIO_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_audio_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_video_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_VIDEO_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_video_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_task_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_TASK_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_task_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_SHOW:
            return {
                ...state,
                is_edit_content_complete_card_visible: true
            };
        case MODAL_CONTENT_CONTENT_COMPLETE_CARD_EDIT_HIDE:
            return {
                ...state,
                is_edit_content_complete_card_visible: false
            };
        case MODAL_CONTENT_CONTENT_READ_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_read_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_READ_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_read_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_SHOW:
            return {
                ...state,
                add_item_content_document_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_HIDE:
            return {
                ...state,
                add_item_content_document_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_SHOW:
            return {
                ...state,
                edit_content_document_card_text: {
                    ...state.edit_content_document_card_text,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_TEXT_ITEM_HIDE:
            return {
                ...state,
                edit_content_document_card_text: {
                    ...state.edit_content_document_card_text,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_SHOW:
            return {
                ...state,
                edit_content_document_card_image: {
                    ...state.edit_content_document_card_image,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_DOCUMENT_CARD_EDIT_IMAGE_ITEM_HIDE:
            return {
                ...state,
                edit_content_document_card_image: {
                    ...state.edit_content_document_card_image,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_timer_card: {
                    ...state.edit_content_timer_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_TIMER_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_timer_card: {
                    ...state.edit_content_timer_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_input_card: {
                    ...state.edit_content_input_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_INPUT_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_input_card: {
                    ...state.edit_content_input_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_SHOW:
            return {
                ...state,
                add_content_input_card: {
                    ...state.add_content_input_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_INPUT_CARD_ADD_HIDE:
            return {
                ...state,
                add_content_input_card: {
                    ...state.add_content_input_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_SHOW:
            return {
                ...state,
                add_content_quote_card: {
                    ...state.add_content_quote_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUOTE_CARD_ADD_HIDE:
            return {
                ...state,
                add_content_quote_card: {
                    ...state.add_content_quote_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_quote_card: {
                    ...state.edit_content_quote_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_QUOTE_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_quote_card: {
                    ...state.edit_content_quote_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };
        case MODAL_CONTENT_CONTENT_MOVE_CARD_SHOW:
            return {
                ...state,
                move_content_card: {
                    ...state.move_content_card,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_MOVE_CARD_HIDE:
            return {
                ...state,
                move_content_card: {
                    ...state.move_content_card,
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };
        case MODAL_ACCESS_CREATE_EMAIL_ACCESS_SHOW:
            return {
                ...state,
                is_create_email_access_visible: true
            };
        case MODAL_ACCESS_CREATE_EMAIL_ACCESS_HIDE:
            return {
                ...state,
                is_create_email_access_visible: false
            };
        case MODAL_CONTENT_ACTION_EXERCISE_EDIT_SHOW:
            return {
                ...state,
                edit_action_exercise_modal: {
                    ...state.edit_action_exercise_modal,
                    is_visible: true,
                    exercise_id: action.payload.exercise_id
                }
            };
        case MODAL_CONTENT_ACTION_EXERCISE_EDIT_HIDE:
            return {
                ...state,
                edit_action_exercise_modal: {
                    ...state.edit_action_exercise_modal,
                    is_visible: false,
                    exercise_id: null
                }
            };

        case MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_SHOW:
            return {
                ...state,
                add_content_guide_concept_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_HIDE:
            return {
                ...state,
                add_content_guide_concept_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };

        case MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_SHOW:
            return {
                ...state,
                edit_content_guide_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index
                }
            };
        case MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_HIDE:
            return {
                ...state,
                edit_content_guide_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null
                }
            };

        case MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_SHOW:
            return {
                ...state,
                edit_content_guide_concept_card: {
                    is_visible: true,
                    exercise_id: action.payload.exercise_id,
                    card_index: action.payload.card_index,
                    item_index: action.payload.item_index
                }
            };
        case MODAL_CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_HIDE:
            return {
                ...state,
                edit_content_guide_concept_card: {
                    is_visible: false,
                    exercise_id: null,
                    card_index: null,
                    item_index: null
                }
            };

        // asessment
        case MODAL_ASSESSMENT_SOURCE_CREATE_SHOW:
            return {
                ...state,
                create_assessment_source_modal: {
                    ...state.create_assessment_source_modal,
                    is_visible: true
                }
            };
        case MODAL_ASSESSMENT_SOURCE_CREATE_HIDE:
            return {
                ...state,
                create_assessment_source_modal: {
                    ...state.create_assessment_source_modal,
                    is_visible: false
                }
            };
        case MODAL_ASSESSMENT_SOURCE_EDIT_SHOW:
            return {
                ...state,
                edit_assessment_source_modal: {
                    ...state.edit_assessment_source_modal,
                    is_visible: true,
                    assessment_id: action.payload.assessment_id
                }
            };
        case MODAL_ASSESSMENT_SOURCE_EDIT_HIDE:
            return {
                ...state,
                edit_assessment_source_modal: {
                    ...state.edit_assessment_source_modal,
                    is_visible: false,
                    assessment_id: null
                }
            };

        case MODAL_ASSESSMENT_DIMENSION_CREATE_SHOW:
            return {
                ...state,
                create_assessment_dimension_modal: {
                    ...state.create_assessment_dimension_modal,
                    assessment_id: action.payload.assessment_id,
                    is_visible: true
                }
            };
        case MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE:
            return {
                ...state,
                create_assessment_dimension_modal: {
                    ...state.create_assessment_dimension_modal,
                    assessment_id: null,
                    is_visible: false
                }
            };

        case MODAL_ASSESSMENT_DIMENSION_EDIT_SHOW:
            return {
                ...state,
                edit_assessment_dimension_modal: {
                    ...state.edit_assessment_dimension_modal,
                    is_visible: true,
                    assessment_id: action.payload.assessment_id,
                    dimension_id: action.payload.dimension_id
                }
            };
        case MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE:
            return {
                ...state,
                edit_assessment_dimension_modal: {
                    ...state.edit_assessment_dimension_modal,
                    is_visible: false,
                    assessment_id: null,
                    dimension_id: null
                }
            };

        case MODAL_DIMENSION_CATEGORY_CREATE_SHOW:
            return {
                ...state,
                create_dimension_category_modal: {
                    ...state.create_dimension_category_modal,
                    assessment_id: action.payload.assessment_id,
                    dimension_id: action.payload.dimension_id,
                    is_visible: true
                }
            };
        case MODAL_DIMENSION_CATEGORY_CREATE_HIDE:
            return {
                ...state,
                create_dimension_category_modal: {
                    ...state.create_dimension_category_modal,
                    assessment_id: null,
                    dimension_id: null,
                    is_visible: false
                }
            };

        case MODAL_DIMENSION_CATEGORY_EDIT_SHOW:
            return {
                ...state,
                edit_dimension_category_modal: {
                    ...state.edit_dimension_category_modal,
                    is_visible: true,
                    assessment_id: action.payload.assessment_id,
                    dimension_id: action.payload.dimension_id,
                    category_id: action.payload.category_id
                }
            };
        case MODAL_DIMENSION_CATEGORY_EDIT_HIDE:
            return {
                ...state,
                edit_dimension_category_modal: {
                    ...state.edit_dimension_category_modal,
                    is_visible: false,
                    assessment_id: null,
                    dimension_id: null,
                    category_id: null
                }
            };

        case MODAL_DIMENSION_QUESTION_CREATE_SHOW:
            return {
                ...state,
                create_dimension_question_modal: {
                    ...state.create_dimension_question_modal,
                    assessment_id: action.payload.assessment_id,
                    dimension_id: action.payload.dimension_id,
                    is_visible: true
                }
            };
        case MODAL_DIMENSION_QUESTION_CREATE_HIDE:
            return {
                ...state,
                create_dimension_question_modal: {
                    ...state.create_dimension_question_modal,
                    assessment_id: null,
                    dimension_id: null,
                    is_visible: false
                }
            };

        case MODAL_DIMENSION_QUESTION_EDIT_SHOW:
            return {
                ...state,
                edit_dimension_question_modal: {
                    ...state.edit_dimension_question_modal,
                    is_visible: true,
                    assessment_id: action.payload.assessment_id,
                    dimension_id: action.payload.dimension_id,
                    question_id: action.payload.question_id
                }
            };
        case MODAL_DIMENSION_QUESTION_EDIT_HIDE:
            return {
                ...state,
                edit_dimension_question_modal: {
                    ...state.edit_dimension_question_modal,
                    is_visible: false,
                    assessment_id: null,
                    dimension_id: null,
                    question_id: null
                }
            };

        case MODAL_DIMENSION_ANSWER_CREATE_SHOW:
            return {
                ...state,
                create_dimension_answer_modal: {
                    ...state.create_dimension_answer_modal,
                    assessment_id: action.payload.assessment_id,
                    is_visible: true
                }
            };
        case MODAL_DIMENSION_ANSWER_CREATE_HIDE:
            return {
                ...state,
                create_dimension_answer_modal: {
                    ...state.create_dimension_answer_modal,
                    assessment_id: null,
                    is_visible: false
                }
            };

        case MODAL_ANSWER_ADD_SHOW:
            return {
                ...state,
                add_answer_modal: {
                    ...state.add_answer_modal,
                    assessment_id: action.payload.assessment_id,
                    answer_id: action.payload.answer_id,
                    is_visible: true
                }
            };
        case MODAL_ANSWER_ADD_HIDE:
            return {
                ...state,
                add_answer_modal: {
                    ...state.add_answer_modal,
                    assessment_id: null,
                    answer_id: null,
                    is_visible: false
                }
            };

        case MODAL_ANSWER_EDIT_SHOW:
            return {
                ...state,
                edit_answer_modal: {
                    ...state.edit_answer_modal,
                    is_visible: true,
                    assessment_id: action.payload.assessment_id,
                    answer_id: action.payload.answer_id
                }
            };
        case MODAL_ANSWER_EDIT_HIDE:
            return {
                ...state,
                edit_answer_modal: {
                    ...state.edit_answer_modal,
                    is_visible: false,
                    assessment_id: null,
                    answer_id: null
                }
            };
        // EAP Focus
        case MODAL_EAP_FOCUS_CREATE_SHOW:
            return {
                ...state,
                create_eap_focus_modal: {
                    ...state.create_eap_focus_modal,
                    is_visible: true
                }
            };
        case MODAL_EAP_FOCUS_CREATE_HIDE:
            return {
                ...state,
                create_eap_focus_modal: {
                    ...state.create_eap_focus_modal,
                    is_visible: false
                }
            };

        case MODAL_EAP_FOCUS_EDIT_SHOW:
            return {
                ...state,
                edit_eap_focus_modal: {
                    ...state.edit_eap_focus_modal,
                    is_visible: true,
                    focus_id: action.payload.focus_id
                }
            };
        case MODAL_EAP_FOCUS_EDIT_HIDE:
            return {
                ...state,
                edit_eap_focus_modal: {
                    ...state.edit_eap_focus_modal,
                    is_visible: false,
                    focus_id: null
                }
            };

        // EAP Framework
        case MODAL_EAP_FRAMEWORK_CREATE_SHOW:
            return {
                ...state,
                create_eap_framework_modal: {
                    ...state.create_eap_framework_modal,
                    is_visible: true
                }
            };
        case MODAL_EAP_FRAMEWORK_CREATE_HIDE:
            return {
                ...state,
                create_eap_framework_modal: {
                    ...state.create_eap_framework_modal,
                    is_visible: false
                }
            };

        case MODAL_EAP_FRAMEWORK_EDIT_SHOW:
            return {
                ...state,
                edit_eap_framework_modal: {
                    ...state.edit_eap_framework_modal,
                    is_visible: true,
                    framework_id: action.payload.framework_id
                }
            };
        case MODAL_EAP_FRAMEWORK_EDIT_HIDE:
            return {
                ...state,
                edit_eap_framework_modal: {
                    ...state.edit_eap_framework_modal,
                    is_visible: false,
                    framework_id: null
                }
            };

        // EAP Speciality
        case MODAL_EAP_SPECIALITY_CREATE_SHOW:
            return {
                ...state,
                create_eap_speciality_modal: {
                    ...state.create_eap_speciality_modal,
                    is_visible: true
                }
            };
        case MODAL_EAP_SPECIALITY_CREATE_HIDE:
            return {
                ...state,
                create_eap_speciality_modal: {
                    ...state.create_eap_speciality_modal,
                    is_visible: false
                }
            };

        case MODAL_EAP_SPECIALITY_EDIT_SHOW:
            return {
                ...state,
                edit_eap_speciality_modal: {
                    ...state.edit_eap_speciality_modal,
                    is_visible: true,
                    speciality_id: action.payload.speciality_id
                }
            };
        case MODAL_EAP_SPECIALITY_EDIT_HIDE:
            return {
                ...state,
                edit_eap_speciality_modal: {
                    ...state.edit_eap_speciality_modal,
                    is_visible: false,
                    speciality_id: null
                }
            };

        // Customer/Organization
        case MODAL_CUSTOMER_ORGANIZATION_CREATE_SHOW:
            return {
                ...state,
                create_customer_organization_modal: {
                    ...state.create_customer_organization_modal,
                    is_visible: true
                }
            };
        case MODAL_CUSTOMER_ORGANIZATION_CREATE_HIDE:
            return {
                ...state,
                create_customer_organization_modal: {
                    ...state.create_customer_organization_modal,
                    is_visible: false
                }
            };

        case MODAL_CUSTOMER_ORGANIZATION_EDIT_SHOW:
            return {
                ...state,
                edit_customer_organization_modal: {
                    ...state.edit_customer_organization_modal,
                    is_visible: true,
                    organization_id: action.payload.organization_id
                }
            };
        case MODAL_CUSTOMER_ORGANIZATION_EDIT_HIDE:
            return {
                ...state,
                edit_customer_organization_modal: {
                    ...state.edit_customer_organization_modal,
                    is_visible: false,
                    organization_id: null
                }
            };

        case MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_SHOW:
            return {
                ...state,
                customer_deployment_modal: {
                    is_visible: true
                }
            };
        case MODAL_CUSTOMER_ORGANIZATION_DEPLOYMENT_HIDE:
            return {
                ...state,
                customer_deployment_modal: {
                    is_visible: false
                }
            };
        default:
            return { ...state };
    }
}
