import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import ReactDropzone from 'react-dropzone';
import './index.css';
import ContentService from '../../../../../service/content';

interface Props {
    card?: object;
    exercise_id?: number;
    is_uploading: boolean;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditVideoCardModal extends React.Component<Props> {
    state = {
        video_url: null,
        title_value: null,
        text_value: null,
        is_immersive: false
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                video_url: card.content.video_url,
                title_value: card.content.title != null ? card.content.title : null,
                text_value: card.content.text != null ? card.content.text : null,
                is_immersive: card.content.is_immersive != null ? card.content.is_immersive : null
            });
        }
    }

    onDrop = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadExerciseVideo(this.props.exercise_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        video_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.video_url != null) params['video_url'] = this.state.video_url;
        if (this.state.title_value != null) params['title'] = this.state.title_value;
        if (this.state.text_value != null) params['text'] = this.state.text_value;
        if (this.state.is_immersive != null) params['is_immersive'] = this.state.is_immersive;

        this.props.onEdit(params);
        this.resetState();
    };

    resetState() {
        this.setState({
            video_url: null,
            title_value: null,
            text_value: null,
            is_immersive: false
        });
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'video_url':
                this.setState({
                    video_url: inputValue
                });
                break;
            case 'title':
                this.setState({
                    title_value: inputValue
                });
                break;
            case 'text':
                this.setState({
                    text_value: inputValue
                });
                break;
        }
    };

    onReferenceChanged = (value) => {
        this.setState({ selected_reference: value });
    };

    render() {
        return (
            <Modal
                id="edit-video-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="edit-video-modal-header">Edit Video Card</Modal.Header>
                <Modal.Content id="edit-video-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <div id="video-drop">
                                <ReactDropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div id={isDragActive ? 'audio-drop-inner-selected' : 'audio-drop-inner'} {...getRootProps()}>
                                            {this.state.is_uploading == true ? (
                                                <div class="ui active inverted dimmer">
                                                    <div class="ui loader"></div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <input {...getInputProps()} />
                                                    <p>Drop the video file here, or click to select file</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ReactDropzone>
                            </div>
                            <Form.Input
                                value={this.state.video_url || ''}
                                type="text"
                                name="video_url"
                                placeholder="Video URL"
                                onChange={this.handleInputChange}
                            />
                            <div id={'title_value'}>
                                <Form.Input
                                    value={this.state.title_value || ''}
                                    type="text"
                                    name="title"
                                    placeholder="Title"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div id={'text_value'}>
                                <Form.Input
                                    value={this.state.text_value || ''}
                                    type="text"
                                    name="text"
                                    placeholder="Text"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="is_immersive"
                                        checked={this.state.is_immersive || false}
                                        onChange={() => {
                                            this.setState({
                                                is_immersive: !this.state.is_immersive
                                            });
                                        }}
                                    />
                                    <label>Is Immersive</label>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-video-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button
                        disabled={
                            this.state.video_url == null &&
                            this.state.text_value == null &&
                            this.state.title_value == null &&
                            this.state.is_immersive == null
                        }
                        onClick={this.handleSubmit}
                        color="teal"
                    >
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditVideoCardModal;
