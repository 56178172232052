import { SETTING_SET_LOCALE } from '../constants/setting';

const initialState = {
    locale: 'en'
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SETTING_SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale
            };
    }
    return { ...state };
}
