import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import ReactDropzone from 'react-dropzone';
import ContentService from '../../../../service/content';

import './index.css';

interface Props {
    locale: string;
    tool?: object;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class EditToolModal extends React.Component<Props> {
    state = {
        is_uploading: false,
        title: null,
        description: null,
        metadata: null,
        emoji: null,
        icon_name: null,
        icon_url: null,
        cover_url: null,
        time: null,
        has_audio: null,
        has_read: null,
        has_input: null,
        has_activity: null,
        has_background: null
    };

    componentDidMount() {
        const { tool } = this.props;
        if (tool != null) {
            this.setState({
                tool_id: tool.id,
                title: tool.title,
                description: tool.description,
                metadata: tool.metadata,
                emoji: tool.emoji,
                icon_name: tool.icon_name,
                icon_url: tool.icon_url,
                cover_url: tool.cover_url,
                time: tool.time,
                has_audio: tool.has_audio,
                has_read: tool.has_read,
                has_input: tool.has_input,
                has_activity: tool.has_activity,
                has_background: tool.has_background
            });
        }
    }

    onDrop = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadToolImage(this.state.tool_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        cover_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    resetState = () => {
        this.setState({
            is_uploading: false,
            tool_id: null,
            title: null,
            description: null,
            metadata: null,
            emoji: null,
            icon_name: null,
            icon_url: null,
            cover_url: null,
            time: null,
            has_audio: null,
            has_read: null,
            has_input: null,
            has_activity: null,
            has_background: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'description':
                this.setState({
                    description: inputValue
                });
                break;
            case 'metadata':
                this.setState({
                    metadata: inputValue
                });
                break;
            case 'emoji':
                this.setState({
                    emoji: inputValue
                });
                break;
            case 'icon_name':
                this.setState({
                    icon_name: inputValue
                });
                break;
            case 'icon_url':
                this.setState({
                    icon_url: inputValue
                });
                break;
            case 'cover_url':
                this.setState({
                    cover_url: inputValue
                });
                break;
            case 'time':
                this.setState({
                    time: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.description != null) params['description'] = this.state.description;
        if (this.state.metadata != null) params['metadata'] = this.state.metadata;
        if (this.state.emoji != null) params['emoji'] = this.state.emoji;
        if (this.state.icon_name != null) params['icon_name'] = this.state.icon_name;
        if (this.state.icon_url != null) params['icon_url'] = this.state.icon_url;
        if (this.state.cover_url != null) params['cover_url'] = this.state.cover_url;
        if (this.state.time != null) params['time'] = parseInt(this.state.time);
        if (this.state.has_audio != null) params['has_audio'] = this.state.has_audio;
        if (this.state.has_read != null) params['has_read'] = this.state.has_read;
        if (this.state.has_input != null) params['has_input'] = this.state.has_input;
        if (this.state.has_activity != null) params['has_activity'] = this.state.has_activity;
        if (this.state.has_background != null) params['has_background'] = this.state.has_background;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { locale, isOpen, isLoading } = this.props;
        return (
            <Modal
                id="edit-tool-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="edit-tool-modal-header">Edit Content</Modal.Header>
                <Modal.Content id="edit-tool-modal-content">
                    <div id={'image-drop'}>
                        <div>
                            <ReactDropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div
                                        id={isDragActive ? 'cover-image-drop-inner-selected' : 'cover-image-drop-inner'}
                                        {...getRootProps()}
                                    >
                                        {this.state.is_uploading == true ? (
                                            <div class="ui active inverted dimmer">
                                                <div class="ui loader"></div>
                                            </div>
                                        ) : (
                                            <div>
                                                <input {...getInputProps()} />
                                                <p>Drop the cover image here, or click to select image</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </ReactDropzone>
                        </div>
                    </div>
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.title || ''}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.description || ''}
                                rows="2"
                                type="text"
                                name="description"
                                placeholder="Description"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.metadata || ''}
                                type="text"
                                name="metadata"
                                placeholder="Metadata"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.emoji || ''}
                                type="text"
                                name="emoji"
                                placeholder="Emoji Icon"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.icon_name || ''}
                                type="text"
                                name="icon_name"
                                placeholder="Icon (Font Awesome)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.cover_url || ''}
                                type="text"
                                name="cover_url"
                                placeholder="Cover URL (Optional)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.time || ''}
                                type="text"
                                name="time"
                                placeholder="Time (seconds)"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_audio"
                                        checked={this.state.has_audio || false}
                                        onChange={() => {
                                            this.setState({
                                                has_audio: !this.state.has_audio
                                            });
                                        }}
                                    />
                                    <label>Has Audio</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_read"
                                        checked={this.state.has_read || false}
                                        onChange={() => {
                                            this.setState({
                                                has_read: !this.state.has_read
                                            });
                                        }}
                                    />
                                    <label>Has Read</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_input"
                                        checked={this.state.has_input || false}
                                        onChange={() => {
                                            this.setState({
                                                has_input: !this.state.has_input
                                            });
                                        }}
                                    />
                                    <label>Has Input</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_activity"
                                        checked={this.state.has_activity || false}
                                        onChange={() => {
                                            this.setState({
                                                has_activity: !this.state.has_activity
                                            });
                                        }}
                                    />
                                    <label>Has Activity</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_background"
                                        checked={this.state.has_background || false}
                                        onChange={() => {
                                            this.setState({
                                                has_background: !this.state.has_background
                                            });
                                        }}
                                    />
                                    <label>Meant for Background?</label>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-tool-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Content
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditToolModal;
