// material-ui
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/neurum-logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <img src={logo} alt="Neurum Health" width="172" />;
};

export default Logo;
