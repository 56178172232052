import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducer from './redux/reducers';
import saga from './redux/sagas';
import { history } from './history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history), sagaMiddleware];

const persistConfig = {
    key: 'neurum',
    storage
};

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistedReducer = persistReducer(persistConfig, reducer); // create a persisted reducer
const store = createStore(persistedReducer, initialState, composedEnhancers);

sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { store, persistor };
