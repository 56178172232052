import { call, select, put, takeLatest, take, takeEvery } from 'redux-saga/effects';
import {
    MODAL_ANSWER_ADD_HIDE,
    MODAL_ANSWER_EDIT_HIDE,
    MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE,
    MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE,
    MODAL_ASSESSMENT_SOURCE_CREATE_HIDE,
    MODAL_ASSESSMENT_SOURCE_EDIT_HIDE,
    MODAL_DIMENSION_ANSWER_CREATE_HIDE,
    MODAL_DIMENSION_CATEGORY_CREATE_HIDE,
    MODAL_DIMENSION_CATEGORY_EDIT_HIDE,
    MODAL_DIMENSION_QUESTION_CREATE_HIDE,
    MODAL_DIMENSION_QUESTION_EDIT_HIDE
} from '../constants/view/modal';
import AssessmentService from '../../service/assessment';
import {
    ASSESSMENT_ANSWER_ADD_COMPLETED,
    ASSESSMENT_ANSWER_ADD_REQUEST,
    ASSESSMENT_ANSWER_DELETE_COMPLETED,
    ASSESSMENT_ANSWER_DELETE_REQUEST,
    ASSESSMENT_ANSWER_EDIT_COMPLETED,
    ASSESSMENT_ANSWER_EDIT_REQUEST,
    ASSESSMENT_CATEGORY_CREATE_COMPLETED,
    ASSESSMENT_CATEGORY_CREATE_REQUEST,
    ASSESSMENT_CATEGORY_DELETE_COMPLETED,
    ASSESSMENT_CATEGORY_DELETE_REQUEST,
    ASSESSMENT_CATEGORY_EDIT_COMPLETED,
    ASSESSMENT_CATEGORY_EDIT_REQUEST,
    ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED,
    ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_CREATE_COMPLETED,
    ASSESSMENT_DIMENSION_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_DELETE_COMPLETED,
    ASSESSMENT_DIMENSION_DELETE_REQUEST,
    ASSESSMENT_DIMENSION_EDIT_COMPLETED,
    ASSESSMENT_DIMENSION_EDIT_REQUEST,
    ASSESSMENT_DIMENSION_MODULE_CREATE_COMPLETED,
    ASSESSMENT_DIMENSION_MODULE_CREATE_REQUEST,
    ASSESSMENT_DIMENSION_MODULE_REMOVE_COMPLETED,
    ASSESSMENT_DIMENSION_MODULE_REMOVE_REQUEST,
    ASSESSMENT_QUESTION_CREATE_COMPLETED,
    ASSESSMENT_QUESTION_CREATE_REQUEST,
    ASSESSMENT_QUESTION_DELETE_COMPLETED,
    ASSESSMENT_QUESTION_DELETE_REQUEST,
    ASSESSMENT_QUESTION_EDIT_COMPLETED,
    ASSESSMENT_QUESTION_EDIT_REQUEST,
    ASSESSMENT_SOURCE_CREATE_COMPLETED,
    ASSESSMENT_SOURCE_CREATE_REQUESTED,
    ASSESSMENT_SOURCE_DELETE_COMPLETED,
    ASSESSMENT_SOURCE_DELETE_REQUESTED,
    ASSESSMENT_SOURCE_EDIT_COMPLETED,
    ASSESSMENT_SOURCE_EDIT_REQUESTED,
    ASSESSMENT_SOURCE_FETCH_COMPLETED,
    ASSESSMENT_SOURCE_FETCH_REQUESTED,
    ASSESSMENT_SOURCE_GET_COMPLETED,
    ASSESSMENT_SOURCE_GET_REQUESTED
} from 'redux/constants/assessment';

function* getAssessment(action) {
    try {
        const result = yield call(AssessmentService.getAssessmentSourceByUuid, action.payload.assessment_id);
        if (result) {
            const { assessment } = result;
            yield put({
                type: ASSESSMENT_SOURCE_GET_COMPLETED,
                payload: { assessment }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* fetchAssessments(action) {
    try {
        const { payload } = action;
        const { source_type } = payload;
        const result = yield call(AssessmentService.fetchAssessmentSources, source_type);
        if (result) {
            const { assessments } = result;
            yield put({
                type: ASSESSMENT_SOURCE_FETCH_COMPLETED,
                payload: { assessments }
            });
        } else {
            yield put({
                type: ASSESSMENT_SOURCE_FETCH_COMPLETED,
                payload: { assessments: [] }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
        yield put({
            type: ASSESSMENT_SOURCE_FETCH_COMPLETED,
            payload: { assessments: [] }
        });
    }
}

function* createAssessment(action) {
    try {
        const results = yield call(AssessmentService.createAssessmentSource, action.payload.params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_ASSESSMENT_SOURCE_CREATE_HIDE });
            yield put({ type: ASSESSMENT_SOURCE_CREATE_COMPLETED });
            // Auto Fetch
            yield put({
                type: ASSESSMENT_SOURCE_FETCH_REQUESTED,
                payload: {}
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* editAssessment(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_ASSESSMENT_SOURCE_EDIT_HIDE });
            yield put({ type: ASSESSMENT_SOURCE_EDIT_COMPLETED });
            return;
        }
        const results = yield call(AssessmentService.editAssessmentSource, params);
        if (results != null) {
            // Close Modal
            yield put({ type: MODAL_ASSESSMENT_SOURCE_EDIT_HIDE });
            yield put({ type: ASSESSMENT_SOURCE_EDIT_COMPLETED });
            // Auto Fetch
            yield put({
                type: ASSESSMENT_SOURCE_GET_REQUESTED,
                payload: {
                    assessment_id: params['assessment_id']
                }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* deleteAssessment(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: ASSESSMENT_SOURCE_DELETE_COMPLETED });
            return;
        }
        yield call(AssessmentService.deleteAssessmentSource, params);
        // Close Modal
        yield put({ type: ASSESSMENT_SOURCE_DELETE_COMPLETED });
        // Auto Fetch
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* createAssessmentDimension(action) {
    try {
        const {
            payload: { assessment_id, params }
        } = action;
        if (!assessment_id || !params) {
            yield put({ type: MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE });
            yield put({ type: ASSESSMENT_DIMENSION_CREATE_COMPLETED });
            return;
        }
        params['assessment_id'] = assessment_id;
        yield call(AssessmentService.createAssessmentDimension, params);
        yield put({ type: MODAL_ASSESSMENT_DIMENSION_CREATE_HIDE });
        yield put({ type: ASSESSMENT_DIMENSION_CREATE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* editAssessmentDimension(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE });
            yield put({ type: ASSESSMENT_DIMENSION_EDIT_COMPLETED });
            return;
        }
        const assessment_id = params['assessment_id'];
        yield call(AssessmentService.editAssessmentDimension, params);
        yield put({ type: MODAL_ASSESSMENT_DIMENSION_EDIT_HIDE });
        yield put({ type: ASSESSMENT_DIMENSION_EDIT_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to edit assessment dimension: ', e);
    }
}

function* deleteAssessmentDimension(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: ASSESSMENT_DIMENSION_DELETE_COMPLETED });
            return;
        }
        const assessment_id = params['assessment_id'];
        yield call(AssessmentService.deleteAssessmentDimension, params);
        yield put({ type: ASSESSMENT_DIMENSION_DELETE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to delete assessment dimension: ', e);
    }
}

function* createAssessmentDimensionModule(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: ASSESSMENT_DIMENSION_MODULE_CREATE_COMPLETED });
            return;
        }
        yield call(AssessmentService.createAssessmentDimensionModule, params);
        yield put({ type: ASSESSMENT_DIMENSION_MODULE_CREATE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* removeAssessmentDimensionModule(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: ASSESSMENT_DIMENSION_MODULE_REMOVE_COMPLETED });
            return;
        }
        yield call(AssessmentService.removeAssessmentDimensionModule, params);
        yield put({ type: ASSESSMENT_DIMENSION_MODULE_REMOVE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* createAssessmentDimensionCategory(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_DIMENSION_CATEGORY_CREATE_HIDE });
            yield put({ type: ASSESSMENT_CATEGORY_CREATE_COMPLETED });
            return;
        }
        yield call(AssessmentService.createAssessmentDimensionCategory, params);
        yield put({ type: MODAL_DIMENSION_CATEGORY_CREATE_HIDE });
        yield put({ type: ASSESSMENT_CATEGORY_CREATE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* editAssessmentDimensionCategory(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_DIMENSION_CATEGORY_EDIT_HIDE });
            yield put({ type: ASSESSMENT_CATEGORY_EDIT_COMPLETED });
            return;
        }
        yield call(AssessmentService.editAssessmentDimensionCategory, params);
        yield put({ type: MODAL_DIMENSION_CATEGORY_EDIT_HIDE });
        yield put({ type: ASSESSMENT_CATEGORY_EDIT_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to edit assessment dimension: ', e);
    }
}

function* deleteAssessmentDimensionCategory(action) {
    try {
        const {
            payload: { params }
        } = action;
        const { assessment_id, dimension_id, category_id } = params;
        if (!assessment_id || !dimension_id || !category_id) {
            yield put({ type: ASSESSMENT_CATEGORY_DELETE_COMPLETED });
            return;
        }
        yield call(AssessmentService.deleteAssessmentDimensionCategory, params);
        yield put({ type: ASSESSMENT_CATEGORY_DELETE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: assessment_id
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to delete assessment dimension: ', e);
    }
}

function* createAssessmentDimensionQuestion(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_DIMENSION_QUESTION_CREATE_HIDE });
            yield put({ type: ASSESSMENT_QUESTION_CREATE_COMPLETED });
            return;
        }
        yield call(AssessmentService.createAssessmentDimensionQuestion, params);
        yield put({ type: MODAL_DIMENSION_QUESTION_CREATE_HIDE });
        yield put({ type: ASSESSMENT_QUESTION_CREATE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* editAssessmentDimensionQuestion(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_DIMENSION_QUESTION_EDIT_HIDE });
            yield put({ type: ASSESSMENT_QUESTION_EDIT_COMPLETED });
            return;
        }
        yield call(AssessmentService.editAssessmentDimensionQuestion, params);
        yield put({ type: MODAL_DIMENSION_QUESTION_EDIT_HIDE });
        yield put({ type: ASSESSMENT_QUESTION_EDIT_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* deleteAssessmentDimensionQuestion(action) {
    try {
        const {
            payload: { params }
        } = action;
        const { assessment_id, dimension_id, question_id } = params;
        if (!assessment_id || !dimension_id || !question_id) {
            yield put({ type: ASSESSMENT_QUESTION_DELETE_COMPLETED });
            return;
        }
        yield call(AssessmentService.deleteAssessmentDimensionQuestion, params);
        yield put({ type: ASSESSMENT_QUESTION_DELETE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: assessment_id
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to delete assessment dimension: ', e);
    }
}

function* createAssessmentDimensionAnswer(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_DIMENSION_ANSWER_CREATE_HIDE });
            yield put({ type: ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED });
            return;
        }
        yield call(AssessmentService.createAssessmentDimensionAnswer, params);
        yield put({ type: MODAL_DIMENSION_ANSWER_CREATE_HIDE });
        yield put({ type: ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED });
        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* addAssessmentAnswer(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_ANSWER_ADD_HIDE });
            yield put({ type: ASSESSMENT_ANSWER_ADD_COMPLETED });
            return;
        }
        yield call(AssessmentService.addAssessmentDimensionAnswer, params);
        yield put({ type: MODAL_ANSWER_ADD_HIDE });
        yield put({ type: ASSESSMENT_ANSWER_ADD_COMPLETED });

        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to create assessment dimension: ', e);
    }
}

function* editAssessmentAnswer(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: MODAL_ANSWER_EDIT_HIDE });
            yield put({ type: ASSESSMENT_ANSWER_EDIT_COMPLETED });
            return;
        }
        yield call(AssessmentService.editAssessmentDimensionAnswer, params);
        yield put({ type: MODAL_ANSWER_EDIT_HIDE });
        yield put({ type: ASSESSMENT_ANSWER_EDIT_COMPLETED });

        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to edit assessment dimension: ', e);
    }
}

function* deleteAssessmentAnswer(action) {
    try {
        const {
            payload: { params }
        } = action;
        if (!params) {
            yield put({ type: ASSESSMENT_ANSWER_DELETE_COMPLETED });
            return;
        }
        yield call(AssessmentService.deleteAssessmentDimensionAnswer, params);
        yield put({ type: ASSESSMENT_ANSWER_DELETE_COMPLETED });

        yield put({
            type: ASSESSMENT_SOURCE_GET_REQUESTED,
            payload: {
                assessment_id: params['assessment_id']
            }
        });
    } catch (e) {
        console.error('[Assessment Saga] Failed to delete assessment dimension: ', e);
    }
}

function* AssessmentSaga() {
    yield takeLatest(ASSESSMENT_SOURCE_CREATE_REQUESTED, createAssessment);
    yield takeLatest(ASSESSMENT_SOURCE_FETCH_REQUESTED, fetchAssessments);
    yield takeLatest(ASSESSMENT_SOURCE_EDIT_REQUESTED, editAssessment);
    yield takeLatest(ASSESSMENT_SOURCE_GET_REQUESTED, getAssessment);
    yield takeLatest(ASSESSMENT_SOURCE_DELETE_REQUESTED, deleteAssessment);

    yield takeLatest(ASSESSMENT_DIMENSION_CREATE_REQUEST, createAssessmentDimension);
    yield takeLatest(ASSESSMENT_DIMENSION_EDIT_REQUEST, editAssessmentDimension);
    yield takeLatest(ASSESSMENT_DIMENSION_DELETE_REQUEST, deleteAssessmentDimension);

    yield takeLatest(ASSESSMENT_DIMENSION_MODULE_CREATE_REQUEST, createAssessmentDimensionModule);
    yield takeLatest(ASSESSMENT_DIMENSION_MODULE_REMOVE_REQUEST, removeAssessmentDimensionModule);

    yield takeLatest(ASSESSMENT_CATEGORY_CREATE_REQUEST, createAssessmentDimensionCategory);
    yield takeLatest(ASSESSMENT_CATEGORY_EDIT_REQUEST, editAssessmentDimensionCategory);
    yield takeLatest(ASSESSMENT_CATEGORY_DELETE_REQUEST, deleteAssessmentDimensionCategory);

    yield takeLatest(ASSESSMENT_QUESTION_CREATE_REQUEST, createAssessmentDimensionQuestion);
    yield takeLatest(ASSESSMENT_QUESTION_EDIT_REQUEST, editAssessmentDimensionQuestion);
    yield takeLatest(ASSESSMENT_QUESTION_DELETE_REQUEST, deleteAssessmentDimensionQuestion);

    yield takeLatest(ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST, createAssessmentDimensionAnswer);
    yield takeLatest(ASSESSMENT_ANSWER_ADD_REQUEST, addAssessmentAnswer);
    yield takeLatest(ASSESSMENT_ANSWER_EDIT_REQUEST, editAssessmentAnswer);
    yield takeLatest(ASSESSMENT_ANSWER_DELETE_REQUEST, deleteAssessmentAnswer);
}

export default AssessmentSaga;
