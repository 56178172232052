import React, { useState } from 'react';

import './index.css';
import { Image } from 'semantic-ui-react';

const ProgressSteps = ({ steps }) => {
    const [activeStep, setActiveStep] = useState(1);

    const nextStep = () => {
        if (activeStep < totalSteps) {
            setActiveStep(activeStep + 1);
        }
    };

    const prevStep = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);
        }
    };

    const totalSteps = steps.length;

    const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;
    const currentStep = steps[activeStep - 1];
    return (
        <div className="progress-steps-main-container">
            <div className="progress-steps-step-container" data-width={width}>
                {steps.map(({ step, label }) => (
                    <div className="progress-steps-step-wrapper" key={step}>
                        <div
                            className="progress-steps-step-style"
                            style={{
                                border: `3px solid ${activeStep >= step ? '#4A154B' : '#F3E7F3'}`
                            }}
                        >
                            {activeStep > step ? (
                                <div className="progress-steps-check-mark">L</div>
                            ) : (
                                <div className="progress-steps-step-count">{step}</div>
                            )}
                        </div>
                        <div className="progress-steps-steps-label-container">
                            <div className="progress-steps-step-label" key={step}>
                                {label}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {currentStep != null && (
                <div className="progress-steps-current-step-container">
                    <div className="progess-steps-current-step-title">
                        {activeStep}. {currentStep.title}
                    </div>
                    <Image id={`curent-step-image-${activeStep}`} src={currentStep.image} />
                </div>
            )}
            <div className="progress-steps-buttons-container">
                <div className={`progress-steps-button-style ${activeStep === 1 ? 'btn-disabled' : 'btn-active'}`} onClick={prevStep}>
                    Previous
                </div>
                <div
                    className={`progress-steps-button-style ${activeStep === totalSteps ? 'btn-disabled' : 'btn-active'}`}
                    onClick={nextStep}
                >
                    Next
                </div>
            </div>
        </div>
    );
};

export default ProgressSteps;
