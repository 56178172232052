// User Feedback
export const CONTENT_USER_FEEDBACK_REQUESTED = 'content/USER_FEEDBACK_REQUESTED';
export const CONTENT_USER_FEEDBACK_IS_LOADING = 'content/USER_FEEDBACK_IS_LOADING';
export const CONTENT_USER_FEEDBACK_NOT_LOADING = 'content/USER_FEEDBACK_NOT_LOADING';
export const CONTENT_USER_FEEDBACK_FETCHED = 'content/USER_FEEDBACK_FETCHED';

// Context
export const CONTENT_CONTEXT_FETCH_REQUESTED = 'content/CONTEXT_FETCH_REQUESTED';
export const CONTENT_CONTEXT_IS_LOADING = 'content/CONTEXT_IS_LOADING';
export const CONTENT_CONTEXT_NOT_LOADING = 'content/CONTEXT_NOT_LOADING';
export const CONTENT_CONTEXT_FETCHED = 'content/CONTEXT_FETCHED';

// Technique
export const CONTENT_TECHNIQUE_FETCH_REQUESTED = 'content/TECHNIQUE_FETCH_REQUESTED';
export const CONTENT_TECHNIQUE_IS_LOADING = 'content/TECHNIQUE_IS_LOADING';
export const CONTENT_TECHNIQUE_NOT_LOADING = 'content/TECHNIQUE_NOT_LOADING';
export const CONTENT_TECHNIQUE_FETCHED = 'content/TECHNIQUE_FETCHED';

// Intention
export const CONTENT_INTENTION_FETCH_REQUESTED = 'content/INTENTION_FETCH_REQUESTED';
export const CONTENT_INTENTION_IS_LOADING = 'content/INTENTION_IS_LOADING';
export const CONTENT_INTENTION_NOT_LOADING = 'content/INTENTION_NOT_LOADING';
export const CONTENT_INTENTION_FETCHED = 'content/INTENTION_FETCHED';

// Category
export const CONTENT_CATEGORY_FETCH_REQUESTED = 'content/CATEGORY_FETCH_REQUESTED';
export const CONTENT_CATEGORY_IS_LOADING = 'content/CATEGORY_IS_LOADING';
export const CONTENT_CATEGORY_NOT_LOADING = 'content/CATEGORY_NOT_LOADING';
export const CONTENT_CATEGORY_FETCHED = 'content/CATEGORY_FETCHED';

// Category Header
export const CONTENT_CATEGORY_HEADER_FETCH_REQUESTED = 'content/CATEGORY_HEADER_FETCH_REQUESTED';
export const CONTENT_CATEGORY_HEADER_IS_LOADING = 'content/CATEGORY_HEADER_IS_LOADING';
export const CONTENT_CATEGORY_HEADER_NOT_LOADING = 'content/CATEGORY_HEADER_NOT_LOADING';
export const CONTENT_CATEGORY_HEADER_FETCHED = 'content/CATEGORY_HEADER_FETCHED';

// Problem
export const CONTENT_PROBLEM_FETCH_REQUESTED = 'content/PROBLEM_FETCH_REQUESTED';
export const CONTENT_PROBLEM_IS_LOADING = 'content/PROBLEM_IS_LOADING';
export const CONTENT_PROBLEM_NOT_LOADING = 'content/PROBLEM_NOT_LOADING';
export const CONTENT_PROBLEM_FETCHED = 'content/PROBLEM_FETCHED';

// Skill
export const CONTENT_SKILL_FETCH_REQUESTED = 'content/SKILL_FETCH_REQUESTED';
export const CONTENT_SKILL_IS_LOADING = 'content/SKILL_IS_LOADING';
export const CONTENT_SKILL_NOT_LOADING = 'content/SKILL_NOT_LOADING';
export const CONTENT_SKILL_FETCHED = 'content/SKILL_FETCHED';

// Tools
export const CONTENT_TOOL_GET_REQUESTED = 'content/TOOL_GET_REQUESTED';
export const CONTENT_TOOL_FETCH_REQUESTED = 'content/TOOL_FETCH_REQUESTED';
export const CONTENT_TOOL_CREATE_REQUESTED = 'content/TOOL_CREATE_REQUESTED';
export const CONTENT_TOOL_DELETE_REQUESTED = 'content/TOOL_DELETE_REQUESTED';
export const CONTENT_TOOL_DELETE_COMPLETED = 'content/TOOL_DELETE_COMPLETED';
export const CONTENT_TOOL_EDIT_REQUESTED = 'content/TOOL_EDIT_REQUESTED';
export const CONTENT_TOOL_SET_TYPE_REQUESTED = 'CONTENT/TOOL_SET_TYPE_REQUESTED';
export const CONTENT_TOOL_TAG_INTENTION_ADD_REQUESTED = 'content/TOOL_TAG_INTENTION_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_INTENTION_REMOVE_REQUESTED = 'content/TOOL_TAG_INTENTION_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_CATEGORY_ADD_REQUESTED = 'content/TOOL_TAG_CATEGORY_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_CATEGORY_REMOVE_REQUESTED = 'content/TOOL_TAG_CATEGORY_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED = 'content/TOOL_TAG_CATEGORY_HEADER_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED = 'content/TOOL_TAG_CATEGORY_HEADER_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_CONTEXT_ADD_REQUESTED = 'content/TOOL_TAG_CONTEXT_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_CONTEXT_REMOVE_REQUESTED = 'content/TOOL_TAG_CONTEXT_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED = 'content/TOOL_TAG_TECHNIQUE_REPLACE_REQUESTED';
export const CONTENT_TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED = 'content/TOOL_TAG_TECHNIQUE_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_AFFECT_ADD_REQUESTED = 'content/TOOL_TAG_AFFECT_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_AFFECT_REMOVE_REQUESTED = 'content/TOOL_TAG_AFFECT_REMOVE_REQUESTED';
export const CONTENT_TOOL_TAG_SKILL_ADD_REQUESTED = 'content/TOOL_TAG_SKILL_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_SKILL_REMOVE_REQUESTED = 'content/TOOL_TAG_SKILL_REMOVE_REQUESTED';

export const CONTENT_TOOL_IS_LOADING = 'content/TOOL_IS_LOADING';
export const CONTENT_TOOL_NOT_LOADING = 'content/TOOL_NOT_LOADING';
export const CONTENT_TOOL_FETCHED = 'content/TOOL_FETCHED';

// Series
export const CONTENT_SERIES_TOOLS_ADD_REQUESTED = 'content/SERIES_TOOLS_ADD_REQUESTED';
export const CONTENT_SERIES_TOOLS_REMOVE_REQUESTED = 'content/SERIES_TOOLS_REMOVE_REQUESTED';

// Quotes
export const CONTENT_QUOTE_GET_REQUESTED = 'content/QUOTE_GET_REQUESTED';
export const CONTENT_QUOTE_FETCH_REQUESTED = 'content/QUOTE_FETCH_REQUESTED';
export const CONTENT_QUOTE_CREATE_REQUESTED = 'content/QUOTE_CREATE_REQUESTED';
export const CONTENT_QUOTE_DELETE_REQUESTED = 'content/QUOTE_DELETE_REQUESTED';
export const CONTENT_QUOTE_DELETE_COMPLETED = 'content/QUOTE_DELETE_COMPLETED';
export const CONTENT_QUOTE_EDIT_REQUESTED = 'content/QUOTE_EDIT_REQUESTED';

export const CONTENT_QUOTE_IS_LOADING = 'content/QUOTE_IS_LOADING';
export const CONTENT_QUOTE_NOT_LOADING = 'content/QUOTE_NOT_LOADING';
export const CONTENT_QUOTE_FETCHED = 'content/QUOTE_FETCHED';

export const CONTENT_QUOTE_PUBLISH_REQUESTED = 'content/QUOTE_PUBLISH_REQUESTED';

export const CONTENT_QUOTE_ARCHIVE_REQUESTED = 'content/QUOTE_ARCHIVE_REQUESTED';

// Goals
export const CONTENT_GOALS_REQUESTED = 'content/GOALS_REQUESTED';
export const CONTENT_GOALS_IS_LOADING = 'content/GOALS_IS_LOADING';
export const CONTENT_GOALS_NOT_LOADING = 'content/GOALS_NOT_LOADING';
export const CONTENT_GOALS_FETCHED = 'content/GOALS_FETCHED';
export const CONTENT_GOAL_CREATE_REQUESTED = 'content/GOAL_CREATE_REQUESTED';

// Goal Headers
export const CONTENT_GOAL_HEADERS_REQUESTED = 'content/GOAL_HEADERS_REQUESTED';
export const CONTENT_GOAL_HEADERS_IS_LOADING = 'content/GOAL_HEADERS_IS_LOADING';
export const CONTENT_GOAL_HEADERS_NOT_LOADING = 'content/GOAL_HEADERS_NOT_LOADING';
export const CONTENT_GOAL_HEADERS_FETCHED = 'content/GOAL_HEADERS_FETCHED';
export const CONTENT_GOAL_HEADER_CREATE_REQUESTED = 'content/GOAL_HEADER_CREATE_REQUESTED';
export const CONTENT_GOAL_HEADER_DELETE_REQUESTED = 'content/GOAL_HEADER_DELETE_REQUESTED';
export const CONTENT_GOAL_HEADER_DELETE_COMPLETED = 'content/GOAL_HEADER_DELETE_COMPLETED';
export const CONTENT_GOAL_HEADER_LINK_REQUESTED = 'content/GOAL_HEADER_LINK_REQUESTED';
export const CONTENT_GOAL_HEADER_UNLINK_REQUESTED = 'content/GOAL_HEADER_UNLINK_REQUESTED';

// Journey
export const CONTENT_GOAL_JOURNIES_REQUESTED = 'content/GOAL_JOURNIES_REQUESTED';
export const CONTENT_GOAL_JOURNIES_IS_LOADING = 'content/GOAL_JOURNIES_IS_LOADING';
export const CONTENT_GOAL_JOURNIES_NOT_LOADING = 'content/GOAL_JOURNIES_NOT_LOADING';
export const CONTENT_GOAL_JOURNIES_FETCHED = 'content/GOAL_JOURNIES_FETCHED';

export const CONTENT_JOURNEY_REQUESTED = 'content/JOURNEY_REQUESTED';
export const CONTENT_JOURNEY_IS_LOADING = 'content/JOURNEY_IS_LOADING';
export const CONTENT_JOURNEY_NOT_LOADING = 'content/JOURNEY_NOT_LOADING';
export const CONTENT_JOURNEY_FETCHED = 'content/JOURNEY_FETCHED';

export const CONTENT_JOURNEY_CREATE_REQUESTED = 'content/JOURNEY_CREATE_REQUESTED';
export const CONTENT_JOURNEY_EDIT_REQUESTED = 'content/JOURNEY_EDIT_REQUESTED';
export const CONTENT_JOURNEY_GOAL_EDIT_REQUESTED = 'content/JOURNEY_GOAL_EDIT_REQUESTED';
export const CONTENT_JOURNEY_DELETE_REQUESTED = 'content/JOURNEY_DELETE_REQUESTED';
export const CONTENT_JOURNEY_DELETE_COMPLETED = 'content/JOURNEY_DELETE_COMPLETED';

// Exercise
export const CONTENT_GOAL_EXERCISES_REQUESTED = 'content/GOAL_EXERCISES_REQUESTED';
export const CONTENT_GOAL_EXERCISES_IS_LOADING = 'content/GOAL_EXERCISES_IS_LOADING';
export const CONTENT_GOAL_EXERCISES_NOT_LOADING = 'content/GOAL_EXERCISES_NOT_LOADING';
export const CONTENT_GOAL_EXERCISES_FETCHED = 'content/GOAL_EXERCISES_FETCHED';

export const CONTENT_GENERAL_EXERCISES_REQUESTED = 'content/GENERAL_EXERCISES_REQUESTED';

export const CONTENT_EXERCISE_REQUESTED = 'content/EXERCISE_REQUESTED';
export const CONTENT_EXERCISE_IS_LOADING = 'content/EXERCISE_IS_LOADING';
export const CONTENT_EXERCISE_NOT_LOADING = 'content/EXERCISE_NOT_LOADING';
export const CONTENT_EXERCISE_FETCHED = 'content/EXERCISE_FETCHED';
export const CONTENT_EXERCISES_FETCHED = 'content/EXERCISES_FETCHED';

export const CONTENT_EXERCISE_CREATE_REQUESTED = 'content/EXERCISE_CREATE_REQUESTED';
export const CONTENT_EXERCISE_EDIT_REQUESTED = 'content/EXERCISE_EDIT_REQUESTED';
export const CONTENT_EXERCISE_DELETE_REQUESTED = 'content/EXERCISE_DELETE_REQUESTED';
export const CONTENT_EXERCISE_DELETE_COMPLETED = 'content/EXERCISE_DELETE_COMPLETED';
export const CONTENT_EXERCISE_MOVE_REQESTED = 'content/EXERCISE_MOVE_REQUESTED';

export const CONTENT_EXERCISE_CONTENT_REQUESTED = 'content/EXERCISE_CONTENT_REQUESTED';
export const CONTENT_EXERCISE_CONTENT_IS_LOADING = 'content/EXERCISE_CONTENT_IS_LOADING';
export const CONTENT_EXERCISE_CONTENT_NOT_LOADING = 'content/EXERCISE_CONTENT_NOT_LOADING';
export const CONTENT_EXERCISE_CONTENT_FETCHED = 'content/EXERCISE_CONTENT_FETCHED';

export const CONTENT_CONTENT_CARD_COPY_REQUESTED = 'content/CONTENT_CARD_COPY_REQUESTED';
export const CONTENT_CONTENT_CARD_CREATE_REQUESTED = 'content/CONTENT_CARD_CREATE_REQUESTED';
export const CONTENT_CONTENT_CARD_DELETE_REQUESTED = 'content/CONTENT_CARD_DELETE_REQUESTED';
export const CONTENT_CONTENT_CARD_MOVE_REQUESTED = 'content/CONTENT_CARD_MOVE_REQUESTED';
export const CONTENT_CONTENT_CARD_COMPLETION_EDIT_REQUESTED = 'content/CONTENT_CARD_COMPLETION_EDIT_REQUESTED';
export const CONTENT_CONTENT_CARD_REFERENCE_EDIT_REQUESTED = 'content/CONTENT_CARD_REFERENCE_EDIT_REQUESTED';
export const CONTENT_CONTENT_CAPTION_CARD_EDIT_REQUESTED = 'content/CONTENT_CAPTION_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_INFORMATION_CARD_EDIT_REQUESTED = 'content/CONTENT_INFORMATION_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_AUDIO_CARD_EDIT_REQUESTED = 'content/CONTENT_AUDIO_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_VIDEO_CARD_EDIT_REQUESTED = 'content/CONTENT_VIDEO_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_TASK_CARD_EDIT_REQUESTED = 'content/CONTENT_TASK_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_COMPLETE_CARD_EDIT_REQUESTED = 'content/CONTENT_COMPLETE_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED = 'content/CONTENT_DOCUMENT_CARD_ADD_ITEM_REQUESTED';
export const CONTENT_CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED = 'content/CONTENT_DOCUMENT_CARD_DELETE_ITEM_REQUESTED';
export const CONTENT_CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED = 'content/CONTENT_DOCUMENT_CARD_UPDATE_ITEM_REQUESTED';
export const CONTENT_CONTENT_READ_CARD_EDIT_REQUESTED = 'content/CONTENT_READ_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED = 'content/CONTENT_INPUT_CARD_ADD_ITEM_REQUESTED';
export const CONTENT_CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED = 'content/CONTENT_INPUT_CARD_DELETE_ITEM_REQUESTED';
export const CONTENT_CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED = 'content/CONTENT_INPUT_CARD_EDIT_ITEM_REQUESTED';
export const CONTENT_CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED = 'content/CONTENT_QUOTE_CARD_ADD_ITEM_REQUESTED';
export const CONTENT_CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED = 'content/CONTENT_QUOTE_CARD_DELETE_ITEM_REQUESTED';
export const CONTENT_CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED = 'content/CONTENT_QUOTE_CARD_EDIT_ITEM_REQUESTED';
export const CONTENT_CONTENT_TIMER_CARD_EDIT_REQUESTED = 'content/CONTENT_TIMER_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED = 'content/CONTENT_ASSESSMENT_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED = 'content/CONTENT_ASSESSMENT_RESULT_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_CHECKLIST_CARD_EDIT_REQUESTED = 'content/CONTENT_CHECKLIST_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED = 'content/CONTENT_CHECKLIST_CARD_ADD_ITEM_REQUESTED';
export const CONTENT_CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED = 'content/CONTENT_CHECKLIST_CARD_EDIT_ITEM_REQUESTED';
export const CONTENT_CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED = 'content/CONTENT_CHECKLIST_CARD_DELETE_ITEM_REQUESTED';
export const CONTENT_CONTENT_SUMMARY_CARD_EDIT_REQUESTED = 'content/CONTENT_SUMMARY_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_REFLECTION_CARD_EDIT_REQUESTED = 'content/CONTENT_REFLECTION_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED = 'content/CONTENT_QUESTIONNAIRE_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_QUIZ_CARD_EDIT_REQUESTED = 'content/CONTENT_QUIZ_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED = 'content/CONTENT_QUIZ_CARD_ADD_ITEM_REQUESTED';
export const CONTENT_CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED = 'content/CONTENT_QUIZ_CARD_EDIT_ITEM_REQUESTED';
export const CONTENT_CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED = 'content/CONTENT_QUIZ_CARD_DELETE_ITEM_REQUESTED';
export const CONTENT_CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED = 'content/CONTENT_OVERVIEW_SESSIONS_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_TOOL_CARD_EDIT_REQUESTED = 'content/CONTENT_TOOL_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED = 'content/CONTENT_TOOL_CARD_ADD_TOOL_REQUESTED';
export const CONTENT_CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED = 'content/CONTENT_TOOL_CARD_REMOVE_TOOL_REQUESTED';

export const CONTENT_TAG_CREATE_REQUESTED = 'content/TAG_CREATE_REQUESTED';
export const CONTENT_TAG_DELETE_REQUESTED = 'content/TAG_DELETE_REQUESTED';

export const CONTENT_GOAL_PUBLISH_REQUESTED = 'content/GOAL_PUBLISH_REQUESTED';
export const CONTENT_JOURNEY_PUBLISH_REQUESTED = 'content/JOURNEY_PUBLISH_REQUESTED';
export const CONTENT_EXERCISE_PUBLISH_REQUESTED = 'content/EXERCISE_PUBLISH_REQUESTED';
export const CONTENT_TOOL_PUBLISH_REQUESTED = 'content/TOOL_PUBLISH_REQUESTED';

export const CONTENT_TOOL_ARCHIVE_REQUESTED = 'content/TOOL_ARCHIVE_REQUESTED';

export const CONTENT_GOAL_LOCK_REQUESTED = 'content/GOAL_LOCK_REQUESTED';
export const CONTENT_JOURNEY_LOCK_REQUESTED = 'content/JOURNEY_LOCK_REQUESTED';
export const CONTENT_EXERCISE_LOCK_REQUESTED = 'content/EXERCISE_LOCK_REQUESTED';

export const CONTENT_EXERCISE_ACTION_EXERCISE_LINK_REQUESTED = 'content/EXERCISE_ACTION_EXERCISE_LINK_REQUESTED';
export const CONTENT_EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED = 'content/EXERCISE_ACTION_EXERCISE_UNLINK_REQUESTED';

export const CONTENT_TOOL_TAG_CREATE_REQUESTED = 'content/TOOL_TAG_CREATE_REQUESTED';
export const CONTENT_TOOL_TAG_DELETE_REQUESTED = 'content/TOOL_TAG_DELETE_REQUESTED';

export const CONTENT_GENERIC_TAG_CREATE_REQUESTED = 'content/GENERIC_TAG_CREATE_REQUESTED';
export const CONTENT_GENERIC_TAG_DELETE_REQUESTED = 'content/GENERIC_TAG_DELETE_REQUESTED';

export const CONTENT_TECHNIQUE_TAG_FETCH_REQUESTED = 'content/TECHNIQUE_TAG_FETCH_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_IS_LOADING = 'content/TECHNIQUE_TAG_IS_LOADING';
export const CONTENT_TECHNIQUE_TAG_NOT_LOADING = 'content/TECHNIQUE_TAG_NOT_LOADING';
export const CONTENT_TECHNIQUE_TAG_FETCHED = 'content/TECHNIQUE_TAG_FETCHED';

export const CONTENT_TECHNIQUE_TAG_ENABLE_REQUESTED = 'content/TECHNIQUE_TAG_ENABLE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED = 'content/TECHNIQUE_TAG_ADD_PUSH_MESSAGE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED = 'content/TECHNIQUE_TAG_REMOVE_PUSH_MESSAGE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_REMOVE_RULE_REQUESTED = 'content/TECHNIQUE_TAG_REMOVE_RULE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_ADD_RULE_REQUESTED = 'content/TECHNIQUE_TAG_ADD_RULE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_CREATE_REQUESTED = 'content/TECHNIQUE_TAG_CREATE_REQUESTED';
export const CONTENT_TECHNIQUE_TAG_DELETE_REQUESTED = 'content/TECHNIQUE_TAG_DELETE_REQUESTED';

export const CONTENT_FOCUS_FETCH_REQUESTED = 'content/CONTENT_FOCUS_FETCH_REQUESTED';
export const CONTENT_FOCUS_FETCHED = 'content/CONTENT_FOCUS_FETCHED';

export const CONTENT_TOOL_TAG_FOCUS_ADD_REQUESTED = 'content/TOOL_TAG_FOCUS_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_FOCUS_REMOVE_REQUESTED = 'content/TOOL_TAG_FOCUS_REMOVE_REQUESTED';

export const CONTENT_TOOL_TAG_PROBLEM_ADD_REQUESTED = 'content/TOOL_TAG_PROBLEM_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_PROBLEM_REMOVE_REQUESTED = 'content/TOOL_TAG_PROBLEM_REMOVE_REQUESTED';

export const CONTENT_LINK_ADD_REQUESTED = 'content/LINK_ADD_REQUESTED';
export const CONTENT_LINK_REMOVE_REQUESTED = 'content/LINK_REMOVE_REQUESTED';

export const CONTENT_CONTENT_GUIDE_CARD_EDIT_REQUESTED = 'content/CONTENT_GUIDE_CARD_EDIT_REQUESTED';
export const CONTENT_CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED = 'content/CONTENT_GUIDE_CARD_ADD_CONCEPT_REQUESTED';
export const CONTENT_CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED = 'content/CONTENT_GUIDE_CARD_EDIT_CONCEPT_REQUESTED';
export const CONTENT_CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED = 'content/CONTENT_GUIDE_CARD_DELETE_CONCEPT_REQUESTED';

// content-keyword
export const CONTENT_TOOL_TAG_KEYWORD_ADD_REQUESTED = 'content/TOOL_TAG_KEYWORD_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_KEYWORD_IS_LOADING = 'content/TOOL_TAG_KEYWORD_IS_LOADING';
export const CONTENT_TOOL_TAG_KEYWORD_NOT_LOADING = 'content/TOOL_TAG_KEYWORD_NOT_LOADING';
export const CONTENT_TOOL_TAG_KEYWORD_REMOVE_REQUESTED = 'content/TOOL_TAG_KEYWORD_REMOVE_REQUESTED';

export const CONTENT_TOOL_TAG_TAG_ADD_REQUESTED = 'content/TOOL_TAG_TAG_ADD_REQUESTED';
export const CONTENT_TOOL_TAG_TAG_IS_LOADING = 'content/TOOL_TAG_TAG_IS_LOADING';
export const CONTENT_TOOL_TAG_TAG_NOT_LOADING = 'content/TOOL_TAG_TAG_NOT_LOADING';
export const CONTENT_TOOL_TAG_TAG_REMOVE_REQUESTED = 'content/TOOL_TAG_TAG_REMOVE_REQUESTED';
