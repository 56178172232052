import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import * as textIcon from 'assets/images/icons/icon-content-document-text.png';
import * as imageIcon from 'assets/images/icons/icon-content-document-image.png';
import './index.css';

interface Props {
    locale: string;
    isOpen: boolean;
    isLoading: boolean;
    onAdd: (any) => any;
    onClose: () => any;
}

function adaptInputValueToData(type, value) {
    switch (type) {
        case 'text':
            return {
                text: value
            };
        case 'image':
            return {
                image_url: value
            };
        case 'audio':
            return null;
    }
    return null;
}

class AddItemDocumentContentModal extends React.Component<Props> {
    state = {
        type: null,
        value: null,
        data: null
    };

    resetState = () => {
        this.setState({
            type: null,
            value: null,
            data: null
        });
    };

    handleSubmit = () => {
        this.props.onAdd(this.state.type, this.state.data);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'value':
                this.setState({
                    value: inputValue
                });
                break;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.type != this.state.type) {
            this.setState({ data: adaptInputValueToData(this.state.type, this.state.value) });
        } else if (prevState.value != this.state.value) {
            this.setState({ data: adaptInputValueToData(this.state.type, this.state.value) });
        }
    }

    render() {
        return (
            <Modal
                id="add-item-document-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="add-item-document-modal-header">Add Item to Document Card</Modal.Header>
                <Modal.Content id="add-item-document-modal-content">
                    <Card.Group id="add-item-document-card-container" centered>
                        <Card
                            link
                            onClick={() => {
                                this.setState({ type: 'text' });
                            }}
                        >
                            <Card.Content>
                                <Card.Header
                                    id={
                                        this.state.type == 'text' ? 'add-item-document-card-title-selected' : 'add-item-document-card-title'
                                    }
                                >
                                    Text
                                </Card.Header>
                            </Card.Content>
                            <Card.Content>
                                <Image centered id="add-item-document-card-image" src={textIcon} />
                            </Card.Content>
                        </Card>
                        <Card
                            link
                            onClick={() => {
                                this.setState({ type: 'image' });
                            }}
                        >
                            <Card.Content>
                                <Card.Header
                                    id={
                                        this.state.type == 'image'
                                            ? 'add-item-document-card-title-selected'
                                            : 'add-item-document-card-title'
                                    }
                                >
                                    Image
                                </Card.Header>
                            </Card.Content>
                            <Card.Content>
                                <Image centered id="add-item-document-card-image" src={imageIcon} />
                            </Card.Content>
                        </Card>
                    </Card.Group>
                    {this.state.type != null && (
                        <Form size="large" loading={false}>
                            <Segment basic>
                                <Form.Input type="text" name="value" placeholder="Value" onChange={this.handleInputChange} />
                            </Segment>
                        </Form>
                    )}
                </Modal.Content>
                <Modal.Actions id="add-item-document-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={this.state.type == null} onClick={this.handleSubmit} color="teal">
                        Add
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddItemDocumentContentModal;
