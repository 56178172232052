import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import NavigationReducer from './navigation';
import AuthenticationReducer from './authentication';
import ContentReducer from './content';
import AssessmentReducer from './assessment';
import SettingReducer from './setting';
import OrganizationReducer from './organization';
import moodReducer from './mood';
import HumanCareReducer from './human-care';
import EapReducer from './eap';
import ModalViewReducer from './view/modalReducer';
import ContentViewReducer from './view/contentReducer';
import AssessmentViewReducer from './view/assessmentReducer';
import CustomizationReducer from './view/customizationReducer';
import CustomerReducer from './customer';
import { history } from '../../history';

const viewReducer = combineReducers({
    customization: CustomizationReducer,
    modal: ModalViewReducer,
    content: ContentViewReducer,
    assessment: AssessmentViewReducer
});

const dataReducer = combineReducers({
    content: ContentReducer,
    mood: moodReducer,
    setting: SettingReducer,
    organization: OrganizationReducer,
    assessment: AssessmentReducer,
    human_care: HumanCareReducer,
    eap: EapReducer,
    customer: CustomerReducer
});

export default combineReducers({
    router: connectRouter(history),
    navigation: NavigationReducer,
    authentication: AuthenticationReducer,
    view: viewReducer,
    data: dataReducer
});
