import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import ReactDropzone from 'react-dropzone';
import './index.css';
import ContentService from '../../../../../service/content';

interface Props {
    card?: object;
    exercise_id?: number;
    is_uploading: boolean;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditTaskCardModal extends React.Component<Props> {
    state = {
        task_type: 'single',
        can_edit: true,
        task_prompt: null,
        task_placeholder: null,
        task_description: null,
        task_time_range: null,
        task_time_frequency: null
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                task_type: card.content.type,
                can_edit: card.content.can_edit,
                task_prompt: card.content.prompt != null ? card.content.prompt : null,
                task_placeholder: card.content.placeholder != null ? card.content.placeholder : null,
                task_description: card.content.description != null ? card.content.description : null,
                task_time_range: card.content.time != null ? card.content.time.range : null,
                task_time_frequency: card.content.time != null ? card.content.time.frequency : null
            });
        }
    }

    handleSubmit = () => {
        var params = {};
        if (this.state.task_type != null) params['type'] = this.state.task_type;
        if (this.state.can_edit != null) params['can_edit'] = this.state.can_edit;
        if (this.state.task_prompt != null) params['prompt'] = this.state.task_prompt;
        if (this.state.task_placeholder != null) params['placeholder'] = this.state.task_placeholder;
        if (this.state.task_description != null) params['description'] = this.state.task_description;
        if (this.state.task_time_range != null) params['time_range'] = this.state.task_time_range;
        if (this.state.task_time_frequency != null) params['time_frequency'] = this.state.task_time_frequency;

        this.props.onEdit(params);
        this.resetState();
    };

    resetState() {
        this.setState({
            task_type: 'single',
            can_edit: true,
            task_prompt: null,
            tasl_placeholder: null,
            task_description: null,
            task_time_range: null,
            task_time_frequency: null
        });
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'task_prompt':
                this.setState({
                    task_prompt: inputValue
                });
                break;
            case 'task_description':
                this.setState({
                    task_description: inputValue
                });
                break;
            case 'task_placeholder':
                this.setState({
                    task_placeholder: inputValue
                });
                break;
            case 'task_time_range':
                this.setState({
                    task_time_range: inputValue
                });
                break;
            case 'task_time_frequency':
                this.setState({
                    task_time_frequency: inputValue
                });
                break;
        }
    };

    onTaskTimeRangeChanges = (value) => {
        this.setState({ task_time_range: value });
    };

    onTaskTypeChanged = (value) => {
        this.setState({ task_type: value });
    };

    render() {
        return (
            <Modal
                id="edit-audio-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="edit-task-modal-header">Edit Audio Card</Modal.Header>
                <Modal.Content id="edit-task-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <div id={'form-item'}>
                                <Form.Input
                                    value={this.state.task_prompt || ''}
                                    type="text"
                                    name="task_prompt"
                                    placeholder="Prompt (i.e. What task would you like to achieve?)"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div id={'form-item'}>
                                <Form.Input
                                    value={this.state.task_placeholder || ''}
                                    type="text"
                                    name="task_placeholder"
                                    placeholder="Prompt Placeholder"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            {/* <div id={"form-item"}>
                <Form.Input
                  value={this.state.task_description || ""}
                  type="text"
                  name="task_description"
                  placeholder="Default Description (Leave Empty for User Input)"
                  onChange={this.handleInputChange}
                />
              </div> */}
                            {/* <div id={"form-item"}>
                <Form.Input
                  value={this.state.task_time_frequency || ""}
                  type="text"
                  name="task_time_frequency"
                  placeholder="Frequency (Number)"
                  onChange={this.handleInputChange}
                />
              </div>
              <div id={"form-item"}>
                <RadioGroup
                  value={this.state.task_type || "single"}
                  onChange={this.onTaskTimeRangeChanges}
                  horizontal
                >
                  <RadioButton value="day">Day</RadioButton>
                  <RadioButton value="week">Week</RadioButton>
                </RadioGroup>
              </div> */}
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-task-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditTaskCardModal;
