import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import ReactDropzone from 'react-dropzone';
import './index.css';
import ContentService from '../../../../../service/content';

interface Props {
    card?: object;
    exercise_id?: number;
    is_uploading: boolean;
    isOpen: boolean;
    isLoading: boolean;
    onEdit: (any) => any;
    onClose: () => any;
}

class EditAudioCardModal extends React.Component<Props> {
    state = {
        audio_url: null,
        has_breathing: null,
        selected_completion: 'none',
        completion_value: null,
        has_background_audio: false
    };

    componentDidMount() {
        const { card } = this.props;
        if (card != null && card.content != null) {
            this.setState({
                audio_url: card.content.audio_url,
                selected_completion: card.content.completion != null ? card.content.completion.type : null,
                completion_value: card.content.completion != null ? card.content.completion.value : null,
                has_background_audio: card.content.has_background_audio != null ? card.content.has_background_audio : null
            });
        }
    }

    onDrop = (files) => {
        if (files.length == 0) {
            return;
        }

        this.setState({ is_uploading: true });
        ContentService.uploadToolAudio(this.props.exercise_id, files[0], files[0].name)
            .then((result) => {
                this.setState({ is_uploading: false });
                if (result != null && result.path != null) {
                    this.setState({
                        audio_url: result.path
                    });
                }
            })
            .catch((err) => {
                this.setState({ is_uploading: false });
            });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.audio_url != null) params['audio_url'] = this.state.audio_url;
        if (this.state.selected_completion != null) params['completion_type'] = this.state.selected_completion;
        if (this.state.completion_value != null) params['completion_value'] = this.state.completion_value;
        if (this.state.has_background_audio != null) params['has_background_audio'] = this.state.has_background_audio;

        this.props.onEdit(params);
        this.resetState();
    };

    resetState() {
        this.setState({
            audio_url: null,
            has_breathing: null,
            selected_completion: 'none',
            completion_value: null,
            has_background_audio: false
        });
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'audio_url':
                this.setState({
                    audio_url: inputValue
                });
                break;
            case 'completion_value':
                this.setState({
                    completion_value: inputValue
                });
        }
    };

    onCompletionChanged = (value) => {
        this.setState({ selected_completion: value });
    };

    render() {
        return (
            <Modal
                id="edit-audio-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="edit-audio-modal-header">Edit Audio Card</Modal.Header>
                <Modal.Content id="edit-audio-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <div id="audio-drop">
                                <ReactDropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div id={isDragActive ? 'audio-drop-inner-selected' : 'audio-drop-inner'} {...getRootProps()}>
                                            {this.state.is_uploading == true ? (
                                                <div class="ui active inverted dimmer">
                                                    <div class="ui loader"></div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <input {...getInputProps()} />
                                                    <p>Drop the audio file here, or click to select file</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ReactDropzone>
                            </div>
                            <Form.Input
                                value={this.state.audio_url || ''}
                                type="text"
                                name="audio_url"
                                placeholder="Audio URL"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_background_audio"
                                        checked={this.state.has_background_audio || false}
                                        onChange={() => {
                                            this.setState({
                                                has_background_audio: !this.state.has_background_audio
                                            });
                                        }}
                                    />
                                    <label>Has Background Audio</label>
                                </div>
                            </div>
                            {/* <RadioGroup
                value={this.state.selected_completion || ""}
                onChange={this.onCompletionChanged}
                horizontal
              >
                <RadioButton value="none">Normal</RadioButton>
                <RadioButton value="time">Timed</RadioButton>
              </RadioGroup>
              {this.state.selected_completion == "time" && (
                <div id={"reference_value"}>
                  <Form.Input
                    value={this.state.completion_value || ""}
                    type="text"
                    name="completion_value"
                    placeholder="Completion Value (i.e. Timed [seconds])"
                    onChange={this.handleInputChange}
                  />
                </div>
              )} */}
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-audio-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditAudioCardModal;
