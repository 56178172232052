import {
    AUTHENTICATION_REQUIRED,
    AUTHENTICATION_REQUESTED,
    AUTHENTICATION_LOGOUT_REQUESTED,
    AUTHENTICATION_LOGIN_REQUESTED,
    AUTHENTICATION_PENDING,
    AUTHENTICATION_NOT_PENDING,
    AUTHENTICATION_SUCCEED,
    AUTHENTICATION_FAIL
} from '../constants/authentication';

const initialState = {
    isAuthenticated: false,
    isLoading: false,
    error: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case AUTHENTICATION_REQUIRED:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                error: null
            };
            break;
        case AUTHENTICATION_REQUESTED:
            break;
        case AUTHENTICATION_LOGIN_REQUESTED:
            break;
        case AUTHENTICATION_LOGOUT_REQUESTED:
            break;
        case AUTHENTICATION_PENDING:
            return {
                ...state,
                isLoading: true
            };
        case AUTHENTICATION_NOT_PENDING:
            return {
                ...state,
                isLoading: false
            };
        case AUTHENTICATION_SUCCEED:
            return {
                ...state,
                isAuthenticated: true,
                error: null
            };
            break;
        case AUTHENTICATION_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                error: {
                    message: action.payload.message
                }
            };
            break;
        default:
            return state;
    }
    return state;
}
