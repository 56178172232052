import { VIEW_ASSESSMENT_SOURCE_TYPE } from '../../constants/view/assessment';

const initialState = {
    source_type: 'emotion'
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case VIEW_ASSESSMENT_SOURCE_TYPE:
            return {
                ...state,
                source_type: action.payload.type
            };
        default:
            return { ...state };
    }
}
