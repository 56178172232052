import { call, put, takeLatest } from 'redux-saga/effects';
import {
    AUTHENTICATION_REQUESTED,
    AUTHENTICATION_LOGIN_REQUESTED,
    AUTHENTICATION_LOGOUT_REQUESTED,
    AUTHENTICATION_PENDING,
    AUTHENTICATION_NOT_PENDING,
    AUTHENTICATION_SUCCEED,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_REQUIRED
} from '../constants/authentication';
import AuthenticationService from '../../service/authentication';

function* authenticateUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const isAuthorized = yield call(AuthenticationService.authenticate);
        if (isAuthorized) {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({ type: AUTHENTICATION_SUCCEED });
        } else {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_REQUIRED
            });
        }
    } catch (e) {
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        yield put({
            type: AUTHENTICATION_FAIL,
            payload: { message: 'Failed to login User' }
        });
    }
}

function* loginUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const success = yield call(AuthenticationService.login, action.payload.email, action.payload.password);
        if (success) {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({ type: AUTHENTICATION_SUCCEED });
        } else {
            yield put({ type: AUTHENTICATION_NOT_PENDING });
            yield put({
                type: AUTHENTICATION_FAIL,
                payload: { message: 'Wrong User Credentials Entered' }
            });
        }
    } catch (e) {
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        yield put({
            type: AUTHENTICATION_FAIL,
            payload: { message: 'Failed to Login User' }
        });
    }
}

function* logoutUser(action) {
    try {
        yield put({ type: AUTHENTICATION_PENDING });
        const success = yield call(AuthenticationService.logout);
        yield put({ type: AUTHENTICATION_NOT_PENDING });
        if (success) {
            yield put({
                type: AUTHENTICATION_REQUIRED
            });
        }
    } catch (e) {}
}

function* AuthenticationSaga() {
    yield takeLatest(AUTHENTICATION_REQUESTED, authenticateUser);
    yield takeLatest(AUTHENTICATION_LOGIN_REQUESTED, loginUser);
    yield takeLatest(AUTHENTICATION_LOGOUT_REQUESTED, logoutUser);
}

export default AuthenticationSaga;
