import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onAdd: (any) => any;
    onClose: () => any;
}

class AddInputCardModal extends React.Component<Props> {
    state = {
        text: null,
        placeholder: null,
        image_url: null,
        currentConcept: null,
        concepts: null
    };

    componentDidMount() {
        const { allCards } = this.props;
        const guideCard = allCards?.find((card) => card.type === 'guide');
        let concepts;
        if (guideCard && guideCard.content) {
            concepts = guideCard.content.concepts;
            const formattedConcepts = concepts.map((concept) => {
                return {
                    ...concept,
                    value: concept.id,
                    label: concept.title,
                    type: concept.title
                };
            });
            this.setState({
                concepts: formattedConcepts
            });
        }
    }

    resetState = () => {
        this.setState({
            text: null,
            placeholder: null,
            image_url: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.text != null) params['text'] = this.state.text;
        if (this.state.placeholder != null) params['placeholder'] = this.state.placeholder;
        if (this.state.image_url != null) params['image_url'] = this.state.image_url;
        if (this.state.currentConcept !== null) {
            params['concept_id'] = this.state.currentConcept.value;
        }

        this.props.onAdd(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'text':
                this.setState({
                    text: inputValue
                });
                break;
            case 'placeholder':
                this.setState({
                    placeholder: inputValue
                });
                break;
            case 'image_url':
                this.setState({
                    image_url: inputValue
                });
                break;
        }
    };

    render() {
        const { locale } = this.props;
        const { concepts, currentConcept } = this.state;
        return (
            <Modal
                id="content-input-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={this.props.isOpen}
                onClose={this.handleClose}
            >
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header id="content-input-modal-header">Add Input Variant</Modal.Header>
                <Modal.Content id="content-input-modal-content">
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.text || ''}
                                type="text"
                                name="text"
                                placeholder="Text"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.placeholder || ''}
                                type="text"
                                name="placeholder"
                                placeholder="Placeholder"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.image_url || ''}
                                type="text"
                                name="image_url"
                                placeholder="Image URL"
                                onChange={this.handleInputChange}
                            />
                            {concepts && concepts.length > 0 && (
                                <Dropdown
                                    options={concepts}
                                    onChange={(selected) => {
                                        this.setState({
                                            currentConcept: selected
                                        });
                                    }}
                                    value={currentConcept != null ? currentConcept.label : null}
                                    placeholder="Select an option"
                                />
                            )}
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="content-input-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={false} onClick={this.handleSubmit} color="teal">
                        Add Variant
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddInputCardModal;
