import { call, put, takeLatest } from 'redux-saga/effects';
import { MOOD_AFFECT_FETCH_REQUESTED, MOOD_AFFECT_IS_LOADING, MOOD_AFFECT_NOT_LOADING, MOOD_AFFECT_FETCHED } from '../constants/mood';
import MoodService from '../../service/mood';

function* fetchMoodAffects(action) {
    try {
        yield put({ type: MOOD_AFFECT_IS_LOADING });
        const results = yield call(MoodService.fetchAffects);
        yield put({ type: MOOD_AFFECT_NOT_LOADING });
        if (results != null) {
            yield put({
                type: MOOD_AFFECT_FETCHED,
                payload: {
                    affects: results.affects
                }
            });
        }
    } catch (e) {
        console.error('[Mood Saga]', e);
    }
}

function* MoodSaga() {
    yield takeLatest(MOOD_AFFECT_FETCH_REQUESTED, fetchMoodAffects);
}

export default MoodSaga;
