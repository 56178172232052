import { call, put, takeLatest } from 'redux-saga/effects';
import EapService from '../../service/eap';
import {
    EAP_FOCUS_CREATE_COMPLETED,
    EAP_FOCUS_CREATE_REQUESTED,
    EAP_FOCUS_FETCH_COMPLETED,
    EAP_FOCUS_FETCH_REQUESTED,
    EAP_FOCUS_GET_BY_ID_REQUESTED,
    EAP_FOCUS_REMOVE_REQUESTED,
    EAP_FOCUS_TAG_KEYWORD_ADD_REQUESTED,
    EAP_FOCUS_TAG_KEYWORD_REMOVE_REQUESTED,
    EAP_FOCUS_UPDATE_COMPLETED,
    EAP_FOCUS_UPDATE_REQUESTED,
    EAP_FRAMEWORK_CREATE_COMPLETED,
    EAP_FRAMEWORK_CREATE_REQUESTED,
    EAP_FRAMEWORK_FETCH_COMPLETED,
    EAP_FRAMEWORK_FETCH_REQUESTED,
    EAP_FRAMEWORK_GET_BY_ID_COMPLETED,
    EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
    EAP_FRAMEWORK_REMOVE_REQUESTED,
    EAP_FRAMEWORK_UPDATE_COMPLETED,
    EAP_FRAMEWORK_UPDATE_REQUESTED,
    EAP_SPECIALITY_CREATE_COMPLETED,
    EAP_SPECIALITY_CREATE_REQUESTED,
    EAP_SPECIALITY_FETCH_COMPLETED,
    EAP_SPECIALITY_FETCH_REQUESTED,
    EAP_SPECIALITY_GET_BY_ID_COMPLETED,
    EAP_SPECIALITY_GET_BY_ID_REQUESTED,
    EAP_SPECIALITY_REMOVE_REQUESTED,
    EAP_SPECIALITY_UPDATE_COMPLETED,
    EAP_SPECIALITY_UPDATE_REQUESTED
} from 'redux/constants/eap';
import {
    MODAL_EAP_FOCUS_CREATE_HIDE,
    MODAL_EAP_FOCUS_EDIT_HIDE,
    MODAL_EAP_FRAMEWORK_CREATE_HIDE,
    MODAL_EAP_FRAMEWORK_EDIT_HIDE,
    MODAL_EAP_SPECIALITY_CREATE_HIDE,
    MODAL_EAP_SPECIALITY_EDIT_HIDE
} from 'redux/constants/view/modal';
import { EAP_FOCUS_GET_BY_ID_COMPLETED } from 'redux/constants/eap';

function* fetchEapFocuses() {
    try {
        const result = yield call(EapService.fetchEapFocuses);
        if (result && result.labels) {
            yield put({
                type: EAP_FOCUS_FETCH_COMPLETED,
                payload: { labels: result.labels }
            });
        } else {
            yield put({
                type: EAP_FOCUS_FETCH_COMPLETED,
                payload: { labels: [] }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_FOCUS_FETCH_COMPLETED,
            payload: { labels: [] }
        });
    }
}

function* getEapFocusDetail(action) {
    if (action.payload.focus_id == null) {
        console.log('[EAP Saga] No Focus Detected');
        return;
    }
    try {
        const result = yield call(EapService.getEapFocusDetail, action.payload.focus_id);
        if (result) {
            yield put({
                type: EAP_FOCUS_GET_BY_ID_COMPLETED,
                payload: { focus: result }
            });
        } else {
            yield put({
                type: EAP_FOCUS_GET_BY_ID_COMPLETED,
                payload: { focus: {} }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_FOCUS_GET_BY_ID_COMPLETED,
            payload: { focus: {} }
        });
    }
}

function* addEapFocus(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    try {
        const results = yield call(EapService.createEapFocus, action.payload.params);
        if (results != null) {
            yield put({ type: MODAL_EAP_FOCUS_CREATE_HIDE });
            yield put({ type: EAP_FOCUS_CREATE_COMPLETED });
            yield put({
                type: EAP_FOCUS_FETCH_REQUESTED,
                payload: {}
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* updateEapFocus(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    if (!params.focus_id) {
        console.log('[EAP Saga] No Focus');
        return;
    }
    try {
        const results = yield call(EapService.updateEapFocus, params);
        if (results != null) {
            yield put({ type: MODAL_EAP_FOCUS_EDIT_HIDE });
            yield put({ type: EAP_FOCUS_UPDATE_COMPLETED });
            yield put({
                type: EAP_FOCUS_GET_BY_ID_REQUESTED,
                payload: { focus_id: params.focus_id }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* removeEapFocus(action) {
    const {
        payload: { focus_id }
    } = action;
    if (!focus_id) {
        console.log('[EAP Saga] No Focus');
        return;
    }
    try {
        yield call(EapService.removeEapFocus, focus_id);
        yield put({
            type: EAP_FOCUS_GET_BY_ID_REQUESTED,
            payload: { focus_id: focus_id }
        });
        yield put({
            type: EAP_FOCUS_FETCH_REQUESTED,
            payload: {}
        });
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* addFocusKeywordTag(action) {
    if (action.payload.focus_id == null) {
        console.log('[EAP Saga] No Focus Detected');
        return;
    }

    if (action.payload.keyword == null) {
        console.log('[EAP Saga] No Keyword Detected');
        return;
    }

    try {
        const success = yield call(EapService.addTagFocusKeyword, action.payload.focus_id, action.payload.keyword);
        if (success) {
            yield put({
                type: EAP_FOCUS_GET_BY_ID_REQUESTED,
                payload: { focus_id: action.payload.focus_id }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
    }
}

function* removeFocusKeywordTag(action) {
    if (action.payload.focus_id == null) {
        console.log('[EAP Saga] No Focus Detected');
        return;
    }

    if (action.payload.keyword == null) {
        console.log('[EAP Saga] No Keyword Detected');
        return;
    }

    try {
        const success = yield call(EapService.removeTagFocusKeyword, action.payload.focus_id, action.payload.keyword);
        if (success) {
            if (success) {
                yield put({
                    type: EAP_FOCUS_GET_BY_ID_REQUESTED,
                    payload: { focus_id: action.payload.focus_id }
                });
            }
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
    }
}

// Frameworks
function* fetchEapFrameworks() {
    try {
        const result = yield call(EapService.fetchEapFrameworks);
        if (result && result.labels) {
            yield put({
                type: EAP_FRAMEWORK_FETCH_COMPLETED,
                payload: { labels: result.labels }
            });
        } else {
            yield put({
                type: EAP_FRAMEWORK_FETCH_COMPLETED,
                payload: { labels: [] }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_FRAMEWORK_FETCH_COMPLETED,
            payload: { labels: [] }
        });
    }
}

function* getEapFrameworkDetail(action) {
    if (action.payload.framework_id == null) {
        console.log('[EAP Saga] No Framework Detected');
        return;
    }
    try {
        const result = yield call(EapService.getEapFrameworkDetail, action.payload.framework_id);
        if (result) {
            yield put({
                type: EAP_FRAMEWORK_GET_BY_ID_COMPLETED,
                payload: { framework: result }
            });
        } else {
            yield put({
                type: EAP_FRAMEWORK_GET_BY_ID_COMPLETED,
                payload: { framework: {} }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_FRAMEWORK_GET_BY_ID_COMPLETED,
            payload: { framework: {} }
        });
    }
}

function* addEapFramework(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    try {
        const results = yield call(EapService.createEapFramework, action.payload.params);
        if (results != null) {
            yield put({ type: MODAL_EAP_FRAMEWORK_CREATE_HIDE });
            yield put({ type: EAP_FRAMEWORK_CREATE_COMPLETED });
            yield put({
                type: EAP_FRAMEWORK_FETCH_REQUESTED,
                payload: {}
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* updateEapFramework(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    if (!params.framework_id) {
        console.log('[EAP Saga] No Focus');
        return;
    }
    try {
        const results = yield call(EapService.updateEapFramework, params);
        if (results != null) {
            yield put({ type: MODAL_EAP_FRAMEWORK_EDIT_HIDE });
            yield put({ type: EAP_FRAMEWORK_UPDATE_COMPLETED });
            yield put({
                type: EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
                payload: { framework_id: params.framework_id }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* removeEapFramework(action) {
    const {
        payload: { framework_id }
    } = action;
    if (!framework_id) {
        console.log('[EAP Saga] No Focus');
        return;
    }
    try {
        yield call(EapService.removeEapFramework, framework_id);
        yield put({
            type: EAP_FRAMEWORK_GET_BY_ID_REQUESTED,
            payload: { framework_id: framework_id }
        });
        yield put({
            type: EAP_FRAMEWORK_FETCH_REQUESTED,
            payload: {}
        });
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

// Specialities
function* fetchEapSpecialities() {
    try {
        const result = yield call(EapService.fetchEapSpecialities);
        if (result && result.labels) {
            yield put({
                type: EAP_SPECIALITY_FETCH_COMPLETED,
                payload: { labels: result.labels }
            });
        } else {
            yield put({
                type: EAP_SPECIALITY_FETCH_COMPLETED,
                payload: { labels: [] }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_SPECIALITY_FETCH_COMPLETED,
            payload: { labels: [] }
        });
    }
}

function* getEapSpecialityDetail(action) {
    if (action.payload.speciality_id == null) {
        console.log('[EAP Saga] No Speciality Detected');
        return;
    }
    try {
        const result = yield call(EapService.getEapSpecialityDetail, action.payload.speciality_id);
        if (result) {
            yield put({
                type: EAP_SPECIALITY_GET_BY_ID_COMPLETED,
                payload: { speciality: result }
            });
        } else {
            yield put({
                type: EAP_SPECIALITY_GET_BY_ID_COMPLETED,
                payload: { speciality: {} }
            });
        }
    } catch (e) {
        console.error('[EAP Saga]', e);
        yield put({
            type: EAP_SPECIALITY_GET_BY_ID_COMPLETED,
            payload: { speciality: {} }
        });
    }
}

function* addEapSpeciality(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    try {
        const results = yield call(EapService.createEapSpeciality, action.payload.params);
        if (results != null) {
            yield put({ type: MODAL_EAP_SPECIALITY_CREATE_HIDE });
            yield put({ type: EAP_SPECIALITY_CREATE_COMPLETED });
            yield put({
                type: EAP_SPECIALITY_FETCH_REQUESTED,
                payload: {}
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* updateEapSpeciality(action) {
    const {
        payload: { params }
    } = action;
    if (!params) {
        console.log('[EAP Saga] No params');
        return;
    }
    if (!params.speciality_id) {
        console.log('[EAP Saga] No Speciality');
        return;
    }
    try {
        const results = yield call(EapService.updateEapSpeciality, params);
        if (results != null) {
            yield put({ type: MODAL_EAP_SPECIALITY_EDIT_HIDE });
            yield put({ type: EAP_SPECIALITY_UPDATE_COMPLETED });
            yield put({
                type: EAP_SPECIALITY_GET_BY_ID_REQUESTED,
                payload: { speciality_id: params.speciality_id }
            });
        }
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* removeEapSpeciality(action) {
    const {
        payload: { speciality_id }
    } = action;
    if (!speciality_id) {
        console.log('[EAP Saga] No Focus');
        return;
    }
    try {
        yield call(EapService.removeEapSpeciality, speciality_id);
        yield put({
            type: EAP_SPECIALITY_GET_BY_ID_REQUESTED,
            payload: { speciality_id: speciality_id }
        });
        yield put({
            type: EAP_SPECIALITY_FETCH_REQUESTED,
            payload: {}
        });
    } catch (e) {
        console.error('[Assessment Saga]', e);
    }
}

function* EapSaga() {
    // eap focus
    yield takeLatest(EAP_FOCUS_FETCH_REQUESTED, fetchEapFocuses);
    yield takeLatest(EAP_FOCUS_GET_BY_ID_REQUESTED, getEapFocusDetail);
    yield takeLatest(EAP_FOCUS_CREATE_REQUESTED, addEapFocus);
    yield takeLatest(EAP_FOCUS_UPDATE_REQUESTED, updateEapFocus);
    yield takeLatest(EAP_FOCUS_REMOVE_REQUESTED, removeEapFocus);

    // focus keyword
    yield takeLatest(EAP_FOCUS_TAG_KEYWORD_ADD_REQUESTED, addFocusKeywordTag);
    yield takeLatest(EAP_FOCUS_TAG_KEYWORD_REMOVE_REQUESTED, removeFocusKeywordTag);

    // eap framework
    yield takeLatest(EAP_FRAMEWORK_FETCH_REQUESTED, fetchEapFrameworks);
    yield takeLatest(EAP_FRAMEWORK_GET_BY_ID_REQUESTED, getEapFrameworkDetail);
    yield takeLatest(EAP_FRAMEWORK_CREATE_REQUESTED, addEapFramework);
    yield takeLatest(EAP_FRAMEWORK_UPDATE_REQUESTED, updateEapFramework);
    yield takeLatest(EAP_FRAMEWORK_REMOVE_REQUESTED, removeEapFramework);

    // eap speciality
    yield takeLatest(EAP_SPECIALITY_FETCH_REQUESTED, fetchEapSpecialities);
    yield takeLatest(EAP_SPECIALITY_GET_BY_ID_REQUESTED, getEapSpecialityDetail);
    yield takeLatest(EAP_SPECIALITY_CREATE_REQUESTED, addEapSpeciality);
    yield takeLatest(EAP_SPECIALITY_UPDATE_REQUESTED, updateEapSpeciality);
    yield takeLatest(EAP_SPECIALITY_REMOVE_REQUESTED, removeEapSpeciality);
}

export default EapSaga;
