import React from 'react';
import { Modal, Card, Button, Divider, Image, Loader, Dimmer, Form, Segment } from 'semantic-ui-react';

import './index.css';

interface Props {
    isOpen: boolean;
    isLoading: boolean;
    onAdd: (any) => any;
    onClose: () => any;
}

class AddToolToolContentModal extends React.Component<Props> {
    state = {
        tool_id: null
    };

    resetState = () => {
        this.setState({
            tool_id: null
        });
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.tool_id != null) params['tool_id'] = this.state.tool_id;
        this.props.onAdd(params);
        this.resetState();
    };

    handleClose = () => {
        this.props.onClose();
        this.resetState();
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'tool_id':
                this.setState({
                    tool_id: inputValue
                });
                break;
        }
    };

    render() {
        return (
            <Modal closable={false} closeOnEscape={false} closeOnDimmerClick={false} open={this.props.isOpen} onClose={this.handleClose}>
                <Dimmer active={this.props.isLoading == true} inverted>
                    <Loader active={this.props.isLoading == true} />
                </Dimmer>
                <Modal.Header>Add Tool Card</Modal.Header>
                <Modal.Content>
                    <Form size="large" loading={false}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.tool_id || ''}
                                type="text"
                                name="tool_id"
                                placeholder="Tool ID"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={this.state.tool_id == null} onClick={this.handleSubmit} color="teal">
                        Add
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default AddToolToolContentModal;
