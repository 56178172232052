/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Card, Button, Divider, Segment, Form, Icon } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { connect } from 'react-redux';

import './index.css';
import { FormControl, InputLabel, MenuItem, Grid } from '@mui/material';
import ProgressSteps from 'components/customer/progressSteps';
const steps = [
    {
        label: 'Login',
        step: 1,
        title: 'Login in webflow and enter Neurum site',
        image: require('assets/images/deployment/deployment-1.png')
    },
    {
        label: 'CMS',
        step: 2,
        title: 'Head to the CMS section',
        image: require('assets/images/deployment/deployment-2.png')
    },
    {
        label: 'Click',
        step: 3,
        title: 'Click on "Deployments"',
        image: require('assets/images/deployment/deployment-3.png')
    },
    {
        label: 'Deployment',
        step: 4,
        title: 'Create a new "Deployment" (this generates a link for a company deployment)',
        image: require('assets/images/deployment/deployment-4.png')
    },
    {
        label: 'Register',
        step: 5,
        title: 'Enter required information (company code, name, blurb to announce partnership, etc...)',
        image: require('assets/images/deployment/deployment-5.png')
    },
    {
        label: 'Publish',
        step: 6,
        title: 'When all information is checked through, click add and publish'
    },
    {
        label: 'View',
        step: 7,
        title: 'Head to www.neurumhealth.com/deployment/<company name> to view your deployment link'
    }
];
class OrganizationDeploymentModal extends React.Component {
    state = {
        name: '',
        nameError: false,
        access_code: '',
        accessCodeError: false,
        name_en: '',
        name_yue: '',
        name_zh: '',
        account_limit: 0,
        is_archived: false
    };

    resetState = () => {
        this.setState({
            name: '',
            nameError: false,
            access_code: '',
            accessCodeError: false,
            name_en: '',
            name_yue: '',
            name_zh: '',
            account_limit: 0,
            is_activated: false
        });
    };

    componentDidMount() {
        const { organization, organization_id } = this.props;
        if (!organization || !organization_id) return;
        const { is_loading, data } = organization;
        if (!is_loading && data) {
            if (data.id === organization_id) {
                this.setState({
                    name: data.name,
                    access_code: data.access_code,
                    account_limit: data.account_limit,
                    is_activated: data.is_activated,
                    is_archived: data.has_dashboard
                });
            }
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        this.setState({
            [name]: inputValue
        });
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { isOpen, isLoading } = this.props;
        return (
            <Modal
                id="create-focus-modal"
                closeOnEscape={true}
                closeOnDimmerClick={true}
                open={isOpen}
                onClose={this.handleClose}
                size="large"
            >
                <Modal.Header id="deployment-modal-header">
                    Deployment Link Guide
                    <div>
                        <Button
                            disabled={isLoading == true}
                            color="teal"
                            onClick={() => {
                                window.open('https://webflow.com/design/neurum', '_blank');
                            }}
                        >
                            Open Webflow
                        </Button>
                        <div id="deployment-modal-header-desc">*will open a new browser window</div>
                    </div>
                </Modal.Header>
                <Modal.Content id="create-focus-modal-content">
                    <div id="deployment-content-title">How to create deployment link for new client</div>
                    <ProgressSteps steps={steps} />
                </Modal.Content>
                <Modal.Actions id="deployment-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organization: state.data.customer.organization
    };
};

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDeploymentModal);

OrganizationDeploymentModal.propTypes = {
    locale: PropTypes.string,
    version: PropTypes.any,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCreate: PropTypes.func
};
