import ContentService from '../../../../service/content';
import AssessmentService from '../../../../service/assessment';
import _ from 'lodash';

const isStringValid = (value) => {
    if (!!value && value !== '') {
        return true;
    }
    return false;
};

const isObjectValid = (value) => {
    return typeof value === 'object' && value !== null;
};

export const validateContent = async (tool) => {
    const validatedResult = {};
    const toolType = tool.type;
    const isSeries = toolType === 'series';
    const { title, description, emoji, metadata, time } = tool;
    const isTitleValid = isStringValid(title);
    const isDescriptionValid = isStringValid(description);
    const isMetadataValid = isStringValid(metadata);
    const isTimeValid = isStringValid(time);
    validatedResult.title = {
        isValid: isTitleValid,
        text: title
    };
    validatedResult.description = {
        isValid: isDescriptionValid,
        text: description
    };
    validatedResult.metadata = {
        isValid: isMetadataValid,
        text: metadata
    };
    validatedResult.time = {
        isValid: isTimeValid,
        text: time
    };
    const { exercises } = tool;
    if (isSeries) {
        const isRecommendedToolValid = {
            isValid: true
        };
        let isAssessmentValid = true;
        let isAssessmentQuestionsValid = false;
        let assessmentId;
        let recommendedToolValidation = [];
        let assessmentQuestions;
        let assessmentResults = [];
        const { recommended_tools } = tool;
        if (!recommended_tools?.length > 0) {
            isRecommendedToolValid.isValid = false;
        } else {
            for (const recommended_tool of recommended_tools) {
                const toolValidatedResult = await validateContent(recommended_tool);
                const {
                    title: toolTitle,
                    description: toolDescription,
                    emoji: toolEmoji,
                    metadata: toolMetadata,
                    time: toolTime,
                    technique: toolTechnique,
                    isValid,
                    exercises
                } = toolValidatedResult;
                recommendedToolValidation.push({
                    isValid,
                    id: recommended_tool?.id,
                    title: toolTitle,
                    description: toolDescription,
                    emoji: toolEmoji,
                    metadata: toolMetadata,
                    time: toolTime,
                    technique: toolTechnique,
                    exercises
                });
            }
        }
        validatedResult.recommendedTools = recommendedToolValidation;

        if (!exercises || !Array.isArray(exercises)) {
            isAssessmentValid = false;
        } else {
            if (exercises.length > 1) {
                isAssessmentValid = false;
            } else {
                const exercise = exercises[0];
                if (!exercise) {
                    isAssessmentValid = false;
                } else {
                    const { id } = exercise;
                    // getExerciseContent
                    const { content } = await ContentService.getExerciseContent(id);
                    if (!content) {
                        isAssessmentValid = false;
                    } else {
                        const { cards } = content;
                        if (!cards) {
                            isAssessmentValid = false;
                        } else {
                            const assessmentCards = cards.filter((card) => card.type === 'assessment');
                            if (assessmentCards?.length > 0) {
                                const assessmentCard = assessmentCards[0];
                                const assessmentCardContent = assessmentCard.content;
                                const { type, id } = assessmentCardContent;
                                if (type && id) {
                                    // isAssessmentQuestionsValid
                                    if (type === 'dimension') {
                                        try {
                                            const result = await AssessmentService.getAssessmentDimension(id);
                                            const dimension = result?.dimension;
                                            if (dimension?.questions != null) {
                                                const questions = dimension?.questions ?? [];
                                                assessmentQuestions = _.shuffle(questions);
                                                isAssessmentQuestionsValid = true;
                                            } else {
                                                isAssessmentQuestionsValid = false;
                                            }
                                        } catch (err) {
                                            console.error('[ExerciseAssessmentCard] Error getting assessment dimension', err);
                                            isAssessmentQuestionsValid = false;
                                        }
                                    } else if (type === 'source') {
                                        try {
                                            const result = await AssessmentService.getAssessmentSource(id);
                                            const source = result?.source;
                                            const dimensions = source?.dimensions ?? [];
                                            var questions = [];
                                            for (const dimension of dimensions) {
                                                const dimensionQuestions = dimension?.questions ?? [];
                                                questions = questions.concat(dimensionQuestions);
                                            }

                                            if (questions.length == 0) {
                                                isAssessmentQuestionsValid = false;
                                            } else {
                                                assessmentQuestions = _.shuffle(_.uniqBy(questions, 'id'));
                                                isAssessmentQuestionsValid = true;
                                            }
                                        } catch (err) {
                                            console.error('[ExerciseAssessmentCard] Error getting assessment source', err);
                                            isAssessmentQuestionsValid = false;
                                        }
                                    }
                                }
                            }
                            const assessmentResultCards = cards.filter((card) => card.type === 'assessment_result');
                            if (assessmentResultCards?.length > 0) {
                                for (const assessmentResultCard of assessmentResultCards) {
                                    const assessmentCardContent = assessmentResultCard.content;
                                    const { type, id } = assessmentCardContent;
                                    if (type && id) {
                                        if (type === 'dimension') {
                                            try {
                                                const result = await AssessmentService.getAssessmentDimensionResult(id);
                                                const assessmentResult = result?.result;
                                                if (assessmentResult && !!assessmentResult.category) {
                                                    assessmentResults.push(assessmentResult);
                                                } else if (assessmentResult && !!assessmentResult.categories) {
                                                    const { categories } = assessmentResult;
                                                    const nonNullCategories = categories.filter((category) => !!category);
                                                    assessmentResults.push(assessmentResult);
                                                    if (!nonNullCategories.length > 0) {
                                                        isAssessmentValid = false;
                                                    }
                                                } else {
                                                    isAssessmentValid = false;
                                                    assessmentResults.push(assessmentResult);
                                                }
                                            } catch (err) {
                                                console.error('[ExerciseAssessmentResultCard] Error getting assessment dimension', err);
                                                isAssessmentValid = false;
                                            }
                                        } else if (type === 'source') {
                                            try {
                                                const result = await AssessmentService.getAssessmentSourceResult(id);
                                                const assessmentResult = result?.result;
                                                if (assessmentResult && !!assessmentResult.category) {
                                                    assessmentResults.push(assessmentResult);
                                                } else if (assessmentResult && !!assessmentResult.categories) {
                                                    const { categories } = assessmentResult;
                                                    const nonNullCategories = categories.filter((category) => !!category);
                                                    assessmentResults.push(assessmentResult);
                                                    if (!nonNullCategories.length > 0) {
                                                        isAssessmentValid = false;
                                                    }
                                                } else {
                                                    isAssessmentValid = false;
                                                    assessmentResults.push(assessmentResult);
                                                }
                                            } catch (err) {
                                                console.error('[ExerciseAssessmentResultCard] Error getting assessment source', err);
                                                isAssessmentValid = false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            validatedResult.assessment = {
                isValid: isAssessmentValid,
                // text: assessmentId,
                text: assessmentResults
            };
            validatedResult.assessmentQuestion = {
                isValid: isAssessmentQuestionsValid,
                text: assessmentQuestions
            };
            let isValid =
                isAssessmentValid && isAssessmentQuestionsValid && isDescriptionValid && isTitleValid && isMetadataValid && isTimeValid;
            recommendedToolValidation.forEach((recommendedTool) => {
                if (!recommendedTool.isValid) isValid = false;
            });
            validatedResult.isValid = isValid;
        }
    } else {
        // else if (toolType === 'programme') {
        const isEmojiValid = isStringValid(emoji);
        validatedResult.emoji = {
            isValid: isEmojiValid,
            text: emoji
        };
        const { technique } = tool;
        const isTechniqueValid = isObjectValid(technique);
        validatedResult.technique = {
            isValid: isTechniqueValid,
            text: technique
        };
        validatedResult.exercises = [];
        let areExercisesValid = true;
        if (!exercises || !Array.isArray(exercises)) {
            //
            areExercisesValid = false;
        } else {
            for (const exercise of exercises) {
                const { id, time, metadata, title, description_long: description, session_type } = exercise;
                const isTimeValid = isStringValid(time);
                const isMetadataValid = isStringValid(metadata);
                const isTitleValid = isStringValid(title);
                const isDescriptionValid = isStringValid(description);
                const isContentTypeValid = isStringValid(session_type);
                let isExerciseValid = true;
                if (!isTimeValid) {
                    isExerciseValid = false;
                }
                if (!isMetadataValid) {
                    isExerciseValid = false;
                }
                if (!isTitleValid) {
                    isExerciseValid = false;
                }
                if (!isDescriptionValid) {
                    isExerciseValid = false;
                }
                if (!isContentTypeValid) {
                    isExerciseValid = false;
                }

                const exerciseValidation = {
                    isValid: isExerciseValid,
                    title: {
                        isValid: isTitleValid,
                        text: title
                    },
                    description: {
                        isValid: isDescriptionValid,
                        text: description
                    },
                    time: {
                        isValid: isTimeValid,
                        text: time
                    },
                    metadata: {
                        isValid: isMetadataValid,
                        text: metadata
                    },
                    contentType: {
                        isValid: isContentTypeValid,
                        text: session_type
                    }
                };
                areExercisesValid = isExerciseValid;
                // getExerciseContent
                const { content } = await ContentService.getExerciseContent(id);
                const { cards } = content;
                if (!cards) {
                    areExercisesValid = false;
                    validatedResult.exercises.push({
                        id,
                        ...exerciseValidation,
                        isValid: false
                    });
                } else {
                    validatedResult.exercises.push({
                        id,
                        ...exerciseValidation,
                        isValid: isExerciseValid
                    });
                }
            }
        }
        validatedResult.isValid =
            isTitleValid && isDescriptionValid && isMetadataValid && isTimeValid && isEmojiValid && isTechniqueValid && areExercisesValid;
    }
    return validatedResult;
};
