import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import ReactDropzone from 'react-dropzone';
import ContentService from '../../../../service/content';

import './index.css';

interface Props {
    locale: string;
    quote?: object;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class EditQuoteModal extends React.Component<Props> {
    state = {
        is_uploading: false,
        title: null,
        description: null,
        metadata: null,
        emoji: null,
        icon_name: null,
        icon_url: null,
        cover_url: null,
        time: null,
        has_audio: null,
        has_read: null,
        has_input: null,
        has_activity: null,
        has_background: null
    };

    componentDidMount() {
        const { quote } = this.props;
        if (quote != null) {
            this.setState({
                quote_id: quote.id,
                author: quote.author,
                message: quote.message
            });
        }
    }

    resetState = () => {
        this.setState({
            is_uploading: false,
            quote_id: null,
            author: null,
            message: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'author':
                this.setState({
                    author: inputValue
                });
                break;
            case 'message':
                this.setState({
                    message: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        if (this.state.author != null) params['author'] = this.state.author;
        if (this.state.message != null) params['message'] = this.state.message;

        this.props.onEdit(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    render() {
        const { locale, isOpen, isLoading } = this.props;
        return (
            <Modal
                id="edit-tool-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="edit-tool-modal-header">Edit Quote</Modal.Header>
                <Modal.Content id="edit-tool-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <Form.Input
                                value={this.state.author || ''}
                                type="text"
                                name="author"
                                placeholder="Author"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.message || ''}
                                rows="2"
                                type="text"
                                name="message"
                                placeholder="Message"
                                onChange={this.handleInputChange}
                            />
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="edit-tool-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Edit Quote
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EditQuoteModal;
