import React from 'react';
import { Modal, Card, Button, Divider, Form, Segment } from 'semantic-ui-react';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';

import './index.css';

interface Props {
    locale: string;
    version: any;
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => any;
    onEdit: (any) => any;
}

class CreateToolModal extends React.Component<Props> {
    state = {
        tool_type_is_immediate: false,
        is_series: false, // check if programmes or series
        content_type: 'tool',
        title: null,
        description: null,
        metadata: null,
        emoji: null,
        icon_name: null,
        icon_url: null,
        cover_url: null,
        time: null,
        has_audio: null,
        has_read: null,
        has_input: null,
        has_activity: null,
        has_background: null
    };

    resetState = () => {
        this.setState({
            title: null,
            description: null,
            metadata: null,
            emoji: null,
            icon_name: null,
            icon_url: null,
            cover_url: null,
            time: null,
            has_audio: null,
            has_read: null,
            has_input: null,
            has_activity: null,
            has_background: null
        });
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        var inputValue = value;
        if (value == '') {
            inputValue = null;
        }
        switch (name) {
            case 'title':
                this.setState({
                    title: inputValue
                });
                break;
            case 'description':
                this.setState({
                    description: inputValue
                });
                break;
            case 'metadata':
                this.setState({
                    metadata: inputValue
                });
                break;
            case 'emoji':
                this.setState({
                    emoji: inputValue
                });
                break;
            case 'icon_name':
                this.setState({
                    icon_name: inputValue
                });
                break;
            case 'icon_url':
                this.setState({
                    icon_url: inputValue
                });
                break;
            case 'cover_url':
                this.setState({
                    cover_url: inputValue
                });
                break;
            case 'time':
                this.setState({
                    time: inputValue
                });
                break;
        }
    };

    handleSubmit = () => {
        var params = {};
        params['version'] = this.props.version;
        params['is_immediate'] = this.state.tool_type_is_immediate;
        params['is_series'] = this.state.is_series;
        params['content_type'] = this.state.content_type;
        if (this.state.title != null) params['title'] = this.state.title;
        if (this.state.description != null) params['description'] = this.state.description;
        if (this.state.metadata != null) params['metadata'] = this.state.metadata;
        if (this.state.emoji != null) params['emoji'] = this.state.emoji;
        if (this.state.icon_name != null) params['icon_name'] = this.state.icon_name;
        if (this.state.icon_url != null) params['icon_url'] = this.state.icon_url;
        if (this.state.cover_url != null) params['cover_url'] = this.state.cover_url;
        if (this.state.time != null) params['time'] = parseInt(this.state.time);
        if (this.state.has_audio != null) params['has_audio'] = this.state.has_audio;
        if (this.state.has_read != null) params['has_read'] = this.state.has_read;
        if (this.state.has_input != null) params['has_input'] = this.state.has_input;
        if (this.state.has_activity != null) params['has_activity'] = this.state.has_activity;
        if (this.state.has_background != null) params['has_background'] = this.state.has_background;

        this.props.onCreate(params);
        this.resetState();
    };

    handleClose = () => {
        this.resetState();
        this.props.onClose();
    };

    onToolTypeUpdated = (value) => {
        this.setState({ tool_type_is_immediate: value == 'tool', is_series: value == 'series', content_type: value });
    };

    render() {
        const { isOpen, isLoading, version } = this.props;
        return (
            <Modal
                id="create-tool-modal"
                closable={false}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={isOpen}
                onClose={this.handleClose}
            >
                <Modal.Header id="create-tool-modal-header">Create Content</Modal.Header>
                <Modal.Content id="create-tool-modal-content">
                    <Form size="large" loading={isLoading}>
                        <Segment basic>
                            <div id={'tool-type-selector'}>
                                <RadioGroup value={this.state.content_type} onChange={this.onToolTypeUpdated} horizontal>
                                    <RadioButton value={'tool'}>Moment</RadioButton>
                                    <RadioButton value={version > 1 ? 'programme' : 'tip'}>Programme</RadioButton>
                                    {
                                        <RadioButton disabled={version == 1} value={'series'}>
                                            Series
                                        </RadioButton>
                                    }
                                </RadioGroup>
                            </div>
                            <Form.Input
                                value={this.state.title || ''}
                                type="text"
                                name="title"
                                placeholder="Title"
                                onChange={this.handleInputChange}
                            />
                            <Form.TextArea
                                value={this.state.description || ''}
                                rows="2"
                                type="text"
                                name="description"
                                placeholder="Description"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.metadata || ''}
                                type="text"
                                name="metadata"
                                placeholder="Metadata (i.e. 3 mins)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.emoji || ''}
                                type="text"
                                name="emoji"
                                placeholder="Emoji Icon"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.icon_name || ''}
                                type="text"
                                name="icon_name"
                                placeholder="Icon (Font Awesome)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.cover_url || ''}
                                type="text"
                                name="cover_url"
                                placeholder="Cover URL (Optional)"
                                onChange={this.handleInputChange}
                            />
                            <Form.Input
                                value={this.state.time || ''}
                                type="text"
                                name="time"
                                placeholder="Time (seconds)"
                                onChange={this.handleInputChange}
                            />
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_audio"
                                        checked={this.state.has_audio || false}
                                        onChange={() => {
                                            this.setState({
                                                has_audio: !this.state.has_audio
                                            });
                                        }}
                                    />
                                    <label>Has Audio</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_read"
                                        checked={this.state.has_read || false}
                                        onChange={() => {
                                            this.setState({
                                                has_read: !this.state.has_read
                                            });
                                        }}
                                    />
                                    <label>Has Read</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_input"
                                        checked={this.state.has_input || false}
                                        onChange={() => {
                                            this.setState({
                                                has_input: !this.state.has_input
                                            });
                                        }}
                                    />
                                    <label>Has Input</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_activity"
                                        checked={this.state.has_activity || false}
                                        onChange={() => {
                                            this.setState({
                                                has_activity: !this.state.has_activity
                                            });
                                        }}
                                    />
                                    <label>Has Activity</label>
                                </div>
                            </div>
                            <div className="inline field">
                                <div className="ui toggle checkbox">
                                    <input
                                        type="checkbox"
                                        name="has_background"
                                        checked={this.state.has_background || false}
                                        onChange={() => {
                                            this.setState({
                                                has_background: !this.state.has_background
                                            });
                                        }}
                                    />
                                    <label>Meant for Background?</label>
                                </div>
                            </div>
                        </Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions id="create-tool-modal-action-container">
                    <Button onClick={this.handleClose}>Dismiss</Button>
                    <Button disabled={isLoading == true} onClick={this.handleSubmit} color="teal">
                        Create Content
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CreateToolModal;
