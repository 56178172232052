import {
    CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED,
    CUSTOMER_ORGANIZATION_ACTIVATE_USER_COMPLETED,
    CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED,
    CUSTOMER_ORGANIZATION_EXPIRE_USER_COMPLETED,
    CUSTOMER_ORGANIZATION_FETCH_COMPLETED,
    CUSTOMER_ORGANIZATION_FETCH_IS_LOADING,
    CUSTOMER_ORGANIZATION_FETCH_NOT_LOADING,
    CUSTOMER_ORGANIZATION_GET_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_IS_LOADING,
    CUSTOMER_ORGANIZATION_GET_NOT_LOADING,
    CUSTOMER_ORGANIZATION_GET_USERS_COMPLETED,
    CUSTOMER_ORGANIZATION_GET_USERS_IS_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING,
    CUSTOMER_ORGANIZATION_MIND_HEALTH_NOT_LOADING,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED,
    CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING
} from '../constants/customer';

const initialState = {
    organizations: {
        is_loading: false,
        data: []
    },
    organization: {
        is_loading: false,
        data: {}
    },
    organization_users: {
        is_loading: false,
        data: {}
    },
    statistics_accounts_registered: {
        is_loading: false,
        data: {}
    },
    statistics_mind_health: {
        is_loading: false,
        data: {}
    },
    statistics_support_engaged: {
        is_loading: false,
        data: {}
    },
    statistics_care_credit: {
        is_loading: false,
        data: {}
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CUSTOMER_ORGANIZATION_FETCH_IS_LOADING:
            return {
                ...state,
                organizations: {
                    ...state.organizations,
                    is_loading: true
                }
            };
        case CUSTOMER_ORGANIZATION_FETCH_NOT_LOADING:
            return {
                ...state,
                organizations: {
                    ...state.organizations,
                    is_loading: false
                }
            };
        case CUSTOMER_ORGANIZATION_FETCH_COMPLETED:
            return {
                ...state,
                organizations: {
                    ...state.organizations,
                    data: action.payload.organizations || []
                }
            };

        case CUSTOMER_ORGANIZATION_GET_IS_LOADING:
            return {
                ...state,
                organization: {
                    data: {},
                    is_loading: true
                }
            };
        case CUSTOMER_ORGANIZATION_GET_NOT_LOADING:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    is_loading: false
                }
            };
        case CUSTOMER_ORGANIZATION_GET_COMPLETED:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    data: action.payload.organization || {}
                }
            };

        case CUSTOMER_ORGANIZATION_ACCOUNTS_REGISTERED_FETCHED:
            return {
                ...state,
                statistics_accounts_registered: {
                    is_loading: false,
                    data: action.payload
                }
            };
        case CUSTOMER_ORGANIZATION_MIND_HEALTH_IS_LOADING:
            return {
                ...state,
                statistics_mind_health: {
                    data: {},
                    is_loading: true
                }
            };

        case CUSTOMER_ORGANIZATION_MIND_HEALTH_FETCHED:
            return {
                ...state,
                statistics_mind_health: {
                    is_loading: false,
                    data: action.payload
                }
            };

        case CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_IS_LOADING:
            return {
                ...state,
                statistics_support_engaged: {
                    data: {},
                    is_loading: true
                }
            };

        case CUSTOMER_ORGANIZATION_SUPPORT_ENGAGED_FETCHED:
            return {
                ...state,
                statistics_support_engaged: {
                    is_loading: false,
                    data: action.payload
                }
            };

        case CUSTOMER_ORGANIZATION_GET_USERS_IS_LOADING: {
            return {
                ...state,
                organization_users: {
                    is_loading: true
                }
            };
        }

        case CUSTOMER_ORGANIZATION_GET_USERS_COMPLETED: {
            return {
                ...state,
                organization_users: {
                    is_loading: false,
                    data: {
                        organization_id: action.payload.organization_id,
                        users: action.payload.users
                    }
                }
            };
        }

        case CUSTOMER_ORGANIZATION_EXPIRE_USER_COMPLETED: {
            const user_id = action.payload.user_id;
            // find user
            const prevUsers = [...state.organization_users?.data?.users];
            const selectedUser = prevUsers.find((user) => user.id === user_id);
            selectedUser.expires_at = new Date();
            return {
                ...state,
                organization_users: {
                    is_loading: false,
                    data: {
                        organization_id: action.payload.organization_id,
                        users: prevUsers
                    }
                }
            };
        }

        case CUSTOMER_ORGANIZATION_ACTIVATE_USER_COMPLETED: {
            const user_id = action.payload.user_id;
            // find user
            const prevUsers = [...state.organization_users?.data?.users];
            const selectedUser = prevUsers.find((user) => user.id === user_id);
            selectedUser.expires_at = null;
            return {
                ...state,
                organization_users: {
                    is_loading: false,
                    data: {
                        organization_id: action.payload.organization_id,
                        users: [...state.organization_users.data.users]
                    }
                }
            };
        }

        case CUSTOMER_ORGANIZATION_CARE_CREDITS_FETCHED: {
            return {
                ...state,
                statistics_care_credit: {
                    is_loading: false,
                    data: action.payload.care_credit
                }
            };
        }

        default:
            return state;
    }
}
