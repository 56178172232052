// Assessments
export const ASSESSMENT_SOURCE_GET_REQUESTED = 'assessment/SOURCE_GET_REQUESTED';
export const ASSESSMENT_SOURCE_GET_COMPLETED = 'assessment/SOURCE_GET_COMPLETED';
export const ASSESSMENT_SOURCE_FETCH_REQUESTED = 'assessment/SOURCE_FETCH_REQUESTED';
export const ASSESSMENT_SOURCE_FETCH_COMPLETED = 'assessment/SOURCE_FETCH_COMPLETED';
export const ASSESSMENT_SOURCE_CREATE_REQUESTED = 'assessment/SOURCE_CREATE_REQUESTED';
export const ASSESSMENT_SOURCE_CREATE_COMPLETED = 'assessment/SOURCE_CREATE_COMPLETED';
export const ASSESSMENT_SOURCE_EDIT_REQUESTED = 'assessment/SOURCE_EDIT_REQUESTED';
export const ASSESSMENT_SOURCE_EDIT_COMPLETED = 'assessment/SOURCE_EDIT_COMPLETED';
export const ASSESSMENT_SOURCE_DELETE_REQUESTED = 'assessment/SOURCE_DELETE_REQUESTED';
export const ASSESSMENT_SOURCE_DELETE_COMPLETED = 'assessment/SOURCE_DELETE_COMPLETED';

export const ASSESSMENT_DIMENSION_CREATE_REQUEST = 'assessment/DIMENSION_CREATE_REQUESTED';
export const ASSESSMENT_DIMENSION_CREATE_COMPLETED = 'assessment/DIMENSION_CREATE_COMPLETED';
export const ASSESSMENT_DIMENSION_EDIT_REQUEST = 'assessment/DIMENSION_EDIT_REQUESTED';
export const ASSESSMENT_DIMENSION_EDIT_COMPLETED = 'assessment/DIMENSION_EDIT_COMPLETED';
export const ASSESSMENT_DIMENSION_DELETE_REQUEST = 'assessment/DIMENSION_DELETE_REQUESTED';
export const ASSESSMENT_DIMENSION_DELETE_COMPLETED = 'assessment/DIMENSION_DELETE_COMPLETED';

export const ASSESSMENT_DIMENSION_MODULE_CREATE_REQUEST = 'assessment/DIMENSION_MODULE_CREATE_REQUESTED';
export const ASSESSMENT_DIMENSION_MODULE_CREATE_COMPLETED = 'assessment/DIMENSION_MODULE_CREATE_COMPLETED';

export const ASSESSMENT_DIMENSION_MODULE_REMOVE_REQUEST = 'assessment/DIMENSION_MODULE_REMOVE_REQUESTED';
export const ASSESSMENT_DIMENSION_MODULE_REMOVE_COMPLETED = 'assessment/DIMENSION_MODULE_REMOVE_COMPLETED';

export const ASSESSMENT_CATEGORY_CREATE_REQUEST = 'assessment/CATEGORY_CREATE_REQUEST';
export const ASSESSMENT_CATEGORY_CREATE_COMPLETED = 'assessment/CATEGORY_CREATE_COMPLETED';
export const ASSESSMENT_CATEGORY_EDIT_REQUEST = 'assessment/CATEGORY_EDIT_REQUEST';
export const ASSESSMENT_CATEGORY_EDIT_COMPLETED = 'assessment/CATEGORY_EDIT_COMPLETED';
export const ASSESSMENT_CATEGORY_DELETE_REQUEST = 'assessment/CATEGORY_DELETE_REQUEST';
export const ASSESSMENT_CATEGORY_DELETE_COMPLETED = 'assessment/CATEGORY_DELETE_COMPLETED';

export const ASSESSMENT_QUESTION_CREATE_REQUEST = 'assessment/QUESTION_CREATE_REQUEST';
export const ASSESSMENT_QUESTION_CREATE_COMPLETED = 'assessment/QUESTION_CREATE_COMPLETED';

export const ASSESSMENT_QUESTION_EDIT_REQUEST = 'assessment/QUESTION_EDIT_REQUEST';
export const ASSESSMENT_QUESTION_EDIT_COMPLETED = 'assessment/QUESTION_EDIT_COMPLETED';
export const ASSESSMENT_QUESTION_DELETE_REQUEST = 'assessment/QUESTION_DELETE_REQUEST';
export const ASSESSMENT_QUESTION_DELETE_COMPLETED = 'assessment/QUESTION_DELETE_COMPLETED';

export const ASSESSMENT_DIMENSION_ANSWER_CREATE_REQUEST = 'assessment/DIMENSION_ANSWER_CREATE_REQUEST';
export const ASSESSMENT_DIMENSION_ANSWER_CREATE_COMPLETED = 'assessment/DIMENSION_ANSWER_CREATE_COMPLETED';
export const ASSESSMENT_ANSWER_ADD_REQUEST = 'assessment/ANSWER_ADD_REQUEST';
export const ASSESSMENT_ANSWER_ADD_COMPLETED = 'assessment/ANSWER_ADD_COMPLETED';
export const ASSESSMENT_ANSWER_EDIT_REQUEST = 'assessment/ANSWER_EDIT_REQUEST';
export const ASSESSMENT_ANSWER_EDIT_COMPLETED = 'assessment/ANSWER_EDIT_COMPLETED';
export const ASSESSMENT_ANSWER_DELETE_REQUEST = 'assessment/ANSWER_DELETE_REQUESTED';
export const ASSESSMENT_ANSWER_DELETE_COMPLETED = 'assessment/ANSWER_DELETE_COMPLETED';
